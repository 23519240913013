

// import axios from 'axios';

// export const getYoutubeVideos = async ( offset = 1, limit = 10) => {
//   const userDetails = localStorage.getItem("userDetails");

//   if (!userDetails) {
//     console.error("User details not found in local storage.");
//     return null;
//   }

//   let userData;
//   try {
//     userData = JSON.parse(userDetails);
//   } catch (error) {
   
//     return null;
//   }

//   if (!userData?.access_token) {
  
//     return null;
//   }

//   const token = `Bearer ${userData.access_token}`;
  

//   const headers = {
//     Authorization: token,
//     'Content-Type': 'application/json'
//   };

//   const url = `${process.env.REACT_APP_BASEURL}backoffice/ytvideos?offset=${offset}&limit=${limit}`;

//   try {
//     const response = await axios.get(url, { headers });

//     if (response?.data?.success) {
//       return response.data;
//     }
//     console.error("Error: Response indicates failure:", response?.data?.description);
//     return null;
//   } catch (error) {
//     console.error("Error fetching withdrawals:", error);
//     return null;
//   }
// };



// import axios from 'axios';

// export const getYoutubeVideos = async (offset = 1, limit = 10) => {
//   const userDetails = localStorage.getItem("userDetails");

//   if (!userDetails) {
//     console.error("User details not found in local storage.");
//     return null;
//   }

//   let userData;
//   try {
//     userData = JSON.parse(userDetails);
//   } catch (error) {
//     console.error("Error parsing user details:", error);
//     return null;
//   }

//   if (!userData?.access_token) {
//     console.error("Access token not found.");
//     return null;
//   }

//   const token = `Bearer ${userData.access_token}`;
//   const headers = {
//     Authorization: token,
//     'Content-Type': 'application/json'
//   };

//   const url = `${process.env.REACT_APP_BASEURL}backoffice/ytvideos?offset=${offset}&limit=${limit}`;

//   try {
//     const response = await axios.get(url, { headers });

//     if (response?.data?.success) {
//       console.log("API Response:", response.data);
//       return response.data;
//     }

//     console.error("Error: Response indicates failure:", response?.data?.description);
//     return null;
//   } catch (error) {
//     console.error("Error fetching videos:", error);
//     return null;
//   }
// };
import axios from 'axios';

export const getYoutubeVideos = async (offset = 1, limit = 10) => {
  const userDetails = localStorage.getItem("userDetails");

  if (!userDetails) {
    console.error("User details not found in local storage.");
    return null;
  }

  let userData;
  try {
    userData = JSON.parse(userDetails);
  } catch (error) {
    console.error("Error parsing user details:", error);
    return null;
  }

  if (!userData?.access_token) {
    console.error("Access token not found.");
    return null;
  }

  const token = `Bearer ${userData.access_token}`;
  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  };

  const url = `${process.env.REACT_APP_BASEURL}backoffice/ytvideos?offset=${offset}&limit=${limit}`;

  try {
    const response = await axios.get(url, { headers });

    if (response?.data?.success) {
      console.log("API Response:", response.data);
      return response.data;
    }

    console.error("Error: Response indicates failure:", response?.data?.description);
    return null;
  } catch (error) {
    console.error("Error fetching videos:", error);
    return null;
  }
};







export const createYtVideoForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");

  if (!userDetails) {
    console.error("User details not found in local storage.");
    return null;
  }

  let userData;
  try {
    userData = JSON.parse(userDetails);
  } catch (error) {
    console.error("Error parsing user details:", error);
    return null;
  }

  if (!userData?.access_token) {
    console.error("Access token not found.");
    return null;
  }

  const token = `Bearer ${userData.access_token}`;

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  };

  const url = `${process.env.REACT_APP_BASEURL}backoffice/ytvideos`;

  try {
    const response = await axios.post(url, formData, { headers });

    if (response?.data?.success) {
      return response.data;
    }
    console.error("Error: Response indicates failure:", response?.data?.description);
    return null;
  } catch (error) {
    console.error("Error creating banner:", error);
    return null;
  }
};




export const UploadFiles = async (formData, withUpload = true) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  try {
    let config = {};
    if (withUpload) {
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/store/files`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

