
import axios from "axios";

export const createVillages = async (formData, mandalId) => {
    const userDetails = localStorage.getItem("userDetails");
    const userData = JSON.parse(userDetails);
    const token = `Bearer ${userData?.access_token}`;
    const headers = {
      Authorization: token,
      "content-type": "application/json",
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages?mandal_id=${mandalId}`,
        formData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Failed to submit form data" };
    }
  };
  
  

// export const getVillages = async (offset =1, limit =10 , id = null, ) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;
//   if (id) {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages?mandal_id=${id}`, 
//       {
//         headers,
//       }
//     );
//   } else {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages?&offset=${offset}&limit=${limit}`,
//       {
//         headers,
//       }
//     );
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }
//   return null;
// };

// In your villages.js or API utility file


export const getVillages = async (stateId, offset, limit, mandalId) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  let url = `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages?offset=${offset}&limit=${limit}`;

  if (stateId) {
    url += `&state_id=${stateId}`;
  }

  if (mandalId) {
    url += `&mandal_id=${mandalId}`;
  }

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching villages:", error);
    return { success: false, message: "Failed to fetch villages" };
  }
};




export const getMandalList = async (offset=1, limit=10 , id = null, ) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let response;
  if (id) {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?district_id=${id}`, 
      {
        headers,
      }
    );
  } else {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?&offset=${offset}&limit=${limit}`,
      {
        headers,
      }
    );
  }

  if (response?.data?.success) {
    return response.data;
  }
  return null;
};


export const deleteVillage = async (id, offset = 1, limit = 10) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .delete(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages/${id}`,
      {
        headers,
      }
    )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("Error deleting district:", error);
      throw error;
    });
};


// export const getMandalsData = async (offset = 1, limit = 10, id = null, districtId = null) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;
//   if (id) {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?district_id=${districtId}`,
//       {
//         headers,
//       }
//     );
//   } else {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?offset=${offset}&limit=${limit}`,
//       {
//         headers,
//         params: {
//           district_id: districtId,
//         },
//       }
//     );
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }
//   return null;
// };
export const getMandalsData = async (offset = 1, limit = 60, id = null, districtId = null) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let response;
  if (id) {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?district_id=${districtId}`,
      {
        headers,
      }
    );
  } else {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?offset=${offset}&limit=${limit}`,
      {
        headers,
        params: {
          district_id: districtId,
        },
      }
    );
  }

  if (response?.data?.success) {
    return response.data;
  }
  return null;
};


