import { Uploader } from "../../../utils/upload";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Box, styled, Card, Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const video_resolutions = ["240", "360", "480", "720", "1080"];

function UploadPopup({ source, setOpen }) {
  const [file, setFile] = useState(undefined);
  const [pgvalue, setPgvalue] = useState("-");
  const [perf, setPerf] = useState(undefined);
  const [uploadName, setUploadName] = useState("");
  const [selectedResolutions, setSelectedResolutions] = useState([]);

  useEffect(() => {
    // console.log(pgvalue);
    if (pgvalue === 100) {
      // console.log("Upload completed successfully");
      setOpen(false);
      setUploadName("");
    }
  }, [pgvalue]);

  const handleUploadName = (event) => {
    setUploadName(event.target.value);
  };

  function handleClose() {
    setOpen(false);
    setUploadName("");
  }

  const handleResolutionsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedResolutions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSubmission = async () => {
    if (file) {
      const uploaderOptions = {
        file: file,
        baseURL: "https://9ydog8fx37.execute-api.ap-south-1.amazonaws.com/prod",
        chunkSize: 50,
        threadsQuantity: 5,
        useTransferAcceleration: true,
        uploadName: uploadName,
        environment: "development",
        source: source,
        selectedResolutions: selectedResolutions,
      };

      let percentage = undefined;
      setPgvalue(0);
      setPerf("-");
      const uploader = new Uploader(uploaderOptions);
      const tBegin = performance.now();
      uploader
        .onProgress(({ percentage: newPercentage }) => {
          // to avoid the same percentage to be logged twice
          if (percentage === 100) {
            setPerf((performance.now() - tBegin) / 1000);
          }
          if (newPercentage !== percentage) {
            percentage = newPercentage;
            setPgvalue(percentage);
          }
        })
        .onError((error) => {
          setFile(undefined);
          // console.error(error);
        });

      uploader.start();
    }
  };

  return (
    <div>
      <div style={{ padding: "20px" }}>
        <TextField
          autoFocus
          id="name"
          label="Upload Name"
          type="text"
          fullWidth
          onChange={handleUploadName}
          value={uploadName}
          sx={{ mb: 5 }}
        />
        <TextField
          autoFocus
          id="source"
          label="Upload Type"
          type="text"
          value={source}
          sx={{ width: "47%", mr: "3%", mb: 2 }}
          disabled
        />
        {source === "VIDEOS" && (
          <FormControl sx={{ width: "50%", mb: 2 }}>
            <InputLabel id="multiple-checkbox-label">
              Conversion Resolutions
            </InputLabel>
            <Select
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              multiple
              // label="Video Conversion Resolutions"
              value={selectedResolutions}
              onChange={handleResolutionsChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {video_resolutions.map((resolution) => (
                <MenuItem key={resolution} value={resolution}>
                  <Checkbox
                    checked={selectedResolutions.indexOf(resolution) > -1}
                  />
                  <ListItemText primary={resolution} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
      <div style={{ padding: "20px", margin: "10px" }}>
        <strong style={{ display: "block" }}>Choose a file to upload</strong>
        <br />
        <Box>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              onChange={(e) => {
                setFile(e.target?.files?.[0]);
              }}
            />
          </Button>
        </Box>
        <DialogContent>{file ? file.name : <></>}</DialogContent>

        <strong style={{ display: "block" }}>Upload Progress</strong>
        <br />
        <span id="output">
          {pgvalue}% ({perf} sec)
        </span>
      </div>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmission}
          color="primary"
          variant="contained"
          disabled={!(selectedResolutions && file && uploadName)}
        >
          Submit
        </Button>
      </DialogActions>
    </div>
  );
}

export default UploadPopup;
