import axios from "axios";

// export const getDistricts = async (id, stateID) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;

//   response = await axios.get(
//     `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//     {
//       headers,
//     }
//   );

//   if (response?.data?.success) {
//     return response.data;
//   }

//   return null;
// };


// export const getDistricts = async (offset, limit, id, stateId) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   const params = {
//     offset,
//     limit,
//     id,
//     stateId,
//   };

//   let response;

//   try {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//       {
//         headers,
//         params,
//       }
//     );
//   } catch (error) {
//     console.error('Error fetching districts:', error);
//     return null;
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }

//   return null;
// };



export const getDistricts = async (offset, limit, id, stateId) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  const params = {
    offset,
    limit, 
    id,
    stateId,
  };

  let response;

  try {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${stateId}`,
      {
        headers,
        params,
        ...(stateId ) // changed here
      }
    );
  } catch (error) {
    console.error('Error fetching districts:', error);
    return null;
  }

  if (response?.data?.success) {
    return response.data;
  }

  return null;
};


export const createDistricts = async (formData, id) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${id}`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

export const deleteDistrict = async (id, offset = 1, limit = 10) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .delete(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/${id}`,
      {
        headers,
      }
    )
    .then((response) => {
      console.log(response);
      return response;
    })
    .catch((error) => {
      console.error("Error deleting district:", error);
      throw error;
    });
};
