import React from "react";
import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export default function CreateStates({ formData, handleStateChange }) {
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman & Nicobar (UT)",
    "Chandigarh (UT)",
    "Dadra & Nagar Haveli and Daman & Diu (UT)",
    "Delhi [National Capital Territory (NCT)]",
    "Jammu & Kashmir (UT)",
    "Ladakh (UT)",
    "Lakshadweep (UT)",
    "Puducherry (UT)",
  ];

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <Autocomplete
        multiple
        options={states}
        value={formData.state ? formData.state : []}
        onChange={handleStateChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="State"
            placeholder="Select State"
          />
        )}
      />
    </FormControl>
  );
}
