


// export const getAdds = async (offset, limit , id = null) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;
//   if (id) {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}backoffice/advertisement/ads?id=${id}`, 
//       {
//         headers,
//       }
//     );
//   } else {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}backoffice/advertisement/ads?&offset=${offset}&limit=${limit}`,
//       {
//         headers,
//       }
//     );
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }
//   return null;
// };


import axios from "axios";

export const getAdds = async (
  offset = 1,
  limit = 10,
  id = null,
 
) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let url = `${process.env.REACT_APP_BASEURL}backoffice/advertisement/ads`;

  const params = {
    offset,
    limit,
    id, 
  };

 

  try {
    const response = await axios.get(url, {
      params,
      headers,
    });

    if (response?.data?.success) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching news:", error);
    return null;
  }
};


// ads form post api




export const createAdds = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}backoffice/advertisement/ads`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};


// for local storage 
export const UploadAddsFiles = async (formData, withUpload = true) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  try {
    let config = {};
    if (withUpload) {
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/store/files`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};


export const updateAdds = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASEURL}backoffice/advertisement/ads`,
      formData,
      { headers }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};