

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TablePagination,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Avatar,
//   Box,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
// import { getBanners } from "./banner";
// import BannersForm from "./bannersform";
// import { Edit } from "@mui/icons-material";
// import Popup from "./popup";

// const BannersTable = ({ filters, setFilters }) => {
//   const navigate = useNavigate();
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);
//   const [bannersListData, setBannersListData] = useState(null);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [openPopup, setOpenPopup] = useState(false);

//   useEffect(() => {
//     async function getBannerDetails() {
//       const data = await getBanners(
//         filters.offset,
//         filters.limit,
//         filters.id
//       );
//       setBannersListData(data);
//     }
//     getBannerDetails();
//   }, [filters.offset, filters.limit, filters.id]);

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-banners");
//     console.log("Create button clicked");
//   };

//   const handleChangePage = (event, newPage) => {
//     setFilters({ ...filters, offset: newPage + 1 });
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setFilters({
//       ...filters,
//       limit: parseInt(event.target.value, 10),
//       offset: 1,
//     });
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getBanners(setBannersListData, filters.offset, filters.limit);
//     setShowForm(false);
//   };

//   const handleEditClick = (id) => {
//     navigate(`/dhunia_bo/edit-banners/${id}`);
//   };

//   const handleImageClick = (images) => {
//     setSelectedImages(images);
//     setOpenPopup(true);
//   };

//   return (
//     <>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         marginBottom="20px"
//         paddingLeft="20px"
//         paddingRight="20px"
//       >
//         <Typography variant="h3" fontWeight="bold" paddingLeft="10px">
//           BANNERS
//         </Typography>
//         <Button
//           variant="contained"
//           style={{ paddingRight: "20px", marginRight: "20px" }}
//           color="primary"
//           onClick={handleCreateClick}
//         >
//           Create
//         </Button>
//       </Box>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableBody>
//             {bannersListData?.info?.map((row, index) => (
//               <TableRow key={index}>
//                 <TableCell align="center" style={{ width: "40%" }}>
//                   {Array.isArray(row.images) ? (
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       onClick={() => handleImageClick(row.images)}
//                       style={{ cursor: "pointer" }}
//                     >
//                       {row.images.map((image, i) => (
//                         <Avatar
//                           key={i}
//                           alt={`Image ${i}`}
//                           src={image}
//                           style={{
//                             marginRight: "5px",
//                             width: "70px",
//                             height: "70px",
//                             borderRadius:"8px"
//                           }}
//                         />
//                       ))}
//                     </Box>
//                   ) : (
//                     <Avatar alt={`Image ${index}`} src={row.images} />
//                   )}
//                 </TableCell>
//                 <TableCell align="center" style={{ width: "30%" }}>
//                   <Typography>{row.name}</Typography>
//                   <Typography>{row.status}</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Edit
//                     style={{ cursor: "pointer" }}
//                     onClick={() => handleEditClick(row.id)}
//                   />
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={bannersListData?.total}
//           rowsPerPage={filters.limit}
//           page={filters.offset - 1}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           style={{ backgroundColor: "#007effa6" }}
//         />
//       </TableContainer>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>{editData ? "Edit News" : "Create News"}</DialogTitle>
//         <DialogContent>
//           <BannersForm onFormSubmit={onFormSubmit} editData={editData} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//       <Popup
//         open={openPopup}
//         handleClose={() => setOpenPopup(false)}
//         images={selectedImages}
//       />
//     </>
//   );
// };

// export default BannersTable;


//===================================================================



// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TablePagination,
//   TableHead,
//   TableRow,
//   Paper,
//   Typography,
//   Avatar,
//   Box,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
// } from "@mui/material";
// import { getBanners } from "./banner"; // Replace with actual import
// import BannersForm from "./bannersform"; // Replace with actual import
// import { Edit } from "@mui/icons-material";
// import Popup from "./popup"; // Replace with actual import

// const BannersTable = ({ filters, setFilters }) => {
//   const navigate = useNavigate();
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);
//   const [bannersListData, setBannersListData] = useState(null);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [openPopup, setOpenPopup] = useState(false);

//   useEffect(() => {
//     async function getBannerDetails() {
//       const data = await getBanners(filters.offset, filters.limit, filters.id);
//       setBannersListData(data);
//     }
//     getBannerDetails();
//   }, [filters.offset, filters.limit, filters.id]);

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-banners");
//     console.log("Create button clicked");
//   };

//   const handleChangePage = (event, newPage) => {
//     setFilters({ ...filters, offset: newPage + 1 });
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setFilters({
//       ...filters,
//       limit: parseInt(event.target.value, 10),
//       offset: 1,
//     });
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getBanners(setBannersListData, filters.offset, filters.limit);
//     setShowForm(false);
//   };

//   const handleEditClick = (id) => {
//     navigate(`/dhunia_bo/edit-banners/${id}`);
//   };

//   const handleImageClick = (images) => {
//     setSelectedImages(images);
//     setOpenPopup(true);
//   };

//   return (
//     <>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         marginBottom="20px"
//         paddingLeft="20px"
//         paddingRight="20px"
//       >
//         <Typography variant="h3" fontWeight="bold" paddingLeft="10px">
//           BANNERS
//         </Typography>
//         <Button
//           variant="contained"
//           style={{ paddingRight: "20px", marginRight: "20px" }}
//           color="primary"
//           onClick={handleCreateClick}
//         >
//           Create
//         </Button>
//       </Box>
//       <TableContainer component={Paper} style={{maxWidth: "95%" , marginLeft : "30px"}}>
//         <Table>
//           <TableHead style={{ backgroundColor: "#007effa6" }}>
//             <TableRow>
//               <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Images</TableCell>
//               <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Details</TableCell>
//               <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Actions</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {bannersListData?.info?.map((row, index) => (
//               <TableRow key={index} hover style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3" : "#ffffff" }} >
//                 <TableCell  align="center" style={{ width: "40%", borderBottom: "1px solid #ddd" }}>
//                   {Array.isArray(row.images) ? (
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       onClick={() => handleImageClick(row.images)}
//                       style={{ cursor: "pointer" }}
//                     >
//                       {row.images.map((image, i) => (
//                         <Avatar
//                           key={i}
//                           alt={`Image ${i}`}
//                           src={image}
//                           style={{
//                             marginRight: "5px",
//                             width: "70px",
//                             height: "70px",
//                             borderRadius: "8px"
//                           }}
//                         />
//                       ))}
//                     </Box>
//                   ) : (
//                     <Avatar alt={`Image ${index}`} src={row.images} />
//                   )}
//                 </TableCell>
//                 <TableCell align="center" style={{ width: "30%", borderBottom: "1px solid #ddd" }}>
//                   <Typography variant="body1" fontWeight="bold">{row.name}</Typography>
//                   <Typography variant="body2" color="textSecondary">{row.status}</Typography>
//                 </TableCell>
//                 <TableCell align="center" style={{ borderBottom: "1px solid #ddd" }}>
//                   <Edit
//                     style={{ cursor: "pointer", color: "#007eff" }}
//                     onClick={() => handleEditClick(row.id)}
//                   />
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={bannersListData?.total}
//           rowsPerPage={filters.limit}
//           page={filters.offset - 1}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           style={{ backgroundColor: "#007effa6", color: "#ffffff" }}
//         />
//       </TableContainer>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>{editData ? "Edit Banner" : "Create Banner"}</DialogTitle>
//         <DialogContent>
//           <BannersForm onFormSubmit={onFormSubmit} editData={editData} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//       <Popup
//         open={openPopup}
//         handleClose={() => setOpenPopup(false)}
//         images={selectedImages}
//       />
//     </>
//   );
// };

// export default BannersTable;






import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { getBanners } from "./banner"; // Replace with actual import
import BannersForm from "./bannersform"; // Replace with actual import
import { Edit } from "@mui/icons-material";
import Popup from "./popup"; // Replace with actual import

const BannersTable = ({ filters, setFilters }) => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [bannersListData, setBannersListData] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    async function getBannerDetails() {
      const data = await getBanners(filters.offset, filters.limit, filters.id);
      setBannersListData(data);
    }
    getBannerDetails();
  }, [filters.offset, filters.limit, filters.id]);

  const handleCreateClick = () => {
    navigate("/dhunia_bo/create-banners");
    
  };

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, offset: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({
      ...filters,
      limit: parseInt(event.target.value, 10),
      offset: 1,
    });
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const onFormSubmit = () => {
    getBanners(setBannersListData, filters.offset, filters.limit);
    setShowForm(false);
  };

  const handleEditClick = (id) => {
    navigate(`/dhunia_bo/edit-banners/${id}`);
  };

  const handleImageClick = (images) => {
    setSelectedImages(images);
    setOpenPopup(true);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
        paddingLeft="20px"
        paddingRight="20px"
      >
        <Typography variant="h3" fontWeight="bold" paddingLeft="10px"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          BANNERS
        </Typography>
        <Button
          variant="contained"
          style={{ paddingRight: "20px", marginRight: "20px" }}
          color="primary"
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </Box>
      <TableContainer component={Paper} style={{ maxWidth: "95%", marginLeft: "30px" }}>
        <Table>
          <TableHead style={{ backgroundColor: "#007effa6" }}>
            <TableRow>
              <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Images</TableCell>
              <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Details</TableCell>
              <TableCell style={{ color: "#ffffff", fontWeight: "bold" }} align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bannersListData?.info?.map((row, index) => (
              <TableRow key={index} hover style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3" : "#ffffff" }}>
                <TableCell align="center" style={{ width: "40%", borderBottom: "1px solid #ddd" }}>
                  {Array.isArray(row.images) ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      onClick={() => handleImageClick(row.images)}
                      style={{ cursor: "pointer" }}
                    >
                      {row.images.map((image, i) => (
                        <Avatar
                          key={i}
                          alt={`Image ${i}`}
                          src={image}
                          style={{
                            marginRight: "5px",
                            width: "70px",
                            height: "70px",
                            borderRadius: "8px"
                          }}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Avatar alt={`Image ${index}`} src={row.images} />
                  )}
                </TableCell>
                <TableCell align="center" style={{ width: "30%", borderBottom: "1px solid #ddd" }}>
                  <Typography variant="body1" fontWeight="bold">{row.name}</Typography>
                  <Typography variant="body2" color="textSecondary">{row.status}</Typography>
                </TableCell>
                <TableCell align="center" style={{ borderBottom: "1px solid #ddd" }}>
                  <Edit
                    style={{ cursor: "pointer", color: "#007eff" }}
                    onClick={() => handleEditClick(row.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={bannersListData?.total}
          rowsPerPage={filters.limit}
          page={filters.offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ backgroundColor: "#007effa6", color: "#ffffff" }}
        />
      </TableContainer>
      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>{editData ? "Edit Banner" : "Create Banner"}</DialogTitle>
        <DialogContent>
          <BannersForm onFormSubmit={onFormSubmit} editData={editData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        images={selectedImages}
      />
    </>
  );
};

export default BannersTable;
