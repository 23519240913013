import axios from 'axios';

export const getOpenApps = async () => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  };

  const url = `${process.env.REACT_APP_BASEURL}backoffice/reports`;

  try {
    const response = await axios.get(url, { headers });

    if (response?.data?.success) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching reports:", error);
    return null;
  }
};
