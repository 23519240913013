import axios from "axios";
export const getMovieData = async (next, source) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}ott_service/movies?source=${source}`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    if (next) {
      next(response.data.info);
    } else {
      return response?.data.info;
    }
  }
};
export const createMovie = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };
  return axios
    .post(`${process.env.REACT_APP_BASEURL}ott_service/movies`, json_payload, {
      headers,
    })
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const editMovieData = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    // "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(`${process.env.REACT_APP_BASEURL}ott_service/movies`, formData, {
      headers,
    })
    .then((response) => {
      return response?.data;
      // handle the response
      // console.log(response);
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const deleteMovieData = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .delete(
      `${process.env.REACT_APP_BASEURL}ott_service/movies`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      return response?.data;
      // handle the response
      // console.log(response);
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const getGenres = async () => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}ott_service/genres`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    return response?.data.info;
  }
  return [];
};
export const getUploadData = async (source, offset = 0) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  return axios
    .get(
      `${process.env.REACT_APP_BASEURL}ott_service/uploads?source=${source}`,
      {
        headers,
      }
    )
    .then((response) => {
      // console.log("data response ", response);
      if (response?.data?.success) {
        return response?.data.info;
      }
    });
};
export const getTrending = async () => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  return axios
    .get(`${process.env.REACT_APP_BASEURL}ott_service/trending`, {
      headers,
    })
    .then((response) => {
      // console.log("data response ", response);
      if (response?.data?.success) {
        return response?.data.info;
      }
    });
};
export const updateTrending = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };
  return axios
    .post(
      `${process.env.REACT_APP_BASEURL}ott_service/trending`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // console.log("data response ", response);
      if (response?.data?.success) {
        return response?.data.info;
      }
    });
};
export const getMovieMakersData = async (offset) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  // console.log(section);
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}backoffice/ott/moviemaker?status=APPROVED`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    return response?.data.info;
  } else {
    return [];
  }
};
