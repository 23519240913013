import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Autocomplete,
  Typography,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UploadAddsFiles, createAdds } from "./ads";
import AdsCities from "./ads-cities";

const locations = [
  "Adilabad, TS",
  "Bhadradri Kothagudem, TS",
  "Hanumakonda, TS",
  "Hyderabad, TS",
  "Jagtial, TS",
  "Jangoan, TS",
  "Jayashankar Bhoopalpally, TS",
  "Jogulamba Gadwal, TS",
  "Kamareddy, TS",
  "Karimnagar, TS",
  "Khammam, TS",
  "Komaram Bheem Asifabad, TS",
  "Mahabubabad, TS",
  "Mahabubnagar, TS",
  "Mancherial, TS",
  "Medak, TS",
  "Medchal-Malkajgiri, TS",
  "Mulug, TS",
  "Nagarkurnool, TS",
  "Nalgonda, TS",
  "Narayanpet, TS",
  "Nirmal, TS",
  "Nizamabad, TS",
  "Peddapalli, TS",
  "Rajanna Sircilla, TS",
  "Rangareddy, TS",
  "Sangareddy, TS",
  "Siddipet, TS",
  "Suryapet, TS",
  "Vikarabad, TS",
  "Wanaparthy, TS",
  "Warangal, TS",
  "Yadadri Bhuvanagiri, TS",
  "Srikakulam, AP",
  "Parvathipuram Manyam, AP",
  "Vizianagaram, AP",
  "Visakhapatnam, AP",
  "Alluri Sitharama Raju, AP",
  "Anakapalli, AP",
  "Kakinada, AP",
  "East Godavari, AP",
  "Dr. B. R. Ambedkar Konaseema, AP",
  "Eluru, AP",
  "West Godavari, AP",
  "Ntr, AP",
  "Krishna, AP",
  "Palnadu, AP",
  "Guntur , AP",
  "Bapatla, AP",
  "Prakasam, AP",
  "Sri Potti Sriramulu Nellore, AP",
  "Kurnool, AP",
  "Nandyal, AP",
  "Anantapur, AP",
  "Sri Sathya Sai, AP",
  "Kadapa, AP",
  "Annamayya, AP",
  "Tirupati, AP",
  "Chittoor, AP",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Maharashtra",
  "Madhya Pradesh",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Telangana",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman & Nicobar (UT)",
  "Chandigarh (UT)",
  "Dadra & Nagar Haveli and Daman & Diu (UT)",
  "Delhi [National Capital Territory (NCT)]",
  "Jammu & Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
];

const AddsForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [formData, setFormData] = useState({
    mobile_number: "",
    whatsapp_number: "",
    destination_url: "",
    display_type: "",
    action_title: "",
    view_type: "",
    sponsor_name: "",
    title: "",
    status: "",
    ad_description: "",
    location: [],
    position : "",
  });

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideo(video.filter((_, index) => index !== indexToRemove));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      expiration_date: date,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const UploadAndSaveNews = async (data, images, videos) => {
    try {
      let images_response = { success: true, info: null };
      if (images.length > 0) {
        const imagesFormData = new FormData();
        images.forEach((file) => {
          imagesFormData.append("files", file);
        });
        imagesFormData.append("entity", data.entity);
        images_response = await UploadAddsFiles(imagesFormData);
      } else {
        data.images = null; // Set images to null if no images were selected
      }

      // Upload videos if available
      let video_response = { success: true, info: null };
      if (videos.length > 0) {
        const videosFormData = new FormData();
        videos.forEach((file) => {
          videosFormData.append("files", file);
        });
        videosFormData.append("entity", data.entity);
        video_response = await UploadAddsFiles(videosFormData);
      } else {
        data.videos = null; // Set videos to null if no videos were selected
      }

      if (images_response.success && video_response.success) {
        data.images = images_response.info;
        data.videos = video_response.info;
        const form_response = await createAdds(data);
        return form_response;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UploadAndSaveNews(formData, images, video);
      if (apiResponse && apiResponse.success) {
        onFormSubmit(navigate("/dhunia_bo/ads"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/ads");
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const handleVideoChange = (event) => {
    const selectedVideos = Array.from(event.target.files);
    setVideo(selectedVideos);
  };
  const handle_status_Change = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLocationChange = (event, value) => {
    const selectedLocations = value ? value : [];
    setFormData((prevData) => ({
      ...prevData,
      location: selectedLocations,
    }));
  };

  const resetForm = () => {
    setFormData({
      mobile_number: "",
      whatsapp_number: "",
      destination_url: "",
      display_type: "",
      action_title: "",
      view_type: "",
      sponsor_name: "",
      title: "",
      status: "",
      ad_description: "",
      location: [],
      position: "",
    });
    setImages([]);
    setVideo([]);
  };

  return (
    <Box sx={{ backgroundColor: "#A7E6FF" }}>
      <Box
        width="100%"
        maxWidth="900px"
        marginLeft="5rem"
        justifyContent="center"
        alignItems="center"
        padding="30px"
      >
        <Box
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
          sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="30px"
            marginBottom="15px"
            align="center"
            color="#007effa6"
            sx={{ marginTop: "10px" }}
          >
            CREATE ADS
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="sponsor_name"
                  label="sponsor_name"
                  value={formData.sponsor_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="title"
                  label="title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box
                marginRight="10px"
                style={{ flex: "50%", marginLeft: "10px" }}
              >
                <TextField
                  name="status"
                  select // Use select for dropdown
                  label="status"
                  value={formData.status}
                  onChange={handle_status_Change}
                  fullWidth
                  required
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                  <MenuItem value="DELETED">DELETED</MenuItem>
                  <MenuItem value="PENDING">PENDING</MenuItem>
                  <MenuItem value="CLASSIFIED">CLASSIFIED</MenuItem>
                  <MenuItem value="EXPIRED">EXPIRED</MenuItem>
                </TextField>
              </Box>

              <Box
                marginBottom="20px"
                style={{ flex: "50%", marginLeft: "10px", padding: "0px" }}
              >
                <Autocomplete
                  multiple
                  id="location"
                  options={locations}
                  onChange={handleLocationChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="location"
                      label="Location"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="whatsapp_number"
                  label="WhatsApp Number"
                  value={formData.whatsapp_number}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="mobile_number"
                  label="Mobile Number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="destination_url"
                  label="Destination Url"
                  value={formData.destination_url}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="action_title"
                  label="Action Title"
                  value={formData.action_title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>

            <Box display="flex" marginBottom="10px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="view_type"
                  select // Use select for dropdown
                  label="View Type"
                  value={formData.view_type}
                  onChange={handleChange}
                  fullWidth
                  required
                >
                  <MenuItem value="DEFAULT">DEFAULT</MenuItem>
                  <MenuItem value="FULLSCREEN">FULLSCREEN</MenuItem>
                  <MenuItem value="PROMOTED">PROMOTED</MenuItem>
                </TextField>
              </Box>
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="display_type"
                  select // Use select for dropdown
                  label="Display Type"
                  value={formData.display_type}
                  onChange={handleChange}
                  fullWidth
                  required
                >
                  <MenuItem value="OTT">OTT</MenuItem>
                  <MenuItem value="JOBS">JOBS</MenuItem>
                  <MenuItem value="NEWS">NEWS</MenuItem>
                  <MenuItem value="LISTINGS">LISTINGS</MenuItem>
                  <MenuItem value="CLASSIFIED">CLASSIFIED</MenuItem>
                  <MenuItem value="OTHERS">OTHERS</MenuItem>
                </TextField>
              </Box>
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="position"
                  label="Position"
                  type="number"
                  value={formData.position}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>

              <Box style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="expiration_date"
                  label="Expiration Date"
                  type="date"
                  value={formData.expiration_date}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>

            <Box style={{ padding: "10px" }}>
              <TextField
                fullWidth
                label="Description"
                variant="outlined"
                name="description"
                value={formData.description}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={4}
                required
              />
            </Box>

            <Box style={{ padding: "10px" }}>
              <TextField
                fullWidth
                label="Ad Description"
                variant="outlined"
                name="ad_description"
                value={formData.ad_description}
                onChange={handleChange}
                margin="normal"
                multiline
                rows={2}
                required
              />
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <input
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="images">
                  <Button variant="outlined" component="span">
                    Upload Images
                  </Button>
                </label>
                {images.length > 0 && (
                  <ul>
                    {images.map((image, index) => (
                      <li key={index}>
                        <img
                          src={
                            typeof image === "object"
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveImage(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

              <Box style={{ flex: "50%", padding: "10px" }}>
                <input
                  type="file"
                  id="video"
                  name="videos"
                  accept="video/*"
                  onChange={handleVideoChange}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="video">
                  <Button variant="outlined" component="span">
                    Upload Video
                  </Button>
                </label>
                {video.length > 0 && (
                  <ul>
                    {video.map((video, index) => (
                      <li key={index}>
                        <video
                          src={
                            typeof video === "object"
                              ? URL.createObjectURL(video)
                              : video
                          }
                          controls
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveVideo(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>

            <Box
              marginTop="55px"
              display="flex"
              justifyContent="flex-end"
              style={{ padding: "10px" }}
            >
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddsForm;
