import axios from "axios";
export const getRolesData = async (section, next) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  // console.log(section);
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}backoffice/user/list?role=${section}`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    next(response.data.info);
  } else {
    next([]);
  }
};
