import React from "react";
import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { editMovieData } from "../../../services/ott_movies";
// import ImageUploader from "./imageUploader";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
function EditMovies({
  openEdit,
  setEditOpen,
  moviePayload,
  getMoviesData,
  setMoviesList,
}) {
  const [isActive, setIsActive] = useState(
    moviePayload.is_active ? moviePayload.is_active : false
  );
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [movieData, setMoviesData] = useState(moviePayload);
  // const [movieName, setMoviesName] = useState(moviePayload.name);
  const [, setState] = React.useState(false);
  const [file, setFile] = useState(moviePayload?.images);
  const [thumbnailFiles, setThumbnailFiles] = useState(moviePayload?.images);
  const [logoFile, setLogoFile] = useState(moviePayload?.company_logo);
  const [logoSelectedFile, setLogoSelectedFile] = useState(
    moviePayload?.company_logo
  );
  function handleClose() {
    setEditOpen(false);
  }
  const changeHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    // const uploadResponse = await uploadImages(formData);
    // if (uploadResponse) {
    //   setLogoSelectedFile(event.target.files[0]);
    //   setIsSelected(true);
    //   setLogoFile(uploadResponse?.info[0]);
    // }
  };
  const handleStatusChange = (event) => {
    setIsActive(!isActive);
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("movie_id", moviePayload.id);
    formData.append("movie_type", movieData?.movie_type);
    formData.append("movie_style", movieData?.movie_style);
    formData.append("movie_role", movieData?.movie_role);
    formData.append("movie_category", movieData?.movie_category);
    formData.append("qualification", movieData?.qualification);
    formData.append("movie_description", movieData?.movie_description);
    formData.append("movie_location", movieData?.movie_location);
    formData.append("min_experience", movieData?.min_experience);
    formData.append("max_experience", movieData?.max_experience);
    formData.append("min_salary", movieData?.min_salary);
    formData.append("max_salary", movieData?.max_salary);
    formData.append("company_name", movieData?.company_name);
    formData.append("company_description", movieData?.company_description);
    formData.append("company_mobile", movieData?.company_mobile);
    formData.append("company_whatsapp", movieData?.company_whatsapp);
    formData.append("company_email", movieData?.company_email);
    // if (movieData?.images) {
    //   for (var i = 0; i < movieData?.images.length; i++) {
    //     formData.append("images", movieData?.images[i]);
    //   }
    // }
    // // console.log(thumbnailFiles);
    // for (var i = 0; i < thumbnailFiles.length; i++) {
    //   // console.log("new upload file", i, thumbnailFiles[i]);
    //   formData.append("thumbnails", thumbnailFiles[i]);
    // }
    for (let i = 0; i < file.length; i++) {
      formData.append("images", file[i]);
    }
    formData.append("company_logo", logoFile);
    let response = await editMovieData(formData);
    if (response?.success) {
      setEditOpen(false);
      getMoviesData(setMoviesList);
    }
  };
  const handleDataChange = (event) => {
    // console.log(event.target.id, event.target.value);
    setMoviesData((movieData) => {
      let key = event.target.id;
      // console.log(movieData, event.target.value);
      movieData[key] = event.target.value;
      // console.log(movieData, movieData?.movie_title);
      return movieData;
    });
    setState((prev) => !prev);
  };
  const handleDropdownDataChange = (event) => {
    setMoviesData((movieData) => {
      let key = event.target.name;
      // console.log(movieData, event.target.value);
      movieData[`${key}`] = `${event.target.value}`;
      return movieData;
    });
  };
  return (
    <Container>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <b>Edit a Movie</b>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Movie Details</b>
          </DialogContentText>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Title</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="movie_title"
              label="Movie Title"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_title}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Type</DialogContentText> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Movie Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="movie_type"
                // value={movieData?.movie_type}
                defaultValue={movieData?.movie_type}
                label="Movie Type"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              autoFocus
              id="movie_type"
              label="Movie Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_type}
            /> */}
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Style</DialogContentText> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Movie Style</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="movie_style"
                defaultValue={movieData?.movie_style}
                label="Movie Style"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="ON-SITE">ON SITE</MenuItem>
                <MenuItem value="WORK-FROM-OFFICE">WORK-FROM-OFFICE</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              autoFocus
              id="movie_style"
              label="Movie Style"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_style}
            /> */}
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Role</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="movie_role"
              label="Movie Role"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_role}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Category</DialogContentText> */}
            <TextField
              autoFocus
              id="movie_category"
              label="Movie Category"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_category}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Qualification</DialogContentText> */}
            {/* <TextField
              autoFocus
              id="qualification"
              label="Qualification"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.qualification}
            /> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Qualification
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="qualification"
                defaultValue={movieData?.qualification}
                label="Qualification"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="SSC">SSC</MenuItem>
                <MenuItem value="INTER">INTER</MenuItem>
                <MenuItem value="DEGREE">DEGREE</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Description</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="movie_location"
              label="Movie Location"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_location}
            />
            <TextField
              autoFocus
              id="movie_description"
              label="Movie Description"
              type="text"
              sx={{ width: "66%", mr: "3%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.movie_description}
              multiline
              rows={3}
              // maxRows={4}
            />
            {/* <TextareaAutosize
              sx={{ width: "66%", mr: "3%", mb: 2 }}
              id="movie_description"
              label="Movie Description"
              aria-label="empty textarea"
              placeholder="Movie Description"
            /> */}
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Location</DialogContentText> */}
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Movie Experience</b>
          </DialogContentText>
          {/* <DialogContentText sx={{ mb: 1 }}>Min. Experience</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="min_experience"
              label="Min. Experience"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.min_experience}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Max. Experience</DialogContentText> */}
            <TextField
              autoFocus
              id="max_experience"
              label="Max. Experience"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.max_experience}
            />
          </div>
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Movie Salary</b>
          </DialogContentText>
          {/* <DialogContentText sx={{ mb: 1 }}>Min. Salary</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="min_salary"
              label="Min. Salary"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.min_salary}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Max. Salary</DialogContentText> */}
            <TextField
              autoFocus
              id="max_salary"
              label="Max. Salary"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.max_salary}
            />
          </div>
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Company Details</b>
          </DialogContentText>
          {/* <DialogContentText sx={{ mb: 1 }}>Company Name</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="company_name"
              label="Company Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.company_name}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>
            Company Description
          </DialogContentText> */}
            {/* <DialogContentText sx={{ mb: 1 }}>Company Mobile</DialogContentText> */}
            <TextField
              autoFocus
              id="company_mobile"
              label="Company Mobile"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.company_mobile}
            />
            <TextField
              autoFocus
              id="company_whatsapp"
              label="Company WhatsApp"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.company_whatsapp}
            />
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Company WhatsApp</DialogContentText> */}
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>Company Email</DialogContentText> */}
            <TextField
              autoFocus
              id="company_email"
              label="Company Email"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData.company_email}
            />
            <TextField
              autoFocus
              id="company_description"
              label="Company Description"
              type="text"
              sx={{ width: "66%", mr: "3%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.company_description}
              multiline
              rows={3}
            />
          </div>
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>
              <b>Select Logo</b>
            </DialogContentText> */}
            <DialogContent>
              <DialogContentText>
                <b>Upload Logo</b>
              </DialogContentText>
              <Box>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={changeHandler} />
                </Button>
              </Box>
            </DialogContent>
            <DialogContent>
              {isSelected || logoFile ? (
                <img
                  src={logoFile}
                  height={100}
                  width={100}
                  alt={logoSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
          </div>
          {/* <div style={{ display: "flex" }}>
            <DialogContentText sx={{ mb: 1 }}>
              <b>Select Thumbnail(s)</b>
            </DialogContentText>
            <ImageUploader
              file={file}
              setFile={setFile}
              thumbnailFiles={thumbnailFiles}
              setThumbnailFiles={setThumbnailFiles}
            />
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
            // disabled={!(isSelected && movieData)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
export default EditMovies;
