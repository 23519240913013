import React from "react";
import PropTypes from "prop-types";
import "./card.css";

const Card = (props) => {
  // console.log(props);
  return (
    <div className="card" style={{ margin: "10px", padding: "10px" }}>
      {/* <div className="card__img">
        <img src={props.item.img} alt="" />
      </div> */}
      <div className="card__title">
        <span>{props.item.title}</span>
      </div>
    </div>
  );
};

Card.propTypes = {
  item: PropTypes.object,
};

export default Card;
