import React from "react";
import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import Switch from "@mui/material/Switch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { editCategoryData } from "../../services/category";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  textTransform: "capitalize",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
function EditCategory({
  openEdit,
  setEditOpen,
  categoryPayload,
  getCategoryData,
  setCategoryList,
}) {
  const [isActive, setIsActive] = useState(
    categoryPayload.is_active ? categoryPayload.is_active : false
  );
  const [file, setFile] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [categoryName, setCategoryName] = useState(categoryPayload.name);
  const [isSelected, setIsSelected] = useState(false);
  function handleClose() {
    setEditOpen(false);
  }
  const changeHandler = (event) => {
    // console.log(event.target.files);
    // console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const handleStatusChange = (event) => {
    // console.log(isActive);
    setIsActive(!isActive);
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("category_id", categoryPayload.id);
    formData.append("icon_image", selectedFile);
    formData.append("name", categoryName);
    formData.append("is_active", isActive);
    // const json_payload = {
    //   category_id: categoryPayload.id,
    //   name: categoryName,
    //   is_active: isActive,
    // };
    // editCategoryData(json_payload);
    let response = await editCategoryData(formData);
    if (response?.success) {
      setEditOpen(false);
      getCategoryData(setCategoryList);
    }
  };
  const handleCategoryName = (event) => {
    setCategoryName(event.target.value);
  };
  return (
    <Container>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          <b>Edit a Category</b>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>Please edit a category.</DialogContentText> */}
          <TextField
            autoFocus
            id="name"
            label="category"
            type="text"
            fullWidth
            onChange={handleCategoryName}
            defaultValue={categoryPayload.name}
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <div style={{ display: "flex" }}>
          <DialogContent>
            <DialogContentText sx={{ mb: 1 }}>Is Active</DialogContentText>
            <Switch
              checked={isActive}
              onChange={handleStatusChange}
              label="Is Active"
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText sx={{ mb: 1 }}>
              Update category image.
            </DialogContentText>
            <Box>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={changeHandler} />
              </Button>
              {isSelected ? (
                <div>
                  {/* <p>Filename: {selectedFile.name}</p>
                <p>Filetype: {selectedFile.type}</p> */}
                </div>
              ) : (
                <p>Select a file to show details</p>
              )}
            </Box>
          </DialogContent>
          <DialogContent>
            {isSelected ? (
              <img src={file} height={100} width={100} />
            ) : (
              <img
                src={categoryPayload.icon}
                style={{ width: 100, height: 100 }}
              />
            )}
          </DialogContent>
        </div>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
export default EditCategory;
