import axios from "axios";
export const getUploadData = async (next, source) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}ott_service/uploads?source=${source}`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    next(response.data.info);
  }
};
export const createUpload = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };
  return axios
    .post(`${process.env.REACT_APP_BASEURL}ott_service/uploads`, json_payload, {
      headers,
    })
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const editUploadData = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    // "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(`${process.env.REACT_APP_BASEURL}ott_service/uploads`, formData, {
      headers,
    })
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const deleteUploadData = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .delete(
      `${process.env.REACT_APP_BASEURL}ott_service/uploads`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
