import axios from 'axios';

export const getReports = async (start_date, end_date) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  };

  const url = `${process.env.REACT_APP_BASEURL}backoffice/reports`;

  const data = {
    start_date,
    end_date,
  };

  try {
    const response = await axios.post(url, data, { headers });

    if (response?.data?.success) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching reports:", error);
    return null;
  }
};
