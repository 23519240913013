import axios from "axios";

// export const getStates = async (offset = 1, limit = 10, id = null) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;
//   if (id) {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states?id=${id}`,
//       {
//         headers,
//       }
//     );
//   } else {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states?&offset=${offset}&limit=${limit}`,
//       {
//         headers,
//       }
//     );
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }
//   return null;
// };



//   export const deleteState = async (json_payload) => {
//     const userDetails = localStorage.getItem("userDetails");
//     const userData = JSON.parse(userDetails);
//     const token = `Bearer ${userData?.access_token}`;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: token,
//     };
//     return axios
//       .delete(
//         `${process.env.REACT_APP_BASEURL}userservice/location/states`,
//         json_payload,
//         {
//           headers,
//         }
//       )
//       .then((response) => {
//         // handle the response
//         // console.log(response);
//       })
//       .catch((error) => {
//         // handle errors
//         // console.log(error);
//       });
//   };

export const getStates = async (offset = 1, limit = 10, id, status = null) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(`${process.env.REACT_APP_BASEURL}userservice/location/states`, {
    params: { offset, limit, id, status },
    headers,
  });
  return response.data;
}

export const deleteState = async (stateId) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  await axios.delete(`${process.env.REACT_APP_BASEURL}userservice/location/states/${stateId}`, {
    headers,
  });
}


  export const createStates = async (formData) => {
    const userDetails = localStorage.getItem("userDetails");
    const userData = JSON.parse(userDetails);
    const token = `Bearer ${userData?.access_token}`;
    const headers = {
      Authorization: token,
      "content-type": "application/json",
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}userservice/location/states`,
        formData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Failed to submit form data" };
    }
  };