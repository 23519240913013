//===============================================================================================

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  Paper,
  Chip,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import { getNewsList } from "../../../services/news";
import { updateForm, UploadFiles, getCategoriesList,  } from "./form";

import CreateCities from "./cities-1";
import CreateStates from "./state-1";
import { useLocation } from "react-router-dom";

const EditForm = ({ onFormSubmit }) => {


  const { state } = useLocation();
  const { newsItem } = state || {};


  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    news_id: id,
    title: "",
    categories: [],
    description: "",
    language: "",
    city: "",
    tags: [],
    status: "",
    state: [],
    district: [],
    mandal: [],
    village: [],
    youtube_url: [],
    promoted_text : "",
    destination_url :"",
    type:"",
  });
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputValue, setInputValue] = useState("");
 

  useEffect(() => {
    const getData = async () => {
      let data = await getNewsList(1, 10, id);
      data = data.info[0];
      setFormData({
        news_id: data?.id || id,
        title: data?.title || "",
        description: data?.description || "",
        language: data?.language || "",
        city: data?.city || "",
        tags: data?.tags || [],
        categories: data?.categories || [],
        status: data?.status || "",
        state: data?.state || "",
        district: data?.district || "",
        mandal: data?.mandal || "",
        village: data?.village || "",
        youtube_url: data?.youtube_url || "",
        promoted_text : data?.promoted_text || "",
        destination_url : data?.destination_url || "",
        type : data?.type || "",
      });
      setImages(data?.image ? data.image : []);
      setVideo(data?.video ? data.video : []);
    };
    getData();
  }, [id]);

  useEffect(() => {
    if (newsItem) {
      setFormData(newsItem);
    }
  }, [newsItem]);
  

  useEffect(() => {
    async function getCategories() {
      const data = await getCategoriesList(1, 10);
      setSelectedCategory(data.info || []);
    }
    getCategories();
  }, []);

  const navigate = useNavigate();

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideo(video.filter((_, index) => index !== indexToRemove));
  };

  const handleLink = (e) => {
    const { name, value } = e.target;
    if (name === "youtube_url") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value] || null,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "district" || name === "mandal" || name === "youtube_url") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value] || null, // Always store as array
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleAddTag = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const value = event.target.value.trim();
      if (value && !formData.tags.includes(value)) {
        setFormData((prevData) => ({
          ...prevData,
          tags: [...prevData.tags, value],
        }));
      }
      event.target.value = "";
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setFormData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleTagsChange = (event, newValues) => {
    setFormData((prevData) => ({
      ...prevData,
      tags: newValues,
    }));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      setFormData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, inputValue.trim()],
      }));
      setInputValue("");
    }
  };

  const handleCityChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      city: value || "",
    }));
  };

  const handleStateChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      state: value || [],
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages((prevData) => [...prevData, ...selectedImages]);
  };

  const handleVideoChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setVideo((prevData) => [...prevData, ...selectedImages]);
  };

  const resetForm = () => {
    setFormData({
      news_id: id,
      title: "",
      categories: [],
      description: "",
      language: "",
      city: "",
      tags: [],
      status: "PENDING",
      state: [],
      district: [],
      mandal: [],
      village: [],
      youtube_url: [],
      promoted_text : "",
      destination_url :"",
      type:"",
    });
    setImages([]);
    setVideo([]);
  };

  const UpdateAndSaveNews = async (data, images, videos) => {
    try {
      const newImages = images.filter((item) => typeof item === "object");
      const newVideos = videos.filter((item) => typeof item === "object");
      const oldImages = images.filter((item) => typeof item === "string");
      const oldVideos = videos.filter((item) => typeof item === "string");

      // Upload new images
      let imagesResponse;
      if (newImages.length > 0) {
        const imagesFormData = new FormData();
        newImages.forEach((file, index) => {
          imagesFormData.append(`files`, file);
        });
        imagesFormData.append("entity", data.entity);
        imagesResponse = await UploadFiles(imagesFormData);
      } else {
        imagesResponse = { success: true, info: [] };
      }

      // Upload new videos
      let videosResponse;
      if (newVideos.length > 0) {
        const videosFormData = new FormData();
        newVideos.forEach((file, index) => {
          videosFormData.append(`files`, file);
        });
        videosFormData.append("entity", data.entity);
        videosResponse = await UploadFiles(videosFormData);
      } else {
        videosResponse = { success: true, info: [] };
      }

      if (imagesResponse.success && videosResponse.success) {
        data.image = oldImages.concat(imagesResponse.info);
        data.video = oldVideos.concat(videosResponse.info);
        const formResponse = await updateForm(data);
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const preparedFormData = {
      ...formData,
      district: formData.district.length > 0 ? formData.district : null,
      mandal: formData.mandal.length > 0 ? formData.mandal : null,
      village: formData.village.length > 0 ? formData.village : null,
      youtube_url : formData.youtube_url.length > 0 ? formData.youtube_url  : null ,
    };
    try {
      const apiResponse = await UpdateAndSaveNews(
        preparedFormData,
        images,
        video
      );
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/posts"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/posts");
  };

  const handleRemoveTag = (indexToRemove) => {
    setFormData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((_, index) => index !== indexToRemove),
    }));
  };
  const resetStatus = () => {
    setFormData({ ...formData, status: "PENDING" });
  };

  return (
    <Box sx={{ backgroundColor: "#A7E6FF" }}>
      <Box
        width="100%"
        maxWidth="900px"
        marginLeft="5rem"
        justifyContent="center"
        alignItems="center"
        padding="30px"
      >
        <Box
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
          sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="30px"
            marginBottom="15px"
            align="center"
            color="#007effa6"
            sx={{ marginTop: "10px" }}
          >
            EDIT NEWS
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="title"
              value={formData.title}
              onChange={handleChange}
              margin="normal"
              required
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                {formData.status === "PENDING" ? (
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    sx={{ padding: "10px" }}
                  >
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                      label="Status"
                      required
                    >
                      <MenuItem value="APPROVED">APPROVED</MenuItem>
                      <MenuItem value="REJECTED">REJECTED</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Status"
                        variant="outlined"
                        sx={{ padding: "10px" }}
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={resetStatus}
                        fullWidth
                      >
                        Reset Status
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    label="Language"
                    required
                  >
                    <MenuItem value="ENGLISH">ENGLISH</MenuItem>
                    <MenuItem value="TELUGU">TELUGU</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <CreateCities
                  formData={formData}
                  handleCityChange={handleCityChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <Autocomplete
                    multiple
                    options={selectedCategory.map((category) => category.name)}
                    value={formData.categories || []} // Ensure categories is always an array
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, categories: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categories"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <CreateStates
                  formData={formData}
                  handleStateChange={handleStateChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  sx={{ padding: "10px" }}
                  label="Promoted Text"
                  variant="outlined"
                  name="promoted_text"
                  value={formData.promoted_text}
                  onChange={handleChange}
                  margin="normal"
                  rows={1}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <InputLabel id="language-label">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    label="Type"
                    required
                  >
                    <MenuItem value="REGULAR">REGULAR</MenuItem>
                    <MenuItem value="PROMOTED">PROMOTED</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>


            <TextField
              fullWidth
              label="Destination Url"
              sx={{ padding: "10px" }}
              variant="outlined"
              name="destination_url"
              value={formData.destination_url
              }
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
              required
            />

            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="description"
              value={formData.description}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              required
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="destrict"
                  sx={{ padding: "10px" }}
                  variant="outlined"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  margin="normal"
                  rows={1}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Mandal"
                  variant="outlined"
                  sx={{ padding: "10px" }}
                  name="mandal"
                  value={formData.mandal}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={1}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              label="village"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="village"
              value={formData.village}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
            />

<TextField
              fullWidth
              label="Youtube url"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="youtube_url"
              value={formData.youtube_url}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
            />

            <TextField
              fullWidth
              label="Tags"
              variant="outlined"
              sx={{ padding: "10px" }}
              onKeyPress={handleAddTag}
              margin="normal"
              placeholder="Press Enter or comma to add a tag"
            />
            <Box sx={{ padding: "10px" }}>
              <Paper
                component="ul"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px",
                  marginTop: "1px",
                }}
              >
                {formData.tags.map((tag, index) => (
                  <li key={index} style={{ listStyle: "none" }}>
                    <Chip
                      label={tag}
                      onDelete={handleDeleteTag(tag)}
                      style={{ margin: "4px" }}
                    />
                  </li>
                ))}
              </Paper>
            </Box>

            <Box container spacing={2} display="flex">
              <Box
                marginBottom="20px"
                marginTop="20px"
                sx={{ padding: "10px" }}
                item
                xs={6}
              >
                <input
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple // Enable multiple file selection
                  style={{
                    display: "none",
                  }}
                />
                <label htmlFor="images">
                  <Button variant="outlined" component="span">
                    Upload Images
                  </Button>
                </label>
                {images.length > 0 && (
                  <ul>
                    {images.map((image, index) => (
                      <li key={index}>
                        <img
                          src={
                            typeof image === "object"
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveImage(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

              <Box
                marginBottom="20px"
                sx={{ paddingLeft: "270px", paddingTop: "30px" }}
                item
                xs={6}
              >
                <input
                  type="file"
                  id="video"
                  name="video"
                  accept="video/*"
                  onChange={handleVideoChange}
                  multiple
                  style={{
                    display: "none",
                  }}
                />
                <label htmlFor="video">
                  <Button variant="outlined" component="span">
                    Upload Video
                  </Button>
                </label>
                {video.length > 0 && (
                  <ul>
                    {video.map((video, index) => (
                      <li key={index}>
                        <video
                          src={
                            typeof video === "object"
                              ? URL.createObjectURL(video)
                              : video
                          }
                          controls
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveVideo(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>
            <Box
              marginTop="55px"
              display="flex"
              justifyContent="flex-end"
              sx={{ padding: "15px" }}
            >
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};
export default EditForm;



// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import ReactQuill from "react-quill"; 
// import "react-quill/dist/quill.snow.css"; 

// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { getNewsList } from "../../../services/news";
// import { PushNotification } from "./notification";

// const NotificationForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const [formData, setFormData] = useState({
//     news_id: id,
//     title: "", 
//     include_image: false,
//   });

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getNewsList(1, 10, id);
//       data = data.info[0];

//       setFormData((prevData) => ({
//         ...prevData,
//         news_id: data?.id,
//         title: data?.title || "", 
//       }));
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTitleChange = (value) => {
//     // Handle title change
//     setFormData((prevData) => ({
//       ...prevData,
//       title: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Convert color codes from RGB to hexadecimal
//       const htmlContent = formData.title
//         .replace(/<p>/g, "") // Remove <p> tags
//         .replace(/<\/p>/g, "") // Remove </p> tags
//         .replace(/rgb\((\d+), (\d+), (\d+)\)/g, (match, r, g, b) => {
//           return (
//             "#" +
//             ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
//               .toString(16)
//               .slice(1)
//           );
          
//         });

//       // Send the constructed HTML content

//       const notificationResponse = await PushNotification({
//         ...formData,
//         title: `<html>${htmlContent}</html>`,
//       });

//       if (notificationResponse && notificationResponse.success) {
//         alert("Data submitted successfully and notification sent");
//         onFormSubmit(navigate("/dhunia_bo/posts"));
//       } else {
//         const errorMessage =
//           notificationResponse && notificationResponse.message
//             ? notificationResponse.message
//             : "Unknown error occurred while sending notification";
//         console.error(errorMessage);
//         alert("Error in sending notification");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       include_image: false,
//     });
//     navigate("/dhunia_bo/posts");
//   };

//   const modules = {
//     toolbar: [
//       [{ font: [] }],
//       [{ size: [] }],
//       [{ header: "1" }, { header: "2" }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ color: [] }, { background: [] }], 
//       [{ list: "ordered" }, { list: "bullet" }],
//       [{ indent: "-1" }, { indent: "+1" }],
//       [{ align: [] }],
//       ["link", "image"],
//       ["clean"],
//     ],
//   };

//   return (
//     <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
//       <Typography variant="h3" fontWeight="bold" marginBottom="20px">
//         PUSH NOTIFICATION
//       </Typography>
//       <form>
//         <ReactQuill 
//           theme="snow"
//           value={formData.title}
//           onChange={handleTitleChange}
//           modules={modules}
//           style={{ height: "100px" }}
//         />
//         <FormControl fullWidth margin="normal" style={{ marginTop: "100px" }}>
//           <InputLabel htmlFor="include-images">Include Images</InputLabel>
//           <Select
//             id="include_image"
//             name="include_image"
//             value={formData.include_image}
//             onChange={handleChange}
//             label="Include Images"
//           >
//             <MenuItem value={true}>True</MenuItem>
//             <MenuItem value={false}>False</MenuItem>
//           </Select>
//         </FormControl>

//         <Box marginTop="10px" display="flex" justifyContent="flex-end">
//           <Button
//             type="button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//           >
//             Send
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default NotificationForm;




