import axios from "axios";

export const getBanners = async (offset = 1, limit = 10, id = null) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let response;
  if (id) {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}backoffice/news/banners?id=${id}`,
      {
        headers,
      }
    );
  } else {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}backoffice/news/banners?&offset=${offset}&limit=${limit}`,
      {
        headers,
      }
    );
  }

  if (response?.data?.success) {
    return response.data;
  }
  return null;
};

// banner form post api



export const createBannerForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}backoffice/news/banners`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

// for local storage
export const UploadBannerFiles = async (formData, withUpload = true) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  try {
    let config = {};
    if (withUpload) {
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/store/files`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

export const updateBannerForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASEURL}backoffice/news/banners`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};
