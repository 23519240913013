import React from "react";
import { useState } from "react";
import MandalsTable from "./mandals-table";

function Mandals() {
  const [filters, setFilters] = useState({
    offset: 1,
    limit: 10,
    id: null,
     
  });

  return (
    <div>
      <MandalsTable filters={filters} setFilters={setFilters} />
    </div>
  );
}

export default Mandals;
