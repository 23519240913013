import DraggableList from "./list/DraggableList";
import Card from "./card/Card";

function Trending() {
  return (
    <>
      <h3 className="header" style={{ paddingLeft: "40px" }}>
        Movies Trending List
      </h3>
      <DraggableList
        data={[]}
        renderItemContent={(item) => <Card item={item} />}
      />
    </>
  );
}

export default Trending;
