// import React from "react";

// function OpenApps () {

//     return(
//         <>
//         <h1>OPEN APPS </h1>
//         </>
//     )
// }

// export default OpenApps;


import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Icon, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { getOpenApps } from "./open-apps";

const CustomCard = styled(Card)(({ color, hoverColor }) => ({
  backgroundColor: color,
  color: "#fff",
  width: "100%",
  height: "150px", // Set a fixed height
  transition: "background-color 0.3s ease, transform 0.3s ease",
  "&:hover": {
    backgroundColor: hoverColor,
    transform: "scale(1.05)", // Slightly increase size on hover
  },
}));

const cardStyles = [
  { color: "#f44336", hoverColor: "#d32f2f" }, // Red
  { color: "#e91e63", hoverColor: "#c2185b" }, // Pink
  { color: "#9c27b0", hoverColor: "#7b1fa2" }, // Purple
  { color: "#673ab7", hoverColor: "#512da8" }, // Deep Purple
  { color: "#3f51b5", hoverColor: "#303f9f" }, // Indigo
  { color: "#2196f3", hoverColor: "#1976d2" }, // Blue
];

const cardData = [
  { title: "Installs", icon: "install_icon", key: "installs" },
  { title: "Daily Users", icon: "daily_users_icon", key: "dau" },
  { title: "News Count", icon: "news_count_icon", key: "news_count" },
  { title: "Approved News", icon: "approved_news_icon", key: "approved_news_count" },
  { title: "Rejected News", icon: "rejected_news_icon", key: "rejected_news_count" },
  { title: "New Reporters", icon: "new_reporters_icon", key: "new_reporters_count" },
];

function OpenApps() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError(null);
      const result = await getOpenApps();

      if (result) {
        setData(result.info);
      } else {
        setError("Failed to fetch data.");
      }
      setLoading(false);
    }

    fetchData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <>
      <h1>OPEN APPS</h1>
      <Grid container spacing={2}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <CustomCard color={cardStyles[index % cardStyles.length].color} hoverColor={cardStyles[index % cardStyles.length].hoverColor}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <Icon>{card.icon}</Icon> {card.title}
                </Typography>
                <Typography variant="h4">
                  {data[card.key] !== undefined ? data[card.key] : "Loading..."}
                </Typography>
              </CardContent>
            </CustomCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default OpenApps;
