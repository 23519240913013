import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const TimeInput = ({ open, handleClose, handleSubmit }) => {
  const [timeFields, setTimeFields] = React.useState([{ time: "" }]);

  const handleTimeChange = (index, event) => {
    const newTimeFields = [...timeFields];
    newTimeFields[index].time = event.target.value;
    setTimeFields(newTimeFields);
  };

  const handleAddField = () => {
    setTimeFields([...timeFields, { time: "" }]);
  };

  const handleFormSubmit = () => {
    handleSubmit(timeFields);
  };
  const handleCancel = () => {
    setTimeFields([{ time: "" }]); // Reset the form fields
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align="center">Select Time</DialogTitle>
      <DialogContent>
        {timeFields.map((field, index) => (
          <Box key={index} display="grid" alignItems="center" width="20rem" mb={6}>
            <TextField
              type="text"
              value={field.time}
              onChange={(event) => handleTimeChange(index, event)}
              fullWidth
            />
            <Box>
            {index === timeFields.length - 1 && (
              <IconButton onClick={handleAddField} color="primary"  sx={{ ml: 16, width: 56, height: 56 }}>
                <AddIcon />
              </IconButton>
            )}
            </Box>

          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleFormSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TimeInput;
