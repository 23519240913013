


import axios from "axios";

export const PushNotification = async (formData ) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
   
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}backoffice/news/posts/notification`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};


