import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ListRealEstate from "./ListRealEstate";
import Header from "../../../components/Header";
import { createClassifieds, uploadImages } from "../../../services/jobs";
import ImageUploader from "../jobs/imageUploader";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { options } from "../../../utils/locations";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const CreateRealEstate = ({ classified_type, permissions }) => {
  // console.log(classified_type);
  // const [file, setFile] = useState();
  const [value, setValue] = useState("ACTIVE");
  const [file, setFile] = useState([]);
  const [thumbnailFiles, setThumbnailFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [RealEstateData, setRealEstateData] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [isCoverSelected, setCoverIsSelected] = useState(false);
  const [logoFile, setLogoFile] = useState();
  const [logoSelectedFile, setLogoSelectedFile] = useState();
  const [coverFile, setCoverFile] = useState();
  const [coverSelectedFile, setCoverSelectedFile] = useState();
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setRealEstateData({});
    setIsSelected(false);
    setCoverIsSelected(false);
  }
  const changeHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setLogoSelectedFile(event.target.files[0]);
      setIsSelected(true);
      setLogoFile(uploadResponse?.info[0]);
    }
  };
  const changeCoverHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setCoverSelectedFile(event.target.files[0]);
      setCoverIsSelected(true);
      setCoverFile(uploadResponse?.info[0]);
    }
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("title", RealEstateData?.title);
    formData.append("other_details", RealEstateData?.other_details);
    formData.append("description", RealEstateData?.description);
    formData.append("highlights", RealEstateData?.highlights?.split(","));
    formData.append("price", RealEstateData?.price);
    formData.append("classified_type", classified_type);
    // formData.append("classified_type", RealEstateData?.classified_type);
    if (classified_type === "REAL_ESTATE") {
      formData.append("real_estate_type", RealEstateData?.real_estate_type);
      formData.append("listing_type", RealEstateData?.listing_type);
    }
    formData.append("contact_name", RealEstateData?.contact_name);
    formData.append("mobile", RealEstateData?.mobile);
    formData.append("whatsapp", RealEstateData?.whatsapp);
    formData.append("email", RealEstateData?.email);
    formData.append("location", RealEstateData?.location);
    // for (var i = 0; i < thumbnailFiles.length; i++) {
    //   formData.append("pictures", thumbnailFiles[i]);
    // }
    if (file) {
      for (let i = 0; i < file.length; i++) {
        formData.append("thumbnail", file[i]);
      }
    }
    formData.append("pictures", logoFile);
    formData.append("cover", coverFile);
    let response = await createClassifieds(formData);
    if (response?.success) {
      window.location.reload();
      setOpen(false);
      setRealEstateData({});
      setIsSelected(false);
      setCoverIsSelected(false);
    }
  };
  const handleDataChange = (event) => {
    setRealEstateData((RealEstateData) => {
      let key = event.target.id;
      if (event.target.id.includes("location")) {
        // console.log(event.target.innerHTML);
        RealEstateData["location"] = `${event.target.innerHTML}`;
      } else {
        RealEstateData[`${key}`] = `${event.target.value}`;
      }
      return RealEstateData;
    });
  };
  const handleDropdownDataChange = (event) => {
    setRealEstateData((RealEstateData) => {
      let key = event.target.name;
      RealEstateData[`${key}`] = `${event.target.value}`;
      return RealEstateData;
    });
  };
  const handleTabChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  return (
    <Container sx={{ m: "10px" }}>
      <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
        <CardHeader>
          <Header
            title={
              classified_type === "REAL_ESTATE" ? "Real Estate" : "Buy / Sell"
            }
          />
          {permissions.write_jobmaster_permission && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Create
            </Button>
          )}
        </CardHeader>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0 }}>
            <TabList onChange={handleTabChange}>
              <Tab label="ACTIVE" value="ACTIVE" />
              <Tab label="INACTIVE" value="INACTIVE" />
            </TabList>
          </Box>
          <TabPanel value="ACTIVE" sx={{ padding: 0 }}>
            <ListRealEstate
              classified_type={classified_type}
              is_active={value}
              permissions={permissions}
            />
          </TabPanel>
          <TabPanel value="INACTIVE" sx={{ padding: 0 }}>
            <ListRealEstate
              classified_type={classified_type}
              is_active={value}
              permissions={permissions}
            />
          </TabPanel>
        </TabContext>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <b>Create</b>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <DialogContentText sx={{ mb: 1 }}>Job Title</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="title"
              label="Title"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.title}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Job Type</DialogContentText> */}
            <TextField
              autoFocus
              id="email"
              label="Email"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.email}
            />
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="job_type">Job Type</InputLabel>
              <Select
                labelId="job_type"
                id="job_type"
                label="Job Type"
                value={RealEstateData?.job_type}
                onChange={handleDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
              </Select>
            </FormControl> */}
            {/* <DialogContentText sx={{ mb: 1 }}>Job Style</DialogContentText> */}
            <Autocomplete
              id="location"
              name="location"
              options={options.sort(
                (a, b) => -b.category.localeCompare(a.category)
              )}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.label}
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  onChange={handleDataChange}
                />
              )}
            />
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-label">Job Type</InputLabel>
              <Select
                labelId="demo-label"
                id="job_style"
                label="Job Style"
                value="FULL-TIME"
                onChange={handleDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
              </Select>
            </FormControl> */}
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Job Role</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="price"
              label="Price"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.price}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Job Category</DialogContentText> */}
            <TextField
              autoFocus
              id="contact_name"
              label="Contact Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.contact_name}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Qualification</DialogContentText> */}
            <TextField
              autoFocus
              id="mobile"
              label="mobile"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.mobile}
            />
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Job Description</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="highlights"
              label="Highlights"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.highlights}
            />
            <TextField
              autoFocus
              id="other_details"
              label="Other Details"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.other_details}
            />
            <TextField
              autoFocus
              id="whatsapp"
              label="Whatsapp"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.whatsapp}
            />
          </div>
          <div style={{ display: "flex" }}>
            {/* <TextField
              autoFocus
              id="classified_type"
              label="Classified Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.classified_type}
            /> */}
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Classified Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="classified_type"
                defaultValue=""
                label="Classified Type"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="BUY-SELL">BUY-SELL</MenuItem>
                <MenuItem value="REAL_ESTATE">REAL_ESTATE</MenuItem>
                <MenuItem value="NA">NA</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </Select>
            </FormControl> */}
            {/* <TextField
              autoFocus
              id="listing_type"
              label="Listing Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.listing_type}
            /> */}
            {classified_type === "REAL_ESTATE" && (
              <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Listing Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="listing_type"
                  defaultValue=""
                  label="Listing Type"
                  onChange={handleDropdownDataChange}
                >
                  <MenuItem value="BUY">BUY</MenuItem>
                  <MenuItem value="RENT">RENT</MenuItem>
                  <MenuItem value="NA">NA</MenuItem>
                </Select>
              </FormControl>
            )}
            {/* <DialogContentText sx={{ mb: 1 }}>Max. Experience</DialogContentText> */}
            {/* <TextField
              autoFocus
              id="real_estate_type"
              label="Real Estate Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.real_estate_type}
            /> */}
            {classified_type === "REAL_ESTATE" && (
              <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
                <InputLabel id="demo-simple-select-label">
                  Real Estate Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="real_estate_type"
                  defaultValue=""
                  label="Real Estate Type "
                  onChange={handleDropdownDataChange}
                >
                  <MenuItem value="LAND">LAND</MenuItem>
                  <MenuItem value="HOUSE">HOUSE</MenuItem>
                  <MenuItem value="FLAT">FLAT</MenuItem>
                  <MenuItem value="PLOT">PLOT</MenuItem>
                  <MenuItem value="INDEPENDENT_HOUSE">
                    INDEPENDENT_HOUSE
                  </MenuItem>
                  <MenuItem value="COMMERCIAL">COMMERCIAL</MenuItem>
                  <MenuItem value="NA">NA</MenuItem>
                  <MenuItem value="OTHERS">OTHERS</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="description"
              label="Description"
              type="text"
              sx={{ width: "100%", mr: "3%", mb: 2 }}
              onChange={handleDataChange}
              value={RealEstateData?.description}
              multiline
              rows={3}
            />
          </div>
          <div style={{ display: "flex" }}>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>
                Upload Picture
              </DialogContentText>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={changeHandler} />
              </Button>
            </DialogContent>
            <DialogContent>
              {isSelected ? (
                <img
                  src={logoFile}
                  height={100}
                  width={100}
                  alt={logoSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>Upload Cover</DialogContentText>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  onChange={changeCoverHandler}
                />
              </Button>
            </DialogContent>
            <DialogContent>
              {isCoverSelected ? (
                <img
                  src={coverFile}
                  height={100}
                  width={100}
                  alt={coverSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
          </div>
          <div style={{ display: "flex" }}>
            <ImageUploader
              file={file}
              setFile={setFile}
              thumbnailFiles={thumbnailFiles}
              setThumbnailFiles={setThumbnailFiles}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
            // disabled={!(isSelected && RealEstateData)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default CreateRealEstate;
