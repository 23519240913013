export const LocationOptions = [
  {
    value: "Adilabad, TS",
    label: "Adilabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Bhadradri Kothagudem, TS",
    label: "Bhadradri Kothagudem, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Hanumakonda, TS",
    label: "Hanumakonda, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Hyderabad, TS",
    label: "Hyderabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Jagtial, TS",
    label: "Jagtial, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Jangoan, TS",
    label: "Jangoan, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Jayashankar Bhoopalpally, TS",
    label: "Jayashankar Bhoopalpally, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Jogulamba Gadwal, TS",
    label: "Jogulamba Gadwal, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Kamareddy, TS",
    label: "Kamareddy, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Karimnagar, TS",
    label: "Karimnagar, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Khammam, TS",
    label: "Khammam, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Komaram Bheem Asifabad, TS",
    label: "Komaram Bheem Asifabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Mahabubabad, TS",
    label: "Mahabubabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Mahabubnagar, TS",
    label: "Mahabubnagar, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Mancherial, TS",
    label: "Mancherial, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Medak, TS",
    label: "Medak, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Medchal-Malkajgiri, TS",
    label: "Medchal-Malkajgiri, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Mulug, TS",
    label: "Mulug, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Nagarkurnool, TS",
    label: "Nagarkurnool, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Nalgonda, TS",
    label: "Nalgonda, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Narayanpet, TS",
    label: "Narayanpet, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Nirmal, TS",
    label: "Nirmal, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Nizamabad, TS",
    label: "Nizamabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Peddapalli, TS",
    label: "Peddapalli, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Rajanna Sircilla, TS",
    label: "Rajanna Sircilla, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Rangareddy, TS",
    label: "Rangareddy, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Sangareddy, TS",
    label: "Sangareddy, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Siddipet, TS",
    label: "Siddipet, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Suryapet, TS",
    label: "Suryapet, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Vikarabad, TS",
    label: "Vikarabad, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Wanaparthy, TS",
    label: "Wanaparthy, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Warangal, TS",
    label: "Warangal, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  {
    value: "Yadadri Bhuvanagiri, TS",
    label: "Yadadri Bhuvanagiri, TS",
    state: "Telangana",
    target: { id: "job_location" },
  },
  // ];
  // export const andhraOptions = [
  {
    value: "Srikakulam, AP",
    label: "Srikakulam, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Parvathipuram Manyam, AP",
    label: "Parvathipuram Manyam, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Vizianagaram, AP",
    label: "Vizianagaram, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Visakhapatnam, AP",
    label: "Visakhapatnam, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Alluri Sitharama Raju, AP",
    label: "Alluri Sitharama Raju, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Anakapalli, AP",
    label: "Anakapalli, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Kakinada, AP",
    label: "Kakinada, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "East Godavari, AP",
    label: "East Godavari, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Dr. B. R. Ambedkar Konaseema, AP",
    label: "Dr. B. R. Ambedkar Konaseema, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Eluru, AP",
    label: "Eluru, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "West Godavari, AP",
    label: "West Godavari, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Ntr, AP",
    label: "Ntr, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Krishna, AP",
    label: "Krishna, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Palnadu, AP",
    label: "Palnadu, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Guntur , AP",
    label: "Guntur , AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Bapatla, AP",
    label: "Bapatla, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Prakasam, AP",
    label: "Prakasam, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Sri Potti Sriramulu Nellore, AP",
    label: "Sri Potti Sriramulu Nellore, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Kurnool, AP",
    label: "Kurnool, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Nandyal, AP",
    label: "Nandyal, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Anantapur, AP",
    label: "Anantapur, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Sri Sathya Sai, AP",
    label: "Sri Sathya Sai, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Kadapa, AP",
    label: "Kadapa, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Annamayya, AP",
    label: "Annamayya, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Tirupati, AP",
    label: "Tirupati, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Chittoor, AP",
    label: "Chittoor, AP",
    state: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  // ];
  // export const stateOptions = [
  {
    value: "Andhra Pradesh",
    label: "Andhra Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Arunachal Pradesh",
    label: "Arunachal Pradesh",
    target: { id: "job_location" },
  },
  { value: "Assam", label: "Assam", target: { id: "job_location" } },
  { value: "Bihar", label: "Bihar", target: { id: "job_location" } },
  {
    value: "Chhattisgarh",
    label: "Chhattisgarh",
    target: { id: "job_location" },
  },
  { value: "Goa", label: "Goa", target: { id: "job_location" } },
  { value: "Gujarat", label: "Gujarat", target: { id: "job_location" } },
  { value: "Haryana", label: "Haryana", target: { id: "job_location" } },
  {
    value: "Himachal Pradesh",
    label: "Himachal Pradesh",
    target: { id: "job_location" },
  },
  { value: "Jharkhand", label: "Jharkhand", target: { id: "job_location" } },
  { value: "Karnataka", label: "Karnataka", target: { id: "job_location" } },
  { value: "Kerala", label: "Kerala", target: { id: "job_location" } },
  {
    value: "Maharashtra",
    label: "Maharashtra",
    target: { id: "job_location" },
  },
  {
    value: "Madhya Pradesh",
    label: "Madhya Pradesh",
    target: { id: "job_location" },
  },
  { value: "Manipur", label: "Manipur", target: { id: "job_location" } },
  { value: "Meghalaya", label: "Meghalaya", target: { id: "job_location" } },
  { value: "Mizoram", label: "Mizoram", target: { id: "job_location" } },
  { value: "Nagaland", label: "Nagaland", target: { id: "job_location" } },
  { value: "Odisha", label: "Odisha", target: { id: "job_location" } },
  { value: "Punjab", label: "Punjab", target: { id: "job_location" } },
  { value: "Rajasthan", label: "Rajasthan", target: { id: "job_location" } },
  { value: "Sikkim", label: "Sikkim", target: { id: "job_location" } },
  { value: "Tamil Nadu", label: "Tamil Nadu", target: { id: "job_location" } },
  { value: "Tripura", label: "Tripura", target: { id: "job_location" } },
  { value: "Telangana", label: "Telangana", target: { id: "job_location" } },
  {
    value: "Uttar Pradesh",
    label: "Uttar Pradesh",
    target: { id: "job_location" },
  },
  {
    value: "Uttarakhand",
    label: "Uttarakhand",
    target: { id: "job_location" },
  },
  {
    value: "West Bengal",
    label: "West Bengal",
    target: { id: "job_location" },
  },
  {
    value: "Andaman & Nicobar (UT)",
    label: "Andaman & Nicobar (UT)",
    target: { id: "job_location" },
  },
  {
    value: "Chandigarh (UT)",
    label: "Chandigarh (UT)",
    target: { id: "job_location" },
  },
  {
    value: "Dadra & Nagar Haveli and Daman & Diu (UT)",
    label: "Dadra & Nagar Haveli and Daman & Diu (UT)",
  },
  {
    value: "Delhi [National Capital Territory (NCT)]",
    label: "Delhi [National Capital Territory (NCT)]",
  },
  {
    value: "Jammu & Kashmir (UT)",
    label: "Jammu & Kashmir (UT)",
    target: { id: "job_location" },
  },
  {
    value: "Ladakh (UT)",
    label: "Ladakh (UT)",
    target: { id: "job_location" },
  },
  {
    value: "Lakshadweep (UT)",
    label: "Lakshadweep (UT)",
    target: { id: "job_location" },
  },
  {
    value: "Puducherry (UT)",
    label: "Puducherry (UT)",
    target: { id: "job_location" },
  },
];
// export const groupedOptions = [
//   {
//     label: "Telangana",
//     options: TelanganaOptions,
//   },
//   {
//     label: "Andhra Pradesh",
//     options: andhraOptions,
//   },
//   {
//     label: "States",
//     options: stateOptions,
//   },
// ];
// export const groupStyles = {
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "space-between",
// };
// export const groupBadgeStyles = {
//   backgroundColor: "#EBECF0",
//   borderRadius: "2em",
//   color: "#172B4D",
//   display: "inline-block",
//   fontSize: 12,
//   fontWeight: "normal",
//   lineHeight: "1",
//   minWidth: 1,
//   padding: "0.16666666666667em 0.5em",
//   textAlign: "center",
// };
export const options = LocationOptions.map((option) => {
  const category = option?.state ? option.state : "State";
  return {
    category: category,
    ...option,
  };
});
