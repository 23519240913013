import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FormControlLabel from "@mui/material/FormControlLabel";
import Categories from "./categories";
import Header from "../../components/Header";
import { createCategory } from "../../services/category";
import Switch from "@mui/material/Switch";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const CreateCategory = ({ permissions }) => {
  const [file, setFile] = useState();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [categoryName, setCategoryName] = useState("");
  const [display_name, setDisplayName] = useState("");
  const [is_active, setIsActive] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setCategoryName("");
    setDisplayName("");
    setIsSelected(false);
    setIsActive(false);
  }
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
    setFile(URL.createObjectURL(event.target.files[0]));
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("icon_image", selectedFile);
    formData.append("name", categoryName);
    formData.append("display_name", display_name);
    let response = await createCategory(formData);
    if (response?.success) {
      setOpen(false);
      setCategoryName("");
      setIsSelected(false);
    }
  };
  const handleCategoryName = (event) => {
    setCategoryName(event.target.value);
  };
  return (
    <Container sx={{ m: "10px" }}>
      <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
        <CardHeader>
          <Header title="CATEGORIES" />
          {permissions?.write_newscategories_permission && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Create
            </Button>
          )}
        </CardHeader>
        <Categories permissions={permissions} />
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Create a Category</DialogTitle>
        <DialogContent>
          {/* <DialogContentText sx={{ mb: 1 }}>Category name</DialogContentText> */}
          <TextField
            sx={{ mt: 2 }}
            autoFocus
            id="name"
            label="Category name"
            type="text"
            fullWidth
            onChange={handleCategoryName}
            value={categoryName}
          />
        </DialogContent>
        <DialogContent>
          {/* <DialogContentText sx={{ mb: 1 }}>Category name</DialogContentText> */}
          <TextField
            sx={{ mt: 2 }}
            autoFocus
            id="display_name"
            label="Display name"
            type="text"
            fullWidth
            onChange={handleCategoryName}
            value={display_name}
          />
        </DialogContent>
        <FormControlLabel
          value="is_active"
          control={
            <Switch
              // sx={{ width: "5%", mb: 2 }}
              id="is_active"
              checked={is_active}
            />
          }
          label="Is Active"
          labelPlacement="top"
        />
        <div style={{ display: "flex" }}>
          <DialogContent>
            <DialogContentText sx={{ mb: 1 }}>
              Upload category image.
            </DialogContentText>
            <Box>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={changeHandler} />
              </Button>
            </Box>
          </DialogContent>
          <DialogContent>
            {isSelected ? (
              <img
                src={file}
                height={100}
                width={100}
                alt={selectedFile.name}
              />
            ) : (
              <></>
            )}
          </DialogContent>
        </div>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
            disabled={!(isSelected && categoryName)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default CreateCategory;
