


// import React, { useEffect, useState } from "react";
// import { getWithdrawals } from "./withdrwal";
// import { useNavigate } from "react-router-dom";
// import {
//   Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
//   Paper, Typography, TablePagination, Avatar, Box,
//   Button
// } from '@mui/material';

// function WithdrawalTable() {
//   const [withdrawals, setWithdrawals] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     async function fetchData() {
//       const offset = page * rowsPerPage + 1;  
//       const data = await getWithdrawals( offset, rowsPerPage);
//       if (data && data.info) {
//         setWithdrawals(data.info);
//       }
//     }
//     fetchData();
//   }, [ page, rowsPerPage]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleViewClick = (withdrawal) => {
//     navigate(`/dhunia_bo/user-bank-details/${withdrawal.id}`); // Pass withdrawal.id to URL
//   };

 

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   return (
//     <>
//     <Box sx={{ p: 3, }} >
//     <Typography variant="h4" align="center" gutterBottom>
//       Withdrawal Table
//     </Typography>
//     <TableContainer component={Paper} sx={{ mt: 3 ,background : "#007effa6" }}>
//       <Table>
//         <TableHead sx={{ backgroundColor: "#f5f5f5" }}>
//           <TableRow>
//             <TableCell>Username</TableCell>
//             <TableCell>Profile Image</TableCell>
//             <TableCell>Full Name</TableCell>
//             <TableCell>Mobile</TableCell>
//             <TableCell>Total Earnings</TableCell>
//             <TableCell>Earnings</TableCell>
//             <TableCell>Reporter</TableCell>
//             <TableCell>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {withdrawals.map((withdrawal, index) => (
//             <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: "#f9f9f9" } }}>
//               <TableCell>{withdrawal.username}</TableCell>
//               <TableCell>
//                 <Avatar src={withdrawal.profile_image} alt="Profile" sx={{ width: 50, height: 50 }} />
//               </TableCell>
//               <TableCell>{withdrawal.full_name}</TableCell>
//               <TableCell>{withdrawal.mobile}</TableCell>
//               <TableCell>{withdrawal.total_earnings}</TableCell>
//               <TableCell>{withdrawal.earnings}</TableCell>
//               <TableCell>{withdrawal.user_role}</TableCell>
//               <TableCell>
//                 <Button variant="contained" color="primary" onClick={() => handleViewClick(withdrawal)}>
//                   View
//                 </Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//     <TablePagination
//       component="div"
//       count={withdrawals.total || 0}
//       page={page}
//       onPageChange={handleChangePage}
//       rowsPerPage={rowsPerPage}
//       onRowsPerPageChange={handleChangeRowsPerPage}
//     />
//   </Box>
//   </>
//   );
// }

// export default WithdrawalTable;



import React, { useEffect, useState } from "react";
import { getWithdrawals } from "./withdrwal";
import { useNavigate } from "react-router-dom";
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, TablePagination, Avatar, Box,
  Button
} from '@mui/material';

function WithdrawalTable() {
  const [withdrawals, setWithdrawals] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate(); 

  useEffect(() => {
    async function fetchData() {
      const offset = page * rowsPerPage + 1;  
      const data = await getWithdrawals(offset, rowsPerPage);
      if (data && data.info) {
        setWithdrawals(data.info);
      }
    }
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleViewClick = (withdrawal) => {
    navigate(`/dhunia_bo/user-bank-details/${withdrawal.id}`); // Pass withdrawal.id to URL
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box   sx={{
        p: 3,        
        m: 2,         
        boxShadow: 3,  
        borderRadius: 2, 
        bgcolor: 'background.paper',
      }}>
        <Typography variant="h4" align="center" gutterBottom fontWeight="bold"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', 
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          WITHDRAWAL TABLE
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 3, background: "#007effa6" }}>
          <Table sx={{ border: "1px solid #007eff" }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#007eff" }}>
                <TableCell sx={{ color: "#fff" , fontWeight : "bold" , width : "12.5%"}}>Username</TableCell>
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%" }}>Profile Image</TableCell>
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%" }}>Full Name</TableCell>
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%" }}>Mobile</TableCell>
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%" }}>Total Earnings</TableCell>
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%" }}>Earnings</TableCell>
               
                <TableCell sx={{ color: "#fff" ,fontWeight : "bold" ,width : "12.5%"}}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawals.map((withdrawal, index) => (
                <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: "#f9f9f9" } }}>
                  <TableCell>{withdrawal.username}</TableCell>
                  <TableCell>
                    <Avatar src={withdrawal.profile_image} alt="Profile" sx={{ width: 50, height: 50 }} />
                  </TableCell>
                  <TableCell>{withdrawal.full_name}</TableCell>
                  <TableCell>{withdrawal.mobile}</TableCell>
                  <TableCell>{withdrawal.total_earnings}</TableCell>
                  <TableCell>{withdrawal.earnings}</TableCell>
                  
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => handleViewClick(withdrawal)}>
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={withdrawals.total || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{backgroundColor : "#007eff"}}
        />
      </Box>
    </>
  );
}

export default WithdrawalTable;
