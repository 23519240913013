import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export default function useToken() {
  const [token, setToken] = useState();
  const [permissions, setPermisssions] = useState({
    read_newsreporters_permission: false,
    write_newsreporters_permission: false,
    read_newscategories_permission: false,
    write_newscategories_permission: false,
    read_newsmaster_permission: false,
    write_newsmaster_permission: false,
    read_jobmaster_permission: false,
    write_jobmaster_permission: false,
    is_bo_user: false,
    is_bo_admin: false,
  });
  // const [token, setToken] = useState(getToken());
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      const userData = JSON.parse(userDetails);
      // console.log(userData);

      let custom_permissions = userData?.profile_details?.custom_permissions;
      let is_bo_user = custom_permissions.includes("is_bo_user");
      let is_bo_admin = custom_permissions.includes("is_bo_admin");
      let read_newsreporters_permission =
        custom_permissions.includes("is_read_bo_newsreporters") ||
        custom_permissions.includes("is_write_bo_newsreporters") ||
        custom_permissions.includes("is_update_bo_newsreporters") ||
        custom_permissions.includes("is_delete_bo_newsreporters");
      let write_newsreporters_permission =
        custom_permissions.includes("is_write_bo_newsreporters") ||
        custom_permissions.includes("is_update_bo_newsreporters") ||
        custom_permissions.includes("is_delete_bo_newsreporters");
      let read_newscategories_permission =
        custom_permissions.includes("is_read_bo_newscategories") ||
        custom_permissions.includes("is_write_bo_newscategories") ||
        custom_permissions.includes("is_update_bo_newscategories") ||
        custom_permissions.includes("is_delete_bo_newscategories");
      let write_newscategories_permission =
        custom_permissions.includes("is_write_bo_newscategories") ||
        custom_permissions.includes("is_update_bo_newscategories") ||
        custom_permissions.includes("is_delete_bo_newscategories");
      let read_newsmaster_permission =
        custom_permissions.includes("is_read_bo_newsmaster") ||
        custom_permissions.includes("is_write_bo_newsmaster") ||
        custom_permissions.includes("is_update_bo_newsmaster") ||
        custom_permissions.includes("is_delete_bo_newsmaster");
      let write_newsmaster_permission =
        custom_permissions.includes("is_write_bo_newsmaster") ||
        custom_permissions.includes("is_update_bo_newsmaster") ||
        custom_permissions.includes("is_delete_bo_newsmaster");
      let read_jobmaster_permission =
        custom_permissions.includes("is_read_bo_jobmaster") ||
        custom_permissions.includes("is_write_bo_jobmaster") ||
        custom_permissions.includes("is_update_bo_jobmaster") ||
        custom_permissions.includes("is_delete_bo_jobmaster");
      let write_jobmaster_permission =
        custom_permissions.includes("is_write_bo_jobmaster") ||
        custom_permissions.includes("is_update_bo_jobmaster") ||
        custom_permissions.includes("is_delete_bo_jobmaster");
      setPermisssions((prevPermissions) => {
        return {
          ...prevPermissions,
          read_newsreporters_permission,
          write_newsreporters_permission,
          read_newscategories_permission,
          write_newscategories_permission,
          read_newsmaster_permission,
          write_newsmaster_permission,
          read_jobmaster_permission,
          write_jobmaster_permission,
          is_bo_user,
          is_bo_admin,
        };
      });

      setToken(userData?.access_token);
      // return userData?.access_token;
    }
  }, [token]);
  // const getToken = () => {
  //   const userDetails = localStorage.getItem("userDetails");
  //   // const tokenString = localStorage.getItem("token");
  //   if (userDetails) {
  //     const userData = JSON.parse(userDetails);
  //     console.log(userData);
  //     setToken(userData?.access_token);
  //     return userData?.access_token;
  //   }
  // };
  const navigate = useNavigate();
  const saveToken = (userDetails) => {
    // localStorage.setItem("token", JSON.stringify(userToken));
    setToken(userDetails?.access_token);
    navigate("/dhunia_bo");
  };
  const logoutUser = () => {
    setToken(null);
    localStorage.removeItem("userDetails");
    // localStorage.removeItem("token");
    navigate("/dhunia_bo/login");
  };
  // console.log({
  //   setToken: saveToken,
  //   token,
  //   logoutUser,
  // });
  return {
    setToken: saveToken,
    token,
    logoutUser,
    permissions,
  };
}
