import { Box, styled, Card, Button, useTheme } from "@mui/material";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
// import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import Movies from "./movies";
import Header from "../../../components/Header";
import { createMovie, getGenres } from "../../../services/ott_movies";
import { uploadImages } from "../../../services/jobs";
import ConfirmationDialog from "./selectMovie";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
// import { useFormik } from "formik";
// import * as yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
// import Paper from "@mui/material/Paper";
import { tokens } from "../../../theme";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom";
// const validationSchema = yup.object({
//   email: yup
//     .string("Enter your email")
//     .email("Enter a valid email")
//     .required("Email is required"),
// });
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
// const Title = styled("span")(() => ({
//   fontSize: "1rem",
//   fontWeight: "500",
//   textTransform: "capitalize",
// }));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CreateMovie = () => {
  // const [file, setFile] = useState();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  // const [file, setFile] = useState([]);
  // const [thumbnailFiles, setThumbnailFiles] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [selectedFile, setSelectedFile] = useState();
  const [movieData, setMoviesData] = useState({});
  // const [isSelected, setIsSelected] = useState(false);
  const [genres, setGenres] = useState([]);
  // const [thumbnails, setThumbnails] = useState([]);
  // const [videos, setVideos] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState("");
  const [selectedCover, setSelectedCover] = useState("");
  const [selectedTrailer, setSelectedTrailer] = useState("");
  const [selectedMovie, setSelectedMovie] = useState("");
  const [selectedMovieMaker, setSelectedMovieMaker] = useState("");
  const tags = [{ name: "New" }, { name: "Banner" }, { name: "Top" }];
  useEffect(() => {
    getGenres().then((genres) => {
      // console.log("genres ", genres);
      setGenres(genres);
    });
    // getUploadData("THUMBNAILS").then((thumbnails) => {
    //   // console.log("thumbnails ", thumbnails);
    //   setThumbnails(thumbnails);
    // });
    // getUploadData("VIDEOS").then((videos) => {
    //   // console.log("videos ", videos);
    //   setVideos(videos);
    // });
  }, []);
  // function handleClickOpen() {
  //   setOpen(true);
  // }
  function handleClose() {
    // setOpen(false);
    setMoviesData({});
    // setIsSelected(false);
  }
  const handleGenresChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleTagsChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleTrailerChange = (event) => {
    const { id, key, value } = event;
    setSelectedTrailer(event);
  };
  const handleMovieChange = (event) => {
    const { id, key, value } = event;
    setSelectedMovie(event);
  };
  const handleMovieMakerChange = (event) => {
    const { id, key, value } = event;
    setSelectedMovieMaker(event);
  };
  const changeThumbnailHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "ott");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setSelectedThumbnail(uploadResponse?.info[0]);
    }
  };
  const changeCoverHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "ott");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setSelectedCover(uploadResponse?.info[0]);
    }
  };
  const handleSubmission = async () => {
    movieData["genres"] = selectedGenres;
    movieData["tags"] = selectedTags;
    movieData["thumbnail_image"] = selectedThumbnail ? selectedThumbnail : "";
    movieData["cover_image"] = selectedCover ? selectedCover : "";
    movieData["trailer_id"] = selectedTrailer?.id ? selectedTrailer.id : "";
    movieData["movie_id"] = selectedMovie?.id ? selectedMovie.id : "";
    movieData["movie_maker_id"] = selectedMovieMaker?.id
      ? selectedMovieMaker.id
      : "";
    // console.log(movieData);
    const create_resp = await createMovie(movieData);
    // console.log(create_resp);
    if (create_resp && create_resp?.success && create_resp?.info?.id) {
      navigate("/dhunia_bo/movies");
    }
  };
  const handleDataChange = (event) => {
    setMoviesData((movieData) => {
      let key = event.target.id;
      let value = `${event.target.value}`;
      if (key === "coming_soon") {
        value = event.target.checked;
      }
      movieData[`${key}`] = value;
      return movieData;
    });
  };
  const handleDropdownDataChange = (event) => {
    // console.log(event.target.name, event.target.value);
    setMoviesData((movieData) => {
      let key = event.target.name;
      // console.log(movieData, event.target.value);
      movieData[`${key}`] = `${event.target.value}`;
      return movieData;
    });
  };
  return (
    // <></>
    <Container sx={{ m: "10px" }}>
      <Box
        m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {/* <Paper sx={{ width: "100%", overflow: "hidden" }}> */}
        <Card elevation={3} sx={{ p: "20px", mb: 2 }}>
          <CardHeader>
            <Header title="CREATE MOVIE" />
            {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Create
          </Button> */}
          </CardHeader>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="title"
              label="Title"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.title}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Type</DialogContentText> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select
                labelId="language-select-label"
                id="language-select"
                name="language"
                defaultValue=""
                label="Language"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="TELUGU">TELUGU</MenuItem>
                <MenuItem value="ENGLISH">ENGLISH</MenuItem>
                <MenuItem value="HINDI">HINDI</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "34%", mb: 2 }}>
              <InputLabel id="genres-checkbox-label">Genres</InputLabel>
              <Select
                labelId="genres-checkbox-label"
                id="genres-checkbox"
                multiple
                defaultValue={selectedGenres}
                value={selectedGenres}
                onChange={handleGenresChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {genres.map((genre) => (
                  <MenuItem key={genre.name} value={genre.name}>
                    <Checkbox
                      checked={selectedGenres.indexOf(genre.name) > -1}
                    />
                    <ListItemText primary={genre.display_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Role</DialogContentText> */}
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Category</DialogContentText> */}
            <TextField
              autoFocus
              id="release_date"
              label="Realease Year"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.release_date}
            />
            <TextField
              autoFocus
              id="duration_in_mins"
              label="Duration in Mins"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.duration_in_mins}
            />
            <FormControl sx={{ width: "34%", mb: 2 }}>
              <InputLabel id="tags-checkbox-label">Tags</InputLabel>
              <Select
                labelId="tags-checkbox-label"
                id="tags-checkbox"
                multiple
                defaultValue={selectedTags}
                value={selectedTags}
                onChange={handleTagsChange}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.name} value={tag.name}>
                    <Checkbox checked={selectedTags.indexOf(tag.name) > -1} />
                    <ListItemText primary={tag.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Category</DialogContentText> */}
            <TextField
              autoFocus
              id="banner_name"
              label="Banner Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.banner_name}
            />
            <TextField
              autoFocus
              id="cast"
              label="Cast (Enter Names separated by comma)"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.cast}
            />
            <div style={{ width: "34%", mr: "2%", mb: 2 }}>
              <span>Coming Soon</span>
              <Switch
                id="coming_soon"
                checked={movieData?.coming_soon}
                // onChange={handleActiveStatusChange}
                label="Coming Soon"
                name="coming_soon"
                onChange={handleDataChange}
                value={movieData?.coming_soon}
              />
            </div>
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Movie Description</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="director_name"
              label="Director Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.director_name}
            />
            <TextField
              autoFocus
              id="synopsis"
              label="Movie Description"
              type="text"
              sx={{ width: "67%", mb: 2 }}
              onChange={handleDataChange}
              value={movieData?.synopsis}
              multiline
              rows={3}
            />
          </div>
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Category</DialogContentText> */}
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="thumbnail-checkbox-label">
                Thumbnail Image
              </InputLabel>
              <Select
                labelId="thumbnail-checkbox-label"
                id="thumbnail-checkbox"
                defaultValue=""
                value={selectedThumbnail ? selectedThumbnail : ""}
                onChange={handleThumbnailChange}
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
              >
                {thumbnails.map((thmbnail) => (
                  <MenuItem key={thmbnail.id} value={thmbnail.id}>
                    {thmbnail.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="cover-checkbox-label">Cover Image</InputLabel>
              <Select
                labelId="cover-checkbox-label"
                id="cover-checkbox"
                defaultValue=""
                value={selectedCover ? selectedCover : ""}
                onChange={handleCoverChange}
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
              >
                {thumbnails.map((thmbnail) => (
                  <MenuItem key={thmbnail.id} value={thmbnail.id}>
                    {thmbnail.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            {/* <FormControl sx={{ width: "34%", mb: 2 }}>
              <InputLabel id="trailer-checkbox-label">Trailer</InputLabel>
              <Select
                labelId="trailer-checkbox-label"
                id="trailer-checkbox"
                defaultValue=""
                value={selectedTrailer ? selectedTrailer : ""}
                onChange={handleTrailerChange}
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
              >
                {videos.map((vid) => (
                  <MenuItem key={vid.id} value={vid.id}>
                    {vid.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <DialogContent>
                <DialogContentText sx={{ mb: 1 }}>
                  Upload Thumbnail image.
                </DialogContentText>
                <Box>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={changeThumbnailHandler}
                    />
                  </Button>
                </Box>
              </DialogContent>
              <DialogContent>
                {selectedThumbnail ? (
                  <img src={selectedThumbnail} height={100} width={100} />
                ) : (
                  <></>
                )}
              </DialogContent>
            </div>
            <div style={{ display: "flex" }}>
              <DialogContent>
                <DialogContentText sx={{ mb: 1 }}>
                  Upload Cover image.
                </DialogContentText>
                <Box>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload file
                    <VisuallyHiddenInput
                      type="file"
                      onChange={changeCoverHandler}
                    />
                  </Button>
                </Box>
              </DialogContent>
              <DialogContent>
                {selectedCover ? (
                  <img src={selectedCover} height={100} width={200} />
                ) : (
                  <></>
                )}
              </DialogContent>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            {/* <DialogContentText sx={{ mb: 1 }}>Movie Category</DialogContentText> */}
            {/* <FormControl sx={{ width: "31%", mb: 2 }}>
              <InputLabel id="movie-checkbox-label">Movie</InputLabel>
              <Select
                labelId="movie-checkbox-label"
                id="movie-checkbox"
                defaultValue=""
                value={selectedMovie ? selectedMovie : ""}
                onChange={handleMovieChange}
                input={<OutlinedInput label="Tag" />}
                MenuProps={MenuProps}
              >
                {videos.map((vid) => (
                  <MenuItem key={vid.id} value={vid.id}>
                    {vid.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <ConfirmationDialog
              sx={{ width: "45%", mb: 2, mr: 1, p: 1 }}
              propName="Trailer"
              selectedMovie={selectedTrailer}
              handleDataChange={handleTrailerChange}
            />
            <ConfirmationDialog
              sx={{ width: "45%", mb: 2, mr: 1 }}
              propName="Movie"
              selectedMovie={selectedMovie}
              handleDataChange={handleMovieChange}
            />
            <ConfirmationDialog
              sx={{ width: "45%", mb: 2, mr: 1 }}
              propName="Movie Maker"
              selectedMovie={selectedMovieMaker}
              handleDataChange={handleMovieMakerChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              width: "100%",
              mb: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-evenly",
                width: "20%",
              }}
            >
              <Button
                sx={{ marginRight: "2%" }}
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmission}
                color="primary"
                variant="contained"
              >
                Submit
              </Button>
            </div>
          </div>
        </Card>
        {/* </Paper> */}
      </Box>
    </Container>
  );
};
export default CreateMovie;
