
import React from 'react'
import { useState } from 'react';
import ListingscategoriesTable from './table';

function ListingCategories() {
  const [filters, setFilters] = useState({
    offset: 1,
   limit: 10,
   id : null,

 
 });

  
  return (
    <div>
      <ListingscategoriesTable filters={filters} setFilters={setFilters} /></div>
  )
}

export default ListingCategories
