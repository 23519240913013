import React from "react";
import { styled, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { editClassifiedsData, uploadImages } from "../../../services/jobs";
import ImageUploader from "./imageUploader";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { options } from "../../../utils/locations";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
function EditRealEstate({
  openEdit,
  setEditOpen,
  realEstatePayload,
  getclassifiedsData,
  setRealEstateList,
  classified_type,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const [isCoverSelected, setCoverIsSelected] = useState(false);
  realEstatePayload["highlights"] = realEstatePayload.highlights.toString();
  const [realEstateData, setRealEstateData] = useState(realEstatePayload);
  // const [jobName, setJobsName] = useState(realEstatePayload.name);
  const [, setState] = React.useState(false);
  const [file, setFile] = useState(realEstatePayload?.pictures);
  const [thumbnailFiles, setThumbnailFiles] = useState(
    realEstatePayload?.pictures
  );
  const [logoFile, setLogoFile] = useState(realEstatePayload?.thumbnail);
  const [logoSelectedFile, setLogoSelectedFile] = useState(
    realEstatePayload?.thumbnail
  );
  const [coverFile, setCoverFile] = useState(realEstatePayload?.cover);
  const [coverSelectedFile, setCoverSelectedFile] = useState(
    realEstatePayload?.cover
  );
  function handleClose() {
    setEditOpen(false);
  }
  const changeHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setLogoSelectedFile(event.target.files[0]);
      setIsSelected(true);
      setLogoFile(uploadResponse?.info[0]);
    }
  };
  const changeCoverHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setCoverSelectedFile(event.target.files[0]);
      setCoverIsSelected(true);
      setCoverFile(uploadResponse?.info[0]);
    }
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("classifieds_id", realEstatePayload?.id);
    formData.append("title", realEstateData?.title);
    formData.append("other_details", realEstateData?.other_details);
    formData.append("description", realEstateData?.description);
    //formData.append("highlights", realEstateData?.highlights.split(","));
    for (const element of realEstateData?.highlights?.split(",")) {
      formData.append("highlights", element);
    }
    formData.append("price", realEstateData?.price);
    formData.append("classified_type", realEstateData?.classified_type);
    formData.append("real_estate_type", realEstateData?.real_estate_type);
    formData.append("listing_type", realEstateData?.listing_type);
    formData.append("contact_name", realEstateData?.contact_name);
    formData.append("mobile", realEstateData?.mobile);
    formData.append("whatsapp", realEstateData?.whatsapp);
    formData.append("email", realEstateData?.email);
    formData.append("location", realEstateData?.location);
    // if (realEstateData?.images) {
    //   for (var i = 0; i < realEstateData?.images.length; i++) {
    //     formData.append("thumbnails", realEstateData?.images[i]);
    //   }
    // }
    if (file) {
      for (let i = 0; i < file.length; i++) {
        formData.append("thumbnail", file[i]);
      }
    }
    formData.append("pictures", logoFile);
    formData.append("cover", coverFile);
    let response = await editClassifiedsData(formData);
    if (response?.success) {
      setEditOpen(false);
      getclassifiedsData(setRealEstateList, classified_type);
    }
  };
  const handleDataChange = (event) => {
    setRealEstateData((realEstateData) => {
      let key = event.target.id;
      if (event.target.id.includes("location")) {
        // console.log(event.target.innerHTML);
        realEstateData["location"] = `${event.target.innerHTML}`;
      } else {
        realEstateData[key] = event.target.value;
      }
      return realEstateData;
    });
    setState((prev) => !prev);
  };
  const handleDropdownDataChange = (event) => {
    setRealEstateData((realEstateData) => {
      let key = event.target.name;
      realEstateData[key] = event.target.value;
      return realEstateData;
    });
    setState((prev) => !prev);
  };
  return (
    <Container>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title" sx={{ mb: 1 }}>
          <b>Edit</b>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <DialogContentText sx={{ mb: 1 }}>Job Title</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="title"
              label="Title"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.title}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Job Type</DialogContentText> */}
            <TextField
              autoFocus
              id="email"
              label="Email"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.email}
            />
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="job_type">Job Type</InputLabel>
              <Select
                labelId="job_type"
                id="job_type"
                label="Job Type"
                value={realEstateData?.job_type}
                onChange={handleDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
              </Select>
            </FormControl> */}
            {/* <DialogContentText sx={{ mb: 1 }}>Job Style</DialogContentText> */}
            <Autocomplete
              id="location"
              name="location"
              options={options.sort(
                (a, b) => -b.category.localeCompare(a.category)
              )}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.label}
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Location"
                  onChange={handleDataChange}
                />
              )}
            />
            {/* <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-label">Job Type</InputLabel>
              <Select
                labelId="demo-label"
                id="job_style"
                label="Job Style"
                value="FULL-TIME"
                onChange={handleDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
              </Select>
            </FormControl> */}
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Job Role</DialogContentText> */}
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="price"
              label="Price"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.price}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Job Category</DialogContentText> */}
            <TextField
              autoFocus
              id="contact_name"
              label="Contact Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.contact_name}
            />
            {/* <DialogContentText sx={{ mb: 1 }}>Qualification</DialogContentText> */}
            <TextField
              autoFocus
              id="mobile"
              label="mobile"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.mobile}
            />
          </div>
          {/* <DialogContentText sx={{ mb: 1 }}>Job Description</DialogContentText> */}
          <div style={{ display: "flex" }}>
            {/* <TextField
              autoFocus
              id="classified_type"
              label="Classified Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.classified_type}
            /> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Classified Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="classified_type"
                defaultValue={realEstateData?.classified_type}
                label="Classified Type"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="REAL_ESTATE">REAL_ESTATE</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Listing Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="listing_type"
                defaultValue={realEstateData?.listing_type}
                label="Listing Type"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="BUY">BUY</MenuItem>
                <MenuItem value="RENT">RENT</MenuItem>
              </Select>
            </FormControl>
            {/* <DialogContentText sx={{ mb: 1 }}>Max. Experience</DialogContentText> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Real Estate Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="real_estate_type"
                defaultValue={realEstateData?.real_estate_type}
                label="Real Estate Type "
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="LAND">LAND</MenuItem>
                <MenuItem value="HOUSE">HOUSE</MenuItem>
                <MenuItem value="FLAT">FLAT</MenuItem>
                <MenuItem value="PLOT">PLOT</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              autoFocus
              id="real_estate_type"
              label="Real Estate Type"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.real_estate_type}
            /> */}
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="highlights"
              label="Highlights"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.highlights}
            />
            <TextField
              autoFocus
              id="other_details"
              label="Other Details"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.other_details}
            />
            <TextField
              autoFocus
              id="whatsapp"
              label="Whatsapp"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.whatsapp}
            />
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="description"
              label="Description"
              type="text"
              sx={{ width: "100%", mr: "3%", mb: 2 }}
              onChange={handleDataChange}
              value={realEstateData?.description}
              multiline
              rows={3}
            />
          </div>
          <div style={{ display: "flex" }}>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>
                Upload Thumbnail
              </DialogContentText>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={changeHandler} />
              </Button>
            </DialogContent>
            <DialogContent>
              {isSelected || logoFile ? (
                <img
                  src={logoFile}
                  height={100}
                  width={100}
                  alt={logoSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>Upload Cover</DialogContentText>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput
                  type="file"
                  onChange={changeCoverHandler}
                />
              </Button>
            </DialogContent>
            <DialogContent>
              {isCoverSelected || coverFile ? (
                <img
                  src={coverFile}
                  height={100}
                  width={100}
                  alt={coverSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
          </div>
          <div style={{ display: "flex" }}>
            <ImageUploader
              file={file}
              setFile={setFile}
              thumbnailFiles={thumbnailFiles}
              setThumbnailFiles={setThumbnailFiles}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
            // disabled={!(isSelected && realEstateData)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
export default EditRealEstate;
