import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import HomeIcon from "@mui/icons-material/Home";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PaymentsIcon from "@mui/icons-material/Payments";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WorkIcon from "@mui/icons-material/Work";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MovieCreationIcon from "@mui/icons-material/MovieCreation";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PaidIcon from "@mui/icons-material/Paid";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FeedIcon from "@mui/icons-material/Feed";
import CategoryIcon from "@mui/icons-material/Category";
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./Sidebar.css";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let active = selected === title;
  return (
    <MenuItem
      active={active}
      style={{
        color: !active ? "#000" : "#007effa6",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography style={{ fontWeight: active ? "bolder" : "200" }}>
        {title}
      </Typography>
      <Link to={to} />
    </MenuItem>
  );
};
const Sidebar = ({ permissions }) => {
  const theme = useTheme();
  const base_url = "dhunia_bo";
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  // console.log(permissions);
  useEffect(() => {}, []);
  return (
    <Box
      sx={{
        "& .pro-sidebar": {
          color: "#000",
        },
        "& .pro-sidebar-inner": {
          // background: `${colors.primary[400]} !important`,
          background: "#f2f0f0!important",
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 20px 5px 20px !important",
          // color: "#FFF",
        },
        // "& .pro-inner-item .active": {
        //   color: "#000",
        //   fontWeight: "bolder",
        // },
        // "& .pro-inner-item:hover": {
        //   color: "#868dfb !important",
        // },
        // "& .pro-menu-item.active": {
        //   color: "#6870fa !important",
        // },
        // "& .pro-menu-item.content": {
        //   color: "#6870fa !important",
        // },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              // color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3">OTT ADMIN</Typography>
                <IconButton>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to={`${base_url}/reports`}
              icon={<HomeIcon />}
              selected={selected}
              setSelected={setSelected}
            />

         

            {permissions?.is_bo_user && (
              <SubMenu title="NEWS">
                {permissions?.read_newsmaster_permission && (
                  <Item
                    title="News"
                    to={`${base_url}/posts`}
                    icon={<NewspaperIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {permissions?.read_newsmaster_permission && (
                  <Item
                    title="Banners"
                    to={`${base_url}/banners`}
                    icon={<NewspaperIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {permissions?.read_newsmaster_permission && (
                  <Item
                    title="Ads"
                    to={`${base_url}/ads`}
                    icon={<NewspaperIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {permissions?.read_newsmaster_permission && (
                  <Item
                    title="locations"
                    to={`${base_url}/locations`}
                    icon={<NewspaperIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}

                {permissions?.read_newscategories_permission && (
                  <Item
                    title="Categories"
                    to={`${base_url}/categories`}
                    icon={<CategoryIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                {permissions?.read_newsreporters_permission && (
                  <Item
                    title="Reporters"
                    to={`${base_url}/reporters`}
                    icon={<FeedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )}
                <Item
                  title="Reporter Earnings"
                  to={`${base_url}/user-earnings`}
                  icon={<CurrencyExchangeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Withdrawal management"
                  to={`${base_url}/withdrwal-management`}
                  icon={<PaymentsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </SubMenu>
            )}

            {permissions?.is_bo_user &&
              permissions?.read_jobmaster_permission && (
                <SubMenu title="LISTINGS">
                  <Item
                    title="Categories"
                    to={`${base_url}/listings-categories`}
                    icon={<WorkIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Sub Catedgories"
                    to={`${base_url}/listings-subcategories`}
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              )}

            {permissions?.is_bo_user &&
              permissions?.read_jobmaster_permission && (
                <SubMenu title="LOCATIONS">
                  <Item
                    title="States"
                    to={`${base_url}/locations-states`}
                    icon={<WorkIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Districts"
                    to={`${base_url}/locations-districts`}
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Mandals"
                    to={`${base_url}/locations-mandals`}
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Villages"
                    to={`${base_url}/locations-villages`}
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              )}

            {permissions?.is_bo_user &&
              permissions?.read_jobmaster_permission && (
                <SubMenu title="CLASSIFIEDS">
                  <Item
                    title="Jobs"
                    to={`${base_url}/jobs`}
                    icon={<WorkIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Real Estate"
                    to={`${base_url}/realestate`}
                    icon={<LocationCityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Buy / Sell"
                    to={`${base_url}/buySell`}
                    icon={<PaidIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Other Classifieds"
                    to={`${base_url}/otherClassifieds`}
                    icon={<MoreHorizIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              )}
            {permissions?.is_bo_user && (
              <SubMenu title="OTT">
                <Item
                  title="Uploads"
                  to={`${base_url}/uploads`}
                  icon={<CloudUploadIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Movies"
                  to={`${base_url}/movies`}
                  icon={<MovieCreationIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Movie Makers"
                  to={`${base_url}/movieMakers`}
                  icon={<MovieCreationIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Trending"
                  to={`${base_url}/trending`}
                  icon={<TrendingUpIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />

<Item
                  title="Youtube Videos"
                  to={`${base_url}/yt-videos`}
                  icon={<YouTubeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </SubMenu>
            )}
            {permissions?.is_bo_admin && (
              <SubMenu title="ROLES & PERMISSIONS">
                <Item
                  title="Roles"
                  to={`${base_url}/roles`}
                  // icon={<LockPersonIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                {/* <Item
                  title="Permissions"
                  to={`${base_url}/permissions`}
                  icon={<PermIdentityIcon />}
                  selected={selected}
                  setSelected={setSelected}
                /> */}
              </SubMenu>
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};
export default Sidebar;
