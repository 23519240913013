


// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { getUserbank } from "./user";
// import {
//   Typography,
//   Paper,
//   CircularProgress,
//   Grid,
//   Card,
//   CardContent,
//   Divider,
//   Chip,
//   Button
// } from '@mui/material';

// function UserBankDetails() {
//   const { userId } = useParams(); // Get userId from URL params
//   const [userBankDetails, setUserBankDetails] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const navigate = useNavigate(); 

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         console.log("Fetching user bank details for user ID:", userId);
//         const data = await getUserbank(userId); // Pass userId to getUserbank API call
//         console.log("Fetched user bank details:", data);

//         if (data && data.info) {
//           setUserBankDetails(data.info); // Set only the `info` part of the response
//         } else {
//           console.error("No info found in response:", data);
//         }
//       } catch (error) {
//         console.error("Error fetching user bank details:", error);
//       } finally {
//         setLoading(false);
//       }
//     }
//     fetchData();
//   }, [userId]);

//   if (loading) {
//     return <CircularProgress style={{ display: 'block', margin: 'auto', marginTop: 20 }} />;
//   }

//   if (!userBankDetails) {
//     return <Typography variant="h6" align="center" color="error">No data available or an error occurred.</Typography>;
//   }

//   const handleFormClick = () => {
//     navigate(`/dhunia_bo/user-bank-form`);
//   };

//   return (
//     <Paper style={{ padding: 20, margin: 20 }}>
//       <Typography variant="h4" align="center" gutterBottom>
//         User Bank Details
//       </Typography>
//       <Button onClick={() => handleFormClick()}>Create </Button>

//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 Account Information
//               </Typography>
//               <Divider />
//               <Typography variant="body1"><strong>Account Number:</strong> {userBankDetails.account_number}</Typography>
//               <Typography variant="body1"><strong>IFSC Code:</strong> {userBankDetails.ifsc}</Typography>
//               <Typography variant="body1"><strong>Bank Name:</strong> {userBankDetails.bank_name}</Typography>
//               <Typography variant="body1"><strong>Branch:</strong> {userBankDetails.branch}</Typography>
//               <Typography variant="body1"><strong>Account Holder Name:</strong> {userBankDetails.account_holder_name}</Typography>
//               <Typography variant="body1"><strong>Is Primary:</strong> {userBankDetails.is_primary ? <Chip label="Yes" color="success" /> : <Chip label="No" color="warning" />}</Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Card>
//             <CardContent>
//               <Typography variant="h6" gutterBottom>
//                 Metadata
//               </Typography>
//               <Divider />
//               <Typography variant="body1"><strong>Created By:</strong> {userBankDetails.created_by}</Typography>
//               <Typography variant="body1"><strong>Updated By:</strong> {userBankDetails.updated_by}</Typography>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>
//     </Paper>
//   );
// }

// export default UserBankDetails;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getUserbank } from "./user";
import {
  Typography,
  Paper,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Divider,
  Chip,
  Button
} from '@mui/material';

function UserBankDetails() {
  const { userId } = useParams(); // Get userId from URL params
  const [userBankDetails, setUserBankDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate(); 

  useEffect(() => {
    async function fetchData() {
      try {
        console.log("Fetching user bank details for user ID:", userId);
        const data = await getUserbank(userId); // Pass userId to getUserbank API call
        console.log("Fetched user bank details:", data);

        if (data && data.info) {
          setUserBankDetails(data.info); // Set only the `info` part of the response
        } else {
          console.error("No info found in response:", data);
        }
      } catch (error) {
        console.error("Error fetching user bank details:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [userId]);

  if (loading) {
    return <CircularProgress style={{ display: 'block', margin: 'auto', marginTop: 20 }} />;
  }

  if (!userBankDetails) {
    return <Typography variant="h6" align="center" color="error">No data available or an error occurred.</Typography>;
  }

  const handleFormClick = () => {
    navigate(`/dhunia_bo/user-bank-form`, { state: { userId } });
  };

  return (
    <Paper style={{ padding: 20, margin: 20 }}>
      <Typography variant="h4" align="center" gutterBottom>
        User Bank Details
      </Typography>
      <Button onClick={() => handleFormClick()}>Create </Button>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Account Information
              </Typography>
              <Divider />
              <Typography variant="body1"><strong>Account Number:</strong> {userBankDetails.account_number}</Typography>
              <Typography variant="body1"><strong>IFSC Code:</strong> {userBankDetails.ifsc}</Typography>
              <Typography variant="body1"><strong>Bank Name:</strong> {userBankDetails.bank_name}</Typography>
              <Typography variant="body1"><strong>Branch:</strong> {userBankDetails.branch}</Typography>
              <Typography variant="body1"><strong>Account Holder Name:</strong> {userBankDetails.account_holder_name}</Typography>
              <Typography variant="body1"><strong>Is Primary:</strong> {userBankDetails.is_primary ? <Chip label="Yes" color="success" /> : <Chip label="No" color="warning" />}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Metadata
              </Typography>
              <Divider />
              <Typography variant="body1"><strong>Created By:</strong> {userBankDetails.created_by}</Typography>
              <Typography variant="body1"><strong>Updated By:</strong> {userBankDetails.updated_by}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default UserBankDetails;

