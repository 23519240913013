// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Box,
//   Typography,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { createUserBankForm } from "./user";

// const UserBankForm = ({ onFormSubmit }) => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     user_id: "",
//     amount: "",
//     remarks: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const apiResponse = await createUserBankForm(formData); // Call your API
//       if (apiResponse.success) {
//         alert("Data submitted successfully");
//         if (onFormSubmit) {
//           onFormSubmit();
//         }
//         resetForm();
//       } else {
//         const errorMessage = apiResponse.message || "Unknown error occurred";
//         console.error(errorMessage);
//         alert("Error in submitting the data");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     navigate("/dhunia_bo/banners");
//   };

//   const resetForm = () => {
//     setFormData({
//        user_id: "",
//       amount: "",
//       remarks: "",
//     });
//   };

//   return (
//     <Box
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       height="100vh"
//       sx={{ backgroundColor: "#A7E6FF" }}
//     >
//       <Box
//         padding="20px"
//         sx={{
//           backgroundColor: "#fff",
//           borderRadius: "8px",
//           boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//           maxWidth: "600px",
//           width: "100%",
//         }}
//       >
//         <Typography
//           variant="h5"
//           fontWeight="bold"
//           marginBottom="20px"
//           align="center"
//           color="#007eff"
//         >
//           ADD RECORD
//         </Typography>
//         <form onSubmit={handleSubmit}>
//           <TextField
//             fullWidth
//             label="User ID"
//             name="user_id"
//             variant="outlined"
//             value={formData.user_id}
//             onChange={handleChange}
//             margin="normal"
//             required
//           />
//           <TextField
//             fullWidth
//             label="Amount"
//             name="amount"
//             type="number"
//             variant="outlined"
//             value={formData.amount}
//             onChange={handleChange}
//             margin="normal"
//             required
//           />
//           <TextField
//             fullWidth
//             label="Remarks"
//             name="remarks"
//             variant="outlined"
//             value={formData.remarks}
//             onChange={handleChange}
//             margin="normal"
//             required
//           />

//           <Box display="flex" justifyContent="flex-end" gap={2}>
//             <Button type="submit" variant="contained" color="primary" fullWidth>
//               Submit
//             </Button>
//             <Button
//               variant="outlined"
//               color="secondary"
//               onClick={handleCancel}
//               fullWidth
//             >
//               Cancel
//             </Button>
//           </Box>
//         </form>
//       </Box>
//     </Box>
//   );
// };

// export default UserBankForm;
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { createUserBankForm } from "./user";

const UserBankForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location.state?.userId || "";
  const [formData, setFormData] = useState({
    user_id: "",
    amount: "",
    remarks: "",
  });

  useEffect(() => {
    console.log("Received userId from navigation state:", userId);
    if (userId) {
      setFormData((prevData) => ({
        ...prevData,
        user_id: userId,
      }));
    }
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await createUserBankForm(formData); // Call your API
      if (apiResponse.success) {
        alert("Data submitted successfully");
        if (onFormSubmit) {
          onFormSubmit();
        }
        resetForm();
      } else {
        const errorMessage = apiResponse.message || "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    navigate("/dhunia_bo/banners");
  };

  const resetForm = () => {
    setFormData({
      user_id: "",
      amount: "",
      remarks: "",
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: "#A7E6FF" }}
    >
      <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          marginBottom="20px"
          align="center"
          color="#007eff"
        >
          ADD RECORD
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="User ID"
            name="user_id"
            variant="outlined"
            value={formData.user_id}
            onChange={handleChange}
            margin="normal"
            required
            disabled // Make the field read-only
          />
          <TextField
            fullWidth
            label="Amount"
            name="amount"
            type="number"
            variant="outlined"
            value={formData.amount}
            onChange={handleChange}
            margin="normal"
            required
          />
          <TextField
            fullWidth
            label="Remarks"
            name="remarks"
            variant="outlined"
            value={formData.remarks}
            onChange={handleChange}
            margin="normal"
            required
          />

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default UserBankForm;
