// import React, { useState } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { createCategoriesForm, UploadFiles } from "./listingscategories";

// const ListingCategoriesForm = ({ onFormSubmit }) => {
//   const navigate = useNavigate();
//   const [images, setImages] = useState([]);

//   const [formData, setFormData] = useState({
//     category_name: "",
//     category_description: "",
//   });

//   const handleRemoveImage = (indexToRemove) => {
//     setImages(images.filter((_, index) => index !== indexToRemove));
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setImages(selectedImages);
//   };

//   const resetForm = () => {
//     setFormData({
//       category_name: "",
//       category_description: "",
//     });
//     setImages([]);
//   };

//   const UploadAndSaveListingCategory = async (data, images) => {
//     try {
//       // upload images
//       const ImagesFormData = new FormData();
//       images.forEach((file, index) => {
//         ImagesFormData.append(`files`, file);
//       });
//       ImagesFormData.append("entity", "categories");
//       const images_response = await UploadFiles(ImagesFormData);

//       if (images_response.success) {
//         data.category_image = images_response.info ? images_response.info[0] : null;
//         // data.video = video_response.info;
//         const form_response = await createCategoriesForm(data);
//         return form_response;
//       }
//       return null;
//     } catch (error) {
//       console.log(error);
//     }
//     return null;
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const apiResponse = await UploadAndSaveListingCategory(formData, images);
//       // console.log("apiResponse", apiResponse);
//       if (apiResponse && apiResponse.success) {
//         // console.log("bbb", apiResponse.message);
//         alert("Data submitted successfully");
//         onFormSubmit(navigate("/dhunia_bo/listings-categories"));
//         resetForm();
//       } else {
//         const errorMessage =
//           apiResponse && apiResponse.message
//             ? apiResponse.message
//             : "Unknown error occurred";
//         console.error(errorMessage);
//         alert("Error in Submitting the data");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     setFormData({
//       category_name: "",
//       category_description: "",
//     });
//     setImages([]);

//     navigate("/dhunia_bo/listings-categories");
//   };
//   return (
//     <Box
//       marginRight="20px"
//       marginLeft="20px"
//       marginBottom="20px"
//       height="600px"
//     >
//       <Typography variant="h5" fontWeight="bold" marginBottom="20px">
//         CREATE CATEGORY
//       </Typography>
//       <form onSubmit={handleSubmit}>
//       <TextField
//           fullWidth
//           label="Category Name"
//           variant="outlined"
//           name="category_name"
//           value={formData.category_name}
//           onChange={handleChange}
//           margin="normal"
//           multiline
//           rows={1}
//           required
//         />

//         <TextField
//           fullWidth
//           label="Description"
//           variant="outlined"
//           name="category_description"
//           value={formData.category_description}
//           onChange={handleChange}
//           margin="normal"
//           multiline
//           rows={4}
//           required
//         />

//         <Box marginBottom="20px" marginTop="20px">
//           <input
//             type="file"
//             id="category_image"
//             name="category_image"
//             accept="image/*"
//             onChange={handleImageChange}
//           />
//           {images.length > 0 && (
//             <ul>
//               {images.map((image, index) => (
//                 <li key={index}>
//                   <img
//                     src={
//                       typeof image === "object"
//                         ? URL.createObjectURL(image)
//                         : image
//                     }
//                     alt={`Preview ${index}`}
//                     style={{ maxWidth: "100px", maxHeight: "100px", backgroundColor:"primary" }}
//                   />
//                   <Button onClick={() => handleRemoveImage(index)}>
//                     Remove
//                   </Button>
//                 </li>
//               ))}
//             </ul>
//           )}
//         </Box>

//         <Box marginTop="55px" display="flex" justifyContent="flex-end">
//           <Button type="submit" variant="contained" color="primary">
//             Submit
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };
// export default ListingCategoriesForm;



import React, { useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createCategoriesForm, UploadFiles } from "./listingscategories";

const ListingCategoriesForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);

  const [formData, setFormData] = useState({
    category_name: "",
    category_description: "",
  });

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const resetForm = () => {
    setFormData({
      category_name: "",
      category_description: "",
    });
    setImages([]);
  };

  const UploadAndSaveListingCategory = async (data, images) => {
    try {
      const ImagesFormData = new FormData();
      images.forEach((file, index) => {
        ImagesFormData.append(`files`, file);
      });
      ImagesFormData.append("entity", "categories");
      const images_response = await UploadFiles(ImagesFormData);

      if (images_response.success) {
        data.category_image = images_response.info
          ? images_response.info[0]
          : null;
        const form_response = await createCategoriesForm(data);
        return form_response;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UploadAndSaveListingCategory(formData, images);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/listings-categories"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    setFormData({
      category_name: "",
      category_description: "",
    });
    setImages([]);

    navigate("/dhunia_bo/listings-categories");
  };

  return (
    <Box sx={{ backgroundColor: "#A7E6FF", width: "100%", height: "130%" }}>
      <Box sx={{padding:"3rem"}}>
      <Box
        marginRight="20px"
        marginLeft="20px"
        marginBottom="20px"
        height="auto"
        bgcolor="#f5f5f5"
        padding="20px"
        borderRadius="8px"
        sx={{
          maxWidth: "600px",
          width: "100%",
          marginLeft: "13rem",
          marginTop: "20px",
        }}
      >
        <Box >
          <Typography
            variant="h5"
            fontWeight="bold"
            marginBottom="20px"
            textAlign="center"
            color="#007eff"
          >
            CREATE CATEGORY
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Category Name"
              variant="outlined"
              name="category_name"
              value={formData.category_name}
              onChange={handleChange}
              margin="normal"
              required
            />

            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              name="category_description"
              value={formData.category_description}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              required
            />
            <Box marginBottom="20px" marginTop="20px">
              <Button
                variant="contained"
                component="label"
                color="primary"
                fullWidth
              >
                Upload Image
                <input
                  type="file"
                  id="category_image"
                  name="category_image"
                  accept="image/*"
                  onChange={handleImageChange}
                  hidden
                />
              </Button>
              {images.length > 0 && (
                <ul style={{ padding: 0, listStyle: "none" }}>
                  {images.map((image, index) => (
                    <li key={index} style={{ marginBottom: "10px" }}>
                      <img
                        src={
                          typeof image === "object"
                            ? URL.createObjectURL(image)
                            : image
                        }
                        alt={`Preview ${index}`}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                      <Button
                        onClick={() => handleRemoveImage(index)}
                        color="error"
                        variant="contained"
                        style={{ marginLeft: "10px" }}
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              )}
            </Box>

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                fullWidth
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
    </Box>
  );
};

export default ListingCategoriesForm;
