

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReports } from "./reports";
import { Grid, Card, CardContent, Typography, TextField, Box, Button } from '@mui/material';
import InstallIcon from '@mui/icons-material/CloudDownload';
import UsersIcon from '@mui/icons-material/Group';
import NewsIcon from '@mui/icons-material/Description';
import ApprovedNewsIcon from '@mui/icons-material/ThumbUp';
import RejectedNewsIcon from '@mui/icons-material/ThumbDown';
import ReportersIcon from '@mui/icons-material/PersonAdd';

function Reports() {
  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchReports();
  }, []);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleResetDates = () => {
    setStartDate('');
    setEndDate('');
    fetchReports();
  };

  const handleGetReports = () => {
    fetchReports();
  };

  const fetchReports = async () => {
    setLoading(true);
    setError(null);
    try {
      const result = await getReports(startDate, endDate);
      if (result && result.success && result.info) {
        setReport(result.info);
      } else {
        setError('Fetched data is not in expected format');
        setReport(null);
      }
    } catch (err) {
      setError('Failed to fetch report');
      setReport(null);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const cardData = report ? [
    { title: "Apps Opens", value: report.installs, icon: <InstallIcon />, color: "#FFF3E0", textColor: "#F57C00", path: "/dhunia_bo/reports-openapps" },
    { title: "Active Users", value: report.dau, icon: <UsersIcon />, color: "#E8F5E9", textColor: "#2E7D32" },
    { title: "News Count", value: report.news_count, icon: <NewsIcon />, color: "#E3F2FD", textColor: "#039BE5" },
    { title: "Approved News Count", value: report.approved_news_count, icon: <ApprovedNewsIcon />, color: "#F3E5F5", textColor: "#8E24AA" },
    { title: "Rejected News Count", value: report.rejected_news_count, icon: <RejectedNewsIcon />, color: "#FFEBEE", textColor: "#C62828" },
    { title: "New Reporters Count", value: report.new_reporters_count, icon: <ReportersIcon />, color: "#F1F8E9", textColor: "#689F38" }
  ] : [];

  return (
    <div style={{ padding: '2rem', backgroundColor: "#A7E6FF" }}>
      <h1>Reports</h1>
      <Box mb={2} display="flex" alignItems="center">
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mr: 2 }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mr: 2 }}
        />
        <Button variant="contained" onClick={handleGetReports} sx={{ mr: 2 }}>
          Get Reports
        </Button>
        <Button variant="contained" onClick={handleResetDates} >
          Reset Dates
        </Button>
      </Box>
      <Grid container spacing={2}>
        {cardData.length > 0 ? (
          cardData.map((data, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ 
                textAlign: 'center',
                padding: '20px',
                boxShadow: 3, 
                border: 1, 
                borderColor: 'grey.300', 
                backgroundColor: data.color, 
                color: data.textColor,
                '&:hover': { 
                  boxShadow: 6,
                  cursor: 'pointer'
                },
                borderRadius: '10px'
              }}
              onClick={() => data.path && handleCardClick(data.path)}
              >
                <CardContent>
                  <Box display="flex" justifyContent="center" alignItems="center" mb={2} sx={{ fontSize: '50px' }}>
                    {data.icon}
                  </Box>
                  <Typography variant="h5" sx={{ fontWeight: 'bold', color: data.textColor }}>{data.title}</Typography>
                  <Typography variant="h4" sx={{ marginTop: '10px', color: data.textColor }}>{data.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography>No reports available</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default Reports;

