






import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { adds_columns } from "../../../utils/constants";
import AddsForm from "./addsform";
import { getAdds } from "./ads";

function AddsTable() {
  const navigate = useNavigate();

  const [addsListData, setAddsList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [filters, setFilters] = useState({
    offset: 1,
    limit: 10,
  
    
  });

  useEffect(() => {
    async function getAddsDetails() {
      const data = await getAdds(filters.offset, filters.limit, );
      setAddsList(data);
    }
    getAddsDetails();
  }, [filters.offset, filters.limit]);

  const handleChangePage = (event, newPage) => {
    setFilters({ ...filters, offset: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setFilters({ ...filters, limit: parseInt(event.target.value, 10), offset: 1 });
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/create-adds");
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const handleEditClick = (id) => {
    navigate(`/dhunia_bo/edit-ads/${id}`);
  };

  const onFormSubmit = () => {
    getAdds(setAddsList, filters.offset, filters.limit);
    setShowForm(false);
  };

  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.id) {
      case "images":
        return (
          <TableCell key={column.id} align={column.align} style={{ marginBottom: "10px" }}>
            <img
              src={value}
              alt="ads"
              style={{ width: "120px", marginRight: "10px", borderRadius: "8px" }}
            />
          </TableCell>
        );
      case "mobile_number":
        return (
          <TableCell key={column.id} align={column.align} style={{ verticalAlign: "top" }}>
            <Typography variant="subtitle1" marginBottom="5px">
              {row.mobile_number}
            </Typography>
          </TableCell>
        );
      case "whatsapp_number":
        return (
          <TableCell key={column.id} align={column.align} style={{ verticalAlign: "top" }}>
            <Typography variant="subtitle1" marginBottom="5px">
              {row.whatsapp_number}
            </Typography>
          </TableCell>
        );
      case "view_type":
        return (
          <TableCell key={column.id} align={column.align} style={{ verticalAlign: "top" }}>
            <Typography variant="subtitle1" marginBottom="5px">
              {row.view_type}
            </Typography>
          </TableCell>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
          <Typography variant="h3" fontWeight="bold"
           sx={{
            background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          >
            ADS
          </Typography>

          <Button variant="contained" color="primary" onClick={handleCreateClick}>
            Create
          </Button>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table" style={{ maxWidth: "90%", marginLeft: "auto", marginRight: "auto" }}>
              <TableHead style={{ backgroundColor: "#007effa6" }}>
                <TableRow>
                  <TableCell style={{ color: "black", fontWeight: "bold" }} align="center">Images</TableCell>
                  <TableCell style={{ color: "black", fontWeight: "bold" }} align="center">Mobile Number</TableCell>
                  <TableCell style={{ color: "black", fontWeight: "bold" }} align="center">WhatsApp Number</TableCell>
                  <TableCell style={{ color: "black", fontWeight: "bold" }} align="center">Display Type</TableCell>
                  <TableCell style={{ color: "black", fontWeight: "bold" }} align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addsListData?.info.map((row, index) => (
                  <TableRow
                    hover
                    key={row.id}
                    style={{ backgroundColor: index % 2 === 0 ? "#f3f3f3" : "#ffffff" }}
                  >
                    {adds_columns.map((column) => renderContent(row, column))}
                    <TableCell align="center" style={{ width: "40%", borderBottom: "1px solid #ddd" }}>
                      {/* <Link to={`/dhunia_bo/edit-ads/${row.id}`}>
                        <Edit style={{ cursor: "pointer", color: "#007eff" }} />
                      </Link> */}
                        <EditIcon
                    style={{ cursor: "pointer", color: "#007eff" }}
                    onClick={() => handleEditClick(row.id)}
                  />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={addsListData?.total}
            rowsPerPage={filters.limit}
            page={filters.offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={{ backgroundColor: "#007effa6", color: "#ffffff" }}
          />
        </Paper>
      </Box>

      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>{editData ? "Edit News" : "Create News"}</DialogTitle>
        <DialogContent>
          <AddsForm onFormSubmit={onFormSubmit} editData={editData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddsTable;
