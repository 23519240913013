import React from 'react'
import BannersTable from './bannerstable';
import { useState } from 'react';


function Banners() {
  
    const [filters, setFilters] = useState({
      offset: 1,
     limit: 10,
     id : null,
    
   
   });
  return (
    <div>
        <BannersTable
         filters={filters} setFilters={setFilters}
          />
        
    </div>
  )
}

export default Banners;