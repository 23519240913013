// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   MenuItem,
//   IconButton,
//   EmailOutlined,
//   Select,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
// import { Edit } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import EditListingCategories from "./edit";

// // import { listColums_colums } from "../../../utils/constants";
// import { listColums_colums } from "../../utils/constants";
// import { getListingsCategories, deleteCategories } from "./listingscategories";
// import ListingCategoriesForm from "./form";
// // import NewsForm from "./createform";

// function ListingscategoriesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [listingsListData, setListingsList] = useState(null);
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);

//   useEffect(() => {
//     async function getListingsCategoriesDetails() {
//       const data = await getListingsCategories(
//         filters.offset,
//         filters.limit,
//         filters.id
//       );
//       setListingsList(data);
//     }
//     getListingsCategoriesDetails();
//   }, [filters.offset, filters.limit, filters.id]);
  

 
//   const handlePageChange = (event, newPage) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       offset: newPage,
//     }));
//   };

  

 

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-listing-categories");
//   };

//   const handleDelete = async (categoryId) => {
//     try {
//       const response = await deleteCategories(categoryId);
//       if (response.success) {
//         alert("Category deleted successfully");
//         getListingsCategories(setListingsList, filters.offset, filters.limit);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.error("Error deleting category:", error);
//     }
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getListingsCategories(setListingsList, filters.offset, filters.limit);
//     setShowForm(false);
//   };

 

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "category_image":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ marginBottom: "10px" }}
//           >
//             <img
//               src={value}
//               alt="categoryimage"
//               style={{
//                 width: "120px",
//                 marginRight: "10px",
//                 borderRadius: "8px",
//               }}
//             />
//           </TableCell>
//         );
//       case "category_name":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ verticalAlign: "top" }}
//           >
//             <Typography variant="subtitle1" marginBottom="5px">
//               {row.category_name}
//             </Typography>
//           </TableCell>
//         );

//       default:
//         return null; 
//     }
//   };

//   return (
//     <>
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="10px"
//         >
//           <Typography variant="h3" fontWeight="bold">
//             CATEGORIES TABLE
//           </Typography>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>
//         <Box display="flex" marginBottom="10px"></Box>
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>
//               {listingsListData?.info.map((row) => (
//                 <TableRow hover key={row.id}>
//                   {listColums_colums.map((column) =>
//                     renderContent(row, column)
//                   )}
//                   <TableCell>
//                     <Link to={`/dhunia_bo/edit-categories/${row.id}`}>
//                       <Edit style={{ cursor: "pointer" }} />
//                     </Link>
//                   </TableCell>
//                   <TableCell>
//                     <DeleteIcon onClick={() => handleDelete(row.id)} />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>

//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset}
//             count={listingsListData?.total ? listingsListData.total : 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6" }}
//           />
//         </Paper>
//       </Box>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>
//           {editData ? "Edit Categories" : "Create Categories"}
//         </DialogTitle>
//         <DialogContent>
//           <ListingCategoriesForm
//             onFormSubmit={onFormSubmit}
//             editData={editData}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ListingscategoriesTable;






























// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TableHead,
//   MenuItem,
//   IconButton,
//   EmailOutlined,
//   Select,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
// import { Edit } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import EditListingCategories from "./edit";

// // import { listColums_colums } from "../../../utils/constants";
// import { listColums_colums } from "../../utils/constants";
// import { getListingsCategories, deleteCategories } from "./listingscategories";
// import ListingCategoriesForm from "./form";

// function ListingscategoriesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [listingsListData, setListingsList] = useState(null);
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);

//   useEffect(() => {
//     async function getListingsCategoriesDetails() {
//       const data = await getListingsCategories(
//         filters.offset,
//         filters.limit,
//         filters.id
//       );
//       setListingsList(data);
//     }
//     getListingsCategoriesDetails();
//   }, [filters.offset, filters.limit, filters.id]);

//   const handlePageChange = (event, newPage) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       offset: newPage + 1, // Adjust offset to start from 1
//     }));
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-listing-categories");
//   };

//   const handleDelete = async (categoryId) => {
//     try {
//       const response = await deleteCategories(categoryId);
//       if (response.success) {
//         alert("Category deleted successfully");
//         const data = await getListingsCategories(
//           filters.offset,
//           filters.limit,
//           filters.id
//         );
//         setListingsList(data);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.error("Error deleting category:", error);
//     }
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getListingsCategories(setListingsList, filters.offset, filters.limit);
//     setShowForm(false);
//   };

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "category_image":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ marginBottom: "10px" }}
//           >
//             <img
//               src={value}
//               alt="categoryimage"
//               style={{
//                 width: "120px",
//                 marginRight: "10px",
//                 borderRadius: "8px",
//               }}
//             />
//           </TableCell>
//         );
//       case "category_name":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ verticalAlign: "top" }}
//           >
//             <Typography variant="subtitle1" marginBottom="5px">
//               {row.category_name}
//             </Typography>
//           </TableCell>
//         );

//       default:
//         return null; // Return null for other columns as we only want image and title/description in each row
//     }
//   };
//   return (
//     <>
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="20px"
//         >
//           <Typography variant="h3" fontWeight="bold" color="#007eff">
//             CATEGORIES TABLE
//           </Typography>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//             style={{ backgroundColor: "#007eff", color: "#fff" }}
//           >
//             Create
//           </Button>
//         </Box>
//         <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "10px" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead sx={{padding:"20px"}}>
//               <TableRow>
//                 {listColums_colums.map((column) => (
//                   <TableCell
//                     key={column.id}
//                     align={column.align}
//                     style={{
//                       backgroundColor: "#007eff",
//                       color: "#fff",
//                       fontWeight: "bold",
//                       fontSize: "1rem",
//                       padding: "10px",
//                       textAlign: "left",
//                     }}
//                   >
//                     {column.label}
//                   </TableCell>
//                 ))}
//                 <TableCell
//                   style={{
//                     backgroundColor: "#007eff",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     fontSize: "1rem",
//                     padding: "10px",
//                     textAlign: "left",
//                   }}
//                 >
//                   Edit
//                 </TableCell>
//                 <TableCell
//                   style={{
//                     backgroundColor: "#007eff",
//                     color: "#fff",
//                     fontWeight: "bold",
//                     fontSize: "1rem",
//                     padding: "20px",
                  

//                     textAlign: "left",
//                   }}
//                 >
//                   Delete
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {listingsListData?.info.map((row) => (
//                 <TableRow hover key={row.id} style={{ backgroundColor: "#f9f9f9" , }}>
//                   {listColums_colums.map((column) =>
//                     renderContent(row, column)
//                   )}
//                   <TableCell>
//                     <Link to={`/dhunia_bo/edit-categories/${row.id}`}>
//                       <IconButton color="primary">
//                         <Edit />
//                       </IconButton>
//                     </Link>
//                   </TableCell>
//                   <TableCell>
//                     <IconButton color="error" onClick={() => handleDelete(row.id)}>
//                       <DeleteIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>

//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1} // Adjust page to zero-based index
//             count={listingsListData?.total ? listingsListData.total : 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007eff", color: "#fff" }}
//           />
//         </Paper>
//       </Box>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>
//           {editData ? "Edit Categories" : "Create Categories"}
//         </DialogTitle>
//         <DialogContent>
//           <ListingCategoriesForm
//             onFormSubmit={onFormSubmit}
//             editData={editData}
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm} color="primary">Cancel</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ListingscategoriesTable;























import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getListingsCategories, deleteCategories } from "./listingscategories";
 import { listColums_colums } from "../../utils/constants";
import ListingCategoriesForm from "./form";

// Assuming listColums_colums is correctly imported from constants

function ListingscategoriesTable({ filters, setFilters }) {
  const navigate = useNavigate();

  const [listingsListData, setListingsList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editData, setEditData] = useState(null);

  // State for delete confirmation dialog
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    async function getListingsCategoriesDetails() {
      const data = await getListingsCategories(
        filters.offset,
        filters.limit,
        filters.id
      );
      setListingsList(data);
    }
    getListingsCategoriesDetails();
  }, [filters.offset, filters.limit, filters.id]);

  const handlePageChange = (event, newPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: newPage + 1, // Adjust offset to start from 1
    }));
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/create-listing-categories");
  };

  const handleDelete = (categoryId) => {
    // Show delete confirmation dialog
    setShowDeleteConfirmation(true);
    setDeleteItemId(categoryId);
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteCategories(deleteItemId);
      if (response.success) {
        alert("Category deleted successfully");
        const data = await getListingsCategories(
          filters.offset,
          filters.limit,
          filters.id
        );
        setListingsList(data);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    } finally {
      // Close confirmation dialog
      setShowDeleteConfirmation(false);
    }
  };

  const handleCloseDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const onFormSubmit = () => {
    getListingsCategories(setListingsList, filters.offset, filters.limit);
    setShowForm(false);
  };

  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.id) {
      case "category_image":
        return (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ marginBottom: "10px" }}
          >
            <img
              src={value}
              alt="categoryimage"
              style={{
                width: "120px",
                marginRight: "10px",
                borderRadius: "8px",
              }}
            />
          </TableCell>
        );
      case "category_name":
        return (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ verticalAlign: "top" }}
          >
            <Typography variant="subtitle1" marginBottom="5px">
              {row.category_name}
            </Typography>
          </TableCell>
        );
      default:
        return null; // Return null for other columns as we only want image and title/description in each row
    }
  };

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography variant="h3" fontWeight="bold" color="#007eff"
           sx={{
            background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          >
            CATEGORIES TABLE
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateClick}
            style={{ backgroundColor: "#007eff", color: "#fff" }}
          >
            Create
          </Button>
        </Box>
        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: "10px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{ padding: "20px" }}>
              <TableRow>
                {/* Adjusting TableHead with your styles */}
                {listColums_colums.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      backgroundColor: "#007eff",
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      padding: "10px",
                      textAlign: "left",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {/* Edit and Delete headers */}
                <TableCell
                  style={{
                    backgroundColor: "#007eff",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Edit
                </TableCell>
                <TableCell
                  style={{
                    backgroundColor: "#007eff",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "10px",
                    textAlign: "left",
                  }}
                >
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listingsListData?.info.map((row) => (
                <TableRow
                  hover
                  key={row.id}
                  style={{ backgroundColor: "#f9f9f9" }}
                >
                  {/* Render content for each row */}
                  {listColums_colums.map((column) =>
                    renderContent(row, column)
                  )}
                  {/* Edit icon */}
                  <TableCell>
                    <Link to={`/dhunia_bo/edit-categories/${row.id}`}>
                      <IconButton color="primary">
                        <Edit />
                      </IconButton>
                    </Link>
                  </TableCell>
                  {/* Delete icon with confirmation dialog */}
                  <TableCell>
                    <IconButton color="error" onClick={() => handleDelete(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {/* Pagination */}
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={filters.offset - 1} // Adjust page to zero-based index
            count={listingsListData?.total ? listingsListData.total : 0}
            rowsPerPage={filters.limit}
            style={{ backgroundColor: "#007eff", color: "#fff" }}
          />
        </Paper>
      </Box>

      {/* Confirmation Dialog for Delete */}
      <Dialog open={showDeleteConfirmation} onClose={handleCloseDeleteConfirmation}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete this category?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ListingscategoriesTable;








