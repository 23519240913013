

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { createDistricts } from "./districts";

const DistrictForm = () => {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState([]);
  const [formData, setFormData] = useState({
    state_id: "", 
    district_name: "",
  });

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASEURL}userservice/location/states`);
        setSelectedState(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    fetchStates();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onFormSubmit = () => {
    navigate("/dhunia_bo/locations-districts");
  };


  

  const resetForm = () => {
    setFormData({
      state_id: "",
      district_name: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await createDistricts(formData);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit();
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/locations-districts");
  };


 

  return (
    <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    sx={{ backgroundColor: "#A7E6FF" }}
    >
        <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
      <Typography variant="h5" fontWeight="bold" marginBottom="20px"  align="center"
          color="#007eff">
        ADD DISTRICT
      </Typography>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          options={selectedState}
          getOptionLabel={(option) => option.state_name || ""}
          value={selectedState.find((state) => state.id === formData.state) || null}
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              state: newValue ? newValue.id : "", // Store the state ID
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />
        <TextField
          fullWidth
          label="District"
          variant="outlined"
          name="district_name"
          value={formData.district_name}
          onChange={handleChange}
          margin="normal"
          required
        />
     <Box display="flex" justifyContent="flex-end" gap={2} paddingTop="20px">
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
      </form>
      </Box>
    </Box>
  );
};

export default DistrictForm;
