import React from 'react';
import { useNavigate } from 'react-router-dom';
import NewsForm from './createform';

const NewsPage = () => {
  const navigate = useNavigate();

  const handleFormSubmit = (redirectPath) => {
    navigate(redirectPath);
  };

  return (
    <div>
      <NewsForm onFormSubmit={handleFormSubmit} />
    </div>
  );
};

export default NewsPage;
