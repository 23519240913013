import axios from "axios";




export const createUserBankForm = async (formData) => {
    const userDetails = localStorage.getItem("userDetails");
    const userData = JSON.parse(userDetails);
    const token = `Bearer ${userData?.access_token}`;
    const headers = {
      Authorization: token,
      "content-type": "application/json",
    };
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}backoffice/withdrawal/cashout`,
        formData,
        { headers }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return { success: false, message: "Failed to submit form data" };
    }
  };







export const getUserbank = async (id) => {
    const userDetails = localStorage.getItem("userDetails");
  
    if (!userDetails) {
      console.error("User details not found in local storage.");
      return null;
    }
  
    let userData;
    try {
      userData = JSON.parse(userDetails);
    } catch (error) {
      console.error("Error parsing user details:", error);
      return null;
    }
  
    if (!userData?.access_token) {
      console.error("Access token not found.");
      return null;
    }
  
    const token = `Bearer ${userData.access_token}`;
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json'
    };
  
    const url = `${process.env.REACT_APP_BASEURL}backoffice/user/bank?id=${id}`;
  
    try {
      console.log("Making request to URL:", url); 
      const response = await axios.get(url, { headers });
      console.log("Response data:", response.data); 
      if (response?.data?.success) {
        return response.data;
      } else {
        console.error("Error in response:", response?.data?.description);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user bank details:", error);
      return null;
    }
  };
  