import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createYtVideoForm } from "./youtube-apis";
import { UploadFiles } from "./youtube-apis";

const YtVideoForm = ({ onFormSubmit }) => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    creator_name: [""],
    url: "",
    thumbnail: "",
    video_type: "",
  });

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      // If the input is a file input, handle it separately
      setFormData((prevData) => ({
        ...prevData,
        [name]: files, // Store the files object
      }));
    } else {
      // Handle other types of inputs
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCreatorNameChange = (index, value) => {
    const updatedCreatorNames = [...formData.creator_name];
    updatedCreatorNames[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      creator_name: updatedCreatorNames,
    }));
  };

  const removeCreatorNameField = (index) => {
    const updatedCreatorNames = formData.creator_name.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      creator_name: updatedCreatorNames,
    }));
  };

  const UploadAndSaveNews = async (data, images) => {
    try {
      // Upload images
      const ImagesFormData = new FormData();
      images.forEach((file) => {
        ImagesFormData.append("files", file);
      });
      ImagesFormData.append("entity", data.entity);
      const images_response = await UploadFiles(ImagesFormData);

      if (!images_response.success) {
        throw new Error("Image upload failed");
      }

      // Assign uploaded image info to data
      data.image = images_response.info;

      // Create the form
      const form_response = await createYtVideoForm(data);

      if (!form_response.success) {
        throw new Error(form_response.message || "Form creation failed");
      }

      return form_response;
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error to be caught in handleSubmit
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const apiResponse = await createYtVideoForm(formData);
  //     if (apiResponse && apiResponse.success) {
  //       alert("Data submitted successfully");
  //       if (onFormSubmit) {
  //         onFormSubmit();
  //       }
  //       resetForm();
  //     } else {
  //       const errorMessage = apiResponse
  //         ? apiResponse.message
  //         : "Unknown error occurred";
  //       console.error(errorMessage);
  //       alert("Error in submitting the data");
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form data:", error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedFormData = { ...formData };

      if (formData.thumbnail && formData.thumbnail.length > 0) {
        // Prepare the form data for the file upload
        const ImagesFormData = new FormData();
        Array.from(formData.thumbnail).forEach((file) => {
          ImagesFormData.append("files", file);
        });
        ImagesFormData.append("entity", formData.entity);

        // Upload the files
        const imagesResponse = await UploadFiles(ImagesFormData);

        if (imagesResponse && imagesResponse.success) {
          // Assign the uploaded image info to formData
          updatedFormData.image = imagesResponse.info;
        } else {
          throw new Error(imagesResponse.message || "Image upload failed");
        }
      }

      // Submit the updated form data
      const apiResponse = await createYtVideoForm(updatedFormData);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        if (onFormSubmit) {
          onFormSubmit();
        }
        resetForm();
      } else {
        const errorMessage = apiResponse
          ? apiResponse.message
          : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    navigate("/dhunia_bo/yt-videos");
  };

  const resetForm = () => {
    setFormData({
      creator_name: [""],
      url: "",
      thumbnail: "",
      video_type: "",
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: "#A7E6FF" }}
    >
      <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          marginBottom="20px"
          align="center"
          color="#007eff"
        >
          CREATE FORM
        </Typography>
        <form onSubmit={handleSubmit}>
          {formData.creator_name.map((name, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              marginBottom="16px"
            >
              <TextField
                fullWidth
                label="Creator Name"
                name="creator_name"
                variant="outlined"
                value={name}
                onChange={(e) => handleCreatorNameChange(index, e.target.value)}
                margin="normal"
                required
              />
              {formData.creator_name.length > 1 && (
                <Button
                  onClick={() => removeCreatorNameField(index)}
                  color="error"
                >
                  Remove
                </Button>
              )}
            </Box>
          ))}
          <TextField
            fullWidth
            label="URL"
            name="url"
            variant="outlined"
            value={formData.url}
            onChange={handleChange}
            margin="normal"
            required
          />
          {/* <TextField
            fullWidth
            label="Thumbnail"
            name="thumbnail"
            variant="outlined"
            value={formData.thumbnail}
            onChange={handleChange}
            margin="normal"
            required
          /> */}
          <Input
            fullWidth
            label="Thumbnail"
            name="thumbnail"
            type="file"
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            required
          />

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Video Type</InputLabel>
            <Select
              value={formData.video_type}
              onChange={handleChange}
              label="Video Type"
              name="video_type"
              id="video_type"
            >
              <MenuItem value="SHORTS">SHORTS</MenuItem>
              <MenuItem value="VIDEO">VIDEO</MenuItem>
            </Select>
          </FormControl>

          <Box
            display="flex"
            justifyContent="flex-end"
            gap={2}
            sx={{ paddingTop: "20px" }}
          >
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default YtVideoForm;
