import React, { useState } from "react";
import { useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  createSubCategoriesForm,
  UploadFiles,
  CategoriesList,
} from "./subcategories";

const SubCategoriesForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [formData, setFormData] = useState({
    sub_category_name: "",
    sub_category_description: "",
  });
  const filters = {
    offset: 1,
    limit: 30,
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //categories
  useEffect(() => {
    async function getCategories() {
      const data = await CategoriesList(filters.offset, filters.limit);
      setSelectedCategory(data.info);
    }
    getCategories();
  }, [filters.offset, filters.limit]);

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const resetForm = () => {
    setFormData({
      sub_category_name: "",
      sub_category_description: "",
    });
    setImages([]);
  };

  const UploadAndSavesubCategory = async (data, images) => {
    try {
      // upload images
      const ImagesFormData = new FormData();
      images.forEach((file, index) => {
        ImagesFormData.append(`files`, file);
      });
      ImagesFormData.append("entity", "categories");
      const images_response = await UploadFiles(ImagesFormData);

      if (images_response.success) {
        data.sub_category_image = images_response.info
          ? images_response.info[0]
          : null;
        // data.video = video_response.info;
        const form_response = await createSubCategoriesForm(data);
        return form_response;
      }
      return null;
    } catch (error) {
      console.log(error);
    }
    return null;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UploadAndSavesubCategory(formData, images);
      // console.log("apiResponse", apiResponse);
      if (apiResponse && apiResponse.success) {
        // console.log("bbb", apiResponse.message);
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/listings-subcategories"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    setFormData({
      category_name: "",
      category_description: "",
    });
    setImages([]);

    navigate("/dhunia_bo/listings-subcategories");
  };
  return (
    <Box sx={{ backgroundColor: "#A7E6FF", width: "100%", height: "130%" }}>
      <Box sx={{padding:"3rem"}}>
      <Box
        marginRight="20px"
        marginLeft="20px"
        marginBottom="20px"
        height="auto"
        bgcolor="#f5f5f5"
        padding="20px"
        borderRadius="8px"
        sx={{
          maxWidth: "600px",
          width: "100%",
          marginLeft: "13rem",
          marginTop: "20px",
        }}
      >
      <Typography variant="h5" fontWeight="bold" marginBottom="20px"  textAlign="center"
            color="#007eff">
        CREATE SUB CATEGORY
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <Autocomplete
            options={selectedCategory}
            getOptionLabel={(option) => option.category_name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={formData.category}
            onChange={(event, newValue) => {
              setFormData({ ...formData, category: newValue? newValue.id : null });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Categories"
                name="category"
              />
            )}
          />
        </FormControl>

        <TextField
          fullWidth
          label="Sub Category Name"
          variant="outlined"
          name="sub_category_name"
          value={formData.sub_category_name}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={1}
          required
        />

        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          name="sub_category_description"
          value={formData.sub_category_description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
          required
        />

        {/* <Box marginBottom="20px" marginTop="20px">
          <input
            type="file"
            id="sub_category_image"
            name="sub_category_image"
            accept="image/*"
            onChange={handleImageChange}
          />
          {images.length > 0 && (
            <ul>
              {images.map((image, index) => (
                <li key={index}>
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button onClick={() => handleRemoveImage(index)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </Box> */}
        <Box marginBottom="20px" marginTop="20px">
  <Button
    variant="contained"
    component="label"
    color="primary"
    fullWidth
  >
    Upload Image
    <input
      type="file"
      id="sub_category_image"
      name="sub_category_image"
      accept="image/*"
      onChange={handleImageChange}
      hidden
    />
  </Button>
  {images.length > 0 && (
    <ul style={{ padding: 0, listStyle: "none" }}>
      {images.map((image, index) => (
        <li key={index} style={{ marginBottom: "10px" }}>
          <img
            src={
              typeof image === "object"
                ? URL.createObjectURL(image)
                : image
            }
            alt={`Preview ${index}`}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
          <Button
            onClick={() => handleRemoveImage(index)}
            color="error"
            variant="contained"
            style={{ marginLeft: "10px" }}
          >
            Remove
          </Button>
        </li>
      ))}
    </ul>
  )}
</Box>


<Box display="flex" justifyContent="flex-end" gap={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                fullWidth
              >
                Cancel
              </Button>
            </Box>
      </form>
    </Box>
    </Box>
    </Box>
  );
};
export default SubCategoriesForm;
