import { Box, styled, Card, Button, TextField } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Switch from "@mui/material/Switch";
import Header from "../../../components/Header";
import { useState, forwardRef, useEffect } from "react";
import { editMovieMakersData } from "../../../services/movieMakers";
import { Formik } from "formik";
import * as yup from "yup";
import MovieMakersList from "./movieMakersList";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { updateMovieMakerStatus } from "../../../services/movieMakers";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CreateMovieMakers = ({ permissions }) => {
  const [actionOpen, setActionOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  // const [isActive, setIsActive] = useState(false);
  // const [isApproved, setIsApprobved] = useState(false);
  const [value, setValue] = useState("PENDING");
  const [LanguageList, setLanguageList] = useState(["English", "Hindi"]);
  const [updateStatusPayload, setUpdateStatusPayload] = useState({});
  const [initialValues, setInitialValues] = useState({
    remarks: "",
    isActive: false,
    isApproved: false,
  });
  // const [permissions, setPermisssions] = useState({
  //   read_permission: false,
  //   write_permission: false,
  //   delete_permission: false,
  //   update_permission: false,
  // });
  // console.log(permissions);
  useEffect(() => {
    // const userDetails = localStorage.getItem("userDetails");
    // if (userDetails) {
    //   const userData = JSON.parse(userDetails);
    //   let custom_permissions = userData?.profile_details?.custom_permissions;
    //   let read_permission =
    //     custom_permissions.includes("is_read_bo_newsreporters") ||
    //     custom_permissions.includes("is_write_bo_newsreporters") ||
    //     custom_permissions.includes("is_update_bo_newsreporters") ||
    //     custom_permissions.includes("is_delete_bo_newsreporters");
    //   let write_permission =
    //     custom_permissions.includes("is_write_bo_newsreporters") ||
    //     custom_permissions.includes("is_update_bo_newsreporters") ||
    //     custom_permissions.includes("is_delete_bo_newsreporters");
    //   let update_permission =
    //     custom_permissions.includes("is_update_bo_newsreporters") ||
    //     custom_permissions.includes("is_delete_bo_newsreporters");
    //   let delete_permission = custom_permissions.includes(
    //     "is_delete_bo_newsreporters"
    //   );
    //   setPermisssions((prevPermissions) => {
    //     return {
    //       ...prevPermissions,
    //       read_permission,
    //       write_permission,
    //       update_permission,
    //       delete_permission,
    //     };
    //   });
    // }
  }, []);
  const handleSelectDropdownChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguageList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleTabChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  function handleEditOpen(reporter_payload) {
    // console.log("handleEditOpen variable reporter_payload:", reporter_payload);
    setUpdateStatusPayload(reporter_payload);
    setInitialValues({
      id: reporter_payload.id,
      remarks: reporter_payload.remarks,
      isActive: reporter_payload.is_active,
      isApproved: reporter_payload.is_approved,
    });
    setLanguageList(reporter_payload.languages);
    setEditOpen(true);
  }
  function handleActionOpen(reporter_payload) {
    setUpdateStatusPayload(reporter_payload);
    setActionOpen(true);
  }
  function handleClose() {
    setEditOpen(false);
    setActionOpen(false);
    setLanguageList([]);
    setUpdateStatusPayload({});
  }
  const handleFormSubmit = (values) => {
    debugger;
    const json_payload = {
      is_active: values.isActive,
      is_approved: values.isApproved,
      languages: LanguageList,
      remarks: values.remarks,
      reporter_id: values.id,
    };
    editMovieMakersData(json_payload);
    setEditOpen(false);
    setLanguageList([]);
    handleTabChange({}, value);
    setInitialValues({
      remarks: "",
      isActive: false,
      isApproved: false,
    });
  };
  const handleActionStatus = async () => {
    const json_payload = {
      movie_maker_id: updateStatusPayload.id,
      status: updateStatusPayload.action,
    };
    // console.log(updateStatusPayload);
    updateMovieMakerStatus(json_payload);
    setActionOpen(false);
    // getCategoryData(setCategoryList);
    setValue(value);
    window.location.reload();
  };
  return (
    <Container sx={{ m: "10px" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Card elevation={3} sx={{ pt: "20px" }}>
          <CardHeader>
            <Header title="Movie Makers" />
          </CardHeader>
          {permissions.read_newsreporters_permission && (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0 }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="PENDING" value="PENDING" />
                  <Tab label="APPROVED" value="APPROVED" />
                  <Tab label="REJECTED" value="REJECTED" />
                  <Tab label="BLOCKED" value="BLOCKED" />
                  {/* <Tab label="Deleted" value="Deleted" /> */}
                </TabList>
              </Box>
              <TabPanel value="PENDING" sx={{ padding: 0 }}>
                <MovieMakersList
                  section={value}
                  handleEditOpen={handleEditOpen}
                  handleActionOpen={handleActionOpen}
                  permissions={permissions}
                />
              </TabPanel>
              <TabPanel value="APPROVED" sx={{ padding: 0 }}>
                {" "}
                <MovieMakersList
                  section={value}
                  handleEditOpen={handleEditOpen}
                  handleActionOpen={handleActionOpen}
                  permissions={permissions}
                />
              </TabPanel>
              <TabPanel value="REJECTED" sx={{ padding: 0 }}>
                {" "}
                <MovieMakersList
                  section={value}
                  handleEditOpen={handleEditOpen}
                  handleActionOpen={handleActionOpen}
                  permissions={permissions}
                />
              </TabPanel>
              <TabPanel value="BLOCKED" sx={{ padding: 0 }}>
                {" "}
                <MovieMakersList
                  section={value}
                  handleEditOpen={handleEditOpen}
                  handleActionOpen={handleActionOpen}
                  permissions={permissions}
                />
              </TabPanel>
            </TabContext>
          )}
          {!permissions.read_newsreporters_permission &&
            "Sorry, you don't have required permissions"}
        </Card>
      </Box>
      <Dialog
        open={actionOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle>
          <b>{"Action Confirmation"}</b>
        </DialogTitle>
        <DialogContent>
          {/* <Alert variant="outlined" severity="error"> */}
          <DialogContentText id="alert-dialog-slide-description" color="black">
            {`Are You Sure You Want To ${updateStatusPayload?.action}?`}
          </DialogContentText>
          {/* </Alert> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            startIcon={<ClearIcon />}
            variant="contained"
            // color="#e0e0e0"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={handleActionStatus}
            startIcon={<DoneIcon />}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">Edit Movie Maker</DialogTitle>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                gap="30px"
                sx={{
                  // "& > div": { gridColumn: "span 4" },
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <TextField
                    variant="filled"
                    type="text"
                    label="Remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.remarks}
                    name="remarks"
                    error={!!touched.remarks && !!errors.remarks}
                    helperText={touched.remarks && errors.remarks}
                    sx={{ width: "45%", mr: "4%" }}
                  />
                  <FormControl sx={{ width: "50%" }}>
                    <InputLabel id="demo-multiple-checkbox-label">
                      Languages
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={LanguageList}
                      onChange={handleSelectDropdownChange}
                      input={<OutlinedInput label="Languages" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      name="Languages"
                    >
                      {LanguageList.map((LG) => (
                        <MenuItem key={LG} value={LG}>
                          <Checkbox checked={LanguageList.indexOf(LG) > -1} />
                          <ListItemText primary={LG} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={{ display: "flex" }}>
                  <DialogContent sx={{ width: "50%" }}>
                    <span>Is Active</span>
                    <Switch
                      checked={values.isActive}
                      // onChange={handleActiveStatusChange}
                      label="Is Active"
                      name="isActive"
                      onChange={handleChange}
                      value={values.isActive}
                    />
                  </DialogContent>
                  <DialogContent sx={{ width: "50%" }}>
                    <span>Is Approved</span>
                    <Switch
                      checked={values.isApproved}
                      // onChange={handleApprovedStatusChange}
                      label="Is Approved"
                      name="isApproved"
                      onChange={handleChange}
                      value={values.isApproved}
                    />
                  </DialogContent>
                </div>
              </Box>
              <DialogActions>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained">
                  Submit
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </Container>
  );
};
const checkoutSchema = yup.object().shape({
  remarks: yup.string().required("required"),
  isActive: yup.boolean().required("required"),
});
// const initialValues = {
//   remarks: "",
//   isActive: false,
//   isApproved: false,
// };
export default CreateMovieMakers;
