


import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
  Box,
  TablePagination,
} from "@mui/material";
import { getYoutubeVideos } from "./youtube-apis";
import { useNavigate } from "react-router-dom";

const YtMoviesTable = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await getYoutubeVideos();
        console.log('API Response in useEffect:', JSON.stringify(response, null, 2)); // Detailed log of the API response

        // Ensure response has 'info' array
        if (response && Array.isArray(response.info)) {
          setVideos(response.info); 
        } else {
          console.error("Invalid response structure:", JSON.stringify(response, null, 2));
          throw new Error("Invalid response structure");
        }
      } catch (err) {
        console.error("Error in fetchVideos:", err);
        setError(err.message || "Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleCreateClick = () => {
    navigate("/dhunia_bo/yt-videos-form");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} style={{padding:"20px" , borderRadius :"10px"}}>
      <Box display="flex" justifyContent="space-between" p={2}>
      <Typography variant="h3" fontWeight="bold" paddingLeft="10px"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          YOUTUBE VIDEOS
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </Box>
      <Table >
        <TableHead>
          <TableRow sx={{ backgroundColor: "#007effa6", fontWeight: "bold" }}>
            <TableCell>ID</TableCell>
            <TableCell>Creator Name</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Thumbnail</TableCell>
           
            <TableCell>Video Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {videos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((video) => (
            <TableRow key={video.id}>
              <TableCell>{video.id}</TableCell>
              <TableCell>{Array.isArray(video.creator_name) ? video.creator_name.join(", ") : video.creator_name}</TableCell>
              <TableCell>{video.url}</TableCell>
              <TableCell>{video.thumbnail}</TableCell>
            
              <TableCell>{video.video_type}</TableCell>
            </TableRow>
          ))}
          {videos.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No videos available</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={videos.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ backgroundColor: "#007effa6" }}
      />
    </TableContainer>
  );
};

export default YtMoviesTable;
