// import React from 'react'
// import NotificationForm from './news-notification';

// function Push() {
//   return (
//     <div><NotificationForm /></div>
//   )
// }

// export default Push;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import NotificationForm  from './news-notification'; // Adjust the import path as needed

function Notification() {
  const navigate = useNavigate();

  const handleFormSubmit = (redirectPath) => {
    navigate(redirectPath);
  };

  return (
    <div>
      <NotificationForm onFormSubmit={handleFormSubmit} />
    </div>
  );
}

export default Notification;
