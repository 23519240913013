// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   Select,
//   MenuItem,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
// } from "@mui/material";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { getMandals, deleteMandal } from "./mandal";

// function MandalsTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [mandalListData, setMandalListData] = useState(null);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedDistricts, setSelectedDistricts] = useState([]);

//   // useEffect(() => {
//   //   async function fetchMandals() {
//   //     try {
//   //       const response = await getMandals(
//   //         filters.offset,
//   //         filters.limit,
//   //         null,
//   //         filters.districtId // Pass districtId to getMandals
//   //       );
//   //       console.log("Mandals data:", response);
//   //       setMandalListData(response);
//   //     } catch (error) {
//   //       console.error("Error fetching mandals:", error);
//   //     }
//   //   }

//   //   fetchMandals();
//   // }, [filters]);
//   useEffect(() => {
//     async function fetchMandals() {
//       try {
//         let response;
//         if (!filters.stateId && !filters.districtId) {
//           console.log("Fetching all mandals", response);
//           response = await getMandals(filters.offset, filters.limit);
//         } else {
//           console.log(
//             "Fetching mandals based on districtId:",
//             filters.districtId
//           );
//           response = await getMandals(
//             filters.offset,
//             filters.limit,
//             null,
//             filters.districtId
//           );
//         }
//         console.log("Mandals data:", response);
//         setMandalListData(response);
//       } catch (error) {
//         console.error("Error fetching mandals:", error);
//       }
//     }

//     fetchMandals();
//   }, [filters]);

//   useEffect(() => {
//     const fetchStates = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states`
//         );
//         setSelectedStates(response.data.info);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     };
//     fetchStates();
//   }, []);

//   useEffect(() => {
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
//         );
//         setSelectedDistricts(response.data.info);
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };
//     fetchDistricts();
//   }, [filters.stateId]);

//   const handleDeleteMandal = async (id) => {
//     try {
//       await deleteMandal(id);

//       const response = await getMandals(
//         filters.offset,
//         filters.limit,
//         null,
//         filters.districtId // Pass districtId to getMandals
//       );
//       setMandalListData(response);
//     } catch (error) {
//       console.error("Error deleting mandal:", error);
//     }
//   };

//   const handlePageChange = (event, newPage) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       offset: newPage + 1,
//     }));
//   };

//   const handleStateChange = (event) => {
//     const stateId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       stateId,
//       districtId: null, // Reset districtId when state changes
//       offset: 1, // Reset pagination
//     }));
//   };

//   const handleDistrictChange = (event) => {
//     const districtId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       districtId,
//       offset: 1, // Reset pagination
//     }));
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-mandal-create-form");
//   };

//   return (
//     <Box >
    
//       <Box m="20px">
//       <Typography variant="h3" fontWeight="bold">
//         MANDALS TABLE
//       </Typography>
//       <h4>*Please Select State and District to view Mandals </h4>
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="10px"
//         >
//           <Box display="flex" alignItems="center">
//             <Select
//               value={filters.stateId || ""}
//               onChange={handleStateChange}
//               displayEmpty
//               inputProps={{ "aria-label": "Select State" }}
//               style={{ marginRight: "10px", width: "200px" }}
//             >
//               <MenuItem value="">
//                 <em>All States</em>
//               </MenuItem>
//               {selectedStates.map((state) => (
//                 <MenuItem key={state.id} value={state.id}>
//                   {state.state_name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>

//           <Box display="flex" alignItems="center">
//             <Select
//               value={filters.districtId || ""}
//               onChange={handleDistrictChange}
//               displayEmpty
//               inputProps={{ "aria-label": "Select District" }}
//               style={{ marginRight: "10px", width: "200px" }}
//               disabled={!filters.stateId} // Disable if no state is selected
//             >
//               <MenuItem value="">
//                 <em>All Districts</em>
//               </MenuItem>
//               {selectedDistricts.map((district) => (
//                 <MenuItem key={district.id} value={district.id}>
//                   {district.district_name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>

//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>
//               {mandalListData?.info?.length > 0 ? (
//                 mandalListData.info.map((row, index) => (
//                   <TableRow hover key={row.id}>
//                     <TableCell align="center">{index + 1}</TableCell>
//                     <TableCell align="center">
//                       <Typography>{row.mandal_name}</Typography>
//                     </TableCell>
//                     <TableCell>
//                       <DeleteIcon
//                         onClick={() => handleDeleteMandal(row.id)}
//                         style={{ margin: "10px", cursor: "pointer" }}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={3} align="center">
//                   Please Select State and District to view Mandals 
//                   </TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1}
//             count={mandalListData?.total || 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6" }}
//           />
//         </Paper>
//       </Box>
//     </Box>
//   );
// }

// export default MandalsTable;









//-------------------------------------------------------------------------------------------------------



// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   Select,
//   MenuItem,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   TableHead,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { getMandals, deleteMandal } from "./mandal";

// function MandalsTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [mandalListData, setMandalListData] = useState(null);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedDistricts, setSelectedDistricts] = useState([]);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [mandalToDelete, setMandalToDelete] = useState(null);

//   useEffect(() => {
//     async function fetchMandals() {
//       try {
//         let response;
//         if (!filters.stateId && !filters.districtId) {
//           response = await getMandals(filters.offset, filters.limit);
//         } else {
//           response = await getMandals(
//             filters.offset,
//             filters.limit,
//             null,
//             filters.districtId
//           );
//         }
//         setMandalListData(response);
//       } catch (error) {
//         console.error("Error fetching mandals:", error);
//       }
//     }

//     fetchMandals();
//   }, [filters]);

//   useEffect(() => {
//     const fetchStates = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states`
//         );
//         setSelectedStates(response.data.info);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     };
//     fetchStates();
//   }, []);

//   useEffect(() => {
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
//         );
//         setSelectedDistricts(response.data.info);
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };
//     fetchDistricts();
//   }, [filters.stateId]);

//   const handleDeleteIconClick = (id) => {
//     setMandalToDelete(id);
//     setOpenDelete(true);
//   };

//   const handleDeleteMandal = async () => {
//     try {
//       await deleteMandal(mandalToDelete);
//       setOpenDelete(false);
//       setMandalToDelete(null);
//       const response = await getMandals(
//         filters.offset,
//         filters.limit,
//         null,
//         filters.districtId
//       );
//       setMandalListData(response);
//     } catch (error) {
//       console.error("Error deleting mandal:", error);
//     }
//   };

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//     setMandalToDelete(null);
//   };

//   const handlePageChange = (event, newPage) => {
//     setFilters((prevFilters) => ({
//       ...prevFilters,
//       offset: newPage + 1,
//     }));
//   };

//   const handleStateChange = (event) => {
//     const stateId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       stateId,
//       districtId: null,
//       offset: 1,
//     }));
//   };

//   const handleDistrictChange = (event) => {
//     const districtId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       districtId,
//       offset: 1,
//     }));
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-mandal-create-form");
//   };

//   return (
//     <Box>
//       <Box m="20px">
//         <Typography variant="h3" fontWeight="bold">
//           MANDALS TABLE
//         </Typography>
//         <h4>*Please Select State and District to view Mandals </h4>
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="10px"
//         >
//           <Box display="flex" alignItems="center">
//             <Select
//               value={filters.stateId || ""}
//               onChange={handleStateChange}
//               displayEmpty
//               inputProps={{ "aria-label": "Select State" }}
//               style={{ marginRight: "10px", width: "200px" }}
//             >
//               <MenuItem value="">
//                 <em>All States</em>
//               </MenuItem>
//               {selectedStates.map((state) => (
//                 <MenuItem key={state.id} value={state.id}>
//                   {state.state_name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>

//           <Box display="flex" alignItems="center">
//             <Select
//               value={filters.districtId || ""}
//               onChange={handleDistrictChange}
//               displayEmpty
//               inputProps={{ "aria-label": "Select District" }}
//               style={{ marginRight: "10px", width: "200px" }}
//               disabled={!filters.stateId}
//             >
//               <MenuItem value="">
//                 <em>All Districts</em>
//               </MenuItem>
//               {selectedDistricts.map((district) => (
//                 <MenuItem key={district.id} value={district.id}>
//                   {district.district_name}
//                 </MenuItem>
//               ))}
//             </Select>
//           </Box>

//           <Button
//             variant="contained"
//             style={{ backgroundColor: "#007effa6", color: "white" }}
//             onClick={() => navigate("/dhunia_bo/locations-mandal-create-form")}
//           >
//             Create
//           </Button>
//         </Box>

//         <Paper sx={{
//             width: "100%",
//             overflow: "hidden",
//             boxShadow: 3,
//             borderRadius: "15px",
//             backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
         
//           }}>
//           <Table stickyHeader aria-label="sticky table"
//           sx={{
//             '& .MuiTableCell-root': {
//               border: '1px solid #e0e0e0',
//               padding: '8px',
//             },
//           }}
//           >
//               <TableHead  >
//               <TableRow   sx={{ backgroundColor: "#007effa6",  '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>#</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>Mandal Name</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody></TableBody>
            
//             <TableBody>
//               {mandalListData?.info?.length > 0 ? (
//                 mandalListData.info.map((row, index) => (
//                   <TableRow hover key={row.id}
//                   sx={{
//                     '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
//                     '&:hover': { backgroundColor: '#e0f7fa' },
//                   }}
//                   >
//                     <TableCell align="center">{index + 1}</TableCell>
//                     <TableCell align="center">
//                       <Typography>{row.mandal_name}</Typography>
//                     </TableCell>
//                     <TableCell
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                       }}
//                       >
//                       <DeleteIcon
//                         onClick={() => handleDeleteIconClick(row.id)}
//                         style={{ margin: "10px", cursor: "pointer" }}
//                         sx={{
//                           margin: "10px",
//                           marginLeft :"40px",
//                           cursor: "pointer",
//                           color: "#f50057",
//                           "&:hover": { color: "#ff4081" },
//                         }}
//                       />
//                     </TableCell>
//                   </TableRow>
//                 ))
//               ) : (
//                 <TableRow>
//                   <TableCell colSpan={3} align="center">
//                     Please Select State and District to view Mandals
//                   </TableCell>
//                 </TableRow>
//               )}
//             </TableBody>
//           </Table>
//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1}
//             count={mandalListData?.total || 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6",color: "black" }}
//           />
//         </Paper>
//       </Box>

//       <Dialog
//         open={openDelete}
//         onClose={handleCloseDelete}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Are you sure you want to delete this mandal?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//         <Button
//             onClick={handleCloseDelete}
//             sx={{
//               color: "white",
//               backgroundColor: "#318CE7",
//               "&:hover": {
//                 backgroundColor: "#6CB4EE",
//               },
//             }}
//           >
//             Cancel
//           </Button>
//           <Button
//             onClick={handleDeleteMandal}
//             sx={{
//               color: "white",
//               backgroundColor: "red",
//               "&:hover": {
//                 backgroundColor: "lightcoral",
//               },
//             }}
//             autoFocus
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default MandalsTable;





import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  Select,
  MenuItem,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { getMandals, deleteMandal } from "./mandal";

function MandalsTable({ filters, setFilters }) {
  const navigate = useNavigate();

  const [mandalListData, setMandalListData] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [mandalToDelete, setMandalToDelete] = useState(null);

  useEffect(() => {
    async function fetchMandals() {
      try {
        let response;
        if (!filters.stateId && !filters.districtId) {
          response = await getMandals(filters.offset, filters.limit);
        } else {
          response = await getMandals(
            filters.offset,
            filters.limit,
            null,
            filters.districtId
          );
        }
        setMandalListData(response);
      } catch (error) {
        console.error("Error fetching mandals:", error);
      }
    }

    fetchMandals();
  }, [filters]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states`
        );
        setSelectedStates(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
        );
        setSelectedDistricts(response.data.info);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };
    fetchDistricts();
  }, [filters.stateId]);

  const handleDeleteIconClick = (id) => {
    setMandalToDelete(id);
    setOpenDelete(true);
  };

  const handleDeleteMandal = async () => {
    try {
      await deleteMandal(mandalToDelete);
      setOpenDelete(false);
      setMandalToDelete(null);
      const response = await getMandals(
        filters.offset,
        filters.limit,
        null,
        filters.districtId
      );
      setMandalListData(response);
    } catch (error) {
      console.error("Error deleting mandal:", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setMandalToDelete(null);
  };

  const handlePageChange = (event, newPage) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      offset: newPage + 1,
    }));
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      stateId,
      districtId: null,
      offset: 1,
    }));
  };

  const handleDistrictChange = (event) => {
    const districtId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      districtId,
      offset: 1,
    }));
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/locations-mandal-create-form");
  };

  return (
    <Box>
      <Box m="20px">
        <Typography variant="h3" fontWeight="bold"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          MANDALS TABLE
        </Typography>
        <h4>*Please Select State and District to view Mandals </h4>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Box display="flex" alignItems="center">
            <Select
              value={filters.stateId || ""}
              onChange={handleStateChange}
              displayEmpty
              inputProps={{ "aria-label": "Select State" }}
              style={{ marginRight: "10px", width: "200px" }}
            >
              <MenuItem value="">
                <em>All States</em>
              </MenuItem>
              {selectedStates.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.state_name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box display="flex" alignItems="center">
            <Select
              value={filters.districtId || ""}
              onChange={handleDistrictChange}
              displayEmpty
              inputProps={{ "aria-label": "Select District" }}
              style={{ marginRight: "10px", width: "200px" }}
              disabled={!filters.stateId}
            >
              <MenuItem value="">
                <em>All Districts</em>
              </MenuItem>
              {selectedDistricts.map((district) => (
                <MenuItem key={district.id} value={district.id}>
                  {district.district_name}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            variant="contained"
            style={{ backgroundColor: "#007effa6", color: "white" }}
            onClick={() => navigate("/dhunia_bo/locations-mandal-create-form")}
          >
            Create
          </Button>
        </Box>

        <Paper sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 3,
            borderRadius: "15px",
            backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
         
          }}>
          <Table stickyHeader aria-label="sticky table"
          sx={{
            '& .MuiTableCell-root': {
              border: '1px solid #e0e0e0',
              padding: '8px',
            },
          }}
          >
              <TableHead  >
              <TableRow   sx={{ backgroundColor: "#007effa6",  '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>#</strong>
                </TableCell>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>Mandal Name</strong>
                </TableCell>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
            
            <TableBody>
              {mandalListData?.info?.length > 0 ? (
                mandalListData.info.map((row, index) => (
                  <TableRow hover key={row.id}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                    '&:hover': { backgroundColor: '#e0f7fa' },
                  }}
                  >
                    <TableCell align="center">{(filters.offset - 1) * filters.limit + index + 1}</TableCell>
                    <TableCell align="center">
                      <Typography>{row.mandal_name}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      >
                      <DeleteIcon
                        onClick={() => handleDeleteIconClick(row.id)}
                        style={{ margin: "10px", cursor: "pointer" }}
                        sx={{
                          margin: "10px",
                          marginLeft :"40px",
                          cursor: "pointer",
                          color: "#f50057",
                          "&:hover": { color: "#ff4081" },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    Please Select State and District to view Mandals
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={filters.offset - 1}
            count={mandalListData?.total || 0}
            rowsPerPage={filters.limit}
            style={{ backgroundColor: "#007effa6",color: "black" }}
          />
        </Paper>
      </Box>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this mandal?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            onClick={handleCloseDelete}
            sx={{
              color: "white",
              backgroundColor: "#318CE7",
              "&:hover": {
                backgroundColor: "#6CB4EE",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteMandal}
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "lightcoral",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MandalsTable;
