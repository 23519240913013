// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   Grid,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import Autocomplete from "@mui/material/Autocomplete";
// import { useNavigate } from "react-router-dom";

// import { useParams } from "react-router-dom";
// import { getBanners } from "./banner";
// import { updateForm, UploadFiles } from "./form";

// const BannerEditForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const [formData, setFormData] = useState({
//     news_id: id,
//     title: "title",
//     categories: [],
//     description: "",
//     language: "",
//     city: "",
//     tags: [],
//   });
//   const [images, setImages] = useState([]);
//   const [video, setVideo] = useState([]);

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getBanners(1, 10, id);
//       data = data.info[0];
//       setFormData({

//         title: data?.title,

//       });
//       setImages(data?.image ? data?.image : []);
//       setVideo(data?.video ? data?.video : []);
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();

//   const handleRemoveImage = (indexToRemove) => {
//     setImages(images.filter((_, index) => index !== indexToRemove));
//   };

//   const handleRemoveVideo = (indexToRemove) => {
//     setVideo(video.filter((_, index) => index !== indexToRemove));
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };
//   const handleTagsChange = (event, newValues) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       tags: newValues,
//     }));
//   };
//   const [inputValue, setInputValue] = React.useState("");
//   const handleKeyDown = (event) => {
//     if (event.key === "Enter" && inputValue.trim()) {
//       setFormData((prevData) => ({
//         ...prevData,
//         tags: [...prevData.tags, inputValue.trim()],
//       }));
//       setInputValue("");
//     }
//   };

//   const handleCityChange = (event, value) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       city: value || "",
//     }));
//   };

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setImages((prevData) => [...prevData, ...selectedImages]);
//   };

//   const handleVideoChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setVideo((prevData) => [...prevData, ...selectedImages]);
//   };

//   const resetForm = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       categories: [],
//       description: "",
//       language: "",
//       city: "",
//       tags: [],
//     });
//     setImages([]);
//     setVideo([]);
//   };
//   const UpdateAndSaveNews = async (data, images, videos) => {
//     try {
//       const newImages = images.filter((item) => typeof item === "object");
//       const newVideos = videos.filter((item) => typeof item === "object");
//       const oldImages = images.filter((item) => typeof item === "string");
//       const oldVideos = videos.filter((item) => typeof item === "string");

//       // Upload new images
//       let imagesResponse;
//       if (newImages.length > 0) {
//         const imagesFormData = new FormData();
//         newImages.forEach((file, index) => {
//           imagesFormData.append(`files`, file);
//         });
//         imagesFormData.append("entity", data.entity);
//         imagesResponse = await UploadFiles(imagesFormData);
//       } else {
//         imagesResponse = { success: true, info: [] };
//       }

//       // Upload new videos
//       let videosResponse;
//       if (newVideos.length > 0) {
//         const videosFormData = new FormData();
//         newVideos.forEach((file, index) => {
//           videosFormData.append(`files`, file);
//         });
//         videosFormData.append("entity", data.entity);
//         videosResponse = await UploadFiles(videosFormData);
//       } else {
//         videosResponse = { success: true, info: [] };
//       }

//       if (imagesResponse.success && videosResponse.success) {
//         data.image = oldImages.concat(imagesResponse.info);
//         data.video = oldVideos.concat(videosResponse.info);
//         const formResponse = await updateForm(data);
//         return formResponse;
//       }
//       return null;
//     } catch (error) {
//       console.error(error);
//       return null;
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const apiResponse = await UpdateAndSaveNews(formData, images, video);
//       if (apiResponse && apiResponse.success) {
//         alert("Data submitted successfully");
//         onFormSubmit(navigate("/dhunia_bo/posts"));
//         resetForm();
//       } else {
//         const errorMessage =
//           apiResponse && apiResponse.message
//             ? apiResponse.message
//             : "Unknown error occurred";
//         console.error(errorMessage);
//         alert("Error in Submitting the data");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };
//   const handleCancel = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       categories: [],
//       description: "",
//       language: "",
//       city: "BANGALORE",
//       tags: "",
//     });
//     setImages([]);
//     setVideo([]);
//     navigate("/dhunia_bo/posts");
//   };
//   return (
//     <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
//       <Typography variant="h3" fontWeight="bold" marginBottom="20px">
//         EDIT NEWS
//       </Typography>
//       <form>
//         <TextField
//           fullWidth
//           label="Title"
//           variant="outlined"
//           name="title"
//           value={formData.title}
//           onChange={handleChange}
//           margin="normal"
//           required
//         />
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <FormControl fullWidth variant="outlined" margin="normal">
//               <InputLabel id="language-label">Language</InputLabel>
//               <Select
//                 labelId="language-label"
//                 id="language"
//                 name="language"
//                 value={formData.language}
//                 onChange={handleChange}
//                 label="Language"
//                 required
//               >
//                 <MenuItem value="ENGLISH">ENGLISH</MenuItem>
//                 <MenuItem value="TELUGU">TELUGU</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//         <Grid container spacing={2}>
//           <Grid item xs={12} md={6}>
//             <FormControl fullWidth variant="outlined" margin="normal">
//               <Autocomplete
//                 multiple
//                 id="categories"
//                 options={[
//                   "POLITICS",
//                   "SPORTS",
//                   "ENTERTAINMENT",
//                   "MOVIES",
//                   "MOVIE",
//                 ]}
//                 value={formData.categories || []}
//                 isOptionEqualToValue={(option, value) =>
//                   value && option.toLowerCase() === value.toLowerCase()
//                 }
//                 onChange={(event, newValue) => {
//                   setFormData({ ...formData, categories: newValue });
//                 }}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     variant="outlined"
//                     label="Categories"
//                   />
//                 )}
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} md={6}>
//           <CreateEditCities formData={formData} handleCityChange={handleCityChange}/>
//           </Grid>
//         </Grid>

//         <Box marginBottom="20px" marginTop="20px">
//           <label htmlFor="images">
//             <Button variant="outlined" component="span">
//               Upload Images
//             </Button>
//           </label>
//           <input
//             type="file"
//             id="images"
//             name="images"
//             accept="image/*"
//             onChange={handleImageChange}
//             multiple
//             style={{
//               display: "none",
//             }}
//           />
//           {images.length > 0 && (
//             <ul>
//               {images.map((image, index) => (
//                 <li key={index}>
//                   <img
//                     src={
//                       typeof image === "object"
//                         ? URL.createObjectURL(image)
//                         : image
//                     }
//                     alt={`Preview ${index}`}
//                     style={{ maxWidth: "100px", maxHeight: "100px" }}
//                   />
//                   <Button onClick={() => handleRemoveImage(index)}>
//                     Remove
//                   </Button>
//                 </li>
//               ))}
//             </ul>
//           )}
//         </Box>
//         <Box marginBottom="20px">
//           <label htmlFor="video">
//             <Button variant="outlined" component="span">
//               Upload Video
//             </Button>
//           </label>
//           <input
//             type="file"
//             id="video"
//             name="video"
//             accept="video/*"
//             onChange={handleVideoChange}
//             multiple
//             style={{
//               display: "none",
//             }}
//           />
//           {video.length > 0 && (
//             <ul>
//               {video.map((video, index) => (
//                 <li key={index}>
//                   <video
//                     src={
//                       typeof video === "object"
//                         ? URL.createObjectURL(video)
//                         : video
//                     }
//                     controls
//                     alt={`Preview ${index}`}
//                     style={{ maxWidth: "100px", maxHeight: "100px" }}
//                   />
//                   <Button onClick={() => handleRemoveVideo(index)}>
//                     Remove
//                   </Button>
//                 </li>
//               ))}
//             </ul>
//           )}
//         </Box>

//         <Box marginTop="10px" display="flex" justifyContent="flex-end">
//           <Button
//             type="button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//           >
//             Update
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };
// export default BannerEditForm ;

// // import React, { useState } from "react";
// // import { TextField, Button, Box, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
// // import Autocomplete from "@mui/material/Autocomplete";
// // import { useNavigate } from "react-router-dom";
// // import { UploadBannerFiles } from "./banner";
// // import { createBannerForm } from "./banner";

// // const BannersForm = ({ onFormSubmit }) => {
// //     const navigate = useNavigate();
// //   const [title, setTitle] = useState("");
// //   const [description, setDescription] = useState("");
// //   const [images, setImages] = useState([]);
// //   const [videos, setVideos] = useState([]);
// //   const [formData, setFormData] = useState({
// //     title: "",

// //     description: "",

// //   });

// //   const handleTitleChange = (e) => {
// //     setTitle(e.target.value);
// //   };

// //   const handleDescriptionChange = (e) => {
// //     setDescription(e.target.value);
// //   };

// //   const handleImageChange = (e) => {
// //     setImages(Array.from(e.target.files));
// //   };

// //   const handleVideoChange = (e) => {
// //     setVideos(Array.from(e.target.files));
// //   };

// //   const UploadAndSaveBanners = async (data,images,videos) => {
// //     try {
// //       // upload images
// //       const ImagesFormData = new FormData();
// //       images.forEach((file, index) => {
// //         ImagesFormData.append(`files`, file);
// //       });
// //       ImagesFormData.append("entity", data.entity);
// //       const images_response = await UploadBannerFiles(ImagesFormData);

// //       // upload videos
// //       const videosFormData = new FormData();
// //       videos.forEach((file, index) => {
// //         videosFormData.append(`files`, file);
// //       });
// //       videosFormData.append("entity", data.entity);
// //       const video_response = await UploadBannerFiles(videosFormData);

// //       if (images_response.success && video_response.success) {
// //         data.image = images_response.info;
// //         data.video = video_response.info;
// //         const form_response = await createBannerForm(data);
// //         return form_response;
// //       }
// //       return null;
// //     } catch (error) {
// //       console.log(error);
// //     }
// //     return null;
// //   };

// //   const resetForm = () => {
// //     setFormData({
// //       name: "",

// //     });
// //     setImages([]);

// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     try {
// //       const apiResponse = await UploadAndSaveBanners(formData, images, );
// //       // console.log("apiResponse", apiResponse);
// //       if (apiResponse && apiResponse.success) {
// //         // console.log("bbb", apiResponse.message);
// //         alert("Data submitted successfully");
// //         onFormSubmit(navigate("/dhunia_bo/posts"));
// //         resetForm();
// //       } else {
// //         const errorMessage =
// //           apiResponse && apiResponse.message
// //             ? apiResponse.message
// //             : "Unknown error occurred";
// //         console.error(errorMessage);
// //         alert("Error in Submitting the data");
// //       }
// //     } catch (error) {
// //       console.error("Error submitting form data:", error);
// //     }
// //   };

// //   const handleCancel = () => {
// //     navigate("/dhunia_bo/banners");
// //   };
// //   const handleRemoveImage = (indexToRemove) => {
// //     setImages(images.filter((_, index) => index !== indexToRemove));
// //   };

// // //   const handleRemoveVideo = (indexToRemove) => {
// // //     setVideo(video.filter((_, index) => index !== indexToRemove));
// // //   };

// //   return (
// //     <Box marginLeft= "20px" marginRight= "20px">
// //       <Typography variant="h5" fontWeight="bold" marginBottom="20px">
// //         ADD BANNER
// //       </Typography>
// //       <form onSubmit={handleSubmit}>
// //         <TextField
// //           fullWidth
// //           label="Name"
// //           name="name"
// //           variant="outlined"
// //           // value={name}
// //           onChange={handleTitleChange}
// //           margin="normal"
// //           required
// //         />

// //         <Box marginBottom="20px">
// //           <input
// //             type="file"
// //             id="images"
// //             name="images"
// //             accept="image/*"
// //             onChange={handleImageChange}
// //             multiple
// //             style={{ display: "none" }}
// //           />
// //           <label htmlFor="images">
// //             <Button variant="outlined" component="span">
// //               Upload Images
// //             </Button>
// //           </label>
// //           {images.length > 0 && (
// //             <ul>
// //               {images.map((image, index) => (
// //                 <li key={index}>
// //                   <img
// //                     src={URL.createObjectURL(image)}
// //                     alt={`Preview ${index}`}
// //                     style={{ maxWidth: "100px", maxHeight: "100px" }}
// //                   />
// //                   <Button onClick={() => handleRemoveImage(index)}>
// //                    Remove
// //                  </Button>

// //                 </li>
// //               ))}
// //             </ul>
// //           )}
// //         </Box>
// //         <Box marginBottom="20px">
// //           <input
// //             type="file"
// //             id="videos"
// //             name="videos"
// //             accept="video/*"
// //             onChange={handleVideoChange}
// //             multiple
// //             style={{ display: "none" }}
// //           />
// //           <label htmlFor="videos">
// //             <Button variant="outlined" component="span">
// //               Upload Videos
// //             </Button>
// //           </label>
// //           {videos.length > 0 && (
// //             <ul>
// //               {videos.map((video, index) => (
// //                 <li key={index}>
// //                   <video
// //                     src={URL.createObjectURL(video)}
// //                     controls
// //                     style={{ maxWidth: "100px", maxHeight: "100px" }}
// //                   />
// //                      {/* <Button onClick={() => handleRemoveVideo(index)}>
// //                      Remove
// //                    </Button> */}
// //                 </li>
// //               ))}
// //             </ul>
// //           )}
// //         </Box>
// //         <Box display="flex" justifyContent="flex-end" gap={2}>
// //           <Button type="submit" variant="contained" color="primary">
// //             Submit
// //           </Button>
// //           <Button variant="outlined" color="secondary" onClick={handleCancel}>
// //             Cancel
// //           </Button>
// //         </Box>
// //       </form>
// //     </Box>
// //   );
// // };

// // export default BannersForm;

// //new one first ---------------------------------------------------------------------------------------------------------

// import React, { useState, useEffect } from "react";

// import {
//   TextField,
//   Button,
//   Box,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";

// import { useNavigate } from "react-router-dom";
// import { getBanners } from "./banner";

// import { updateBannerForm, UploadBannerFiles } from "./banner";
// import { useParams } from "react-router-dom";

// const EditBannersForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const navigate = useNavigate();
//   const [images, setImages] = useState([]);
//   const [videos, setVideos] = useState([]);

//   const [formData, setFormData] = useState({
//     name: "",
//     status: "",
//     banner_type: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//   };

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getBanners(1, 10, id);

//       data = data.info[0];

//       setFormData({
//         name: data?.name,
//         banner_id: data?.id,
//         status: data?.status,
//         banner_type: data?.banner_type,
//       });
//       setImages(data?.images ? data?.images : []);
//       setVideos(data?.videos ? data?. videos : []);
//     };
//     getData();
//   }, [id]);

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setImages(selectedImages);
//   };
//   const handleVideoChange = (event) => {
//     const selectedVideos = Array.from(event.target.files);
//     setVideos(selectedVideos);
//   };

//   const UpdateAndSaveNews = async (data, images, videos) => {
//     try {
//       const newImages = images.filter((item) => typeof item === "object");
//       const newVideos = videos.filter((item) => typeof item === "object");

//       const oldImages = images.filter((item) => typeof item === "string");
//       const oldVideos = videos.filter((item) => typeof item === "string");

//       // Upload new images
//       let imagesResponse;
//       if (newImages.length > 0) {
//         const imagesFormData = new FormData();
//         newImages.forEach((file, index) => {
//           imagesFormData.append(`files`, file);
//         });
//         imagesFormData.append("entity", data.entity);
//         imagesResponse = await UploadBannerFiles(imagesFormData);
//       } else {
//         imagesResponse = { success: true, info: [] };
//       }

//       // Upload new videos
//       let videosResponse;
//       if (newVideos.length > 0) {
//         const videosFormData = new FormData();
//         newVideos.forEach((file, index) => {
//           videosFormData.append(`files`, file);
//         });
//         videosFormData.append("entity", data.entity);
//         videosResponse = await UploadBannerFiles(videosFormData);
//       } else {
//         videosResponse = { success: true, info: [] };
//       }

//       if (imagesResponse.success) {
//         data.images = oldImages.concat(imagesResponse.info);
//         data.video = oldVideos.concat(videosResponse.info);

//         const formResponse = await updateBannerForm(data);
//         return formResponse;
//       }
//       return null;
//     } catch (error) {
//       console.error(error);
//       return null;
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const apiResponse = await UpdateAndSaveNews(formData, images, videos);
//       if (apiResponse && apiResponse.success) {
//         alert("Data submitted successfully");
//         onFormSubmit(navigate("/dhunia_bo/banners"));
//         resetForm();
//       } else {
//         const errorMessage =
//           apiResponse && apiResponse.message
//             ? apiResponse.message
//             : "Unknown error occurred";
//         console.error(errorMessage);
//         alert("Error in Submitting the data");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     navigate("/dhunia_bo/banners");
//   };

//   const resetForm = () => {
//     setFormData({
//       name: "",
//       status: "",
//       banner_type:"",
//     });
//     setImages([]);
//     setVideos([]);
//   };

//   const handleRemoveImage = (indexToRemove) => {
//     setImages(images.filter((_, index) => index !== indexToRemove));
//   };
//   const handleRemoveVideo = (indexToRemove) => {
//     setVideos(videos.filter((_, index) => index !== indexToRemove));
//   };

//   return (
//     <Box marginLeft="20px" marginRight="20px">
//       <Typography variant="h5" fontWeight="bold" marginBottom="20px">
//         EDIT BANNER
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <TextField
//           fullWidth
//           label="Name"
//           name="name"
//           variant="outlined"
//           value={formData.name}
//           onChange={handleChange}
//           margin="normal"
//           required
//         />
//         <FormControl fullWidth margin="normal" required>
//           <InputLabel>Status</InputLabel>
//           <Select
//             value={formData.status}
//             onChange={handleChange}
//             label="Status"
//             name="status"
//             id="status"
//           >
//             <MenuItem value="ACTIVE">ACTIVE</MenuItem>
//             <MenuItem value="INACTIVE">IN ACTIVE</MenuItem>
//           </Select>
//         </FormControl>

//         <FormControl fullWidth margin="normal" required>
//           <InputLabel>Banners Type</InputLabel>
//           <Select
//             value={formData.banner_type}
//             onChange={handleChange}
//             label="banner_type"
//             name="banner_type"
//             id="banner_type"
//           >
//             <MenuItem value="IMAGE">IMAGE</MenuItem>
//             <MenuItem value="VIDEO">VIDEO</MenuItem>
//           </Select>
//         </FormControl>

//         {formData.banner_type === "IMAGE" && (
//           <Box marginBottom="20px" marginTop="20px">
//             <input
//               type="file"
//               id="images"
//               name="images"
//               accept="image/*"
//               onChange={handleImageChange}
//               multiple
//               style={{
//                 display: "none",
//               }}
//             />
//             <label htmlFor="images">
//               <Button variant="outlined" component="span">
//                 Upload Images
//               </Button>
//             </label>
//             {images.length > 0 && (
//               <ul>
//                 {images.map((image, index) => (
//                   <li key={index}>
//                     <img
//                       src={URL.createObjectURL(image)}
//                       alt={`Preview ${index}`}
//                       style={{ maxWidth: "100px", maxHeight: "100px" }}
//                     />
//                     <Button onClick={() => handleRemoveImage(index)}>
//                       Remove
//                     </Button>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </Box>
//         )}

//         {formData.banner_type === "VIDEO" && (
//           <Box marginBottom="20px">
//             <input
//               type="file"
//               id="videos"
//               name="videos"
//               accept="video/*"
//               onChange={handleVideoChange}
//               multiple
//               style={{
//                 display: "none",
//               }}
//             />
//             <label htmlFor="videos">
//               <Button variant="outlined" component="span">
//                 Upload Videos
//               </Button>
//             </label>
//             {videos.length > 0 && (
//               <ul>
//                 {videos.map((video, index) => (
//                   <li key={index}>
//                     <video
//                       src={URL.createObjectURL(video)}
//                       controls
//                       alt={`Preview ${index}`}
//                       style={{ maxWidth: "100px", maxHeight: "100px" }}
//                     />
//                     <Button onClick={() => handleRemoveVideo(index)}>
//                       Remove
//                     </Button>
//                   </li>
//                 ))}
//               </ul>
//             )}
//           </Box>
//         )}

//         <Box display="flex" justifyContent="flex-end" gap={2}>
//           <Button type="submit" variant="contained" color="primary">
//             Update
//           </Button>
//           <Button variant="outlined" color="secondary" onClick={handleCancel}>
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default EditBannersForm;

//--------------------------------------------------------------------------------------------------------------------------------------

// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   MenuItem,
//   FormControl,
//   InputLabel,
//   Date,
//   Select,
// } from "@mui/material";
// import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import { UploadBannerFiles, updateBannerForm, getBanners } from "./banner";

// const EditBannersForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getBanners(1, 10, id);
//       data = data.info[0];
//       // console.log(data);
//       setFormData({

//         name: data?.name,
//         status: data?.status,
//         banner_type: data?.banner_type,
//       });
//       setImages(data?.images ? data?.images : []);
//       setVideo(data?.videos ? data?.videos : []);
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();
//   const [images, setImages] = useState([]);
//   const [video, setVideo] = useState([]);
//   const [formData, setFormData] = useState({
//     name :"",
//     status :"",
//     banner_type:"",

//   });

//   const handleRemoveImage = (indexToRemove) => {
//     setImages(images.filter((_, index) => index !== indexToRemove));
//   };

//   const handleRemoveVideo = (indexToRemove) => {
//     setVideo(video.filter((_, index) => index !== indexToRemove));
//   };

//   const handleDateChange = (date) => {
//     setFormData((prevData) => ({
//       ...prevData,
//       expiration_date: date,
//     }));
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const UpdateAndSaveBanner = async (data, images, videos) => {
//     try {
//       const newImages = images.filter((item) => typeof item === "object");
//       const newVideos = videos.filter((item) => typeof item === "object");
//       const oldImages = images.filter((item) => typeof item === "string");
//       const oldVideos = videos.filter((item) => typeof item === "string");

//       // Upload new images
//       let imagesResponse;
//       if (newImages.length > 0) {
//         const imagesFormData = new FormData();
//         newImages.forEach((file, index) => {
//           imagesFormData.append(`files`, file);
//         });
//         imagesFormData.append("entity", data.entity);
//         imagesResponse = await UploadBannerFiles(imagesFormData);
//       } else {
//         imagesResponse = { success: true, info: [] };
//       }

//       // Upload new videos
//       let videosResponse;
//       if (newVideos.length > 0) {
//         const videosFormData = new FormData();
//         newVideos.forEach((file, index) => {
//           videosFormData.append(`files`, file);
//         });
//         videosFormData.append("entity", data.entity);
//         videosResponse = await UploadBannerFiles(videosFormData);
//       } else {
//         videosResponse = { success: true, info: [] };
//       }

//       if (imagesResponse.success && videosResponse.success) {
//         data.images = oldImages.concat(imagesResponse.info);
//         data.videos = oldVideos.concat(videosResponse.info);
//         const formResponse = await updateBannerForm(data);
//         return formResponse;
//       }
//       return null;
//     } catch (error) {
//       console.error(error);
//       return null;
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const apiResponse = await UpdateAndSaveBanner(formData, images, video);
//       if (apiResponse && apiResponse.success) {
//         onFormSubmit(navigate("/dhunia_bo/ads"));
//         resetForm();
//       } else {
//         const errorMessage =
//           apiResponse && apiResponse.message
//             ? apiResponse.message
//             : "Unknown error occurred";
//         console.error(errorMessage);
//         alert("Error in Submitting the data");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     resetForm();
//     navigate("/dhunia_bo/ads");
//   };

//   const handleImageChange = (event) => {
//     const selectedImages = Array.from(event.target.files);
//     setImages(selectedImages);
//   };

//   const handleVideoChange = (event) => {
//     const selectedVideos = Array.from(event.target.files);
//     setVideo(selectedVideos);
//   };
//   const handle_status_Change = (event) => {
//     setFormData({
//       ...formData,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const resetForm = () => {
//     setFormData({
//       name: "",
//       status: "",
//       banner_type: "",
//     });
//     setImages([]);
//     setVideo([]);
//   };

//   return (
//     <Box marginLeft="20px" marginRight="20px">
//       <Typography variant="h5" fontWeight="bold" marginBottom="20px">
//         ADD BANNER
//       </Typography>
//       <form onSubmit={handleSubmit}>
//         <TextField
//           fullWidth
//           label="Name"
//           name="name"
//           variant="outlined"
//           value={formData.name}
//           onChange={handleChange}
//           margin="normal"
//           required
//         />
//         <FormControl fullWidth margin="normal" required>
//           <InputLabel>Status</InputLabel>
//           <Select
//             value={formData.status}
//             onChange={handleChange}
//             label="Status"
//             name="status"
//             id="status"
//           >
//             <MenuItem value="ACTIVE">ACTIVE</MenuItem>
//             <MenuItem value="INACTIVE">INACTIVE</MenuItem>
//           </Select>
//         </FormControl>

//         <FormControl fullWidth margin="normal" required>
//           <InputLabel>Banners Type</InputLabel>
//           <Select
//             value={formData.banner_type}
//             onChange={handleChange}
//             label="banner_type"
//             name="banner_type"
//             id="banner_type"
//           >
//             <MenuItem value="IMAGE">IMAGE</MenuItem>
//             <MenuItem value="VIDEO">VIDEO</MenuItem>
//           </Select>
//         </FormControl>

//         <Box marginBottom="20px" marginTop="20px">
//           <input
//             type="file"
//             id="images"
//             name="images"
//             accept="image/*"
//             onChange={handleImageChange}
//             multiple
//             style={{
//               display: "none",
//             }}
//           />
//           <label htmlFor="images">
//             <Button variant="outlined" component="span">
//               Upload Images
//             </Button>
//           </label>
//           {images.length > 0 && (
//             <ul>
//               {images.map((image, index) => (
//                 <li key={index}>
//                   <img
//                     src={URL.createObjectURL(image)}
//                     alt={`Preview ${index}`}
//                     style={{ maxWidth: "100px", maxHeight: "100px" }}
//                   />
//                   <Button onClick={() => handleRemoveImage(index)}>
//                     Remove
//                   </Button>
//                 </li>
//               ))}
//             </ul>
//           )}
//         </Box>

//         <Box marginBottom="20px">
//           <input
//             type="file"
//             id="videos"
//             name="videos"
//             accept="video/*"
//             onChange={handleVideoChange}
//             multiple
//             style={{
//               display: "none",
//             }}
//           />
//           <label htmlFor="videos">
//             <Button variant="outlined" component="span">
//               Upload Videos
//             </Button>
//           </label>
//           {video.length > 0 && (
//             <ul>
//               {video.map((video, index) => (
//                 <li key={index}>
//                   <video
//                     src={URL.createObjectURL(video)}
//                     controls
//                     alt={`Preview ${index}`}
//                     style={{ maxWidth: "100px", maxHeight: "100px" }}
//                   />
//                   <Button onClick={() => handleRemoveVideo(index)}>
//                     Remove
//                   </Button>
//                 </li>
//               ))}
//             </ul>
//           )}
//         </Box>

//         <Box display="flex" justifyContent="flex-end" gap={2}>
//           <Button type="submit" variant="contained" color="primary">
//             Submit
//           </Button>
//           <Button variant="outlined" color="secondary" onClick={handleCancel}>
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default EditBannersForm;-----------------------------------------------------------------------------------------------------------------------------

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Date,
  Select,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UploadBannerFiles, updateBannerForm, getBanners } from "./banner";

const EditBannersForm = ({ onFormSubmit }) => {
  const params = useParams();
  const { id } = params;

  useEffect(() => {
    const getData = async () => {
      let data = await getBanners(1, 10, id);
      data = data.info[0];
      // console.log(data);
      setFormData({
        banner_id: data?.id,
        name: data?.name,
        status: data?.status,
        banner_type: data?.banner_type,
      });
      setImages(data?.images ? data?.images : []);
      setVideo(data?.videos ? data?.videos : []);
    };
    getData();
  }, [id]);

  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [formData, setFormData] = useState({
    banner_id: id,
    name: "",
    status: "",
    banner_type: "",
  });

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideo(video.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const UpdateAndSaveAds = async (data, images, videos) => {
    try {
      const newImages = images.filter((item) => typeof item === "object");
      const newVideos = videos.filter((item) => typeof item === "object");
      const oldImages = images.filter((item) => typeof item === "string");
      const oldVideos = videos.filter((item) => typeof item === "string");

      // Upload new images
      let imagesResponse;
      if (newImages.length > 0) {
        const imagesFormData = new FormData();
        newImages.forEach((file, index) => {
          imagesFormData.append(`files`, file);
        });
        imagesFormData.append("entity", data.entity);
        imagesResponse = await UploadBannerFiles(imagesFormData);
      } else {
        imagesResponse = { success: true, info: null };
      }

      // Upload new videos
      let videosResponse;
      if (newVideos.length > 0) {
        const videosFormData = new FormData();
        newVideos.forEach((file, index) => {
          videosFormData.append(`files`, file);
        });
        videosFormData.append("entity", data.entity);
        videosResponse = await UploadBannerFiles(videosFormData);
      } else {
        videosResponse = { success: true, info: null };
      }

      if (imagesResponse.success && videosResponse.success) {
        data.images = oldImages.concat(imagesResponse.info);
        data.videos = oldVideos.concat(videosResponse.info);
        const formResponse = await updateBannerForm(data);
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UpdateAndSaveAds(formData, images, video);
      if (apiResponse && apiResponse.success) {
        onFormSubmit(navigate("/dhunia_bo/banners"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/banners");
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const handleVideoChange = (event) => {
    const selectedVideos = Array.from(event.target.files);
    setVideo(selectedVideos);
  };

  const getObjectURL = (file) => {
    if (file instanceof Blob) {
      return URL.createObjectURL(file);
    }
    return null;
  };

  const resetForm = () => {
    setFormData({
      banner_id: id,
      name: "",

      status: "",
      banner_type: "",
    });
    setImages([]);
    setVideo([]);
  };

  return (
    <Box 
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="130vh"
    sx={{ backgroundColor: "#A7E6FF" }}
    
    >
        <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >


      <Typography variant="h5" fontWeight="bold" marginBottom="20px"  align="center"
          color="#007eff">
        ADD BANNER
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Name"
          name="name"
          variant="outlined"
          value={formData.name}
          onChange={handleChange}
          margin="normal"
          required
        />
        <FormControl fullWidth margin="normal" required>
          <InputLabel>Status</InputLabel>
          <Select
            value={formData.status}
            onChange={handleChange}
            label="Status"
            name="status"
            id="status"
          >
            <MenuItem value="ACTIVE">ACTIVE</MenuItem>
            <MenuItem value="INACTIVE">INACTIVE</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal" required>
          <InputLabel>Banners Type</InputLabel>
          <Select
            value={formData.banner_type}
            onChange={handleChange}
            label="banner_type"
            name="banner_type"
            id="banner_type"
          >
            <MenuItem value="IMAGE">IMAGE</MenuItem>
            <MenuItem value="VIDEO">VIDEO</MenuItem>
          </Select>
        </FormControl>

        <Box marginBottom="20px" marginTop="20px">
          <input
            type="file"
            id="images"
            name="images"
            accept="image/*"
            onChange={handleImageChange}
            multiple
            style={{
              display: "none",
            }}
          />
          <label htmlFor="images">
            <Button variant="outlined" component="span" fullWidth>
              Upload Images
            </Button>
          </label>
          {images && images.length > 0 && (
            <ul>
              {images.map((image, index) => (
                <li key={index}>
                  <img
                    src={
                      typeof image === "object" ? getObjectURL(image) : image
                    }
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button onClick={() => handleRemoveImage(index)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </Box>

        <Box marginBottom="20px">
          <input
            type="file"
            id="videos"
            name="videos"
            accept="video/*"
            onChange={handleVideoChange}
            multiple
            style={{
              display: "none",
            }}
          />
          <label htmlFor="videos">
            <Button variant="outlined" component="span" fullWidth>
              Upload Videos
            </Button>
          </label>
          {video && video.length > 0 && (
            <ul>
              {video.map((video, index) => (
                <li key={index}>
                  <video
                    src={
                      typeof image === "object" ? getObjectURL(video) : video
                    }
                    controls
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button onClick={() => handleRemoveVideo(index)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </Box>

        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </form>
      </Box>
    </Box>
  );
};

export default EditBannersForm;
