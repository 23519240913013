import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Avatar,
  Typography,
} from "@mui/material";

const Popup = ({ open, handleClose, images }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ borderBottom: "3px solid #ccc", fontWeight: "bold" }}>
        IMAGES :
        </DialogTitle>
      <DialogContent style={{ width: "500px", height: "500px" }}>
        <Grid container spacing={5}>
          {images.map((image, index) => (
            <Grid item key={index} xs={4}>
              <Avatar alt={`Image ${index}`}
               src={image}
               style={{
                marginRight: "5px",
                marginTop: "10px",
                width: "130px",
                height: "160px",
                borderRadius:"8px"
                
              }}
               
               />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" style={{ borderRadius: "10px" }} onClick={handleClose}>Close</Button>
       
      </DialogActions>
    </Dialog>
  );
};

export default Popup;
