import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import axios from "axios";
import "./Login.css";
import { useNavigate } from "react-router-dom";
async function loginUser(credentials) {
  // console.log(credentials);
  // return { token: "12345" };
  const response = await axios.post(
    `${process.env.REACT_APP_BASEURL}userservice/authentication`,
    {
      mobile: credentials.username,
      password: credentials.password,
    }
  );
  // console.log(response);
  if (response.data.info) {
    return response?.data.info;
  } else {
    // invalid user details screen
  }
  // return fetch(`${process.env.REACT_APP_BASEURL}userservice/authentication", {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     mobile: credentials.username,
  //     password: credentials.password,
  //   }),
  // }).then(async (data) => {
  //   console.log(data.json());
  // });
}
export default function Login({ setToken }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const userDetails = await loginUser({
      username,
      password,
    });
    if (
      !userDetails?.access_token ||
      (!userDetails?.profile_details?.custom_permissions?.includes(
        "is_bo_user"
      ) &&
        !userDetails?.profile_details?.custom_permissions?.includes(
          "is_enduser"
        ))
    ) {
      navigate("/dhunia_bo/login");
    } else {
      // console.log(userDetails);
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      setToken(userDetails);
    }
  };
  return (
    <div
      className="login-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1>Login</h1>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ m: 1, width: "30ch" }}
          autoFocus
          id="username"
          label="Username"
          type="text"
          onChange={(e) => setUsername(e.target.value)}
        />
        {/* <label>
          <p>Username</p>
          <input type="text" onChange={(e) => setUsername(e.target.value)} />
        </label> */}
        <FormControl variant="outlined" sx={{ m: 1, width: "30ch" }}>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>
        {/* <label>
          <p>Password</p>
          <input
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </label> */}
        <Button sx={{ m: 1 }} variant="contained" color="primary" type="submit">
          Submit
        </Button>
        {/* <div>
          <button type="submit">Submit</button>
        </div> */}
      </form>
    </div>
  );
}
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
