import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Topbar from "./screens/global/Topbar";
import Sidebar from "./screens/global/Sidebar";
import Dashboard from "./screens/dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import CreateCategory from "./screens/news/createCategory";
import CreateRealEstate from "./screens/classifieds/realEstates/createRealEstate";
import CreateOtherClassifieds from "./screens/classifieds/otherClassifieds/createOtherClassifieds";
import CreateUpload from "./screens/ott/uploads/createUpload";
import Movies from "./screens/ott/movies/movies";
import CreateMovie from "./screens/ott/movies/createMovie";
import Trending from "./screens/ott/movies/trending/trending";
import Roles from "./screens/permissions/roles";
import Permissions from "./screens/permissions/permissions";
import "./App.css";
import CreateReporter from "./screens/news/reporters/createReporter";
import CreateJob from "./screens/classifieds/jobs/createJob";
import Login from "./screens/login/Login";
import useToken from "./screens/login/useToken";
import Posts from "./screens/news/posts";
import NewsForm from "./screens/news/posts/createform";
import NewsPage from "./screens/news/posts/NewsPage";
import EditPage from "./screens/news/posts/editpage";
import Banners from "./screens/news/banners/banners";
import EditForm from "./screens/news/posts/editform";
import BannersForm from "./screens/news/banners/bannersform";
import EditBannersForm from "./screens/news/banners/editbanner";
import CreateMovieMakers from "./screens/ott/movieMakers/createMovieMakers";
import Adds from "./screens/news/adds/addstable";
import AddsForm from "./screens/news/adds/addsform";
import EditAdds from "./screens/news/adds/addsedit";
import ListingCategories from "./screens/listings";
import ListingCategoriesForm from "./screens/listings/form";
import EditListingCategories from "./screens/listings/edit";
import ListingSubCategories from "./screens/listings/cubcategories";
import SubCategoriesForm from "./screens/listings/cubcategories/form";
import EditSubCategories from "./screens/listings/cubcategories/edit";
import NotificationForm from "./screens/news/posts/news-notification";
import Notification from "./screens/news/posts/notification-1";
import States from "./screens/news/locations/states";
import StatesForm from "./screens/news/locations/states/states-create-form";
import Districts from "./screens/news/locations/districts";
import DistrictForm from "./screens/news/locations/districts/districts-form";
import Mandals from "./screens/news/locations/mandals";
import MandalForm from "./screens/news/locations/mandals/mandals-create-form";
import Villages from "./screens/news/locations/villages";
import VillageForm from "./screens/news/locations/villages/villages-create-form";
import Reports from "./screens/reports/reports-main/reports-table";
import OpenApps from "./screens/openapps/openapps";
import WithdrwalTable from "./screens/withdrawlmanagement/withdrwal-table";
import UserBankDetails from "./screens/userbank/usertable";
import UserBankForm from "./screens/userbank/user-bank-form";
import YtMoviesTable from "./screens/ott/youtubevideos/youtubetable";
import YtVideoForm from "./screens/ott/youtubevideos/youtubecreate";

function App() {
  const navigate = useNavigate();
  const [theme, colorMode] = useMode();
  const base_url = "dhunia_bo";
  const { token, setToken, logoutUser, permissions } = useToken();
  useEffect(() => {
    if (!token) {
      navigate("/dhunia_bo/login");
    } else {
      navigate("/dhunia_bo");
    }
  }, [token]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {token && <Sidebar permissions={permissions} />}
          <main className="content">
            {token && <Topbar logoutUser={logoutUser} />}
            <Routes>
              <Route path={base_url}>
                <Route path="login" element={<Login setToken={setToken} />} />
                <Route
                  path="categories"
                  element={<CreateCategory permissions={permissions} />}
                />
                <Route
                  path="reporters"
                  element={<CreateReporter permissions={permissions} />}
                />
                <Route
                  path="jobs"
                  element={<CreateJob permissions={permissions} />}
                />
                <Route
                  path="realestate"
                  element={
                    <CreateRealEstate
                      classified_type="REAL_ESTATE"
                      permissions={permissions}
                    />
                  }
                />
                <Route
                  path="buySell"
                  element={
                    <CreateRealEstate
                      classified_type="BUY-SELL"
                      permissions={permissions}
                    />
                  }
                />
                <Route
                  path="otherClassifieds"
                  element={<CreateOtherClassifieds permissions={permissions} />}
                />
                <Route
                  path="uploads"
                  element={<CreateUpload permissions={permissions} />}
                />
                <Route
                  path="movies"
                  element={<Movies permissions={permissions} />}
                />
                <Route
                  path="movieMakers"
                  element={<CreateMovieMakers permissions={permissions} />}
                />
                <Route
                  path="createMovie"
                  element={<CreateMovie permissions={permissions} />}
                />
                <Route
                  path="trending"
                  element={<Trending permissions={permissions} />}
                />
                <Route
                  path="posts"
                  element={<Posts permissions={permissions} />}
                />
                <Route
                  path="ads"
                  element={<Adds permissions={permissions} />}
                />
                <Route
                  path="banners"
                  element={<Banners permissions={permissions} />}
                />
                <Route path="roles" element={<Roles />} />
                <Route
                  path="permissions"
                  element={<Permissions permissions={permissions} />}
                />
                <Route path="/dhunia_bo/create-news" element={<NewsPage />} />
                <Route
                  path="/dhunia_bo/create-banners"
                  element={<BannersForm />}
                />
                <Route path="/dhunia_bo/create-adds" element={<AddsForm />} />
                <Route path="/dhunia_bo/edit-news/:id" element={<EditPage />} />
                <Route
                  path="/dhunia_bo/edit-banners/:id"
                  element={<EditBannersForm />}
                />
                <Route
                  path="listings-categories"
                  element={<ListingCategories permissions={permissions} />}
                />
                <Route
                  path="/dhunia_bo/create-listing-categories"
                  element={<ListingCategoriesForm />}
                />
                <Route
                  path="/dhunia_bo/edit-categories/:id"
                  element={<EditListingCategories />}
                />

                <Route
                  path="listings-subcategories"
                  element={<ListingSubCategories permissions={permissions} />}
                />
                <Route
                  path="/dhunia_bo/create-listing-sub-categories"
                  element={<SubCategoriesForm />}
                />
                <Route
                  path="/dhunia_bo/edit-sub-categories/:id"
                  element={<EditSubCategories />}
                />
                <Route
                  path="/dhunia_bo/send-notification/:id"
                  element={<Notification />}
                />

                <Route path="/dhunia_bo/edit-ads/:id" element={<EditAdds />} />
                <Route
                  path="locations-states"
                  element={<States permissions={permissions} />}
                />
                <Route
                  path="/dhunia_bo/locations-create-form"
                  element={<StatesForm />}
                />

                <Route
                  path="locations-districts"
                  element={<Districts permissions={permissions} />}
                />

                <Route
                  path="/dhunia_bo/locations-district-create-form"
                  element={<DistrictForm />}
                />

                <Route
                  path="locations-mandals"
                  element={<Mandals permissions={permissions} />}
                />
                <Route
                  path="/dhunia_bo/locations-mandal-create-form"
                  element={<MandalForm />}
                />

                <Route
                  path="locations-villages"
                  element={<Villages permissions={permissions} />}
                />
                <Route
                  path="/dhunia_bo/locations-villages-create-form"
                  element={<VillageForm />}
                />

                <Route
                  path="reports"
                  element={<Reports permissions={permissions} />}
                />

                <Route
                  path="/dhunia_bo/reports-openapps"
                  element={<OpenApps />}
                />

                <Route
                  path="/dhunia_bo/withdrwal-management"
                  element={<WithdrwalTable permissions={permissions} />}
                />

                <Route
                  path="/dhunia_bo/user-bank-details/:userId"
                  element={<UserBankDetails />}
                />

                <Route
                  path="/dhunia_bo/user-bank-form"
                  element={<UserBankForm />}
                />

                <Route
                  path="yt-videos"
                  element={<YtMoviesTable permissions={permissions} />}
                />
                 <Route
                  path="/dhunia_bo/yt-videos-form"
                  element={<YtVideoForm />}
                />

                <Route index path="*" element={<Dashboard />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
export default App;
