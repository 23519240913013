import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { Box, useTheme, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { tokens } from "../../theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import {
  getPermissionsData,
  setPermissionsData,
} from "../../services/permissions";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import {
  permissions_columns,
  permissions_rows,
  global_permisssions,
} from "../../utils/constants";
export default function Permissions({ mobile, setOpenPermissions }) {
  // const [searchParams] = useSearchParams();
  // const mobile = searchParams.get("mobile");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [permissions, setPermisssions] = useState([]);
  const [roles, setRoles] = useState([]);
  // console.log(permissions);
  useEffect(() => {
    // getCategoryData(setCategoryList);
    // console.log(searchParams.get("mobile"));
    if (mobile) {
      // document.getElementById("user_mobile").innerHTML =
      //   searchParams.get("mobile");
      // callFunction();
      getUserPermisssions(mobile);
    }
  }, []);
  // const callFunction = async () => {
  //   await getUserPermisssions();
  // };
  // const handleStatusChange = (event) => {
  //   // console.log(
  //   //   "clicked: ",
  //   //   event.target.id,
  //   //   event.target.value,
  //   //   event.target.translate
  //   // );
  //   if (permissions.includes(event.target.id)) {
  //     const index = permissions.indexOf(event.target.id);
  //     if (index > -1) {
  //       // only splice array when item is found
  //       permissions.splice(index, 1); // 2nd parameter means remove one item only
  //     }
  //   } else {
  //     permissions.push(event.target.id);
  //   }
  //   setPermisssions([...permissions]);
  // };
  const handleStatusChange = (event) => {
    const updatedPermissions = permissions.includes(event.target.id)
      ? permissions.filter(item => item !== event.target.id)
      : [...permissions, event.target.id];
    setPermisssions(updatedPermissions);
};


  /**
   * The function `handleEditCategory` filters the `categoriesList` array to find the category with the
   * matching `id` as the `event.target.id`, and if found, sets the `categoryPayload` state to the
   * selected category and opens the edit modal.
   * @returns nothing (undefined) if the condition `!event.target.id` is true.
   */
  const renderContent = (row, column) => {
    const value = permissions.includes(`is_${column}_${row}`);
    // console.log(value, `is_${column}_${row}`);
    return (
      <Switch
        id={`is_${column}_${row}`}
        checked={value}
        onChange={handleStatusChange}
      />
    );
  };
  const renderGlobalContent = (column) => {
    const value = permissions.includes(column);
    // console.log(value);
    return <Switch id={column} checked={value} onChange={handleStatusChange} />;
  };
  const getUserPermisssions = async (mobile = null) => {
    // if (!mobile) {
    //   mobile = document.getElementById("user_mobile").value;
    // }
    // console.log(mobile_value);
    const response = await getPermissionsData(mobile);
    if (response) {
      // console.log(response);
      setPermisssions([...response.custom_permissions]);
      setRoles([...response.user_role]);
    }
  };
  const handleUpdatePermissions = async () => {
    // const mobile_value = document.getElementById("user_mobile").value;
    // console.log(mobile_value);
    const response = await setPermissionsData(mobile, permissions, roles);
    if (response) {
      // console.log(response);
      // document.getElementById("user_mobile").value = "";
      setPermisssions([...response]);
      setOpenPermissions(false);
    }
  };
  return (
    <>
      <Box
        m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          // flexDirection="row-reverse"
          // backgroundColor={colors.primary[400]}
          borderRadius="3px"
          // width="30%"
          marginBottom="2%"
        >
          {/* <InputBase
            sx={{ ml: 2, flex: 1 }}
            placeholder="Enter Mobile Number to search"
            id="user_mobile"
          />
          <IconButton type="button" sx={{ p: 1 }} onClick={getUserPermisssions}>
            <SearchIcon />
          </IconButton> */}
          <div>
            <strong>Mobile : {mobile}</strong>
            {permissions && permissions.length > 0 && (
              <Button
                sx={{ ml: 2 }}
                variant="contained"
                color="success"
                onClick={handleUpdatePermissions}
              >
                Update
              </Button>
            )}
          </div>
          <div>
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              color="primary"
              onClick={() => {
                setOpenPermissions(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Box>
        {permissions && permissions.length > 0 && (
          <>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell key="permission" style={{ minWidth: 200 }}>
                        User Permissions
                      </TableCell>
                      {global_permisssions.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      // permissions_rows.map((row) => {
                      <TableRow hover>
                        <TableCell key="permission" style={{ minWidth: 200 }}>
                          User Access
                        </TableCell>
                        {global_permisssions.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id}>
                              {renderGlobalContent(column.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      // })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "3%" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell key="permission" style={{ minWidth: 200 }}>
                        Access Permissions
                      </TableCell>
                      {permissions_columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permissions_rows.map((row) => {
                      return (
                        <TableRow hover key={row.id}>
                          <TableCell key={row.id} style={{ minWidth: 200 }}>
                            {row.label}
                          </TableCell>
                          {permissions_columns.map((column) => {
                            // const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {renderContent(row.id, column.id)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Box>
      {/* {openEdit && (
        <EditCategory
          openEdit={openEdit}
          setEditOpen={setEditOpen}
          categoryPayload={categoryPayload}
          getCategoryData={getCategoryData}
          setCategoryList={setCategoryList}
        />
      )}
      {openDelete && (
        <DeleteCategory
          openDelete={openDelete}
          setDeleteOpen={setDeleteOpen}
          categoryPayload={categoryPayload}
          getCategoryData={getCategoryData}
          setCategoryList={setCategoryList}
        />
      )} */}
    </>
  );
}
