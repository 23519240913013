import axios from "axios";

export const getNewsList = async (
  offset = 1,
  limit = 10,
  id = null,
  status,
  district
) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let url = `${process.env.REACT_APP_BASEURL}backoffice/news/posts`;

  const params = {
    offset,
    limit,
    status,
    id, 
  };

  if (district) {
    params.district = district;
  }

  try {
    const response = await axios.get(url, {
      params,
      headers,
    });

    if (response?.data?.success) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching news:", error);
    return null;
  }
};




// import axios from "axios";

// export const getNewsList = async (
//   offset = 1,
//   limit = 10,
//   id = null,
//   status,
//   district
// ) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let url = `${process.env.REACT_APP_BASEURL}backoffice/news/posts`;

//   const params = {
//     offset,
//     limit,
//     status,
//     id, 
//   };

//   if (district) {
//     params.district = district;
//   }

//   console.log('API call with params:', params); // Log API call parameters

//   try {
//     const response = await axios.get(url, {
//       params,
//       headers,
//     });

//     console.log('API response:', response); // Log API response

//     if (response?.data?.success) {
//       return response.data;
//     }
//     return null;
//   } catch (error) {
//     console.error("Error fetching news:", error);
//     return null;
//   }
// };
