import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./draggable-list.css";
import DraggableListItem from "./DraggableListItem";
import {
  getMovieData,
  getTrending,
  updateTrending,
} from "../../../../../services/ott_movies";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const DraggableList = (props) => {
  // console.log("draglist", props);
  const navigate = useNavigate();
  const [data, setdata] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState("");
  const [dragStartIndex, setdragStartIndex] = useState(null);
  useEffect(() => {
    getMovieData().then((videos) => {
      // console.log("videos ", videos);
      setVideos(videos);
    });
    getTrending().then((videos) => {
      // console.log("trending ", videos);
      videos ? setdata(videos) : setdata([]);
    });
  }, []);
  // get index of draged item
  const onDragStart = (index) => setdragStartIndex(index);
  // update list when item dropped
  const onDrop = (dropIndex) => {
    // get draged item
    const dragItem = data[dragStartIndex];
    // delete draged item in list
    let list = [...data];
    list.splice(dragStartIndex, 1);
    // update list
    if (dragStartIndex < dropIndex) {
      setdata([
        ...list.slice(0, dropIndex - 1),
        dragItem,
        ...list.slice(dropIndex - 1, list.length),
      ]);
    } else {
      setdata([
        ...list.slice(0, dropIndex),
        dragItem,
        ...list.slice(dropIndex, list.length),
      ]);
    }
  };
  const handleDelete = (event) => {
    // console.log("index ", event);
    let index = event.target.id;
    // console.log(index);
    let list = [...data];
    list.splice(index, 1);
    // console.log(list);
    setdata([...list]);
  };
  const handleAddTrending = () => {
    // console.log(selectedMovie);
    const addedMovie = videos.filter((vid) => vid.id === selectedMovie);
    // console.log(addedMovie);
    if (addedMovie) {
      setdata([...data, addedMovie[0]]);
    }
  };
  const handleMovieChange = (event) => {
    // console.log(event);
    const {
      target: { id, key, value },
    } = event;
    // console.log({ id, key, value });
    setSelectedMovie(value);
  };
  const handleSubmission = () => {
    // console.log(JSON.stringify(data));
    updateTrending({ trending_list: data }).then(() => {
      window.location.reload(false);
    });
  };
  return (
    <div style={{ display: "flex" }}>
      <ul className="draggable-list" style={{ width: "50%" }}>
        {data &&
          data.map((item, index) => (
            <div style={{ display: "flex", alignItems: "center" }} key={index}>
              <b>{index + 1}</b>
              <DraggableListItem
                key={index}
                index={index}
                onDragStart={(index) => onDragStart(index)}
                onDrop={(index) => onDrop(index)}
              >
                {props.renderItemContent(item)}
              </DraggableListItem>
              {/* <Button
              id={index}
              variant="outlined"
              color="secondary"
              onClick={(event) => handleDelete(event)}
              index={index}
            >
              Delete
            </Button> */}
              <a key={`btn-${index}`} id={index} onClick={handleDelete}>
                <DeleteForeverIcon />
              </a>
              {/* <DeleteForeverIcon
              key={`btn-${index}`}
              id={index}
              onClick={handleDelete}
            /> */}
            </div>
          ))}
        {/*
                add last item so you can drag item to last position
                last item dont need onDragStart because it can not be draged
            */}
        {data && (
          <DraggableListItem
            key={data.length}
            index={data.length}
            draggale={false}
            onDrop={(index) => onDrop(index)}
          />
        )}
      </ul>
      <div style={{ width: "50%" }}>
        <div style={{ display: "flex" }}>
          <FormControl sx={{ width: "60%", mb: 2, ml: "4%" }}>
            <InputLabel id="movie-checkbox-label">Select a Movie</InputLabel>
            <Select
              labelId="movie-checkbox-label"
              id="movie-checkbox"
              defaultValue=""
              value={selectedMovie ? selectedMovie : ""}
              onChange={handleMovieChange}
              input={<OutlinedInput label="Tag" />}
              MenuProps={MenuProps}
            >
              {videos.map((vid) => (
                <MenuItem key={vid.id} value={vid.id}>
                  {vid.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          variant="outlined"
          onClick={handleAddTrending}
          color="success"
          disabled={!selectedMovie}
          sx={{ marginRight: "2%", marginLeft: "4%" }}
        >
          Add to trending
        </Button>
        <Button
          sx={{ marginRight: "2%" }}
          variant="outlined"
          color="primary"
          onClick={handleSubmission}
        >
          Submit Trending List
        </Button>
      </div>
    </div>
  );
};
DraggableList.propTypes = {
  data: PropTypes.array,
  renderItemContent: PropTypes.func,
};
export default DraggableList;
