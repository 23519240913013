import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Jobs from "./jobs";
import Header from "../../../components/Header";
import { createJobs, uploadImages } from "../../../services/jobs";
// import ImageUploader from "./imageUploader";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { options } from "../../../utils/locations";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const CreateJob = ({ permissions }) => {
  // const [file, setFile] = useState([]);
  // const [thumbnailFiles, setThumbnailFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [jobData, setJobsData] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [logoFile, setLogoFile] = useState();
  const [logoSelectedFile, setLogoSelectedFile] = useState();
  const [value, setValue] = useState("ACTIVE");
  // console.log(jobData);
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setJobsData({});
    setIsSelected(false);
  }
  const changeHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      setLogoSelectedFile(event.target.files[0]);
      setIsSelected(true);
      setLogoFile(uploadResponse?.info[0]);
    }
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("job_title", jobData?.job_title);
    formData.append("job_type", jobData?.job_type);
    formData.append("job_style", jobData?.job_style);
    formData.append("job_role", jobData?.job_role);
    formData.append("job_category", jobData?.job_category);
    formData.append("qualification", jobData?.qualification);
    formData.append("job_description", jobData?.job_description);
    formData.append("is_active", jobData?.is_active);
    formData.append("job_location", jobData?.job_location);
    formData.append("min_experience", jobData?.min_experience);
    formData.append("max_experience", jobData?.max_experience);
    formData.append("min_salary", jobData?.min_salary);
    formData.append("max_salary", jobData?.max_salary);
    formData.append("company_name", jobData?.company_name);
    formData.append("company_description", jobData?.company_description);
    formData.append("company_mobile", jobData?.company_mobile);
    formData.append("company_whatsapp", jobData?.company_whatsapp);
    formData.append("company_email", jobData?.company_email);
    // for (let i = 0; i < file.length; i++) {
    //   formData.append("images", file[i]);
    // }
    formData.append("images", [logoFile]);
    formData.append("company_logo", logoFile);
    let response = await createJobs(formData);
    if (response?.success) {
      setOpen(false);
      setJobsData({});
      setIsSelected(false);
    }
  };
  const handleDataChange = (event) => {
    // console.log(event);
    setJobsData((jobData) => {
      let key = event.target.id;
      if (event.target.id === "is_active") {
        jobData[`${key}`] = !jobData[`${key}`];
      } else if (event.target.id.includes("job_location")) {
        jobData["job_location"] = `${event.target.innerHTML}`;
      } else {
        jobData[`${key}`] = `${event.target.value}`;
      }
      return jobData;
    });
  };
  const handleDropdownDataChange = (event) => {
    setJobsData((jobData) => {
      let key = event.target.name;
      jobData[`${key}`] = `${event.target.value}`;
      return jobData;
    });
  };
  // const formatGroupLabel = (data) => (
  //   <div style={groupStyles}>
  //     <span>{data.label}</span>
  //     <span style={groupBadgeStyles}>{data.options.length}</span>
  //   </div>
  // );
  const handleTabChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  return (
    <Container sx={{ m: "10px" }}>
      <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
        <CardHeader>
          <Header title="Jobs" />
          {permissions.write_jobmaster_permission && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Create
            </Button>
          )}
        </CardHeader>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0 }}>
            <TabList onChange={handleTabChange}>
              <Tab label="ACTIVE" value="ACTIVE" />
              <Tab label="INACTIVE" value="INACTIVE" />
            </TabList>
          </Box>
          <TabPanel value="ACTIVE" sx={{ padding: 0 }}>
            <Jobs value={value} permissions={permissions} />
          </TabPanel>
          <TabPanel value="INACTIVE" sx={{ padding: 0 }}>
            <Jobs value={value} permissions={permissions} />
          </TabPanel>
        </TabContext>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle id="form-dialog-title">
          <b>Create a Job</b>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Job Details</b>
          </DialogContentText>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="job_title"
              label="Job Title"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.job_title}
            />
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Job Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="job_type"
                // value={jobData?.job_type}
                defaultValue=""
                label="Job Type"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="FULL-TIME">FULL-TIME</MenuItem>
                <MenuItem value="PART-TIME">PART-TIME</MenuItem>
                <MenuItem value="CONTRACT">CONTRACT</MenuItem>
                <MenuItem value="INTERNSHIP">INTERNSHIP</MenuItem>
                <MenuItem value="TEMPORARY">TEMPORARY</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Job Style</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="job_style"
                defaultValue=""
                label="Job Style"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="ON-SITE">ON SITE</MenuItem>
                <MenuItem value="WORK-FROM-OFFICE">WORK-FROM-OFFICE</MenuItem>
                <MenuItem value="REMOTE">REMOTE</MenuItem>
                <MenuItem value="WORK-FROM-HOME">WORK-FROM-HOME</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="job_role"
              label="Job Role"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.job_role}
            />
            {/* <TextField
              autoFocus
              id="job_category"
              label="Job Category"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.job_category}
            /> */}
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Job Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="job_category"
                defaultValue=""
                label="Job Category"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="IT">IT</MenuItem>
                <MenuItem value="EDUCATION">EDUCATION</MenuItem>
                <MenuItem value="FINANCE">FINANCE</MenuItem>
                <MenuItem value="ENGINEERING">ENGINEERING</MenuItem>
                <MenuItem value="MEDICAL">MEDICAL</MenuItem>
                <MenuItem value="SALES">SALES</MenuItem>
                <MenuItem value="MARKETING">MARKETING</MenuItem>
                <MenuItem value="ART_DESIGN">ART AND DESIGN</MenuItem>
                <MenuItem value="ENTERTAINMENT">ENTERTAINMENT</MenuItem>
                <MenuItem value="HUMAN_RESOURCES">HUMAN RESOURCES</MenuItem>
                <MenuItem value="CUSTOMER_SERVICE">CUSTOMER SERVICE</MenuItem>
                <MenuItem value="LEGAL">LEGAL</MenuItem>
                <MenuItem value="MANAGEMENT">MANAGEMENT</MenuItem>
                <MenuItem value="SCIENCE">SCIENCE</MenuItem>
                <MenuItem value="TRANSPORTATION">TRANSPORTATION</MenuItem>
                <MenuItem value="AGRICULTURE">AGRICULTURE</MenuItem>
                <MenuItem value="CONSTRUCTION">CONSTRUCTION</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: "31%", mr: "2%", mb: 2 }}>
              <InputLabel id="demo-simple-select-label">
                Qualification
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="qualification"
                defaultValue=""
                label="Qualification"
                onChange={handleDropdownDataChange}
              >
                <MenuItem value="SSC">SSC</MenuItem>
                <MenuItem value="INTER">INTER</MenuItem>
                <MenuItem value="DEGREE">DEGREE</MenuItem>
                <MenuItem value="B.TECH">B.TECH</MenuItem>
                <MenuItem value="BSC">BSC</MenuItem>
                <MenuItem value="OTHERS">OTHERS</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Autocomplete
              id="job_location"
              name="job_location"
              options={options.sort(
                (a, b) => -b.category.localeCompare(a.category)
              )}
              groupBy={(option) => option.category}
              getOptionLabel={(option) => option.label}
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Job Location"
                  onChange={handleDataChange}
                />
              )}
            />
            <FormControlLabel
              value="is_active"
              control={
                <Switch
                  // sx={{ width: "5%", mb: 2 }}
                  id="is_active"
                  checked={jobData?.is_active}
                  onChange={handleDataChange}
                />
              }
              label="Is Active"
              labelPlacement="top"
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <TextField
              autoFocus
              id="job_description"
              label="Job Description"
              type="text"
              sx={{ width: "103%", mr: "3%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.job_description}
              multiline
              rows={3}
            />
          </div>
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Job Experience & Salary</b>
          </DialogContentText>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="min_experience"
              label="Min. Experience"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.min_experience}
            />
            <TextField
              autoFocus
              id="max_experience"
              label="Max. Experience"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.max_experience}
            />
            <TextField
              autoFocus
              id="min_salary"
              label="Min. Salary"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.min_salary}
            />
            <TextField
              autoFocus
              id="max_salary"
              label="Max. Salary"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.max_salary}
            />
          </div>
          <div style={{ display: "flex" }}></div>
          <DialogContentText sx={{ mb: 1 }}>
            <b>Enter Company Details</b>
          </DialogContentText>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="company_name"
              label="Company Name"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.company_name}
            />
            <TextField
              autoFocus
              id="company_mobile"
              label="Company Mobile"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.company_mobile}
            />
            <TextField
              autoFocus
              id="company_whatsapp"
              label="Company WhatsApp"
              type="text"
              sx={{ width: "31%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.company_whatsapp}
            />
            <TextField
              autoFocus
              id="company_email"
              label="Company Email"
              type="text"
              sx={{ width: "32%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.company_email}
            />
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              autoFocus
              id="company_description"
              label="Company Description"
              type="text"
              sx={{ width: "48%", mr: "2%", mb: 2 }}
              onChange={handleDataChange}
              value={jobData?.company_description}
              multiline
              rows={3}
            />
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>
                Upload Logo image.
              </DialogContentText>
              <Box>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  <VisuallyHiddenInput type="file" onChange={changeHandler} />
                </Button>
              </Box>
            </DialogContent>
            <DialogContent>
              {isSelected ? (
                <img
                  src={logoFile}
                  height={100}
                  width={100}
                  alt={logoSelectedFile.name}
                />
              ) : (
                <></>
              )}
            </DialogContent>
          </div>
          {/* <div style={{ display: "flex" }}>
            <ImageUploader
              file={file}
              setFile={setFile}
              thumbnailFiles={thumbnailFiles}
              setThumbnailFiles={setThumbnailFiles}
            />
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmission}
            color="primary"
            variant="contained"
            // disabled={!(isSelected && jobData)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};
export default CreateJob;
