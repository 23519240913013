import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Autocomplete,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import {
  updateSubCategoriesForm,
  UploadFiles,
  getListingsSubCategories,
  CategoriesList,
} from "./subcategories";

const EditSubCategories = ({ onFormSubmit }) => {
  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    category: "",
    sub_category_name: "",
    sub_category_description: "",
  });
  const [images, setImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const filters = {
    offset: 1,
    limit: 30,
  };
  useEffect(() => {
    async function getCategories() {
      const data = await CategoriesList(filters.offset, filters.limit);
      setSelectedCategory(data.info);
    }
    getCategories();
    console.log(selectedCategory, "categories");
  }, [filters.offset, filters.limit]);

  useEffect(() => {
    const getData = async () => {
      let data = await getListingsSubCategories(1, 30, id);
      data = data.info[0];
      setFormData({
        category: data?.category,
        sub_category_id: data?.id,
        sub_category_name: data?.sub_category_name,
        sub_category_description: data?.sub_category_description,
      });
      setImages(data?.sub_category_image ? [data?.sub_category_image] : []);
      console.log(data, "formdata");
    };

    getData();
  }, []);

  const navigate = useNavigate();

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages([...selectedImages]);
  };

  const resetForm = () => {
    setFormData({
      category: "",
      sub_category_id: id,
      sub_category_name: "",
      sub_category_description: "",
    });
    setImages([]);
  };
  const UpdateAndSaveCategory = async (data, images) => {
    try {
      const newImages = images.filter((item) => typeof item === "object");
      const oldImages = images.filter((item) => typeof item === "string");

      // Upload new images
      let imagesResponse = { success: true, info: [oldImages] };
      if (newImages.length > 0) {
        const imagesFormData = new FormData();
        newImages.forEach((file, index) => {
          imagesFormData.append(`files`, file);
        });
        imagesFormData.append("entity", "categories");
        imagesResponse = await UploadFiles(imagesFormData);
      }

      if (imagesResponse.success) {
        data.sub_category_image = imagesResponse.info
          ? imagesResponse.info.join(",") // Assuming info is an array of image URLs
          : null;
        const formResponse = await updateSubCategoriesForm(data);
        console.log(formResponse, "response");
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UpdateAndSaveCategory(formData, images);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/listings-subcategories"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    setFormData({
      sub_category_id: id,
      sub_category_name: "",
      sub_category_description: "",
    });
    setImages([]);
    navigate("/dhunia_bo/listings-subcategories");
  };
  return (
    <Box
      marginRight="20px"
      marginLeft="20px"
      marginBottom="20px"
      height="600px"
    >
      <Typography variant="h5" fontWeight="bold" marginBottom="20px">
        EDIT SUB CATEGORY
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth variant="outlined" margin="normal">
          <Autocomplete
            options={selectedCategory}
            getOptionLabel={(option) => option.category_name}
            getOptionSelected={(option, value) => option.id === value.id}
            value={
              formData.category
                ? selectedCategory.find(
                    (category) => category.id === formData.category
                  )
                : null
            }
            onChange={(event, newValue) => {
              setFormData({
                ...formData,
                category: newValue ? newValue.id : null,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Categories"
                name="category"
              />
            )}
          />
        </FormControl>

        <TextField
          fullWidth
          label="Sub Category Name"
          variant="outlined"
          name="sub_category_name"
          value={formData.sub_category_name}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={1}
          required
        />

        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          name="sub_category_description"
          value={formData.sub_category_description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
          required
        />

        <Box marginBottom="20px" marginTop="20px">
          <input
            type="file"
            id="sub_category_image"
            name="sub_category_image"
            accept="image/*"
            onChange={handleImageChange}
          />
          {images.length > 0 && (
            <ul>
              {images.map((image, index) => (
                <li key={index}>
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button onClick={() => handleRemoveImage(index)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </Box>

        <Box marginTop="55px" display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default EditSubCategories;
