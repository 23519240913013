// //--------------------------------------------------------

// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   MenuItem,
//   Select,
//   Tabs,
//   Tab,
// } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";

// import { addnews_columns } from "../../../utils/constants";
// import { getNewsList } from "../../../services/news";

// function NewsTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [newsListData, setNewsList] = useState(null);
//   const [districts, setDistricts] = useState([]);
//   const [selectedTab, setSelectedTab] = useState(0);

//   // Fetch districts on component mount
//   useEffect(() => {
//     async function fetchDistricts() {
//       const userDetails = JSON.parse(localStorage.getItem("userDetails"));
//       const token = `Bearer ${userDetails?.access_token}`;

//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//           { headers: { Authorization: token } }
//         );

//         if (response.data.success) {
//           setDistricts(response.data.info);
//         } else {
//           console.error("Error fetching districts:", response.data.message);
//           setDistricts([]);
//         }
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//         setDistricts([]);
//       }
//     }
//     fetchDistricts();
//   }, []);

//   // Fetch news list whenever filters change
//   useEffect(() => {
//     async function getNewsDetails() {
//       const data = await getNewsList(
//         filters.offset,
//         filters.limit,
//         filters.id,
//         filters.status,
//         filters.district_name
//       );
//       setNewsList(data);
//     }
//     getNewsDetails();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters((prev) => ({ ...prev, offset: newPage }));
//   };

//   const handleRowsPerPageChange = (event) => {
//     setFilters((prev) => ({
//       ...prev,
//       limit: parseInt(event.target.value, 10),
//       offset: 1,
//     }));
//   };
 

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-news");
//   };

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//     const status =
//       newValue === 0 ? "APPROVED" : newValue === 1 ? "PENDING" : "REJECTED";
//     setFilters((prev) => ({ ...prev, offset: 1, status, district_name: "" }));
//   };

//   const handleDistrictChange = (event) => {
//     const selectedDistrictId = event.target.value;
//     const selectedDistrict = districts.find((d) => d.id === selectedDistrictId);
//     setFilters((prev) => ({
//       ...prev,
//       offset: 1,
//       district_name: selectedDistrict ? selectedDistrict.district_name : "",
//     }));
//   };

//   const handleEditClick = (id) => {
//     console.log(id);
//     navigate(`/dhunia_bo/edit-news/${id}`);
//   };

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "image":
//         return (
//           <TableCell key={column.id} align={column.align}>
//             <img
//               src={value}
//               alt="News"
//               style={{ width: "180px", borderRadius: "8px" }}
//             />
//           </TableCell>
//         );
//       case "title":
//         return (
//           <TableCell key={column.id} align={column.align}>
//             <Typography variant="subtitle1" fontWeight="bold">
//               {row.title}
//             </Typography>
//             <Typography>
//               {row.reporter_name} -{" "}
//               {new Date(row.created_date).toLocaleString()}
//             </Typography>
//             <Typography variant="body2" color="primary">
//               <a href="web.dhuniya.in">Visit Web</a>
//             </Typography>
//             <Typography variant="body2" marginTop="10px">
//               {row.description}
//             </Typography>
//             <Typography variant="body2" marginTop="10px">
//               {row.categories.map((category, index) => (
//                 <Box
//                   key={index}
//                   display="inline-block"
//                   marginRight="10px"
//                   padding="5px 10px"
//                   borderRadius="10px"
//                   border="1px solid #a9a9a9"
//                 >
//                   {category}
//                 </Box>
//               ))}
//             </Typography>
//           </TableCell>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" marginBottom="10px">
//         <Typography variant="h3" fontWeight="bold">
//           NEWS
//         </Typography>
//         <Button variant="contained" color="primary" onClick={handleCreateClick}>
//           Create
//         </Button>
//       </Box>
//       <Tabs value={selectedTab} onChange={handleTabChange}>
//         <Tab label="Approved" />
//         <Tab label="Pending" />
//         <Tab label="Rejected" />
//       </Tabs>
//       {selectedTab === 1 && (
//         <Box display="flex" flexDirection="column" marginBottom="10px">
//           <Select
//             value={filters.district_name}
//             onChange={handleDistrictChange}
//             style={{ marginBottom: "10px" }}
//           >
//             <MenuItem value="">All Districts</MenuItem>
//             {districts.map((district) => (
//               <MenuItem key={district.id} value={district.id}>
//                 {district.district_name}
//               </MenuItem>
//             ))}
//           </Select>
//           {filters.district_name && (
//             <Typography variant="h6">
//               <h3>News related to {filters.district_name} are shown below :</h3>
//             </Typography>
//           )}
//         </Box>
//       )}
//       <Paper sx={{ width: "100%", overflow: "hidden" }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableBody>
//             {newsListData?.info?.map((row) => (
//               <TableRow
//                 hover
//                 key={row.id}
//                 sx={{
//                   margin: "20px 0",
//                   padding: "20px",
//                   boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
//                   "& > *": { borderBottom: "unset !important" },
//                 }}
//               >
//                 {addnews_columns.map((column) => renderContent(row, column))}
//                 <TableCell
//                   align="center"
//                   style={{ borderBottom: "1px solid #ddd" }}
//                 >
//                   <EditIcon
//                     style={{ cursor: "pointer", color: "#007eff" }}
//                     onClick={() => handleEditClick(row.id)}
//                   />
//                 </TableCell>
//                 {row.status === "APPROVED" && (
//                   <TableCell>
//                     <Link to={`/dhunia_bo/send-notification/${row.id}`}>
//                       <SendIcon style={{ cursor: "pointer" }} />
//                     </Link>
//                   </TableCell>
//                 )}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           onPageChange={handlePageChange}
//           onRowsPerPageChange={handleRowsPerPageChange}
//           page={filters.offset}
//           rowsPerPage={filters.limit}
//           count={newsListData?.total || 0}
//           style={{ backgroundColor: "#007effa6" }}
//         />
     
//       </Paper>
//     </Box>
//   );
// }

// export default NewsTable;


// import React, { useState, useEffect } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   MenuItem,
//   Select,
//   Tabs,
//   Tab,
// } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// import EditIcon from "@mui/icons-material/Edit";
// import axios from "axios";

// import { addnews_columns } from "../../../utils/constants";
// import { getNewsList } from "../../../services/news";

// function NewsTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [newsListData, setNewsList] = useState(null);
//   const [districts, setDistricts] = useState([]);
//   const [selectedTab, setSelectedTab] = useState(0);

//   useEffect(() => {
//     async function fetchDistricts() {
//       const userDetails = JSON.parse(localStorage.getItem("userDetails"));
//       const token = `Bearer ${userDetails?.access_token}`;

//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//           { headers: { Authorization: token } }
//         );

//         if (response.data.success) {
//           setDistricts(response.data.info);
//         } else {
//           console.error("Error fetching districts:", response.data.message);
//           setDistricts([]);
//         }
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//         setDistricts([]);
//       }
//     }
//     fetchDistricts();
//   }, []);

//   useEffect(() => {
//     async function getNewsDetails() {
//       const data = await getNewsList(
//         filters.offset,
//         filters.limit,
//         filters.id,
//         filters.status,
//         filters.district_name
//       );
//       setNewsList(data);
//     }
//     getNewsDetails();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters((prev) => ({ ...prev, offset: newPage + 1 }));
//   };

//   const handleRowsPerPageChange = (event) => {
//     setFilters((prev) => ({
//       ...prev,
//       limit: parseInt(event.target.value, 10),
//       offset: 1,
//     }));
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-news");
//   };

//   const handleTabChange = (event, newValue) => {
//     setSelectedTab(newValue);
//     const status =
//       newValue === 0 ? "APPROVED" : newValue === 1 ? "PENDING" : "REJECTED";
//     setFilters((prev) => ({ ...prev, offset: 1, status, district_name: "" }));
//   };

//   const handleDistrictChange = (event) => {
//     const selectedDistrictId = event.target.value;
//     const selectedDistrict = districts.find((d) => d.id === selectedDistrictId);
//     setFilters((prev) => ({
//       ...prev,
//       offset: 1,
//       district_name: selectedDistrict ? selectedDistrict.district_name : "",
//     }));
//   };

//   const handleEditClick = (id) => {
//     console.log(id);
//     navigate(`/dhunia_bo/edit-news/${id}`);
//   };

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "image":
//         return (
//           <TableCell key={column.id} align={column.align}>
//             <img
//               src={value}
//               alt="News"
//               style={{ width: "180px", borderRadius: "8px" }}
//             />
//           </TableCell>
//         );
//       case "title":
//         return (
//           <TableCell key={column.id} align={column.align}>
//             <Typography variant="subtitle1" fontWeight="bold">
//               {row.title}
//             </Typography>
//             <Typography>
//               {row.reporter_name} -{" "}
//               {new Date(row.created_date).toLocaleString()}
//             </Typography>
//             <Typography variant="body2" color="primary">
//               <a href="web.dhuniya.in">Visit Web</a>
//             </Typography>
//             <Typography variant="body2" marginTop="10px">
//               {row.description}
//             </Typography>
//             <Typography variant="body2" marginTop="10px">
//               {row.categories.map((category, index) => (
//                 <Box
//                   key={index}
//                   display="inline-block"
//                   marginRight="10px"
//                   padding="5px 10px"
//                   borderRadius="10px"
//                   border="1px solid #a9a9a9"
//                 >
//                   {category}
//                 </Box>
//               ))}
//             </Typography>
//           </TableCell>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box m="20px">
//       <Box display="flex" justifyContent="space-between" marginBottom="10px">
//         <Typography variant="h3" fontWeight="bold">
//           NEWS
//         </Typography>
//         <Button variant="contained" color="primary" onClick={handleCreateClick}>
//           Create
//         </Button>
//       </Box>
//       <Tabs value={selectedTab} onChange={handleTabChange}>
//         <Tab label="Approved" />
//         <Tab label="Pending" />
//         <Tab label="Rejected" />
//       </Tabs>
//       {selectedTab === 1 && (
//         <Box display="flex" flexDirection="column" marginBottom="10px">
//           <Select
//             value={filters.district_name}
//             onChange={handleDistrictChange}
//             style={{ marginBottom: "10px" }}
//           >
//             <MenuItem value="">All Districts</MenuItem>
//             {districts.map((district) => (
//               <MenuItem key={district.id} value={district.id}>
//                 {district.district_name}
//               </MenuItem>
//             ))}
//           </Select>
//           {filters.district_name && (
//             <Typography variant="h6">
//               <h3>News related to {filters.district_name} are shown below :</h3>
//             </Typography>
//           )}
//         </Box>
//       )}
//       <Paper sx={{ width: "100%", overflow: "hidden" }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableBody>
//             {newsListData?.info?.map((row) => (
//               <TableRow
//                 hover
//                 key={row.id}
//                 sx={{
//                   margin: "20px 0",
//                   padding: "20px",
//                   boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
//                   "& > *": { borderBottom: "unset !important" },
//                 }}
//               >
//                 {addnews_columns.map((column) => renderContent(row, column))}
//                 <TableCell
//                   align="center"
//                   style={{ borderBottom: "1px solid #ddd" }}
//                 >
//                   <EditIcon
//                     style={{ cursor: "pointer", color: "#007eff" }}
//                     onClick={() => handleEditClick(row.id)}
//                   />
//                 </TableCell>
//                 {row.status === "APPROVED" && (
//                   <TableCell>
//                     <Link to={`/dhunia_bo/send-notification/${row.id}`}>
//                       <SendIcon style={{ cursor: "pointer" }} />
//                     </Link>
//                   </TableCell>
//                 )}
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           onPageChange={handlePageChange}
//           onRowsPerPageChange={handleRowsPerPageChange}
//           page={filters.offset - 1} // -1 to convert to 0-based index
//           rowsPerPage={filters.limit}
//           count={newsListData?.total || 0}
//           style={{ backgroundColor: "#007effa6" }}
//         />
//       </Paper>
//     </Box>
//   );
// }

// export default NewsTable;


import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  MenuItem,
  Select,
  Tabs,
  Tab,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

import { addnews_columns } from "../../../utils/constants";
import { getNewsList } from "../../../services/news";

function NewsTable({ filters, setFilters }) {
  const navigate = useNavigate();

  const [newsListData, setNewsList] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    async function fetchDistricts() {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const token = `Bearer ${userDetails?.access_token}`;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
          { headers: { Authorization: token } }
        );

        if (response.data.success) {
          setDistricts(response.data.info);
        } else {
          console.error("Error fetching districts:", response.data.message);
          setDistricts([]);
        }
      } catch (error) {
        console.error("Error fetching districts:", error);
        setDistricts([]);
      }
    }
    fetchDistricts();
  }, []);

  useEffect(() => {
    async function getNewsDetails() {
      const data = await getNewsList(
        filters.offset,
        filters.limit,
        filters.id,
        filters.status,
        filters.district_name
      );
      setNewsList(data);
    }
    getNewsDetails();
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setFilters((prev) => ({ ...prev, offset: newPage + 1 }));
  };

  const handleRowsPerPageChange = (event) => {
    setFilters((prev) => ({
      ...prev,
      limit: parseInt(event.target.value, 10),
      offset: 1,
    }));
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/create-news");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const status =
      newValue === 0 ? "APPROVED" : newValue === 1 ? "PENDING" : "REJECTED";
    setFilters((prev) => ({ ...prev, offset: 1, status, district_name: "" }));
  };

  const handleDistrictChange = (event) => {
    const selectedDistrictId = event.target.value;
    const selectedDistrict = districts.find((d) => d.id === selectedDistrictId);
    setFilters((prev) => ({
      ...prev,
      offset: 1,
      district_name: selectedDistrict ? selectedDistrict.district_name : "",
    }));
  };

  const handleEditClick = (id) => {
    navigate(`/dhunia_bo/edit-news/${id}`);
  };

  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.id) {
      case "image":
        return (
          <TableCell key={column.id} align={column.align}>
            <img
              src={value}
              alt="News"
              style={{ width: "180px", borderRadius: "8px" }}
            />
          </TableCell>
        );
      case "title":
        return (
          <TableCell key={column.id} align={column.align}>
            <Typography variant="subtitle1" fontWeight="bold">
              {row.title}
            </Typography>
            <Typography>
              {row.reporter_name} -{" "}
              {new Date(row.created_date).toLocaleString()}
            </Typography>
            <Typography variant="body2" color="primary">
              <a href="web.dhuniya.in">Visit Web</a>
            </Typography>
            <Typography variant="body2" marginTop="10px">
              {row.description}
            </Typography>
            <Typography variant="body2" marginTop="10px">
              {row.categories.map((category, index) => (
                <Box
                  key={index}
                  display="inline-block"
                  marginRight="10px"
                  padding="5px 10px"
                  borderRadius="10px"
                  border="1px solid #a9a9a9"
                >
                  {category}
                </Box>
              ))}
            </Typography>
          </TableCell>
        );
      default:
        return null;
    }
  };

  const renderRejectedBy = (row) => {
    return selectedTab === 2 ? (
      <TableCell key="rejected_by" align="left" sx={{width:"9rem", paddingTop:"120px"}}>
        <Typography variant="body3" color="error" >
          Reason for Rejection : <Typography color="black">{row.remarks_by_admin}</Typography>
        </Typography>
      </TableCell>
    ) : null;
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" marginBottom="10px">
        <Typography variant="h3" fontWeight="bold"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          NEWS
        </Typography>
        <Button variant="contained" color="primary" onClick={handleCreateClick}>
          Create
        </Button>
      </Box>
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Approved" />
        <Tab label="Pending" />
        <Tab label="Rejected" />
      </Tabs>
      {selectedTab === 1 && (
        <Box display="flex" flexDirection="column" marginBottom="10px">
          <Select
            value={filters.district_name}
            onChange={handleDistrictChange}
            style={{ marginBottom: "10px" }}
          >
            <MenuItem value="">All Districts</MenuItem>
            {districts.map((district) => (
              <MenuItem key={district.id} value={district.id}>
                {district.district_name}
              </MenuItem>
            ))}
          </Select>
          {filters.district_name && (
            <Typography variant="h6">
              <h3>News related to {filters.district_name} are shown below :</h3>
            </Typography>
          )}
        </Box>
      )}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            {newsListData?.info?.map((row) => (
              <TableRow
                hover
                key={row.id}
                sx={{
                  margin: "20px 0",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                  "& > *": { borderBottom: "unset !important" },
                }}
              >
                {addnews_columns.map((column) => renderContent(row, column))}
                {renderRejectedBy(row)}
                <TableCell
                  align="center"
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <EditIcon
                    style={{ cursor: "pointer", color: "#007eff" }}
                    onClick={() => handleEditClick(row.id)}
                  />
                </TableCell>
                {row.status === "APPROVED" && (
                  <TableCell>
                    <Link to={`/dhunia_bo/send-notification/${row.id}`}>
                      <SendIcon style={{ cursor: "pointer" }} />
                    </Link>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={filters.offset - 1} // -1 to convert to 0-based index
          rowsPerPage={filters.limit}
          count={newsListData?.total || 0}
          style={{ backgroundColor: "#007effa6" }}
        />
      </Paper>
    </Box>
  );
}

export default NewsTable;
