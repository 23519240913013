

import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { createBannerForm, UploadBannerFiles } from "./banner";

const BannersForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    status: "",
    banner_type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const handleVideoChange = (event) => {
    const selectedVideos = Array.from(event.target.files);
    setVideos(selectedVideos);
  };

  const UploadAndSaveNews = async (data, images, videos) => {
    try {
      // Upload images if available
      let imagesResponse = { success: true, info: null };
      if (images.length > 0) {
        const imagesFormData = new FormData();
        images.forEach((file) => {
          imagesFormData.append("files", file);
        });
        imagesFormData.append("entity", data.entity);
        imagesResponse = await UploadBannerFiles(imagesFormData);
      } else if (data.banner_type === "IMAGE") {
        data.images = null; // Set images to null if no images were selected
      }

      // Upload videos if available
      let videosResponse = { success: true, info: null };
      if (videos.length > 0) {
        const videosFormData = new FormData();
        videos.forEach((file) => {
          videosFormData.append("files", file);
        });
        videosFormData.append("entity", data.entity);
        videosResponse = await UploadBannerFiles(videosFormData);
      } else if (data.banner_type === "VIDEO") {
        data.videos = null; // Set videos to null if no videos were selected
      }

      // If both image and video uploads are successful
      if (imagesResponse.success && videosResponse.success) {
        // Update data with image and video information
        if (imagesResponse.info) {
          data.images = imagesResponse.info;
        }
        if (videosResponse.info) {
          data.videos = videosResponse.info;
        }

        // Submit form data
        const formResponse = await createBannerForm(data);
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error("Error uploading files:", error);
      return null;
    }
  };
  const handleRemoveVideo = (indexToRemove) => {
    setVideos(videos.filter((_, index) => index !== indexToRemove));
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UploadAndSaveNews(formData, images, videos);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        if (onFormSubmit) {
          onFormSubmit();
        }
        resetForm();
      } else {
        const errorMessage = apiResponse
          ? apiResponse.message
          : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    navigate("/dhunia_bo/banners");
  };

  const resetForm = () => {
    setFormData({
      name: "",
      status: "",
      banner_type: "",
    });
    setImages([]);
    setVideos([]);
  };

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: "#A7E6FF" }}
    >
      <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          marginBottom="20px"
          align="center"
          color="#007eff"
        >
          ADD BANNER
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={handleChange}
            margin="normal"
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Status</InputLabel>
            <Select
              value={formData.status}
              onChange={handleChange}
              label="Status"
              name="status"
              id="status"
            >
              <MenuItem value="ACTIVE">ACTIVE</MenuItem>
              <MenuItem value="INACTIVE">INACTIVE</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal" required>
            <InputLabel>Banners Type</InputLabel>
            <Select
              value={formData.banner_type}
              onChange={handleChange}
              label="banner_type"
              name="banner_type"
              id="banner_type"
            >
              <MenuItem value="IMAGE">IMAGE</MenuItem>
              <MenuItem value="VIDEO">VIDEO</MenuItem>
            </Select>
          </FormControl>

          <Box marginBottom="20px" marginTop="20px">
            <input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              onChange={handleImageChange}
              multiple
              style={{
                display: "none",
              }}
            />
            <label htmlFor="images">
              <Button variant="outlined" component="span" fullWidth>
                Upload Images
              </Button>
            </label>
            {images.length > 0 && (
              <ul>
                {images.map((image, index) => (
                  <li key={index}>
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${index}`}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                    <Button onClick={() => handleRemoveImage(index)}>
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </Box>

          <Box marginBottom="20px">
            <input
              type="file"
              id="videos"
              name="videos"
              accept="video/*"
              onChange={handleVideoChange}
              multiple
              style={{
                display: "none",
              }}
            />
            <label htmlFor="videos">
              <Button variant="outlined" component="span" fullWidth>
                Upload Videos
              </Button>
            </label>
            {videos.length > 0 && (
              <ul>
                {videos.map((video, index) => (
                  <li key={index}>
                    <video
                      src={URL.createObjectURL(video)}
                      controls
                      alt={`Preview ${index}`}
                      style={{ maxWidth: "100px", maxHeight: "100px" }}
                    />
                    <Button onClick={() => handleRemoveVideo(index)}>
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </Box>

          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default BannersForm;
