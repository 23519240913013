
import React from 'react'
import { useState } from 'react';
import NewsTable from './newstable' //reporter_name , language, city ,tags

function Posts() {
  const [filters, setFilters] = useState({
    offset: 1,
   limit: 10,
   id : null,
   status : 'APPROVED',
 
 });

  
  return (
    <div>
      <NewsTable filters={filters} setFilters={setFilters} /></div>
  )
}

export default Posts
