import * as React from "react";
import { Box, styled, useTheme, Card, Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { tokens } from "../../../theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import ModeIcon from "@mui/icons-material/Mode";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import Moment from "react-moment";
import EditMovies from "./editMovies";
import { getMovieData } from "../../../services/ott_movies";
import { movie_columns } from "../../../utils/constants";
import Header from "../../../components/Header";
import { createMovie, getGenres } from "../../../services/ott_movies";
import TimeInput from "./time-pup-up";
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
export default function Movies() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [moviesList, setMoviesList] = useState([]);
  const [moviePayload, setMoviesPayload] = useState({});
  const [openEdit, setEditOpen] = useState(false);
  const [openDelete, setDeleteOpen] = useState(false);
  const [file, setFile] = useState([]);
  const [thumbnailFiles, setThumbnailFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [movieData, setMoviesData] = useState({});
  const [isSelected, setIsSelected] = useState(false);
  const [logoFile, setLogoFile] = useState();
  const [logoSelectedFile, setLogoSelectedFile] = useState();
  const [genres, setGenres] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [timePopupOpen, setTimePopupOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getMovieData(setMoviesList);
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleStatusChange = (event) => {
    // console.log("clicked: ", event);
  };
  const handleTimeIconClick = () => {
    setTimePopupOpen(true); // Open the popup when the time icon is clicked
  };
  const handleTimePopupClose = () => {
    setTimePopupOpen(false);
  };
  const handleTimePopupSubmit = (timeFields) => {
    console.log("Selected times:", timeFields);
    setTimePopupOpen(false);
  };
  /**
   * The function `handleEditMovies` filters the `moviesList` array to find the movie with the
   * matching `id` as the `event.target.id`, and if found, sets the `moviePayload` state to the
   * selected movie and opens the edit modal.
   * @returns nothing (undefined) if the condition `!event.target.id` is true.
   */
  const handleEditMovies = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedMovies = moviesList.filter((movie) => {
      if (movie.id.toString() === event.target.id) {
        return true;
      }
    });
    if (selectedMovies) {
      setMoviesPayload(selectedMovies[0]);
      setEditOpen(true);
    }
  };
  const handleDeleteMovies = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedMovies = moviesList.filter((movie) => {
      if (movie.id.toString() === event.target.id) {
        return true;
      }
    });
    if (selectedMovies) {
      setMoviesPayload(selectedMovies[0]);
      setDeleteOpen(true);
    }
  };
  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.format) {
      case "image":
        return <img src={value} style={{ width: 50, height: 50 }} />;
      case "boolean":
        return (
          <Switch
            id={row.id.toString()}
            checked={value}
            onChange={handleStatusChange}
          />
        );
      case "actions":
        return (
          <div>

            <ModeIcon
              id={row.id.toString()}
              onClick={handleEditMovies}
              style={{ margin: "10px" }}
            />
            <AccessTimeFilledIcon
            onClick={handleTimeIconClick}
            />
            
            {/* <DeleteForeverIcon
              id={row.id.toString()}
              onClick={handleDeleteMovies}
              style={{ margin: "10px" }}
            /> */}

            
            </div>
           
          
        );
      
      case "Date":
        return <Moment format="YYYY/MM/DD hh:mm ">{value}</Moment>;
      default:
        return value;
    }
  };
  useEffect(() => {
    getGenres().then((genres) => {
      // console.log("genres ", genres);
      setGenres(genres);
    });
  }, []);
  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    setMoviesData({});
    setIsSelected(false);
  }
  const handleGenresChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedGenres(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const changeHandler = async (event) => {
    const formData = new FormData();
    for (const element of event.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    // const uploadResponse = await uploadImages(formData);
    // if (uploadResponse) {
    //   setLogoSelectedFile(event.target.files[0]);
    //   setIsSelected(true);
    //   setLogoFile(uploadResponse?.info[0]);
    // }
    // setLogoFile(URL.createObjectURL(event.target.files[0]));
  };
  const handleSubmission = async () => {
    const formData = new FormData();
    formData.append("movie_title", movieData?.movie_title);
    formData.append("movie_type", movieData?.movie_type);
    formData.append("movie_style", movieData?.movie_style);
    formData.append("movie_role", movieData?.movie_role);
    formData.append("movie_category", movieData?.movie_category);
    formData.append("qualification", movieData?.qualification);
    formData.append("movie_description", movieData?.movie_description);
    formData.append("movie_location", movieData?.movie_location);
    formData.append("min_experience", movieData?.min_experience);
    formData.append("max_experience", movieData?.max_experience);
    formData.append("min_salary", movieData?.min_salary);
    formData.append("max_salary", movieData?.max_salary);
    formData.append("company_name", movieData?.company_name);
    formData.append("company_description", movieData?.company_description);
    formData.append("company_mobile", movieData?.company_mobile);
    formData.append("company_whatsapp", movieData?.company_whatsapp);
    const email_value = document.getElementById("email").value;
    // console.log(email_value);
    formData.append("company_email", movieData?.company_email);
    // // console.log(thumbnailFiles);
    // for (var i = 0; i < thumbnailFiles.length; i++) {
    //   // console.log("new upload file", i, thumbnailFiles[i]);
    //   formData.append("thumbnails", thumbnailFiles[i]);
    // }
    for (let i = 0; i < file.length; i++) {
      formData.append("images", file[i]);
    }
    formData.append("company_logo", logoFile);
    let response = await createMovie(formData);
    if (response?.success) {
      setOpen(false);
      setMoviesData({});
      setIsSelected(false);
    }
  };
  const handleDataChange = (event) => {
    // console.log(event.target.id, event.target.value);
    setMoviesData((movieData) => {
      let key = event.target.id;
      // console.log(movieData, event.target.value);
      movieData[`${key}`] = `${event.target.value}`;
      // console.log(movieData, movieData?.movie_title);
      return movieData;
    });
  };
  const handleDropdownDataChange = (event) => {
    // console.log(event.target.name, event.target.value);
    setMoviesData((movieData) => {
      let key = event.target.name;
      // console.log(movieData, event.target.value);
      movieData[`${key}`] = `${event.target.value}`;
      return movieData;
    });
  };
  const navigateToCreateMovie = () => {
    // 👇️ navigate to /contacts
    navigate("/dhunia_bo/createMovie");
  };
  return (
    <Container sx={{ m: "10px" }}>
      <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
        <CardHeader>
          <Header title="MOVIES" />
          <Button
            variant="outlined"
            color="primary"
            onClick={navigateToCreateMovie}
          >
            Create
          </Button>
        </CardHeader>
        <>
          <Box
            m="20px"
            sx={{
              "& .MuiTableContainer-root": {
                border: "none",
              },
              "& .MuiTableContainer-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiToolbar-root": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiTableHead-root .MuiTableCell-root": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {movie_columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {moviesList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow hover key={row.id}>
                            {movie_columns.map((column) => {
                              // const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {renderContent(row, column)}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={moviesList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
          {openEdit && (
            <EditMovies
              openEdit={openEdit}
              setEditOpen={setEditOpen}
              moviePayload={moviePayload}
              getMoviesData={getMovieData}
              setMoviesList={setMoviesList}
            />
          )}
          {/* {openDelete && (
        <DeleteMovies
          openDelete={openDelete}
          setDeleteOpen={setDeleteOpen}
          moviePayload={moviePayload}
          getMoviesData={getMoviesData}
          setMoviesList={setMoviesList}
        />
      )} */}
        </>
      </Card>
      <TimeInput
        open={timePopupOpen}
        handleClose={handleTimePopupClose}
        handleSubmit={handleTimePopupSubmit}
      />
    </Container>
  );
}
