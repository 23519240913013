




import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getDistricts, deleteDistrict } from "./districts";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

function DistrictsTable({ filters, setFilters }) {
  const navigate = useNavigate();
  const [districtListData, setDistrictListData] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [districtToDelete, setDistrictToDelete] = useState(null);

  useEffect(() => {
    const fetchDistricts = async () => {
      const data = await getDistricts(
        filters.offset,
        filters.limit,
        filters.id,
        filters.stateId
      );
      setDistrictListData(data);
    };
    fetchDistricts();
  }, [filters]);

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states`
        );
        setSelectedStates(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, []);

  const handlePageChange = (event, newPage) => {
    setFilters((prev) => ({
      ...prev,
      offset: newPage + 1,
    }));
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      stateId: stateId || "",
      offset: 1,
    }));
  };

  const handleDeleteIconClick = (id) => {
    setDistrictToDelete(id);
    setOpenDelete(true);
  };

  const handleDeleteDistrict = async () => {
    try {
      await deleteDistrict(districtToDelete);
      setOpenDelete(false);
      setDistrictToDelete(null);
      const data = await getDistricts(
        filters.offset,
        filters.limit,
        filters.id,
        filters.stateId
      );
      setDistrictListData(data);
    } catch (error) {
      console.error("Error deleting district:", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDistrictToDelete(null);
  };

  return (
    <>
      <Box m="20px">
        <Typography variant="h3" fontWeight="bold"
         sx={{
          background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        >
          DISTRICTS TABLE
        </Typography>
        <h4>*Please Select State to view Districts</h4>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Box display="flex" alignItems="center">
            <Select
              value={filters.stateId || ""}
              onChange={handleStateChange}
              displayEmpty
              inputProps={{ "aria-label": "Select State" }}
              style={{ marginRight: "10px", width: "200px" }}
            >
              <MenuItem value="">
                <em>All States</em>
              </MenuItem>
              {selectedStates.map((state) => (
                <MenuItem key={state.id} value={state.id}>
                  {state.state_name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant="contained"
            style={{ backgroundColor: "#007effa6", color: "white" }}
            onClick={() => navigate("/dhunia_bo/locations-district-create-form")}
          >
            Create
          </Button>
        </Box>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 3,
            borderRadius: "15px",
            backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              '& .MuiTableCell-root': {
                border: '1px solid #e0e0e0',
                padding: '8px',
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#007effa6",
                  '& .MuiTableCell-root': { fontWeight: 'bold' },
                }}
              >
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>#</strong>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>District Name</strong>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {districtListData?.info.map((row, index) => (
                <TableRow
                  hover
                  key={row.id}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                    '&:hover': { backgroundColor: '#e0f7fa' },
                  }}
                >
                  <TableCell align="center">{(filters.offset - 1) * filters.limit + index + 1}</TableCell>
                  <TableCell align="center">
                    <Typography>{row.district_name}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon
                      id={row.id.toString()}
                      onClick={() => handleDeleteIconClick(row.id)}
                      style={{ margin: "10px", cursor: "pointer" }}
                      sx={{
                        margin: "10px",
                        marginLeft: "40px",
                        cursor: "pointer",
                        color: "#f50057",
                        "&:hover": { color: "#ff4081" },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={filters.offset - 1}
            count={districtListData?.total ? districtListData.total : 0}
            rowsPerPage={filters.limit}
            sx={{ backgroundColor: "#007effa6", color: "black" }}
          />
        </Paper>
      </Box>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this district?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            sx={{
              color: "white",
              backgroundColor: "#318CE7",
              "&:hover": {
                backgroundColor: "#6CB4EE",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteDistrict}
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "lightcoral",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DistrictsTable;
