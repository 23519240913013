
import React from 'react'
import { useState } from 'react';
import StatesTable from './states-table';

function States() {
  const [filters, setFilters] = useState({
    offset: 1,
   limit: 10,
   id : null,
   
 
 });

  
  return (
    <div>
       
      <StatesTable filters={filters} setFilters={setFilters} /></div>
  )
}

export default States