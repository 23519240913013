import axios from "axios";
import { json } from "react-router-dom";
export const getJobsData = async (next, status = null) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  let queryParams = "?";
  if (status === "ACTIVE") {
    queryParams += "is_active=True";
  } else if (status === "INACTIVE") {
    queryParams += "is_active=False";
  }
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}backoffice/business/jobs${
      queryParams !== "?" ? queryParams : ""
    }`,
    {
      headers,
    }
  );
  // console.log(response);
  next(response.data.info);
};
export const createJobs = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  return axios
    .post(
      `${process.env.REACT_APP_BASEURL}backoffice/business/jobs`,
      formData,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const editJobsData = async (formData) => {
  // console.log(formData);
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    // "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(`${process.env.REACT_APP_BASEURL}backoffice/business/jobs`, formData, {
      headers,
    })
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const deleteJobsData = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .delete(
      `${process.env.REACT_APP_BASEURL}backoffice/business/jobs`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const uploadImages = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  return axios
    .post(`${process.env.REACT_APP_BASEURL}userservice/store/files`, formData, {
      headers,
    })
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
      return [];
    });
};
// Real estate
export const getclassifiedsData = async (next, classified_type, is_active) => {
  // console.log(is_active);
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${
      process.env.REACT_APP_BASEURL
    }backoffice/business/classifieds?classified_type=${classified_type}&is_active=${
      is_active === "ACTIVE" ? "True" : "False"
    }`,
    {
      headers,
    }
  );
  // console.log(response);
  next(response.data.info);
};
export const getOtherclassifiedsData = async (next, is_active) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${
      process.env.REACT_APP_BASEURL
    }backoffice/business/classifieds?classified_type=OTHERS&is_active=${
      is_active === "ACTIVE" ? "True" : "False"
    }`,
    {
      headers,
    }
  );
  // console.log(response);
  next(response.data.info);
};
export const createClassifieds = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    // "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .post(
      `${process.env.REACT_APP_BASEURL}backoffice/business/classifieds`,
      formData,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const editClassifiedsData = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    // "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(
      `${process.env.REACT_APP_BASEURL}backoffice/business/classifieds`,
      formData,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
