import axios from "axios";

export const getListingsSubCategories = async (offset = 1, limit = 10, id=null ,) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };
  let response;
  if (id) {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/subcategories?id=${id}`,
      {
        headers,
      }
    );
  }
  
  else {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/subcategories?&offset=${offset}&limit=${limit}`,
      {
        headers,
      }
    );
  }

 
  if (response?.data?.success) {
    console.log(response.data , "data");
    return response.data;
  }
  return null;
};

//local store

export const UploadFiles = async (formData, withUpload = true) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  try {
    let config = {};
    if (withUpload) {
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/store/files`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

//form 



export const createSubCategoriesForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/subcategories`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

//for edit 
export const updateSubCategoriesForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/subcategories`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

//categories 

export const CategoriesList = async (offset = 1, limit = 30, ) => {  
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };
  let response;

  
 
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/categories?&offset=${offset}&limit=${limit}`,
      {
        headers,
      }
    );
  

 
  if (response?.data?.success) {
    console.log(response.data);
    return response.data;
   
  }
}


export const deleteSubCategories = async (id) => {
  try {
    const userDetails = localStorage.getItem("userDetails");
    const userData = JSON.parse(userDetails);
    const token = `Bearer ${userData?.access_token}`;

    const headers = {
      Authorization: token,
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_BASEURL}backoffice/business/listing/subcategories?sub_category_id=${id}`,
      {
        headers,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error deleting category:", error);
    return { success: false, message: "Failed to delete category" };
  }
};

 

