import * as React from "react";
import { Box, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { tokens } from "../../../theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import ModeIcon from "@mui/icons-material/Mode";
import Moment from "react-moment";
import EditRealEstate from "./editRealEstate";
import {
  getclassifiedsData,
  editClassifiedsData,
} from "../../../services/jobs";
import { realEstate_columns } from "../../../utils/constants";
export default function ListRealEstate({
  classified_type,
  is_active,
  permissions,
}) {
  // console.log(classified_type);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [realEstateList, setRealEstateList] = useState([]);
  const [realEstatePayload, setRealEstatePayload] = useState({});
  const [openEdit, setEditOpen] = useState(false);
  useEffect(() => {
    getclassifiedsData(setRealEstateList, classified_type, is_active);
  }, [classified_type, is_active]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleStatusChange = async (event) => {
    const formData = new FormData();
    formData.append("classifieds_id", event.target.id);
    formData.append("is_active", event.target.checked);
    let response = await editClassifiedsData(formData);
  };
  /**
   * The function `EditRealEstate` filters the `realEstateList` array to find the job with the
   * matching `id` as the `event.target.id`, and if found, sets the `jobPayload` state to the
   * selected job and opens the edit modal.
   * @returns nothing (undefined) if the condition `!event.target.id` is true.
   */
  const handleEditRealEstate = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedJobs = realEstateList.filter((job) => {
      if (job.id.toString() === event.target.id) {
        return true;
      }
      return false;
    });
    if (selectedJobs) {
      setRealEstatePayload(selectedJobs[0]);
      setEditOpen(true);
    }
  };
  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.format) {
      case "image":
        return (
          <img
            alt="realEstate_image"
            src={value}
            style={{ width: 50, height: 50 }}
          />
        );
      case "boolean":
        return (
          <Switch
            id={row.id.toString()}
            checked={value}
            onChange={handleStatusChange}
          />
        );
      case "actions":
        return permissions.write_jobmaster_permission ? (
          <>
            <ModeIcon
              id={row.id.toString()}
              onClick={handleEditRealEstate}
              style={{ margin: "10px" }}
            />
            {/* <DeleteForeverIcon
              id={row.id.toString()}
              onClick={handleDeleteJobs}
              style={{ margin: "10px" }}
            /> */}
          </>
        ) : (
          <></>
        );
      case "Date":
        return <Moment format="YYYY/MM/DD hh:mm ">{value}</Moment>;
      default:
        return value;
    }
  };
  return (
    <>
      <Box
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {realEstate_columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {realEstateList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id}>
                        {realEstate_columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderContent(row, column)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={realEstateList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {openEdit && (
        <EditRealEstate
          openEdit={openEdit}
          setEditOpen={setEditOpen}
          realEstatePayload={realEstatePayload}
          getclassifiedsData={getclassifiedsData}
          setRealEstateList={setRealEstateList}
          classified_type={classified_type}
        />
      )}
      {/* {openDelete && (
        <DeleteJobs
          openDelete={openDelete}
          setDeleteOpen={setDeleteOpen}
          jobPayload={jobPayload}
          getclassifiedsData={getclassifiedsData}
          setRealEstateList={setRealEstateList}
        />
      )} */}
    </>
  );
}
