import React from 'react'
import { useState } from 'react';
import DistrictsTable from './districts-table';

function Districts({id}) {
  console.log(id, "ID-1")
  const [filters, setFilters] = useState({
    offset: 1,
   limit: 10,
   id : id,
   stateId: null, // Use null for initial state to fetch all districts  changed here as well
   
   
 
 });

  
  return (
    <div>
      
      <DistrictsTable filters={filters} setFilters={setFilters} /></div>
  )
}

export default Districts