//------------------------------------------------------------------------------------------------------------------------

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Paper,
  Chip,
  Select,
  MenuItem,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { createForm, UploadFiles } from "./form";
import CreateEditCities from "./create-edit-cities";
import CreateEditStates from "./states";

import { getCategoriesList } from "./form";

const NewsForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    categories: [],
    description: "",
    language: "ENGLISH",
    city: [],
    tags: [],
    state: [],
    district: [],
    mandal: [],
    village: [],
    youtube_url: [],
    promoted_text : "",
    destination_url :"",
    type:"",

  });
  const filters = {
    offset: 1,
    limit: 10,
  };

  useEffect(() => {
    async function getCategories() {
      const data = await getCategoriesList(filters.offset, filters.limit);
      setSelectedCategory(data.info);
      console.log(data.info, "news");
    }
    getCategories();
  }, [filters.offset, filters.limit]);

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideo(video.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "district" || name === "mandal" || name === "youtube_url") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [value] || null, // Always store as array
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const [inputValue, setInputValue] = React.useState("");
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim()) {
      setFormData((prevData) => ({
        ...prevData,
        tags: [...prevData.tags, inputValue.trim()],
      }));
      setInputValue("");
      event.preventDefault();
    }
  };

  const handleTagsChange = (event, newValues) => {
    setFormData((prevData) => ({
      ...prevData,
      tags: newValues,
    }));
  };

  const handleCityChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      city: value,
    }));
  };

  const handleStateChange = (event, value) => {
    setFormData((prevData) => ({
      ...prevData,
      state: value || [],
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const handleVideoChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setVideo(selectedImages);
  };

  const resetForm = () => {
    setFormData({
      title: "",
      categories: [],
      description: "",
      language: "ENGLISH",
      city: [],
      tags: [],
      state: [],
      district: [],
      mandal: [],
      village: [],
      youtube_url: [],
      promoted_text : "",
    destination_url :"",
    type:"",
    });
    setImages([]);
    setVideo([]);
  };





  const UploadAndSaveNews = async (data, images, videos) => {
    try {
      // upload images
      const ImagesFormData = new FormData();
      images.forEach((file, index) => {
        ImagesFormData.append(`files`, file);
      });
      ImagesFormData.append("entity", data.entity);
      const images_response = await UploadFiles(ImagesFormData);

      if (!images_response.success) {
        throw new Error("Image upload failed");
      }

      // upload videos
      const videosFormData = new FormData();
      videos.forEach((file, index) => {
        videosFormData.append(`files`, file);
      });
      videosFormData.append("entity", data.entity);
      const video_response = await UploadFiles(videosFormData);

      if (!video_response.success) {
        throw new Error("Video upload failed");
      }

      data.image = images_response.info;
      data.video = video_response.info;
      const form_response = await createForm(data);

      if (!form_response.success) {
        throw new Error(form_response.message || "Form creation failed");
      }

      return form_response;
    } catch (error) {
      console.log(error);
      throw error; // Rethrow the error to be caught in handleSubmit
    }
  };



  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const preparedFormData = {
      ...formData,
      district: formData.district.length > 0 ? formData.district : null,
      mandal: formData.mandal.length > 0 ? formData.mandal : null,
      village: formData.village.length > 0 ? formData.village : null,
      youtube_url:
        formData.youtube_url.length > 0 ? formData.youtube_url : null,
    };

    try {
      // Validation checks
      if (!preparedFormData.title) {
        throw new Error("Title is required");
      }
      if (!preparedFormData.description) {
        throw new Error("Description is required");
      }
      if (!preparedFormData.language) {
        throw new Error("Language is required");
      }
      if (preparedFormData.categories.length === 0) {
        throw new Error("At least one category is required");
      }
      if (preparedFormData.city.length === 0) {
        throw new Error("At least one city is required");
      }
      if (preparedFormData.state.length === 0) {
        throw new Error("At least one state is required");
      }

      // Upload and save news
      const apiResponse = await UploadAndSaveNews(
        preparedFormData,
        images,
        video
      );
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/posts"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert(errorMessage);
      }
    } catch (error) {
      let errorMessage = "Error submitting form data:";
      if (error.message.includes("Title is required")) {
        errorMessage = "Error: Title is required.";
      } else if (error.message.includes("Description is required")) {
        errorMessage = "Error: Description is required.";
      } else if (error.message.includes("Language is required")) {
        errorMessage = "Error: Language is required.";
      } else if (error.message.includes("At least one category is required")) {
        errorMessage = "Error: At least one category is required.";
      } else if (error.message.includes("At least one city is required")) {
        errorMessage = "Error: At least one city is required.";
      } else if (error.message.includes("At least one state is required")) {
        errorMessage = "Error: At least one state is required.";
      } else if (error.message.includes("Image upload failed")) {
        errorMessage = "Error: Failed to upload images.";
      } else if (error.message.includes("Video upload failed")) {
        errorMessage = "Error: Failed to upload videos.";
      } else if (error.message.includes("Form creation failed")) {
        errorMessage = "Error: Failed to create the form.";
      } else {
        errorMessage = "Backend error: " + error.message;
      }
      console.error(errorMessage);
      alert(errorMessage);
    }
  };

  const handleAddTag = (event) => {
    if (event.key === "Enter" || event.key === ",") {
      event.preventDefault();
      const value = event.target.value.trim();
      if (value && !formData.tags.includes(value)) {
        setFormData((prevData) => ({
          ...prevData,
          tags: [...prevData.tags, value],
        }));
      }
      event.target.value = "";
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    setFormData((prevData) => ({
      ...prevData,
      tags: prevData.tags.filter((tag) => tag !== tagToDelete),
    }));
  };

  const handleCancel = () => {
    setFormData({
      title: "",
      categories: [],
      description: "",
      language: "",
      city: [],
      tags: [],
      state: [],
      district: [],
      mandal: [],
      village: [],
      youtube_url: [],
      promoted_text : "",
    destination_url :"",
    type:"",
    });
    setImages([]);
    setVideo([]);
    navigate("/dhunia_bo/posts");
  };

  return (
    <Box sx={{ backgroundColor: "#A7E6FF" }}>
      <Box
        width="100%"
        maxWidth="900px"
        marginLeft="5rem"
        justifyContent="center"
        alignItems="center"
        padding="30px"
      >
        <Box
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
          sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="30px"
            marginBottom="15px"
            align="center"
            color="#007effa6"
            sx={{ marginTop: "10px" }}
          >
            ADD NEWS
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="title"
              value={formData.title}
              onChange={handleChange}
              margin="normal"
              required
            />
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language"
                    name="language"
                    value={formData.language}
                    onChange={handleChange}
                    label="Language"
                    required
                  >
                    <MenuItem value="ENGLISH">ENGLISH</MenuItem>
                    <MenuItem value="TELUGU">TELUGU</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ padding: "10px" }}>
                <CreateEditCities
                  formData={formData}
                  handleCityChange={handleCityChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <Autocomplete
                    multiple
                    options={selectedCategory.map((category) => category.name)}
                    getOptionLabel={(option) => option}
                    value={formData.categories || []}
                    onChange={(event, newValue) => {
                      setFormData({ ...formData, categories: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Categories"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} sx={{ padding: "10px" }}>
                <CreateEditStates
                  formData={formData}
                  handleStateChange={handleStateChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  sx={{ padding: "10px" }}
                  label="Promoted Text"
                  variant="outlined"
                  name="promoted_text"
                  value={formData.promoted_text}
                  onChange={handleChange}
                  margin="normal"
                  rows={1}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  sx={{ padding: "10px" }}
                >
                  <InputLabel id="language-label">Type</InputLabel>
                  <Select
                    labelId="type"
                    id="type"
                    name="type"
                    value={formData.type}
                    onChange={handleChange}
                    label="Type"
                    required
                  >
                    <MenuItem value="REGULAR">REGULAR</MenuItem>
                    <MenuItem value="PROMOTED">PROMOTED</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>


            <TextField
              fullWidth
              label="Destination Url"
              sx={{ padding: "10px" }}
              variant="outlined"
              name="destination_url"
              value={formData.destination_url
              }
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
              required
            />

            <TextField
              fullWidth
              label="Description"
              sx={{ padding: "10px" }}
              variant="outlined"
              name="description"
              value={formData.description}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              required
            />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  sx={{ padding: "10px" }}
                  label="destrict"
                  variant="outlined"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  margin="normal"
                  rows={1}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  sx={{ padding: "10px" }}
                  label="Mandal"
                  variant="outlined"
                  name="mandal"
                  value={formData.mandal}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={1}
                />
              </Grid>
            </Grid>

            <TextField
              fullWidth
              label="village"
              sx={{ padding: "10px" }}
              variant="outlined"
              name="village"
              value={formData.village}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
            />

            <TextField
              fullWidth
              label="Youtube url"
              variant="outlined"
              sx={{ padding: "10px" }}
              name="youtube_url"
              value={formData.youtube_url}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={1}
            />

            <TextField
              fullWidth
              label="Tags"
              variant="outlined"
              sx={{ padding: "10px" }}
              onKeyPress={handleAddTag}
              margin="normal"
              placeholder="Press Enter or comma to add a tag"
            />
            <Box sx={{ padding: "10px" }}>
              <Paper
                component="ul"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px",
                  marginTop: "1px",
                }}
              >
                {formData.tags.map((tag, index) => (
                  <li key={index} style={{ listStyle: "none" }}>
                    <Chip
                      label={tag}
                      onDelete={handleDeleteTag(tag)}
                      style={{ margin: "4px" }}
                    />
                  </li>
                ))}
              </Paper>
            </Box>

            <Box container spacing={2} display="flex">
              <Box
                marginBottom="20px"
                marginTop="20px"
                sx={{ padding: "10px" }}
                item
                xs={6}
              >
                <input
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple // Enable multiple file selection
                  style={{
                    display: "none",
                  }}
                />
                <label htmlFor="images">
                  <Button variant="outlined" component="span">
                    Upload Images
                  </Button>
                </label>
                {images.length > 0 && (
                  <ul>
                    {images.map((image, index) => (
                      <li key={index}>
                        <img
                          src={
                            typeof image === "object"
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveImage(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

              <Box
                marginBottom="20px"
                sx={{ paddingLeft: "270px", paddingTop: "30px" }}
                item
                xs={6}
              >
                <input
                  type="file"
                  id="video"
                  name="video"
                  accept="video/*"
                  onChange={handleVideoChange}
                  multiple
                  style={{
                    display: "none",
                  }}
                />
                <label htmlFor="video">
                  <Button variant="outlined" component="span">
                    Upload Video
                  </Button>
                </label>
                {video.length > 0 && (
                  <ul>
                    {video.map((video, index) => (
                      <li key={index}>
                        <video
                          src={
                            typeof video === "object"
                              ? URL.createObjectURL(video)
                              : video
                          }
                          controls
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveVideo(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>
            <Box
              marginTop="55px"
              display="flex"
              justifyContent="flex-end"
              sx={{ padding: "15px" }}
            >
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsForm;
