
import React from 'react';
import { TextField, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export default function CreateCities({ formData, handleCityChange }) {
  const cities = [
    "Adilabad, TS ,", "Bhadradri Kothagudem, TS ,", "Hanumakonda, TS ,", "Hyderabad, TS ,", 
    "Jagtial, TS ,", "Jangoan, TS ,", "Jayashankar Bhoopalpally, TS ,", "Jogulamba Gadwal, TS ,", 
    "Kamareddy, TS ,", "Karimnagar, TS ,", "Khammam, TS ,", "Komaram Bheem Asifabad, TS ,", 
    "Mahabubabad, TS ,", "Mahabubnagar, TS ,", "Mancherial, TS ,", "Medak, TS ,", 
    "Medchal-Malkajgiri, TS ,", "Mulug, TS ,", "Nagarkurnool, TS ,", "Nalgonda, TS ,", 
    "Narayanpet, TS ,", "Nirmal, TS ,", "Nizamabad, TS ,", "Peddapalli, TS ,", 
    "Rajanna Sircilla, TS ,", "Rangareddy, TS ,", "Sangareddy, TS ,", "Siddipet, TS ,", 
    "Suryapet, TS ,", "Vikarabad, TS ,", "Wanaparthy, TS ,", "Warangal, TS ,", 
    "Yadadri Bhuvanagiri, TS ,", "Srikakulam, AP ,", "Parvathipuram Manyam, AP ,", 
    "Vizianagaram, AP ,", "Visakhapatnam, AP ,", "Alluri Sitharama Raju, AP ,", 
    "Anakapalli, AP ,", "Kakinada, AP ,", "East Godavari, AP ,", "Dr. B. R. Ambedkar Konaseema, AP ,", 
    "Eluru, AP ,", "West Godavari, AP ,", "Ntr, AP ,", "Krishna, AP ,", "Palnadu, AP ,", 
    "Guntur , AP ,", "Bapatla, AP ,", "Prakasam, AP ,", "Sri Potti Sriramulu Nellore, AP ,", 
    "Kurnool, AP ,", "Nandyal, AP ,", "Anantapur, AP ,", "Sri Sathya Sai, AP ,", 
    "Kadapa, AP ,", "Annamayya, AP ,", "Tirupati, AP ,", "Chittoor, AP ,", 
    "Andhra Pradesh ,", "Arunachal Pradesh ,", "Assam ,", "Bihar ,", "Chhattisgarh ,", 
    "Goa ,", "Gujarat ,", "Haryana ,", "Himachal Pradesh ,", "Jharkhand ,", 
    "Karnataka ,", "Kerala ,", "Maharashtra ,", "Madhya Pradesh ,", "Manipur ,", 
    "Meghalaya ,", "Mizoram ,", "Nagaland ,", "Odisha ,", "Punjab ,", 
    "Rajasthan ,", "Sikkim ,", "Tamil Nadu ,", "Tripura ,", "Telangana ,", 
    "Uttar Pradesh ,", "Uttarakhand ,", "West Bengal ,", "Andaman & Nicobar (UT) ,", 
    "Chandigarh (UT) ,", "Dadra & Nagar Haveli and Daman & Diu (UT) ,", 
    "Delhi [National Capital Territory (NCT)] ,", "Jammu & Kashmir (UT) ,", 
    "Ladakh (UT) ,", "Lakshadweep (UT) ,", "Puducherry (UT) ,"
  ];

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
       <Autocomplete
        multiple  // Assuming multiple selection is needed
        options={cities}
        value={formData.city ? formData.city: []} // Split if city is a string
        onChange={handleCityChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="City"
            placeholder="Select City"
          />
        )}
      />
    </FormControl>
  );
}
