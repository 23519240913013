import { Box, styled, Card, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Uploads from "./uploads";
import UploadPopup from "./UploadPopup";
import Header from "../../../components/Header";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));

const Container = styled("div")(({ theme }) => ({
  margin: "0px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));

const CreateUpload = () => {
  const [tabValue, setTabValue] = useState("videos");
  const [open, setOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function handleClickOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* <TabContext value={tabValue}> */}
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}> */}
        {/* <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Videos" value="videos" />
              <Tab label="Thumbnails" value="thumbnails" />
              <Tab label="New Tab" value="3" />
            </TabList>*/}
        {/* </Box> */}
        {/* <TabPanel value="videos"> */}
        {/* Video Uploads Tab starts here */}
        <Container>
          <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
            <CardHeader>
              <Header title="Videos" />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
              >
                Create
              </Button>
            </CardHeader>
            <Uploads source="VIDEOS" />
          </Card>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
          >
            <DialogTitle id="form-dialog-title">Create a Upload</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ mb: 1 }}>
                Please create a upload.
              </DialogContentText>
            </DialogContent>
            <UploadPopup source="VIDEOS" setOpen={setOpen} />
          </Dialog>
        </Container>
        {/* Video Uploads Tab ends here */}
        {/* </TabPanel> */}
        {/* <TabPanel value="thumbnails">
            <Container>
              <Card elevation={3} sx={{ pt: "20px", mb: 2 }}>
                <CardHeader>
                  <Header title="Thumbnails" />
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    Create
                  </Button>
                </CardHeader>
                <Uploads source="THUMBNAILS" />
              </Card>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
              >
                <DialogTitle id="form-dialog-title">
                  Create a Upload
                </DialogTitle>
                <DialogContent>
                  <DialogContentText sx={{ mb: 1 }}>
                    Please create a upload.
                  </DialogContentText>
                </DialogContent>
                <UploadPopup source="THUMBNAILS" setOpen={setOpen} />
              </Dialog>
            </Container>
          </TabPanel>
          <TabPanel value="3">New tab content comes here</TabPanel>
        </TabContext> */}
      </Box>
    </>
  );
};
export default CreateUpload;
