
import React from 'react'
import { useState } from 'react';
import VillagesTable from './villages-table';

function Villages() {
  const [filters, setFilters] = useState({
    offset: 1,
   limit: 10,
   id : null,
   
 
 });

  
  return (
    <div>
       
      <VillagesTable filters={filters} setFilters={setFilters} /></div>
  )
}

export default Villages