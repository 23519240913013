import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { UploadAddsFiles, updateAdds, getAdds } from "./ads";

const locations = [
  "Adilabad, TS",
  "Bhadradri Kothagudem, TS",
  "Hanumakonda, TS",
  "Hyderabad, TS",
  "Jagtial, TS",
  "Jangoan, TS",
  "Jayashankar Bhoopalpally, TS",
  "Jogulamba Gadwal, TS",
  "Kamareddy, TS",
  "Karimnagar, TS",
  "Khammam, TS",
  "Komaram Bheem Asifabad, TS",
  "Mahabubabad, TS",
  "Mahabubnagar, TS",
  "Mancherial, TS",
  "Medak, TS",
  "Medchal-Malkajgiri, TS",
  "Mulug, TS",
  "Nagarkurnool, TS",
  "Nalgonda, TS",
  "Narayanpet, TS",
  "Nirmal, TS",
  "Nizamabad, TS",
  "Peddapalli, TS",
  "Rajanna Sircilla, TS",
  "Rangareddy, TS",
  "Sangareddy, TS",
  "Siddipet, TS",
  "Suryapet, TS",
  "Vikarabad, TS",
  "Wanaparthy, TS",
  "Warangal, TS",
  "Yadadri Bhuvanagiri, TS",
  "Srikakulam, AP",
  "Parvathipuram Manyam, AP",
  "Vizianagaram, AP",
  "Visakhapatnam, AP",
  "Alluri Sitharama Raju, AP",
  "Anakapalli, AP",
  "Kakinada, AP",
  "East Godavari, AP",
  "Dr. B. R. Ambedkar Konaseema, AP",
  "Eluru, AP",
  "West Godavari, AP",
  "Ntr, AP",
  "Krishna, AP",
  "Palnadu, AP",
  "Guntur , AP",
  "Bapatla, AP",
  "Prakasam, AP",
  "Sri Potti Sriramulu Nellore, AP",
  "Kurnool, AP",
  "Nandyal, AP",
  "Anantapur, AP",
  "Sri Sathya Sai, AP",
  "Kadapa, AP",
  "Annamayya, AP",
  "Tirupati, AP",
  "Chittoor, AP",
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Maharashtra",
  "Madhya Pradesh",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Tripura",
  "Telangana",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman & Nicobar (UT)",
  "Chandigarh (UT)",
  "Dadra & Nagar Haveli and Daman & Diu (UT)",
  "Delhi [National Capital Territory (NCT)]",
  "Jammu & Kashmir (UT)",
  "Ladakh (UT)",
  "Lakshadweep (UT)",
  "Puducherry (UT)",
];

const EditAdds = ({ onFormSubmit }) => {
  const params = useParams();
  const { id } = params;
  useEffect(() => {
    const getData = async () => {
      let data = await getAdds(1, 10, id);
      console.log("Fetched ads  data:", data);
      if (data && data.info && data.info.length > 0) {
        data = data.info[0];

        setFormData({
          mobile_number: data?.mobile_number,
          whatsapp_number: data?.whatsapp_number,
          destination_url: data?.destination_url,
          display_type: data?.display_type,
          action_title: data?.action_title,
          view_type: data?.view_type,
          expiration_date: data?.expiration_date,
          ad_id: data?.id,
          sponsor_name: data?.sponsor_name,
          title: data?.title,
          ad_description: data?.ad_description,
          description: data?.description,
          status: data?.status,
          location: data?.location,
          position: data?.position,
        });
        setImages(data?.images ? data?.images : []);
        setVideo(data?.videos ? data?.videos : []);
      }
    };

    getData();
  }, [id]);

  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const [formData, setFormData] = useState({
    mobile_number: "",
    whatsapp_number: "",
    destination_url: "",
    display_type: "",
    action_title: "",
    view_type: "",
    expiration_date: "",
    sponsor_name: "",
    title: "",
    ad_description: "",
    description: "",
    status: "",
    ad_id: id,
    location: [],
    position: "",
  });

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleRemoveVideo = (indexToRemove) => {
    setVideo(video.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const UpdateAndSaveAds = async (data, images, videos) => {
    try {
      const newImages = images.filter((item) => typeof item === "object");
      const newVideos = videos.filter((item) => typeof item === "object");
      const oldImages = images.filter((item) => typeof item === "string");
      const oldVideos = videos.filter((item) => typeof item === "string");

      // Upload new images
      let imagesResponse;
      if (newImages.length > 0) {
        const imagesFormData = new FormData();
        newImages.forEach((file, index) => {
          imagesFormData.append(`files`, file);
        });
        imagesFormData.append("entity", data.entity);
        imagesResponse = await UploadAddsFiles(imagesFormData);
      } else {
        imagesResponse = { success: true, info: null };
      }

      // Upload new videos
      let videosResponse;
      if (newVideos.length > 0) {
        const videosFormData = new FormData();
        newVideos.forEach((file, index) => {
          videosFormData.append(`files`, file);
        });
        videosFormData.append("entity", data.entity);
        videosResponse = await UploadAddsFiles(videosFormData);
      } else {
        videosResponse = { success: true, info: null };
      }

      if (imagesResponse.success && videosResponse.success) {
        data.images = oldImages.concat(imagesResponse.info);
        data.videos = oldVideos.concat(videosResponse.info);
        const formResponse = await updateAdds(data);
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UpdateAndSaveAds(formData, images, video);
      if (apiResponse && apiResponse.success) {
        onFormSubmit(navigate("/dhunia_bo/ads"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const getObjectURL = (file) => {
    if (file instanceof Blob) {
      return URL.createObjectURL(file);
    }
    return null;
  };

  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/ads");
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const handleLocationChange = (event, value) => {
    const selectedLocations = value ? value : [];
    setFormData((prevData) => ({
      ...prevData,
      location: selectedLocations,
    }));
  };

  const handleVideoChange = (event) => {
    const selectedVideos = Array.from(event.target.files);
    setVideo(selectedVideos);
  };
  const handle_status_Change = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const resetForm = () => {
    setFormData({
      mobile_number: "",
      whatsapp_number: "",
      destination_url: "",
      display_type: "",
      action_title: "",
      view_type: "",
      expiration_date: "",
      sponsor_name: "",
      title: "",
      ad_description: "",
      description: "",
      status: "",
      ad_id: id,
      location: [],
      position: "",
    });
    setImages([]);
    setVideo([]);
  };

  return (
    <Box sx={{ backgroundColor: "#A7E6FF" }}>
      <Box
        width="100%"
        maxWidth="900px"
        marginLeft="5rem"
        justifyContent="center"
        alignItems="center"
        padding="30px"
      >
        <Box
          style={{ boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)" }}
          sx={{ backgroundColor: "#fff", borderRadius: "8px" }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize="30px"
            marginBottom="15px"
            align="center"
            color="#007effa6"
            sx={{ marginTop: "10px" }}
          >
            EDIT ADS
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Sponsor Name :
                </Typography>
                <TextField
                  name="sponsor_name"
                  aria-label="Sponsor Name"
                  value={formData.sponsor_name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Title :
                </Typography>
                <TextField
                  name="title"
                  aria-label="Title"
                  value={formData.title}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Status :
                </Typography>
                <TextField
                  select
                  name="status"
                  value={formData.status}
                  onChange={handle_status_Change}
                  fullWidth
                  required
                >
                  <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                  <MenuItem value="INACTIVE">INACTIVE</MenuItem>
                  <MenuItem value="DELETED">DELETED</MenuItem>
                  <MenuItem value="PENDING">PENDING</MenuItem>
                  <MenuItem value="CLASSIFIED">CLASSIFIED</MenuItem>
                  <MenuItem value="EXPIRED">EXPIRED</MenuItem>
                </TextField>
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Location :
                </Typography>
                <Autocomplete
                  multiple
                  value={formData.location}
                  options={locations}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions
                  onChange={handleLocationChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Locations"
                      fullWidth
                    />
                  )}
                />
              </Box>
            </Box>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  WhatsApp Number :
                </Typography>
                <TextField
                  name="whatsapp_number"
                  aria-label="WhatsApp Number"
                  value={formData.whatsapp_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Mobile Number :
                </Typography>
                <TextField
                  name="mobile_number"
                  aria-label="Mobile Number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Destination URL :
                </Typography>
                <TextField
                  name="destination_url"
                  aria-label="Destination URL"
                  value={formData.destination_url}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Action Title :
                </Typography>
                <TextField
                  name="action_title"
                  aria-label="Action Title"
                  value={formData.action_title}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Box>
            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  View Type :
                </Typography>
                <TextField
                  name="view_type"
                  aria-label="View Type"
                  value={formData.view_type}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
              <Box style={{ flex: "50%", padding: "10px" }}>
                <Typography variant="srOnly" fontWeight="bold">
                  Display Type :
                </Typography>
                <TextField
                  name="display_type"
                  aria-label="Display Type"
                  value={formData.display_type}
                  onChange={handleChange}
                  fullWidth
                />
              </Box>
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="position"
                  label="Position"
                  type="number"
                  value={formData.position}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>

              <Box style={{ flex: "50%", padding: "10px" }}>
                <TextField
                  name="expiration_date"
                  label="Expiration Date"
                  type="date"
                  value={formData.expiration_date}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Box>
            </Box>

            <Box style={{ flex: "50%", padding: "10px" }}>
              <Typography variant="srOnly" fontWeight="bold">
                Description :
              </Typography>
              <TextField
                name="description"
                aria-label="Description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
              />
            </Box>
            <Box marginBottom="20px" padding="10px">
              <Typography variant="srOnly" fontWeight="bold">
                Ad Description :
              </Typography>
              <TextField
                name="ad_description"
                aria-label="Ad Description"
                value={formData.ad_description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={3}
              />
            </Box>

            <Box display="flex" marginBottom="20px">
              <Box marginRight="10px" style={{ flex: "50%", padding: "10px" }}>
                <input
                  type="file"
                  id="images"
                  name="images"
                  accept="image/*"
                  onChange={handleImageChange}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="images">
                  <Button variant="outlined" component="span">
                    Upload Images
                  </Button>
                </label>
                {images.length > 0 && (
                  <ul>
                    {images.map((image, index) => (
                      <li key={index}>
                        <img
                          src={
                            typeof image === "object"
                              ? URL.createObjectURL(image)
                              : image
                          }
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveImage(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>

              <Box style={{ flex: "50%", padding: "10px" }}>
                <input
                  type="file"
                  id="video"
                  name="videos"
                  accept="video/*"
                  onChange={handleVideoChange}
                  multiple
                  style={{ display: "none" }}
                />
                <label htmlFor="video">
                  <Button variant="outlined" component="span">
                    Upload Video
                  </Button>
                </label>
                {video.length > 0 && (
                  <ul>
                    {video.map((video, index) => (
                      <li key={index}>
                        <video
                          src={
                            typeof video === "object"
                              ? URL.createObjectURL(video)
                              : video
                          }
                          controls
                          alt={`Preview ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <Button onClick={() => handleRemoveVideo(index)}>
                          Remove
                        </Button>
                      </li>
                    ))}
                  </ul>
                )}
              </Box>
            </Box>

            <Box
              marginTop="55px"
              display="flex"
              justifyContent="flex-end"
              style={{ padding: "10px" }}
            >
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default EditAdds;
