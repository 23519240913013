









import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { createVillages, getMandalsData } from "./villages";
import { getDistrict } from "../mandals/mandal";

const VillageForm = () => {
  const navigate = useNavigate();
  const [selectedState, setSelectedState] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedMandal, setSelectedMandal] = useState([]);
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    mandal: "",
    village_name: "",
  });

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states`
        );
        setSelectedState(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    fetchStates();
  }, []);

  useEffect(() => {
    async function fetchDistricts() {
      try {
        const selectedStateId = formData.state;
        if (!selectedStateId) {
          return;
        }
        console.log("Fetching districts for state:", selectedStateId); // Debug log
        const data = await getDistrict(undefined, undefined, undefined, selectedStateId);
        setSelectedDistrict(data.info);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    }
    fetchDistricts();
  }, [formData.state]);



  useEffect(() => {
    async function fetchMandals() {
      try {
        const selectedDistrictId = formData.district;
        if (!selectedDistrictId) {
          return;
        }
        console.log("Fetching mandals for district:", selectedDistrictId); // Debug log
        const data = await getMandalsData(undefined, undefined, undefined, selectedDistrictId);
        setSelectedMandal(data.info);
      } catch (error) {
        console.error("Error fetching mandals:", error);
      }
    }
    fetchMandals();
  }, [formData.district]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const mandalId = formData.mandal;
      console.log("Submitting form with mandal ID:", mandalId); // Debug log
      const apiResponse = await createVillages(formData, mandalId);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        resetForm();
        navigate("/dhunia_bo/locations-villages");
      } else {
        const errorMessage = apiResponse && apiResponse.message ? apiResponse.message : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/locations-villages");
  };

  const resetForm = () => {
    setFormData({
      state: "",
      district: "",
      mandal: "",
      village_name: "",
    });
  };

  return (
    <Box 
    
    display= "flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    sx={{backgroundColor : "#A7E6FF"}}
    >

<Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >

      <Typography variant="h5" fontWeight="bold" marginBottom="20px"  align="center"
          color="#007eff">
        ADD VILLAGE
      </Typography>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          options={selectedState}
          getOptionLabel={(option) => option.state_name || ""}
          value={selectedState.find((state) => state.id === formData.state) || null}
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              state: newValue ? newValue.id : "",
              district: "",
              mandal: "",
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />

        <Autocomplete
          options={selectedDistrict}
          getOptionLabel={(option) => option.district_name || ""}
          value={selectedDistrict.find((district) => district.id === formData.district) || null}
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              district: newValue ? newValue.id : "",
              mandal: "",
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="District"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />

        <Autocomplete
          options={selectedMandal}
          getOptionLabel={(option) => option.mandal_name || ""}
          value={selectedMandal.find((mandal) => mandal.id === formData.mandal) || null}
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              mandal: newValue ? newValue.id : "",
            }));
            console.log("Selected Mandal:", newValue); // Debugging line
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Mandal"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />

        <TextField
          fullWidth
          label="Village"
          variant="outlined"
          name="village_name"
          value={formData.village_name}
          onChange={handleChange}
          margin="normal"
          required
        />
       <Box display="flex" justifyContent="flex-end" gap={2} paddingTop="20px">
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
      </form>
      </Box>
    </Box>
  );
};

export default VillageForm;
