import * as React from "react";
import { Box, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { tokens } from "../../theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Moment from "react-moment";
import EditCategory from "./editCategory";
import { getCategoryData } from "../../services/category";
import { category_columns } from "../../utils/constants";
import DeleteCategory from "./DeleteCategory";
export default function Categories({ permissions }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [categoriesList, setCategoryList] = useState([]);
  const [categoryPayload, setCategoryPayload] = useState({});
  const [openEdit, setEditOpen] = useState(false);
  const [openDelete, setDeleteOpen] = useState(false);
  useEffect(() => {
    getCategoryData(setCategoryList);
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleStatusChange = (event) => {
    // console.log("clicked: ", event);
  };
  /**
   * The function `handleEditCategory` filters the `categoriesList` array to find the category with the
   * matching `id` as the `event.target.id`, and if found, sets the `categoryPayload` state to the
   * selected category and opens the edit modal.
   * @returns nothing (undefined) if the condition `!event.target.id` is true.
   */
  const handleEditCategory = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedCategory = categoriesList.filter((category) => {
      if (category.id.toString() === event.target.id) {
        return true;
      }
      return false;
    });
    if (selectedCategory) {
      setCategoryPayload(selectedCategory[0]);
      setEditOpen(true);
    }
  };
  const handleDeleteCategory = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedCategory = categoriesList.filter((category) => {
      if (category.id.toString() === event.target.id) {
        return true;
      }
      return false;
    });
    if (selectedCategory) {
      setCategoryPayload(selectedCategory[0]);
      setDeleteOpen(true);
    }
  };
  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.format) {
      case "image":
        return (
          <img
            alt="category_image"
            src={value}
            style={{ width: 50, height: 50 }}
          />
        );
      case "boolean":
        return (
          <Switch
            id={row.id.toString()}
            checked={value}
            onChange={handleStatusChange}
          />
        );
      case "actions":
        return permissions?.write_newscategories_permission ? (
          <>
            <ModeIcon
              id={row.id.toString()}
              onClick={handleEditCategory}
              style={{ margin: "10px" }}
            />
            <DeleteForeverIcon
              id={row.id.toString()}
              onClick={handleDeleteCategory}
              style={{ margin: "10px" }}
            />
          </>
        ) : (
          <></>
        );
      case "Date":
        return <Moment format="YYYY/MM/DD hh:mm ">{value}</Moment>;
      default:
        return value;
    }
  };
  return (
    <>
      <Box
        m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {category_columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {categoriesList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id}>
                        {category_columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderContent(row, column)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={categoriesList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {openEdit && (
        <EditCategory
          openEdit={openEdit}
          setEditOpen={setEditOpen}
          categoryPayload={categoryPayload}
          getCategoryData={getCategoryData}
          setCategoryList={setCategoryList}
        />
      )}
      {openDelete && (
        <DeleteCategory
          openDelete={openDelete}
          setDeleteOpen={setDeleteOpen}
          categoryPayload={categoryPayload}
          getCategoryData={getCategoryData}
          setCategoryList={setCategoryList}
        />
      )}
    </>
  );
}
