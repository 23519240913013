













import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { createMandals } from "./mandal";
import { getDistrict } from "./mandal";

const MandalForm = () => {
  const navigate = useNavigate();
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [formData, setFormData] = useState({
    state: "",
    district: "",
    mandal_name: "",
  });

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states`
        );
        setSelectedStates(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    fetchStates();
  }, []);

  useEffect(() => {
    async function fetchDistricts(stateId) {
      try {
        const response = await getDistrict(1, 33, null, stateId);
        console.log(response.info, "districts of particular states")
        setSelectedDistricts(response.info);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    }

    if (formData.state) {
      fetchDistricts(formData.state);
    }
  }, [formData.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await createMandals(formData, formData.district);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        navigate("/dhunia_bo/locations-mandals");
      } else {
        const errorMessage = apiResponse?.message || "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const handleCancel = () => {
    resetForm();
    navigate("/dhunia_bo/locations-mandals");
  };
  const resetForm = () => {
    setFormData({
      state: "",
    district: "",
    mandal_name: "",
  });
    
  };

  return (
    <Box display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    sx={{ backgroundColor: "#A7E6FF" }}
    >

<Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >

      <Typography variant="h5" fontWeight="bold" marginBottom="20px" align="center"
          color="#007eff">
        ADD MANDAL
      </Typography>
      <form onSubmit={handleSubmit}>
        <Autocomplete
          options={selectedStates}
          getOptionLabel={(option) => option.state_name || ""}
          value={selectedStates.find((state) => state.id === formData.state) || null}
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              state: newValue ? newValue.id : "",
              district: "", // Reset district when state changes
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />

        <Autocomplete
          options={selectedDistricts}
          getOptionLabel={(option) => option.district_name || ""}
          value={
            selectedDistricts.find((district) => district.id === formData.district) ||
            null
          }
          onChange={(event, newValue) => {
            setFormData((prevData) => ({
              ...prevData,
              district: newValue ? newValue.id : "",
            }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="District"
              variant="outlined"
              margin="normal"
              required
            />
          )}
        />

        <TextField
          fullWidth
          label="Mandal"
          variant="outlined"
          name="mandal_name"
          value={formData.mandal_name}
          onChange={handleChange}
          margin="normal"
          required
        />
     <Box display="flex" justifyContent="flex-end" gap={2} paddingTop="20px">
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
      </form>
      </Box>
    </Box>
  );
};

export default MandalForm;
