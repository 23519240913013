import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteCategoryData } from "../../services/category";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DeleteCategory({
  openDelete,
  setDeleteOpen,
  categoryPayload,
  getCategoryData,
  setCategoryList,
}) {
  const handleClose = () => {
    setDeleteOpen(false);
  };
  const handleDeleteSubmission = async () => {
    const json_payload = {
      category_id: categoryPayload.id,
    };
    deleteCategoryData(json_payload);
    setDeleteOpen(false);
    getCategoryData(setCategoryList);
  };
  return (
    <>
      <Dialog
        open={openDelete}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
      >
        <DialogTitle color="red">
          <b>{"Delete Confirmation"}</b>
        </DialogTitle>
        <DialogContent>
          <Alert variant="outlined" severity="error">
            <DialogContentText
              id="alert-dialog-slide-description"
              color="black"
            >
              Are You Sure You Want To Delete?
            </DialogContentText>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            startIcon={<ClearIcon />}
            variant="contained"
            // color="#e0e0e0"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteSubmission}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
