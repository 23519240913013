// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import ReactQuill from "react-quill"; 
// import "react-quill/dist/quill.snow.css"; 

// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { getNewsList } from "../../../services/news";
// import { PushNotification } from "./notification";

// const NotificationForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const [formData, setFormData] = useState({
//     news_id: id,
//     title: "", 
//     include_image: false,
//   });

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getNewsList(1, 10, id);
//       data = data.info[0];

//       setFormData((prevData) => ({
//         ...prevData,
//         news_id: data?.id,
//         title: data?.title || "", 
//       }));
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTitleChange = (value) => {
//     // Handle title change
//     setFormData((prevData) => ({
//       ...prevData,
//       title: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Convert color codes from RGB to hexadecimal
//       const htmlContent = formData.title
//         .replace(/<p>/g, "") // Remove <p> tags
//         .replace(/<\/p>/g, "") // Remove </p> tags
//         .replace(/rgb\((\d+), (\d+), (\d+)\)/g, (match, r, g, b) => {
//           return (
//             "#" +
//             ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
//               .toString(16)
//               .slice(1)
//           );
          
//         });

//       // Send the constructed HTML content

//       const notificationResponse = await PushNotification({
//         ...formData,
//         title: `<html>${htmlContent}</html>`,
//       });

//       if (notificationResponse && notificationResponse.success) {
//         alert("Data submitted successfully and notification sent");
//         onFormSubmit(navigate("/dhunia_bo/posts"));
//       } else {
//         const errorMessage =
//           notificationResponse && notificationResponse.message
//             ? notificationResponse.message
//             : "Unknown error occurred while sending notification";
//         console.error(errorMessage);
//         alert("Error in sending notification");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       include_image: false,
//     });
//     navigate("/dhunia_bo/posts");
//   };

//   const modules = {
//     toolbar: [
//       [{ font: [] }],
//       [{ size: [] }],
//       [{ header: "1" }, { header: "2" }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ color: [] }, { background: [] }], 
//       [{ list: "ordered" }, { list: "bullet" }],
//       [{ indent: "-1" }, { indent: "+1" }],
//       [{ align: [] }],
//       ["link", "image"],
//       ["clean"],
//     ],
//   };

//   return (
//     <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
//       <Typography variant="h3" fontWeight="bold" marginBottom="20px">
//         PUSH NOTIFICATION
//       </Typography>
//       <form>
//         <ReactQuill 
//           theme="snow"
//           value={formData.title}
//           onChange={handleTitleChange}
//           modules={modules}
//           style={{ height: "100px" }}
//         />
//         <FormControl fullWidth margin="normal" style={{ marginTop: "100px" }}>
//           <InputLabel htmlFor="include-images">Include Images</InputLabel>
//           <Select
//             id="include_image"
//             name="include_image"
//             value={formData.include_image}
//             onChange={handleChange}
//             label="Include Images"
//           >
//             <MenuItem value={true}>True</MenuItem>
//             <MenuItem value={false}>False</MenuItem>
//           </Select>
//         </FormControl>

//         <Box marginTop="10px" display="flex" justifyContent="flex-end">
//           <Button
//             type="button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//           >
//             Send
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default NotificationForm;


//============================================



// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import ReactQuill from "react-quill"; 
// import "react-quill/dist/quill.snow.css"; 

// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { getNewsList } from "../../../services/news";
// import { PushNotification } from "./notification";

// const NotificationForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const [formData, setFormData] = useState({
//     news_id: id,
//     title: "", 
//     include_image: false,
//   });

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getNewsList(1, 10, id);
//       data = data.info[0];

//       setFormData((prevData) => ({
//         ...prevData,
//         news_id: data?.id,
//         title: data?.title || "", 
//       }));
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTitleChange = (value) => {
//     // Handle title change
//     setFormData((prevData) => ({
//       ...prevData,
//       title: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Convert color codes from RGB to hexadecimal
//       const htmlContent = formData.title
//         .replace(/<p>/g, "") // Remove <p> tags
//         .replace(/<\/p>/g, "") // Remove </p> tags
//         .replace(/rgb\((\d+), (\d+), (\d+)\)/g, (match, r, g, b) => {
//           return (
//             "#" +
//             ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
//               .toString(16)
//               .slice(1)
//           );
          
//         });

//       // Send the constructed HTML content

//       const notificationResponse = await PushNotification({
//         ...formData,
//         title: `<html>${htmlContent}</html>`,
//       });

//       console.log(htmlContent)

//       if (notificationResponse && notificationResponse.success) {
//         alert("Data submitted successfully and notification sent");
//         onFormSubmit("/dhunia_bo/posts"); // Use onFormSubmit here
//       } else {
//         const errorMessage =
//           notificationResponse && notificationResponse.message
//             ? notificationResponse.message
//             : "Unknown error occurred while sending notification";
//         console.error(errorMessage);
//         alert("Error in sending notification");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleCancel = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       include_image: false,
//     });
//     navigate("/dhunia_bo/posts");
//   };

//   const modules = {
//     toolbar: [
//       [{ font: [] }],
//       [{ size: [] }],
//       [{ header: "1" }, { header: "2" }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ color: [] }, { background: [] }], 
//       [{ list: "ordered" }, { list: "bullet" }],
//       [{ indent: "-1" }, { indent: "+1" }],
//       [{ align: [] }],
//       ["link", "image"],
//       ["clean"],
//     ],
//   };

//   return (
//     <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
//       <Typography variant="h3" fontWeight="bold" marginBottom="20px">
//         PUSH NOTIFICATION
//       </Typography>
//       <form>
//         <ReactQuill 
//           theme="snow"
//           value={formData.title}
//           onChange={handleTitleChange}
//           modules={modules}
//           style={{ height: "100px" }}
//         />
//         <FormControl fullWidth margin="normal" style={{ marginTop: "100px" }}>
//           <InputLabel htmlFor="include-images">Include Images</InputLabel>
//           <Select
//             id="include_image"
//             name="include_image"
//             value={formData.include_image}
//             onChange={handleChange}
//             label="Include Images"
//           >
//             <MenuItem value={true}>True</MenuItem>
//             <MenuItem value={false}>False</MenuItem>
//           </Select>
//         </FormControl>

//         <Box marginTop="10px" display="flex" justifyContent="flex-end">
//           <Button
//             type="button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//           >
//             Send
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>
//     </Box>
//   );
// };

// export default NotificationForm;










//===========================================


import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ReactQuill from "react-quill"; 
import "react-quill/dist/quill.snow.css"; 

import { useNavigate, useParams } from "react-router-dom";
import { getNewsList } from "../../../services/news";
import { PushNotification } from "./notification";

const NotificationForm = ({ onFormSubmit }) => {
  const { id } = useParams();

  const [formData, setFormData] = useState({
    news_id: id,
    title: "", 
    include_image: false,
  });

  useEffect(() => {
    const getData = async () => {
      let data = await getNewsList(1, 10, id);
      data = data.info[0];

      setFormData((prevData) => ({
        ...prevData,
        news_id: data?.id,
        title: data?.title || "", 
      }));
    };
    getData();
  }, [id]);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "true" ? true : value === "false" ? false : value,
    }));
  };

  const handleTitleChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      title: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const htmlContent = formData.title
        .replace(/<p>/g, "")
        .replace(/<\/p>/g, "")
        .replace(/rgb\((\d+), (\d+), (\d+)\)/g, (match, r, g, b) => {
          return (
            "#" +
            ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
              .toString(16)
              .slice(1)
          );
        });

      const notificationResponse = await PushNotification({
        ...formData,
        title: `<html>${htmlContent}</html>`,
      });

      if (notificationResponse && notificationResponse.success) {
        alert("Data submitted successfully and notification sent");
        console.log('Notification response:', notificationResponse);
        onFormSubmit("/dhunia_bo/posts");
      } else {
        const errorMessage = notificationResponse?.description || "Unknown error occurred while sending notification";
        console.error(errorMessage);
        alert("Error in sending notification: " + errorMessage);
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      alert("Error submitting form data: " + error.message);
    }
  };

  const handleCancel = () => {
    setFormData({
      news_id: id,
      title: "",
      include_image: false,
    });
    navigate("/dhunia_bo/posts");
  };

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      [{ header: "1" }, { header: "2" }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ color: [] }, { background: [] }], 
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
      <Typography variant="h3" fontWeight="bold" marginBottom="20px">
        PUSH NOTIFICATION
      </Typography>
      <form>
        <ReactQuill 
          theme="snow"
          value={formData.title}
          onChange={handleTitleChange}
          modules={modules}
          style={{ height: "100px" }}
        />
        <FormControl fullWidth margin="normal" style={{ marginTop: "100px" }}>
          <InputLabel htmlFor="include-image">Include Images</InputLabel>
          <Select
            id="include_image"
            name="include_image"
            value={formData.include_image}
            onChange={handleChange}
            label="Include Images"
          >
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </Select>
        </FormControl>

        <Box marginTop="10px" display="flex" justifyContent="flex-end">
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Send
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default NotificationForm;





// //==============================



// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import ReactQuill from "react-quill"; 
// import "react-quill/dist/quill.snow.css"; 

// import { useNavigate } from "react-router-dom";
// import { useParams } from "react-router-dom";
// import { getNewsList } from "../../../services/news";
// import { PushNotification } from "./notification";

// const NotificationForm = ({ onFormSubmit }) => {
//   const params = useParams();
//   const { id } = params;

//   const [formData, setFormData] = useState({
//     news_id: id,
//     title: "", 
//     include_image: false,
//   });
//   const [open, setOpen] = useState(false); // State to control modal visibility
//   const [finalData, setFinalData] = useState(null); // State to store final data

//   useEffect(() => {
//     const getData = async () => {
//       let data = await getNewsList(1, 10, id);
//       data = data.info[0];

//       setFormData((prevData) => ({
//         ...prevData,
//         news_id: data?.id,
//         title: data?.title || "", 
//       }));
//     };
//     getData();
//   }, [id]);

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleTitleChange = (value) => {
//     // Handle title change
//     setFormData((prevData) => ({
//       ...prevData,
//       title: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       // Convert color codes from RGB to hexadecimal
//       const htmlContent = formData.title
//         .replace(/<p>/g, "") // Remove <p> tags
//         .replace(/<\/p>/g, "") // Remove </p> tags
//         .replace(/rgb\((\d+), (\d+), (\d+)\)/g, (match, r, g, b) => {
//           return (
//             "#" +
//             ((1 << 24) + (parseInt(r) << 16) + (parseInt(g) << 8) + parseInt(b))
//               .toString(16)
//               .slice(1)
//           );
//         });

//       // Final data to be submitted
//       const finalData = {
//         ...formData,
//         title: `<html>${htmlContent}</html>`
//       };

//       // Set the final data and open the modal
//       setFinalData(finalData);
//       setOpen(true);
      
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     }
//   };

//   const handleConfirmSubmit = async () => {
//     try {
//       const notificationResponse = await PushNotification(finalData);

//       if (notificationResponse && notificationResponse.success) {
//         alert("Data submitted successfully and notification sent");
//         onFormSubmit(navigate("/dhunia_bo/posts"));
//       } else {
//         const errorMessage =
//           notificationResponse && notificationResponse.message
//             ? notificationResponse.message
//             : "Unknown error occurred while sending notification";
//         console.error(errorMessage);
//         alert("Error in sending notification");
//       }
//     } catch (error) {
//       console.error("Error submitting form data:", error);
//     } finally {
//       setOpen(false);
//     }
//   };

//   const handleCancel = () => {
//     setFormData({
//       news_id: id,
//       title: "",
//       include_image: false,
//     });
//     navigate("/dhunia_bo/posts");
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const modules = {
//     toolbar: [
//       [{ font: [] }],
//       [{ size: [] }],
//       [{ header: "1" }, { header: "2" }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ color: [] }, { background: [] }], 
//       [{ list: "ordered" }, { list: "bullet" }],
//       [{ indent: "-1" }, { indent: "+1" }],
//       [{ align: [] }],
//       ["link", "image"],
//       ["clean"],
//     ],
//   };

//   return (
//     <Box marginRight="20px" marginLeft="20px" marginBottom="20px">
//       <Typography variant="h3" fontWeight="bold" marginBottom="20px">
//         PUSH NOTIFICATION
//       </Typography>
//       <form>
//         <ReactQuill 
//           theme="snow"
//           value={formData.title}
//           onChange={handleTitleChange}
//           modules={modules}
//           style={{ height: "100px" }}
//         />
//         <FormControl fullWidth margin="normal" style={{ marginTop: "100px" }}>
//           <InputLabel htmlFor="include-images">Include Images</InputLabel>
//           <Select
//             id="include_image"
//             name="include_image"
//             value={formData.include_image}
//             onChange={handleChange}
//             label="Include Images"
//           >
//             <MenuItem value={true}>True</MenuItem>
//             <MenuItem value={false}>False</MenuItem>
//           </Select>
//         </FormControl>

//         <Box marginTop="10px" display="flex" justifyContent="flex-end">
//           <Button
//             type="button"
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//           >
//             Send
//           </Button>
//           <Button
//             variant="outlined"
//             color="secondary"
//             onClick={handleCancel}
//             style={{ marginLeft: "10px" }}
//           >
//             Cancel
//           </Button>
//         </Box>
//       </form>

//       {/* Modal for displaying the final data */}
//       <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
//         <DialogTitle><h1>Final Submitted Data :</h1></DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             <div dangerouslySetInnerHTML={{ __html: formData.title }} />
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleConfirmSubmit} color="primary">
//             Confirm
//           </Button>
//           <Button onClick={handleClose} color="secondary">
//             Cancel
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default NotificationForm;
