// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   Select,
//   MenuItem,
//   TableCell,
//   TablePagination,
//   TableRow,
// } from "@mui/material";
// import axios from "axios";
// import { getVillages, deleteVillage, getMandalsData } from "./villages"; // Assuming getMandalsData is defined here
// import DeleteIcon from "@mui/icons-material/Delete";

// function VillagesTable({ filters, setFilters }) {
//   const navigate = useNavigate();
//   const [VillageList, setVillageList] = useState(null);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedDistricts, setSelectedDistricts] = useState([]);
//   const [selectedMandals, setSelectedMandals] = useState([]);

//   useEffect(() => {
//     async function getVillagesDetails() {
//       const data = await getVillages(filters.id, filters.offset, filters.limit, filters.mandalId);
//       setVillageList(data);
//       console.log(data, "villages list");
//     }
//     getVillagesDetails();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage + 1,
//     });
//   };

//   useEffect(() => {
//     const fetchStates = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states`
//         );
//         setSelectedStates(response.data.info);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     };
//     fetchStates();
//   }, []);

//   useEffect(() => {
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
//         );
//         setSelectedDistricts(response.data.info);
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };
//     if (filters.stateId) {
//       fetchDistricts();
//     }
//   }, [filters.stateId]);

//   useEffect(() => {
//     const fetchMandals = async () => {
//       try {
//         const response = await getMandalsData(undefined, undefined, undefined, filters.districtId);
//         setSelectedMandals(response.info);
//       } catch (error) {
//         console.error("Error fetching mandals:", error);
//       }
//     };
//     if (filters.districtId) {
//       fetchMandals();
//     }
//   }, [filters.districtId]);

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-villages-create-form");
//   };

//   const handleStateChange = (event) => {
//     const stateId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       stateId,
//       districtId: null,
//       mandalId: null,
//       offset: 1,
//     }));
//   };

//   const handleDistrictChange = (event) => {
//     const districtId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       districtId,
//       mandalId: null,
//       offset: 1,
//     }));
//   };

//   const handleMandalChange = (event) => {
//     const mandalId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       mandalId,
//       offset: 1,
//     }));
//   };

//   const handleDeleteVillage = async (id) => {
//     try {
//       await deleteVillage(id);
//       const response = await getVillages(filters.stateId, filters.offset, filters.limit, filters.mandalId);
//       setVillageList(response);
//     } catch (error) {
//       console.error("Error deleting village:", error);
//     }
//   };

//   return (
//     <Box m="20px">
//         <Typography variant="h3" fontWeight="bold">
//           VILLAGES TABLE
//         </Typography>
//         <h4>*Please Select State and District and Mandal to view Villages </h4>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         marginBottom="10px"
//       >

//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.stateId || ""}
//             onChange={handleStateChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select State" }}
//             style={{ marginRight: "10px", width: "200px" }}
//           >
//             <MenuItem value="">
//               <em>All States</em>
//             </MenuItem>
//             {selectedStates.map((state) => (
//               <MenuItem key={state.id} value={state.id}>
//                 {state.state_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.districtId || ""}
//             onChange={handleDistrictChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select District" }}
//             style={{ marginRight: "10px", width: "200px" }}
//             disabled={!filters.stateId}
//           >
//             <MenuItem value="">
//               <em>All Districts</em>
//             </MenuItem>
//             {selectedDistricts.map((district) => (
//               <MenuItem key={district.id} value={district.id}>
//                 {district.district_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.mandalId || ""}
//             onChange={handleMandalChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select Mandal" }}
//             style={{ marginRight: "10px", width: "200px" }}
//             disabled={!filters.districtId}
//           >
//             <MenuItem value="">
//               <em>All Mandals</em>
//             </MenuItem>
//             {selectedMandals.map((mandal) => (
//               <MenuItem key={mandal.id} value={mandal.id}>
//                 {mandal.mandal_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>

//         <Button
//           variant="contained"
//           color="primary"
//           onClick={handleCreateClick}
//         >
//           Create
//         </Button>
//       </Box>

//       <Paper sx={{ width: "100%", overflow: "hidden" }}>
//         <Table stickyHeader aria-label="sticky table">
//           <TableBody>
//             {VillageList?.info.map((row, index) => (
//               <TableRow hover key={row.id}>
//                 <TableCell align="center">{index + 1}</TableCell>
//                 <TableCell align="center">
//                   <Typography>{row.village_name}</Typography>
//                 </TableCell>
//                 <TableCell align="center">
//                   <DeleteIcon
//                     id={row.id.toString()}
//                     onClick={() => handleDeleteVillage(row.id)}
//                     style={{ margin: "10px", cursor: "pointer" }}
//                   />
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>

//         <TablePagination
//           component="div"
//           onPageChange={handlePageChange}
//           page={filters.offset - 1}
//           count={VillageList?.total ? VillageList.total : 0}
//           rowsPerPage={filters.limit}
//           style={{ backgroundColor: "#007effa6" }}
//         />
//       </Paper>
//     </Box>
//   );
// }

// export default VillagesTable;


//==================================================================================================



// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   Select,
//   MenuItem,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   TableHead,
// } from "@mui/material";
// import axios from "axios";
// import { getVillages, deleteVillage, getMandalsData } from "./villages"; // Assuming getMandalsData is defined here
// import DeleteIcon from "@mui/icons-material/Delete";

// function VillagesTable({ filters, setFilters }) {
//   const navigate = useNavigate();
//   const [VillageList, setVillageList] = useState(null);
//   const [selectedStates, setSelectedStates] = useState([]);
//   const [selectedDistricts, setSelectedDistricts] = useState([]);
//   const [selectedMandals, setSelectedMandals] = useState([]);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [villageToDelete, setVillageToDelete] = useState(null);

//   useEffect(() => {
//     async function getVillagesDetails() {
//       const data = await getVillages(
//         filters.id,
//         filters.offset,
//         filters.limit,
//         filters.mandalId
//       );
//       setVillageList(data);
//       console.log(data, "villages list");
//     }
//     getVillagesDetails();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage + 1,
//     });
//   };

//   useEffect(() => {
//     const fetchStates = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states`
//         );
//         setSelectedStates(response.data.info);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     };
//     fetchStates();
//   }, []);

//   useEffect(() => {
//     const fetchDistricts = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
//         );
//         setSelectedDistricts(response.data.info);
//       } catch (error) {
//         console.error("Error fetching districts:", error);
//       }
//     };
//     if (filters.stateId) {
//       fetchDistricts();
//     }
//   }, [filters.stateId]);

//   useEffect(() => {
//     const fetchMandals = async () => {
//       try {
//         const response = await getMandalsData(
//           undefined,
//           undefined,
//           undefined,
//           filters.districtId
//         );
//         setSelectedMandals(response.info);
//       } catch (error) {
//         console.error("Error fetching mandals:", error);
//       }
//     };
//     if (filters.districtId) {
//       fetchMandals();
//     }
//   }, [filters.districtId]);

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-villages-create-form");
//   };

//   const handleStateChange = (event) => {
//     const stateId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       stateId,
//       districtId: null,
//       mandalId: null,
//       offset: 1,
//     }));
//   };

//   const handleDistrictChange = (event) => {
//     const districtId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       districtId,
//       mandalId: null,
//       offset: 1,
//     }));
//   };

//   const handleMandalChange = (event) => {
//     const mandalId = event.target.value;
//     setFilters((prev) => ({
//       ...prev,
//       mandalId,
//       offset: 1,
//     }));
//   };

//   const handleDeleteIconClick = (id) => {
//     setVillageToDelete(id);
//     setOpenDelete(true);
//   };

//   const handleDeleteVillage = async () => {
//     try {
//       await deleteVillage(villageToDelete);
//       setOpenDelete(false);
//       setVillageToDelete(null);
//       const response = await getVillages(
//         filters.stateId,
//         filters.offset,
//         filters.limit,
//         filters.mandalId
//       );
//       setVillageList(response);
//     } catch (error) {
//       console.error("Error deleting village:", error);
//     }
//   };

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//     setVillageToDelete(null);
//   };

//   return (
//     <Box m="20px">
//       <Typography variant="h3" fontWeight="bold">
//         VILLAGES TABLE
//       </Typography>
//       <h4>*Please Select State, District, and Mandal to view Villages </h4>
//       <Box
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         marginBottom="10px"
//       >
//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.stateId || ""}
//             onChange={handleStateChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select State" }}
//             style={{ marginRight: "10px", width: "200px" }}
//           >
//             <MenuItem value="">
//               <em>All States</em>
//             </MenuItem>
//             {selectedStates.map((state) => (
//               <MenuItem key={state.id} value={state.id}>
//                 {state.state_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.districtId || ""}
//             onChange={handleDistrictChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select District" }}
//             style={{ marginRight: "10px", width: "200px" }}
//             disabled={!filters.stateId}
//           >
//             <MenuItem value="">
//               <em>All Districts</em>
//             </MenuItem>
//             {selectedDistricts.map((district) => (
//               <MenuItem key={district.id} value={district.id}>
//                 {district.district_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>
//         <Box display="flex" alignItems="center">
//           <Select
//             value={filters.mandalId || ""}
//             onChange={handleMandalChange}
//             displayEmpty
//             inputProps={{ "aria-label": "Select Mandal" }}
//             style={{ marginRight: "10px", width: "200px" }}
//             disabled={!filters.districtId}
//           >
//             <MenuItem value="">
//               <em>All Mandals</em>
//             </MenuItem>
//             {selectedMandals.map((mandal) => (
//               <MenuItem key={mandal.id} value={mandal.id}>
//                 {mandal.mandal_name}
//               </MenuItem>
//             ))}
//           </Select>
//         </Box>

//         <Button
//             variant="contained"
//             style={{ backgroundColor: "#007effa6", color: "white" }}
//             onClick={() => navigate("/dhunia_bo/locations-villages-create-form")}
//           >
//             Create
//           </Button>
//       </Box>

//       <Paper  sx={{
//             width: "100%",
//             overflow: "hidden",
//             boxShadow: 3,
//             borderRadius: "15px",
//             backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
//       }}
//       >
//         <Table stickyHeader aria-label="sticky table"
//          sx={{
//           '& .MuiTableCell-root': {
//             border: '1px solid #e0e0e0',
//             padding: '8px',
//           },
//         }}
//         >
//           <TableHead  >
//               <TableRow   sx={{ backgroundColor: "#007effa6",  '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>#</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>Village Name</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//           <TableBody>
//             {VillageList?.info.map((row, index) => (
//               <TableRow hover key={row.id}
//               sx={{
//                 '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
//                 '&:hover': { backgroundColor: '#e0f7fa' },
//               }}
//               >
//                 <TableCell align="center">{index + 1}</TableCell>
//                 <TableCell align="center">
//                   <Typography>{row.village_name}</Typography>
//                 </TableCell>
//                 <TableCell align="center">
//                   <DeleteIcon
//                     id={row.id.toString()}
//                     onClick={() => handleDeleteIconClick(row.id)}
//                     style={{ margin: "10px", cursor: "pointer" }}
//                     sx={{
//                       margin: "10px",
//                       marginLeft :"40px",
//                       cursor: "pointer",
//                       color: "#f50057",
//                       "&:hover": { color: "#ff4081" },
//                     }}
//                   />
//                 </TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>

//         <TablePagination
//           component="div"
//           onPageChange={handlePageChange}
//           page={filters.offset - 1}
//           count={VillageList?.total ? VillageList.total : 0}
//           rowsPerPage={filters.limit}
//           style={{ backgroundColor: "#007effa6", color: "black" }}
//         />
//       </Paper>

//       <Dialog
//         open={openDelete}
//         onClose={handleCloseDelete}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Are you sure you want to delete this village?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button
//             onClick={handleCloseDelete}
//             sx={{
//               color: "white",
//               backgroundColor: "#318CE7",
//               "&:hover": {
//                 backgroundColor: "#6CB4EE",
//               },
//             }}
//           >
//             Cancel
//           </Button>
//           <Button
//             onClick={handleDeleteVillage}
//             sx={{
//               color: "white",
//               backgroundColor: "red",
//               "&:hover": {
//                 backgroundColor: "lightcoral",
//               },
//             }}
//             autoFocus
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// }

// export default VillagesTable;




import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  Select,
  MenuItem,
  TableCell,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TableHead,
} from "@mui/material";
import axios from "axios";
import { getVillages, deleteVillage, getMandalsData } from "./villages"; // Assuming getMandalsData is defined here
import DeleteIcon from "@mui/icons-material/Delete";

function VillagesTable({ filters, setFilters }) {
  const navigate = useNavigate();
  const [VillageList, setVillageList] = useState(null);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [selectedMandals, setSelectedMandals] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [villageToDelete, setVillageToDelete] = useState(null);

  useEffect(() => {
    async function getVillagesDetails() {
      const data = await getVillages(
        filters.id,
        filters.offset,
        filters.limit,
        filters.mandalId
      );
      setVillageList(data);
      console.log(data, "villages list");
    }
    getVillagesDetails();
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setFilters({
      ...filters,
      offset: newPage + 1,
    });
  };

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states`
        );
        setSelectedStates(response.data.info);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASEURL}userservice/location/states/districts?state_id=${filters.stateId}`
        );
        setSelectedDistricts(response.data.info);
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };
    if (filters.stateId) {
      fetchDistricts();
    }
  }, [filters.stateId]);

  useEffect(() => {
    const fetchMandals = async () => {
      try {
        const response = await getMandalsData(
          undefined,
          undefined,
          undefined,
          filters.districtId
        );
        setSelectedMandals(response.info);
      } catch (error) {
        console.error("Error fetching mandals:", error);
      }
    };
    if (filters.districtId) {
      fetchMandals();
    }
  }, [filters.districtId]);

  const handleCreateClick = () => {
    navigate("/dhunia_bo/locations-villages-create-form");
  };

  const handleStateChange = (event) => {
    const stateId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      stateId,
      districtId: null,
      mandalId: null,
      offset: 1,
    }));
  };

  const handleDistrictChange = (event) => {
    const districtId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      districtId,
      mandalId: null,
      offset: 1,
    }));
  };

  const handleMandalChange = (event) => {
    const mandalId = event.target.value;
    setFilters((prev) => ({
      ...prev,
      mandalId,
      offset: 1,
    }));
  };

  const handleDeleteIconClick = (id) => {
    setVillageToDelete(id);
    setOpenDelete(true);
  };

  const handleDeleteVillage = async () => {
    try {
      await deleteVillage(villageToDelete);
      setOpenDelete(false);
      setVillageToDelete(null);
      const response = await getVillages(
        filters.stateId,
        filters.offset,
        filters.limit,
        filters.mandalId
      );
      setVillageList(response);
    } catch (error) {
      console.error("Error deleting village:", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setVillageToDelete(null);
  };

  return (
    <Box m="20px">
      <Typography variant="h3" fontWeight="bold"
       sx={{
        background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
      >
        VILLAGES TABLE
      </Typography>
      <h4>*Please Select State, District, and Mandal to view Villages </h4>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="10px"
      >
        <Box display="flex" alignItems="center">
          <Select
            value={filters.stateId || ""}
            onChange={handleStateChange}
            displayEmpty
            inputProps={{ "aria-label": "Select State" }}
            style={{ marginRight: "10px", width: "200px" }}
          >
            <MenuItem value="">
              <em>All States</em>
            </MenuItem>
            {selectedStates.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.state_name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" alignItems="center">
          <Select
            value={filters.districtId || ""}
            onChange={handleDistrictChange}
            displayEmpty
            inputProps={{ "aria-label": "Select District" }}
            style={{ marginRight: "10px", width: "200px" }}
            disabled={!filters.stateId}
          >
            <MenuItem value="">
              <em>All Districts</em>
            </MenuItem>
            {selectedDistricts.map((district) => (
              <MenuItem key={district.id} value={district.id}>
                {district.district_name}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box display="flex" alignItems="center">
          <Select
            value={filters.mandalId || ""}
            onChange={handleMandalChange}
            displayEmpty
            inputProps={{ "aria-label": "Select Mandal" }}
            style={{ marginRight: "10px", width: "200px" }}
            disabled={!filters.districtId}
          >
            <MenuItem value="">
              <em>All Mandals</em>
            </MenuItem>
            {selectedMandals.map((mandal) => (
              <MenuItem key={mandal.id} value={mandal.id}>
                {mandal.mandal_name}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Button
            variant="contained"
            style={{ backgroundColor: "#007effa6", color: "white" }}
            onClick={() => navigate("/dhunia_bo/locations-villages-create-form")}
          >
            Create
          </Button>
      </Box>

      <Paper  sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 3,
            borderRadius: "15px",
            backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
      }}
      >
        <Table stickyHeader aria-label="sticky table"
         sx={{
          '& .MuiTableCell-root': {
            border: '1px solid #e0e0e0',
            padding: '8px',
          },
        }}
        >
          <TableHead  >
              <TableRow   sx={{ backgroundColor: "#007effa6",  '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>#</strong>
                </TableCell>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>Village Name</strong>
                </TableCell>
                <TableCell align="center"
                 sx={{ backgroundColor: "#007effa6", color: "black" }}
                 >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
            {VillageList?.info.map((row, index) => (
              <TableRow hover key={row.id}
              sx={{
                '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                '&:hover': { backgroundColor: '#e0f7fa' },
              }}
              >
                <TableCell align="center">{(filters.offset - 1) * filters.limit + index + 1}</TableCell>
                <TableCell align="center">
                  <Typography>{row.village_name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <DeleteIcon
                    id={row.id.toString()}
                    onClick={() => handleDeleteIconClick(row.id)}
                    style={{ margin: "10px", cursor: "pointer" }}
                    sx={{
                      margin: "10px",
                      marginLeft :"40px",
                      cursor: "pointer",
                      color: "#f50057",
                      "&:hover": { color: "#ff4081" },
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={filters.offset - 1}
          count={VillageList?.total ? VillageList.total : 0}
          rowsPerPage={filters.limit}
          style={{ backgroundColor: "#007effa6", color: "black" }}
        />
      </Paper>

      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this village?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            sx={{
              color: "white",
              backgroundColor: "#318CE7",
              "&:hover": {
                backgroundColor: "#6CB4EE",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteVillage}
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:hover": {
                backgroundColor: "lightcoral",
              },
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default VillagesTable;
