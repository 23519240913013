import * as React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Moment from "react-moment";
import { movieMaker_columns } from "../../../utils/constants";
import { getMovieMakersData } from "../../../services/movieMakers";
import Button from "@mui/material/Button";
const MovieMakersList = ({
  section,
  handleEditOpen,
  handleActionOpen,
  permissions,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [movieMakersListData, setMovieMakersList] = useState([]);
  // const [reporterPayload, setReporterPayload] = useState({});
  useEffect(() => {
    getMovieMakersData(section, setMovieMakersList);
    // other way is
    // const response = getReportersData();
    // setReportersList(response)
  }, [section]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleButtonClick = (event) => {
    handleActionOpen({ id: event.target.id, action: event.target.value });
  };
  // const handleEditReporter = (event) => {
  //   if (!event.target.id) {
  //     return;
  //   }
  //   const selectedReporter = movieMakersListData.filter((reporter) => {
  //     if (reporter.id.toString() === event.target.id) {
  //       return true;
  //     }
  //   });
  //   if (selectedReporter) {
  //     handleEditOpen(selectedReporter[0]);
  //     setReporterPayload(selectedReporter[0]);
  //   }
  // };
  const renderContent = (row, column) => {
    const value = row[column.id];
    if (column.id === "production_house_name") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="logo"
            src={row["logo"]}
            style={{
              width: 40,
              height: 40,
              marginRight: "5px",
              borderRadius: "20px",
            }}
          />{" "}
          <span>{value}</span>
        </div>
      );
    } else if (column.id === "languages") {
      return value.join(", ");
    } else if (column.id === "interactions") {
      return row?.likes_count
        ? Number(row?.likes_count)
        : 0 + row?.shares_count
        ? Number(row?.shares_count)
        : 0 + row?.comments_count
        ? Number(row?.comments_count)
        : 0 + row?.whatsapp_shares_count
        ? Number(row?.whatsapp_shares_count)
        : 0;
    }
    switch (column.format) {
      case "image":
        return (
          <img
            alt="reporter_pic"
            src={value}
            style={{ width: 50, height: 50 }}
          />
        );
      case "boolean":
        return <Switch id={row.id.toString()} checked={value} />;
      case "Date":
        return <Moment format="YYYY/MM/DD hh:mm ">{value}</Moment>;
      case "actions":
        let buttons = [];
        switch (section) {
          case "PENDING":
            buttons = [
              { text: "APPROVE", color: "success", value: "APPROVED" },
              { text: "REJECT", color: "error", value: "REJECTED" },
            ];
            break;
          case "APPROVED":
            buttons = [{ text: "BLOCK", color: "error", value: "BLOCKED" }];
            break;
          case "REJECTED":
            buttons = [
              { text: "APPROVE", color: "success", value: "APPROVED" },
            ];
            break;
          case "BLOCKED":
            buttons = [
              { text: "UNBLOCK", color: "success", value: "APPROVED" },
            ];
            break;
          default:
            break;
        }
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {permissions?.write_newsreporters_permission &&
                buttons.map((buttonObj) => {
                  return (
                    <Button
                      id={row.id.toString()}
                      key={row.id.toString() + buttonObj.text}
                      variant="contained"
                      color={buttonObj.color}
                      onClick={handleButtonClick}
                      sx={{ width: "60%", margin: "2px" }}
                      value={buttonObj.value}
                    >
                      {buttonObj.text}
                    </Button>
                  );
                })}
              {/* <Button
                variant="contained"
                color="error"
                onClick={handleButtonClick}
                sx={{ width: "60%", margin: "2px" }}
              >
                REJECT
              </Button> */}
            </div>
          </div>
        );
      default:
        return value;
    }
  };
  return (
    <>
      <Box
        //   m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {movieMaker_columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {movieMakersListData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id}>
                        {movieMaker_columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderContent(row, column)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={movieMakersListData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};
export default MovieMakersList;
