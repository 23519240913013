import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  getUploadData,
  getMovieMakersData,
} from "../../../services/ott_movies";
function ConfirmationDialogRaw(props) {
  const {
    onClose,
    value: valueProp,
    open,
    propName,
    handleDataChange,
    ...other
  } = props;
  const [value, setValue] = useState(valueProp);
  const [movies, setMovies] = useState([]);
  const [offset, setOffset] = useState(0);
  const radioGroupRef = useRef(null);
  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);
  useEffect(() => {
    if (propName === "Movie Maker") {
      getMovieMakersData(offset).then((videos) => {
        videos.forEach((video) => {
          video.name = video.production_house_name;
          return video;
        });
        setMovies(videos);
      });
    } else {
      getUploadData("VIDEOS", offset).then((videos) => {
        // console.log("videos ", videos);
        setMovies(videos);
      });
    }
  }, [offset]);
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const handleCancel = () => {
    onClose();
  };
  const handleOk = () => {
    onClose(value);
  };
  const prevOffset = () => {
    offset > 0 ? setOffset(offset - 1) : setOffset(0);
  };
  const nextOffset = () => {
    setOffset(offset + 1);
  };
  const handleChange = (event) => {
    // console.log(event);
    setValue(event.target.value);
    const movie_data = {
      id: event?.target?.labels?.[0].id,
      value: event?.target?.value,
    };
    // console.log(movie_data);
    handleDataChange(movie_data);
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>Select {propName}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label={propName}
          name={propName}
          value={value}
          onChange={handleChange}
        >
          {movies.map((option) => (
            <FormControlLabel
              value={option.name}
              key={option.id}
              id={option.id}
              control={<Radio />}
              label={option.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button onClick={prevOffset}>Prev</Button>
          <Button onClick={nextOffset}>Next</Button>
        </div>
        <div>
          <Button autoFocus onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};
export default function ConfirmationDialog({
  propName,
  handleDataChange,
  selectedMovie,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(
    selectedMovie?.value ? selectedMovie.value : ""
  );
  const handleClickListItem = () => {
    setOpen(true);
  };
  const handleClose = (newValue) => {
    setOpen(false);
    if (newValue) {
      setValue(newValue);
    }
  };
  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <List component="div" role="group">
        <ListItemButton
          divider
          aria-haspopup="true"
          aria-controls={`${propName}-menu`}
          onClick={handleClickListItem}
        >
          <ListItemText primary={`Select ${propName}`} secondary={value} />
        </ListItemButton>
        <ConfirmationDialogRaw
          id={`${propName}-menu`}
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          propName={propName}
          handleDataChange={handleDataChange}
        />
      </List>
    </Box>
  );
}
