import * as React from "react";
import { Box, styled, Card, Button, useTheme } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import Moment from "react-moment";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { roles_columns } from "../../utils/constants";
import { getRolesData } from "../../services/roles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Permissions from "./permissions";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import InputBase from "@mui/material/InputBase";
// import Button from "@mui/material/Button";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const RolesList = ({ section, handleActionOpen, permissions }) => {
  // const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [reportersListData, setReportersList] = React.useState([]);
  const [openPermissions, setOpenPermissions] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  // const [reporterPayload, setReporterPayload] = useState({});
  useEffect(() => {
    getRolesData(section, setReportersList);
    // other way is
    // const response = getReportersData();
    // setReportersList(response)
  }, [section]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleButtonClick = (event) => {
    handleActionOpen({ id: event.target.id, action: event.target.value });
  };
  function handleClose() {
    setOpenPermissions(false);
  }
  const renderContent = (row, column) => {
    const value = row[column.id];
    if (column.id === "username") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="profile_image"
            src={row["profile_image"]}
            style={{
              width: 40,
              height: 40,
              marginRight: "5px",
              borderRadius: "20px",
            }}
          />{" "}
          <span>{value}</span>
        </div>
      );
    } else if (column.id === "languages") {
      return value.join(", ");
    } else if (column.id === "interactions") {
      return row?.likes_count
        ? Number(row?.likes_count)
        : 0 + row?.shares_count
        ? Number(row?.shares_count)
        : 0 + row?.comments_count
        ? Number(row?.comments_count)
        : 0 + row?.whatsapp_shares_count
        ? Number(row?.whatsapp_shares_count)
        : 0;
    } else if (column.id === "mobile") {
      return (
        // <a href="" onClick={redirectPermissions}>
        //   {value}
        // </a>
        <Button
          onClick={() => {
            setOpenPermissions(true);
            setMobile(value);
          }}
        >
          {value}
        </Button>
      );
    }
    switch (column.format) {
      case "image":
        return (
          <img
            alt="profile_image"
            src={value}
            style={{ width: 50, height: 50 }}
          />
        );
      default:
        return value;
    }
  };
  return (
    <>
      <Box
        margin="1%"
        maxWidth="250px"
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase
          sx={{ ml: 2, flex: 1 }}
          placeholder="Search Mobile Number"
          id="user_mobile"
        />
        <IconButton
          type="button"
          sx={{ p: 1 }}
          onClick={() => {
            const mobile_value = document.getElementById("user_mobile").value;
            setOpenPermissions(true);
            setMobile(mobile_value);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <Box
        //   m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {roles_columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reportersListData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow hover key={row.id}>
                        {roles_columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {renderContent(row, column)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={reportersListData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Dialog
        open={openPermissions}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="lg"
      >
        <Permissions mobile={mobile} setOpenPermissions={setOpenPermissions} />
      </Dialog>
    </>
  );
};
const RolesTab = ({ section, handleActionOpen, permissions }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rolesListData, setRolesList] = useState([]);
  const [value, setValue] = useState("BO-ADMIN");
  // const [reporterPayload, setReporterPayload] = useState({});
  useEffect(() => {
    getRolesData(section, setRolesList);
    // other way is
    // const response = getReportersData();
    // setReportersList(response)
  }, [section]);
  const handleTabChange = (event, newValue) => {
    // console.log(newValue);
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Card elevation={3} sx={{ pt: "20px" }}>
          {/* <CardHeader>
            <Header title="REPORTERS" />
          </CardHeader> */}
          {/* {permissions.read_newsreporters_permission && ( */}
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider", padding: 0 }}>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                {/* <Tab label="ENDUSER" value="ENDUSER" /> */}
                <Tab label="BO-ADMIN" value="BO-ADMIN" />
                <Tab label="BO-USER" value="BO-USER" />
                <Tab label="LISTING-ADMIN" value="LISTING-ADMIN" />
                <Tab label="NEWS-ADMIN" value="NEWS-ADMIN" />
                <Tab label="REPORTER" value="REPORTER" />
                {/* <Tab label="Deleted" value="Deleted" /> */}
              </TabList>
            </Box>
            {/* <TabPanel value="ENDUSER" sx={{ padding: 0 }}>
              <RolesList section={value} permissions={permissions} />
            </TabPanel> */}
            <TabPanel value="REPORTER" sx={{ padding: 0 }}>
              {" "}
              <RolesList section={value} permissions={permissions} />
            </TabPanel>
            <TabPanel value="NEWS-ADMIN" sx={{ padding: 0 }}>
              {" "}
              <RolesList section={value} permissions={permissions} />
            </TabPanel>
            <TabPanel value="LISTING-ADMIN" sx={{ padding: 0 }}>
              {" "}
              <RolesList section={value} permissions={permissions} />
            </TabPanel>
            <TabPanel value="BO-USER" sx={{ padding: 0 }}>
              {" "}
              <RolesList section={value} permissions={permissions} />
            </TabPanel>
            <TabPanel value="BO-ADMIN" sx={{ padding: 0 }}>
              {" "}
              <RolesList section={value} permissions={permissions} />
            </TabPanel>
          </TabContext>
          {/* )} */}
        </Card>
      </Box>
    </>
  );
};
export default RolesTab;
