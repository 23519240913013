export const category_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  { id: "name", label: "Name", minWidth: 100 },
  {
    id: "icon",
    label: "Icon",
    format: "image",
    minWidth: 100,
  },
  {
    id: "is_active",
    label: "Active",
    format: "boolean",
    minWidth: 100,
  },
  {
    id: "created_by",
    label: "Created By",
    minWidth: 100,
  },
  {
    id: "created_date",
    label: "Creation Date",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const reporter_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  {
    id: "reporter_name",
    label: "Image & Name",
    minWidth: 100,
  },
  {
    id: "created_by",
    label: "Mobile",
    minWidth: 100,
  },
  { id: "about", label: "About", minWidth: 100 },
  { id: "languages", label: "Languages", minWidth: 50 },
  {
    id: "created_date",
    label: "Since",
    minWidth: 100,
    format: "Date",
  },
  // { id: "rank", label: "Rank", minWidth: 30 },
  { id: "submitted_news_count", label: "Submitted News", minWidth: 100 },
  { id: "approved_news_count", label: "Approved News", minWidth: 100 },
  // { id: "views_count", label: "Views", minWidth: 100 },
  // { id: "interactions", label: "Interactions", minWidth: 100 },
  {
    id: "is_active",
    label: "Active",
    format: "boolean",
    minWidth: 100,
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const movieMaker_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  // {
  //   id: "logo",
  //   label: "Logo",
  //   minWidth: 100,
  // },
  {
    id: "production_house_name",
    label: "Production House Name",
    minWidth: 100,
  },
  {
    id: "created_by",
    label: "Mobile",
    minWidth: 100,
  },
  { id: "languages", label: "Languages", minWidth: 50 },
  {
    id: "created_date",
    label: "Since",
    minWidth: 100,
    format: "Date",
  },
  { id: "about", label: "About", minWidth: 30 },
  { id: "live_videos", label: "live_videos", minWidth: 30 },
  { id: "total_videos", label: "total_videos", minWidth: 100 },
  { id: "reviews_count", label: "Reviews Count", minWidth: 100 },
  { id: "ad_views_count", label: "Ad Views Count", minWidth: 100 },
  { id: "views_count", label: "Views", minWidth: 100 },
  { id: "interactions", label: "Interactions", minWidth: 100 },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const roles_columns = [
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  // {
  //   id: "profile_image",
  //   label: "Profile Image",
  //   minWidth: 100,
  // },
  {
    id: "username",
    label: "User Name",
  },
  { id: "mobile", label: "mobile" },
  { id: "gender", label: "gender", minWidth: 10 },
  { id: "earnings", label: "earnings", minWidth: 10 },
  { id: "total_earnings", label: "total_earnings", minWidth: 10 },
];
export const Other_classified_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  {
    id: "title",
    label: "Title",
    minWidth: 100,
  },
  {
    id: "location",
    label: "Location",
    minWidth: 100,
  },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "whatsapp", label: "whatsapp", minWidth: 50 },
  { id: "description", label: "Description", minWidth: 50 },
  { id: "other_details", label: "Other Details", minWidth: 50 },
  { id: "real_estate_type", label: "Real Estate Type", minWidth: 50 },
  { id: "listing_type", label: "Listing Type", minWidth: 50 },
  { id: "contact_name", label: "Contact Type", minWidth: 50 },
  { id: "mobile", label: "mobile", minWidth: 50 },
  {
    id: "created_date",
    label: "Since",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "is_active",
    label: "Active",
    format: "boolean",
    minWidth: 100,
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const realEstate_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  {
    id: "title",
    label: "Title",
    minWidth: 100,
  },
  {
    id: "location",
    label: "Location",
    minWidth: 100,
  },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "whatsapp", label: "whatsapp", minWidth: 50 },
  { id: "description", label: "Description", minWidth: 50 },
  { id: "other_details", label: "Other Details", minWidth: 50 },
  { id: "classified_type", label: "Classified Type", minWidth: 50 },
  { id: "real_estate_type", label: "Real Estate Type", minWidth: 50 },
  { id: "listing_type", label: "Listing Type", minWidth: 50 },
  { id: "contact_name", label: "Contact Type", minWidth: 50 },
  { id: "mobile", label: "mobile", minWidth: 50 },
  {
    id: "created_date",
    label: "Since",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "is_active",
    label: "Active",
    format: "boolean",
    minWidth: 100,
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];

//addnews colums
export const addnews_columns = [
  { id: "id", label: "Id", minWidth: 20 },
  {
    id: "image",
    label: "News Image",
    minWidth: 100,
  },
  {
    id: "title",
    label: "News Title",
    minWidth: 100,
  },
  {
    id: "description",
    label: "News Description",
    minWidth: 100,
  },
];
export const banners_colums = [
  {
    id: "image",
    lable: "Image",
    minWidth: 100,
  },
  {
    id: "active",
    lable: "Active",
    minWidth: 100,
  },
  {
    id: "description",
    lable: "Description",
    minWidth: 100,
  },
];
export const adds_columns = [
  {
    id: "images",
    lable: "Images",
    minWidth: 100,
  },
  {
    id: "mobile_number",
    lable: "Mobile Number",
    minWidth: 100,
  },
  {
    id: "whatsapp_number",
    lable: "Watsapp Number",
    minWidth: 100,
  },
  {
    id: "view_type",
    lable: "View Type",
    minWidth: 100,
  },
]

//listings
export const listColums_colums = [
  {
    id:"category_image",
    label: "Image",
    minWidth : 100
  },
  {
    id:"category_name",
    label: "Category Name",
    minWidth : 100
  },
  
]


//listings sub categories
export const subcategory_colums = [
  {
    id:"sub_category_image",
    label: "Image",
    minWidth : 100
  },
  {
    id:"sub_category_name",
    label: "Category Name",
    minWidth : 100
  },
  
]


export const job_columns = [
  // { id: "id", label: "ID", minWidth: 20 },
  {
    id: "created_date",
    label: "Since",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "is_active",
    label: "Active",
    format: "boolean",
    minWidth: 100,
  },
  // { id: "user_master_id", label: "user_master_id", minWidth: 100 },
  { id: "job_location", label: "Job Location", minWidth: 50 },
  { id: "job_category", label: "Job Category", minWidth: 50 },
  { id: "job_role", label: "Job Role", minWidth: 100 },
  {
    id: "job_title",
    label: "Job Title",
    minWidth: 100,
  },
  {
    id: "job_type",
    label: "Job Type",
    minWidth: 100,
  },
  // { id: "job_description", label: "Job Description", minWidth: 50 },
  { id: "min_experience", label: "Min Experience", minWidth: 50 },
  { id: "max_experience", label: "Max Experience", minWidth: 50 },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const upload_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  { id: "name", label: "Name", minWidth: 100 },
  {
    id: "upload_status",
    label: "Upload Status",
    minWidth: 100,
  },
  {
    id: "conversion_resolutions",
    label: "Resolutions",
    format: "array",
    minWidth: 100,
  },
  {
    id: "created_by",
    label: "Created By",
    minWidth: 100,
  },
  {
    id: "created_date",
    label: "Creation Date",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
];
export const movie_columns = [
  { id: "id", label: "ID", minWidth: 20 },
  { id: "title", label: "Name", minWidth: 100 },
  // {
  //   id: "synopsis",
  //   label: "Synopsis",
  //   minWidth: 100,
  // },
  {
    id: "language",
    label: "Language",
    minWidth: 100,
  },
  {
    id: "movie_id",
    label: "movie_id",
    minWidth: 100,
  },
  {
    id: "trailer_id",
    label: "trailer_id",
    minWidth: 100,
  },
  {
    id: "created_by",
    label: "Created By",
    minWidth: 100,
  },
  {
    id: "created_date",
    label: "Creation Date",
    minWidth: 100,
    format: "Date",
  },
  {
    id: "actions",
    label: "Actions",
    format: "actions",
    minWidth: 100,
  },
  // {
  //   id: "timings",
  //   label :" Timings",
  //   minWidth : 100,
  //   }
];
export const permissions_columns = [
  // { id: "all", label: "All", minWidth: 100 },
  { id: "read", label: "Read", minWidth: 100 },
  { id: "write", label: "Write", minWidth: 100 },
  { id: "update", label: "Update", minWidth: 100 },
  { id: "delete", label: "Delete", minWidth: 100 },
];
export const permissions_rows = [
  { id: "newsmaster", label: "News Master", minWidth: 100 },
  { id: "newsreporters", label: "News Reporters", minWidth: 100 },
  { id: "classifieds", label: "Classifieds", minWidth: 100 },
  { id: "listing_master", label: "Listing Master", minWidth: 100 },
  { id: "bo_newsreporters", label: "BO News Reporters", minWidth: 100 },
  { id: "bo_jobmaster", label: "BO Job Master", minWidth: 100 },
  { id: "bo_newsmaster", label: "BO News Master", minWidth: 100 },
  { id: "bo_newscategories", label: "BO News Categories", minWidth: 100 },
];
export const global_permisssions = [
  // { id: "is_enduser", label: "Is Enduser", minWidth: 100 },
  { id: "is_reporter", label: "Is Reporter", minWidth: 100 },
  { id: "is_newsadmin", label: "Is News Admin", minWidth: 100 },
  { id: "is_admin", label: "Is Admin", minWidth: 100 },
  { id: "is_listingadmin", label: "Is Listing Admin", minWidth: 100 },
  // { id: "is_bo_user", label: "Is BO User", minWidth: 100 },
  // { id: "is_bo_admin", label: "Is BO Admin", minWidth: 100 },
];
export const global_roles = [
  "ENDUSER",
  "REPORTER",
  "NEWS-ADMIN",
  "SYSTEM-ADMIN",
  "LISTING-ADMIN",
];
