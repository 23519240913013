
import axios from "axios";

export const createForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {

    Authorization: token,
    "content-type": "application/json",
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}backoffice/news/posts`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

export const updateForm = async (formData) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASEURL}backoffice/news/posts`,
      formData,
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};


export const UploadFiles = async (formData, withUpload = true) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  try {
    let config = {};
    if (withUpload) {
      config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
    }
    if (token) {
      axios.defaults.headers.common.Authorization = token;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_BASEURL}userservice/store/files`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return { success: false, message: "Failed to submit form data" };
  }
};

// In your services/news.js file

// categories

// import axios from "axios";

export const getCategoriesList = async (offset = 1, limit = 10, ) => {  
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };
  let response;

    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}news/categories?&offset=${offset}&limit=${limit}`,
      {
        headers,
      }
    );
  

 
  if (response?.data?.success) {
    console.log(response.data,"data")
    return response.data;
  }
}





// export const getAllDistrict = async (offset , limit , id, ) => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   const params = {
//     offset,
//     limit, 
//     id,
    
//   };

//   let response;

//   try {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//       {
//         headers,
//         params,
//       }
//     );
//   } catch (error) {
//     console.error('Error fetching districts:', error);
//     return null;
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }

//   return null;
// };




// export const getAllDistrict = async () => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   let response;

//   try {
//     response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//       {
//         headers,
//       }
//     );
//   } catch (error) {
//     console.error('Error fetching districts:', error);
//     return null;
//   }

//   if (response?.data?.success) {
//     return response.data;
//   }

//   return null;
// };


// export  const getAllDistrict = async () => {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;

//   const headers = {
//     Authorization: token,
//   };

//   try {
//     const response = await axios.get(
//       `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
//       { headers }
//     );

//     if (response.data.success) {
//       console.log(response.data.districts , "all districts ")
//       return response.data.districts; // Assuming districts are directly under response.data
//     } else {
//       console.error("Error fetching districts:", response.data.message);
//       return []; // Return empty array if no districts or error
//     }
//   } catch (error) {
//     console.error("Error fetching districts:", error);
//     return []; // Return empty array if there's an error
//   }
// };



export const getAllDistrict = async () => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts`,
      { headers }
    );

    console.log("Full response from districts API:", response); // Log the full response

    if (response.data.success) {
      console.log("Fetched districts:", response.data); // Log the fetched districts
      return response.data.districts; // Assuming districts are directly under response.data
    } else {
      console.error("Error fetching districts:", response.data.message);
      return [];
    }
  } catch (error) {
    console.error("Error fetching districts:", error);
    return [];
  }
};




















export const getAllMandals = async (offset = 1, limit = 10, id = null, districtId = null) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;

  const headers = {
    Authorization: token,
  };

  let response;
  if (id) {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?district_id=${districtId}`,
      {
        headers,
      }
    );
  } else {
    response = await axios.get(
      `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals?offset=${offset}&limit=${limit}`,
      {
        headers,
        params: {
          district_id: districtId,
        },
      }
    );
  }

  if (response?.data?.success) {
    return response.data;
  }
  return null;
};


export const getAllVillages = async (stateId, offset, limit, mandalId) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
    "content-type": "application/json",
  };

  let url = `${process.env.REACT_APP_BASEURL}userservice/location/states/districts/mandals/villages?offset=${offset}&limit=${limit}`;

  if (stateId) {
    url += `&state_id=${stateId}`;
  }

  if (mandalId) {
    url += `&mandal_id=${mandalId}`;
  }

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error("Error fetching villages:", error);
    return { success: false, message: "Failed to fetch villages" };
  }
};

 

