import React from 'react';
import { useNavigate } from 'react-router-dom';
import EditForm from './editform';

const EditPage = () => {
  const navigate = useNavigate();

  const handleFormSubmit = (redirectPath) => {
    navigate(redirectPath);
  };

  return (
    <div>
      <EditForm onFormSubmit={handleFormSubmit} />
    </div>
  );
};

export default EditPage;
