import { styled, Button } from "@mui/material";
import React from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DialogContentText from "@mui/material/DialogContentText";
import { uploadImages } from "../../../services/jobs";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImageUploader = ({
  file,
  setFile,
  thumbnailFiles,
  setThumbnailFiles,
}) => {
  async function uploadSingleFile(e) {
    e.preventDefault();
    const formData = new FormData();
    for (const element of e.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "jobs");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      let ImagesArray = uploadResponse?.info;
      file ? setFile([...file, ...ImagesArray]) : setFile([...ImagesArray]);
      setThumbnailFiles(e.target.files);
    }
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }
  return (
    <form>
      <div className="form-group preview" style={{ display: "flex" }}>
        {file &&
          file.length > 0 &&
          file.map((item, index) => {
            return (
              <div key={item}>
                <img src={item} alt="" height={100} width={100} />
                <button type="button" onClick={() => deleteFile(index)}>
                  delete
                </button>
              </div>
            );
          })}
      </div>
      <div className="form-group">
        <DialogContentText sx={{ mb: 1 }}>
          Upload Thumbnail images.
        </DialogContentText>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            onChange={uploadSingleFile}
            multiple
          />
        </Button>
      </div>
    </form>
  );
};
export default ImageUploader;
