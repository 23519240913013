// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   MenuItem,
//   IconButton,
//   Delete,
//   EmailOutlined,
//   Select,
// } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// import DeleteIcon from "@mui/icons-material/Delete";

// import { Edit } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { EditSubCategories } from "./edit";

// import { subcategory_colums } from "../../../utils/constants";

// import { getListingsSubCategories , deleteSubCategories } from "./subcategories";
// import SubCategoriesForm from "./form";

// function ListingsSubcategoriesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [listingsListData, setListingsList] = useState(null);
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);

//   useEffect(() => {
//     async function getSubCategoriesDetails() {
//       const data = await getListingsSubCategories(
//         filters.offset,
//         filters.limit,
//         filters.id
//         // filters.status
//       );
//       setListingsList(data);
//       console.log(data, "data");
//     }
//     getSubCategoriesDetails();
//   }, [filters.offset, filters.limit, filters.id]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage,
//     });
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-listing-sub-categories");
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getListingsSubCategories(setListingsList, filters.offset, filters.limit);
//     setShowForm(false);
//   };

//   const handleDelete = async (categoryId) => {
//     try {
//       const response = await deleteSubCategories(categoryId);
//       if (response.success) {
//         alert("Sub Category deleted successfully");
//         getListingsSubCategories(setListingsList, filters.offset, filters.limit);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.error("Error deleting category:", error);
//     }
//   };

  
  

//   const handleStatusChange = (event) => {
//     setFilters((prev) => ({
//       ...prev,
//       offset: 1,
//       status: event.target.value,
//     }));
//   };

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "sub_category_image":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ marginBottom: "10px" }}
//           >
//             <img
//               src={value}
//               alt="categoryimage"
//               style={{
//                 width: "120px",
//                 marginRight: "10px",
//                 borderRadius: "8px",
//               }}
//             />
//           </TableCell>
//         );
//       case "sub_category_name":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ verticalAlign: "top" }}
//           >
//             <Typography variant="subtitle1" marginBottom="5px">
//               {row.sub_category_name}
//             </Typography>
//           </TableCell>
//         );

//       default:
//         return null; // Return null for other columns as we only want image and title/description in each row
//     }
//   };

//   return (
//     <>
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="10px"
//         >
//           <Typography variant="h3" fontWeight="bold">
//             SUB CATEGORIES TABLE
//           </Typography>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>
//         <Box display="flex" marginBottom="10px"></Box>
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>
//               {listingsListData?.info.map((row) => (
//                 <TableRow hover key={row.id}>
//                   {subcategory_colums.map((column) =>
//                     renderContent(row, column)
//                   )}
//                   <TableCell>
//                     <Link to={`/dhunia_bo/edit-sub-categories/${row.id}`}>
//                       <Edit style={{ cursor: "pointer" }} />
//                     </Link>
//                   </TableCell>
//                   <TableCell>
//                     <DeleteIcon onClick={() => handleDelete(row.id)} />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>

//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset}
//             count={listingsListData?.total ? listingsListData.total : 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6" }}
//           />
//         </Paper>
//       </Box>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>
//           {editData ? "Edit sub Categories" : "Create sub Categories"}
//         </DialogTitle>
//         <DialogContent>
//           <SubCategoriesForm onFormSubmit={onFormSubmit} editData={editData} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ListingsSubcategoriesTable;




















// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   MenuItem,
//   IconButton,
//   Delete,
//   EmailOutlined,
//   Select,
// } from "@mui/material";
// import SendIcon from "@mui/icons-material/Send";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { Edit } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { EditSubCategories } from "./edit";

// import { subcategory_colums } from "../../../utils/constants";

// import { getListingsSubCategories, deleteSubCategories } from "./subcategories";
// import SubCategoriesForm from "./form";

// function ListingsSubcategoriesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [listingsListData, setListingsList] = useState(null);
//   const [showForm, setShowForm] = useState(false);
//   const [editData, setEditData] = useState(null);

//   useEffect(() => {
//     async function getSubCategoriesDetails() {
//       const data = await getListingsSubCategories(
//         filters.offset,
//         filters.limit,
//         filters.id
//       );
//       setListingsList(data);
//       console.log(data, "data");
//     }
//     getSubCategoriesDetails();
//   }, [filters.offset, filters.limit, filters.id]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage + 1, // Adjust offset to start from 1
//     });
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/create-listing-sub-categories");
//   };

//   const handleCloseForm = () => {
//     setShowForm(false);
//   };

//   const onFormSubmit = () => {
//     getListingsSubCategories(setListingsList, filters.offset, filters.limit);
//     setShowForm(false);
//   };

//   const handleDelete = async (categoryId) => {
//     try {
//       const response = await deleteSubCategories(categoryId);
//       if (response.success) {
//         alert("Sub Category deleted successfully");
//         getListingsSubCategories(setListingsList, filters.offset, filters.limit);
//       } else {
//         console.error(response.message);
//       }
//     } catch (error) {
//       console.error("Error deleting category:", error);
//     }
//   };

//   const handleStatusChange = (event) => {
//     setFilters((prev) => ({
//       ...prev,
//       offset: 1,
//       status: event.target.value,
//     }));
//   };

//   const renderContent = (row, column) => {
//     const value = row[column.id];
//     switch (column.id) {
//       case "sub_category_image":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ marginBottom: "10px" }}
//           >
//             <img
//               src={value}
//               alt="categoryimage"
//               style={{
//                 width: "120px",
//                 marginRight: "10px",
//                 borderRadius: "8px",
//               }}
//             />
//           </TableCell>
//         );
//       case "sub_category_name":
//         return (
//           <TableCell
//             key={column.id}
//             align={column.align}
//             style={{ verticalAlign: "top" }}
//           >
//             <Typography variant="subtitle1" marginBottom="5px">
//               {row.sub_category_name}
//             </Typography>
//           </TableCell>
//         );
//       default:
//         return null; // Return null for other columns as we only want image and title/description in each row
//     }
//   };

//   return (
//     <>
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           marginBottom="10px"
//         >
//           <Typography variant="h3" fontWeight="bold">
//             SUB CATEGORIES TABLE
//           </Typography>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>
//         <Box display="flex" marginBottom="10px"></Box>
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>
//               {listingsListData?.info.map((row) => (
//                 <TableRow hover key={row.id}>
//                   {subcategory_colums.map((column) =>
//                     renderContent(row, column)
//                   )}
//                   <TableCell>
//                     <Link to={`/dhunia_bo/edit-sub-categories/${row.id}`}>
//                       <Edit style={{ cursor: "pointer" }} />
//                     </Link>
//                   </TableCell>
//                   <TableCell>
//                     <DeleteIcon onClick={() => handleDelete(row.id)} />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>

//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1} // Adjust page to zero-based index
//             count={listingsListData?.total || 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6" }}
//           />
//         </Paper>
//       </Box>
//       <Dialog open={showForm} onClose={handleCloseForm}>
//         <DialogTitle>
//           {editData ? "Edit sub Categories" : "Create sub Categories"}
//         </DialogTitle>
//         <DialogContent>
//           <SubCategoriesForm onFormSubmit={onFormSubmit} editData={editData} />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseForm}>Cancel</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// }

// export default ListingsSubcategoriesTable;



import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableHead,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { EditSubCategories } from "./edit";
import { subcategory_colums } from "../../../utils/constants";
import { getListingsSubCategories, deleteSubCategories } from "./subcategories";
import SubCategoriesForm from "./form";

function ListingsSubcategoriesTable({ filters, setFilters }) {
  const navigate = useNavigate();

  const [listingsListData, setListingsList] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editData, setEditData] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState(null);

  useEffect(() => {
    async function getSubCategoriesDetails() {
      const data = await getListingsSubCategories(
        filters.offset,
        filters.limit,
        filters.id
      );
      setListingsList(data);
      console.log(data, "data");
    }
    getSubCategoriesDetails();
  }, [filters.offset, filters.limit, filters.id]);

  const handlePageChange = (event, newPage) => {
    setFilters({
      ...filters,
      offset: newPage + 1, // Adjust offset to start from 1
    });
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/create-listing-sub-categories");
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const onFormSubmit = () => {
    getListingsSubCategories(setListingsList, filters.offset, filters.limit);
    setShowForm(false);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteSubCategories(deleteCategoryId);
      if (response.success) {
        alert("Sub Category deleted successfully");
        getListingsSubCategories(setListingsList, filters.offset, filters.limit);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const openDeleteDialog = (categoryId) => {
    setDeleteCategoryId(categoryId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteCategoryId(null);
  };

  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.id) {
      case "sub_category_image":
        return (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ marginBottom: "10px" }}
          >
            <img
              src={value}
              alt="categoryimage"
              style={{
                width: "120px",
                marginRight: "10px",
                borderRadius: "8px",
              }}
            />
          </TableCell>
        );
      case "sub_category_name":
        return (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ verticalAlign: "top" }}
          >
            <Typography variant="subtitle1" marginBottom="5px">
              {row.sub_category_name}
            </Typography>
          </TableCell>
        );
      default:
        return null; // Return null for other columns as we only want image and title/description in each row
    }
  };

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Typography variant="h3" fontWeight="bold"
           sx={{
            background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          >
            SUB CATEGORIES TABLE
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </Box>
        <Box display="flex" marginBottom="10px"></Box>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead sx={{backgroundColor : "007effa6"}} font="bold">
              <TableRow sx={{ backgroundColor: "#007effa6" }}>
                {subcategory_colums.map((column) => (
                  <TableCell key={column.id} align={column.align}  
                  
                  style={{
                    backgroundColor: "#007eff",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "10px",
                    textAlign: "left",
                  }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell  style={{
                    backgroundColor: "#007eff",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "10px",
                    textAlign: "left",
                  }}>Edit</TableCell>
                <TableCell  style={{
                    backgroundColor: "#007eff",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: "10px",
                    textAlign: "left",
                  }}>Delete</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {listingsListData?.info.map((row) => (
                <TableRow hover key={row.id}>
                  {subcategory_colums.map((column) =>
                    renderContent(row, column)
                  )}
                  <TableCell>
                    <Link to={`/dhunia_bo/edit-sub-categories/${row.id}`}>
                      <Edit style={{ cursor: "pointer" }} />
                    </Link>
                  </TableCell>
                  <TableCell>
                    <DeleteIcon color="error"  onClick={() => openDeleteDialog(row.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={filters.offset - 1} // Adjust page to zero-based index
            count={listingsListData?.total || 0}
            rowsPerPage={filters.limit}
            style={{ backgroundColor: "#007effa6" }}
          />
        </Paper>
      </Box>
      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>
          {editData ? "Edit sub Categories" : "Create sub Categories"}
        </DialogTitle>
        <DialogContent>
          <SubCategoriesForm onFormSubmit={onFormSubmit} editData={editData} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this sub category?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error" backgroundColor="red">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ListingsSubcategoriesTable;
