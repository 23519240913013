














import axios from 'axios';

export const getWithdrawals = async ( offset = 1, limit = 10) => {
  const userDetails = localStorage.getItem("userDetails");

  if (!userDetails) {
    console.error("User details not found in local storage.");
    return null;
  }

  let userData;
  try {
    userData = JSON.parse(userDetails);
  } catch (error) {
   
    return null;
  }

  if (!userData?.access_token) {
  
    return null;
  }

  const token = `Bearer ${userData.access_token}`;
  

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  };

  const url = `${process.env.REACT_APP_BASEURL}backoffice/withdrawal/users?offset=${offset}&limit=${limit}`;

  try {
    const response = await axios.get(url, { headers });

    if (response?.data?.success) {
      return response.data;
    }
    console.error("Error: Response indicates failure:", response?.data?.description);
    return null;
  } catch (error) {
    console.error("Error fetching withdrawals:", error);
    return null;
  }
};
