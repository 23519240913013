import axios from "axios";
export const getPermissionsData = async (mobile) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}backoffice/user/permissions/assign?mobile=${mobile}`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.status && response?.data?.info?.custom_permissions)
    return response?.data.info;
};
export const setPermissionsData = async (mobile, permissions, roles) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  console.log({
    mobile,
    permissions: permissions,
    roles: roles,
  });
  const response = await axios.put(
    `${process.env.REACT_APP_BASEURL}backoffice/user/permissions/assign`,
    {
      mobile,
      permissions: permissions,
      roles: roles,
    },
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.status && response?.data?.info)
    return response?.data?.info?.custom_permissions;
};
