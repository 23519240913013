import axios from "axios";
export const getReportersData = async (section, next) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  // console.log(section);
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}backoffice/news/reporter?status=${section}`,
    {
      headers,
    }
  );
  // console.log(response);
  if (response?.data?.success) {
    next(response.data.info);
  } else {
    next([]);
  }
};
export const editReporterData = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(
      `${process.env.REACT_APP_BASEURL}backoffice/news/reporter`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const editCategoryData = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(
      `${process.env.REACT_APP_BASEURL}backoffice/news/categories`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
export const updateReporterStatus = async (json_payload) => {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    "Content-Type": "application/json",
    Authorization: token,
  };
  return axios
    .put(
      `${process.env.REACT_APP_BASEURL}backoffice/news/reporter`,
      json_payload,
      {
        headers,
      }
    )
    .then((response) => {
      // handle the response
      // console.log(response);
      return response?.data;
    })
    .catch((error) => {
      // handle errors
      // console.log(error);
    });
};
