// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableHead,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// function StatesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [stateListData, setStateListData] = useState(null);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [stateToDelete, setStateToDelete] = useState(null);

//   useEffect(() => {
//     async function fetchStates() {
//       try {
//         const response = await getStates(
//           filters.offset,
//           filters.limit,
//           filters.id,
//           filters.status
//         );
//         setStateListData(response);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     }
//     fetchStates();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage + 1,
//     });
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-create-form");
//   };

//   const handleDeleteIconClick = (stateId) => {
//     setStateToDelete(stateId);
//     setOpenDelete(true);
//   };

//   const handleDeleteState = async () => {
//     try {
//       await deleteState(stateToDelete);
//       setOpenDelete(false);
//       setStateToDelete(null);
//       const response = await getStates(
//         filters.offset,
//         filters.limit,
//         filters.id,
//         filters.status
//       );
//       setStateListData(response);
//     } catch (error) {
//       console.error("Error deleting state:", error);
//     }
//   };

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//     setStateToDelete(null);
//   };

//   return (
//     <>
//       {/* <Box m="20px">
//         <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="10px">
//           <Typography variant="h3" fontWeight="bold">
//             STATES TABLE
//           </Typography>
//           <Button variant="contained" color="primary" onClick={handleCreateClick}>
//             Create
//           </Button>
//         </Box>
        
//         <Paper sx={{ width: "100%", overflow: "hidden" }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>
//               {stateListData?.info.map((row, index) => (
//                 <TableRow hover key={row.id}>
//                   <TableCell align="center">{index + 1}</TableCell>
//                   <TableCell align="center">
//                     <Typography>{row.state_name}</Typography>
//                   </TableCell>
                 
//                   <TableCell>
//                     <DeleteIcon
//                       onClick={() => handleDeleteIconClick(row.id)}
//                       style={{ margin: "10px", cursor: "pointer" }}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1}
//             count={stateListData?.total || 0}
//             rowsPerPage={filters.limit}
//             style={{ backgroundColor: "#007effa6" }}
//           />
//         </Paper>
//       </Box>

//       <Dialog
//         open={openDelete}
//         onClose={handleCloseDelete}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Are you sure you want to delete this state?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//         <Button
//             onClick={handleCloseDelete}
//             sx={{
//               color: "white",
//               backgroundColor: "#318CE7",
//               "&:hover": {
//                 backgroundColor: "#6CB4EE",
//               },
//             }}
//           >
//             Cancel
//           </Button>
//           <Button
//             onClick={handleDeleteState}
//             sx={{
//               color: "white",
//               backgroundColor: "red",
//               "&:hover": {
//                 backgroundColor: "lightcoral",
//               },
//             }}
//             autoFocus
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog> */}
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           mb="10px"
//         >
//           <Typography variant="h3" fontWeight="bold">
//             STATES TABLE
//           </Typography>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>

//         <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: 3 }}>
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead>
//               <TableRow>
//                 <TableCell align="center">
//                   <strong>#</strong>
//                 </TableCell>
//                 <TableCell align="center">
//                   <strong>State Name</strong>
//                 </TableCell>
//                 <TableCell align="center">
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {stateListData?.info.map((row, index) => (
//                 <TableRow
//                   hover
//                   key={row.id}
//                   sx={{
//                     "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
//                     "&:hover": { backgroundColor: "#e0f7fa" },
//                   }}
//                 >
//                   <TableCell align="center">{index + 1}</TableCell>
//                   <TableCell align="center">
//                     <Typography>{row.state_name}</Typography>
//                   </TableCell>
//                   <TableCell align="center">
//                     <DeleteIcon
//                       onClick={() => handleDeleteIconClick(row.id)}
//                       sx={{
//                         margin: "10px",
//                         cursor: "pointer",
//                         color: "#f50057",
//                         "&:hover": { color: "#ff4081" },
//                       }}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1}
//             count={stateListData?.total || 0}
//             rowsPerPage={filters.limit}
//             sx={{ backgroundColor: "#007effa6", color: "white" }}
//           />
//         </Paper>

//         <Dialog
//           open={openDelete}
//           onClose={handleCloseDelete}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="alert-dialog-title">
//             {"Confirm Deletion"}
//           </DialogTitle>
//           <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//               Are you sure you want to delete this state?
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button
//               onClick={handleCloseDelete}
//               sx={{
//                 color: "white",
//                 backgroundColor: "#318CE7",
//                 "&:hover": {
//                   backgroundColor: "#6CB4EE",
//                 },
//               }}
//             >
//               Cancel
//             </Button>
//             <Button
//               onClick={handleDeleteState}
//               sx={{
//                 color: "white",
//                 backgroundColor: "red",
//                 "&:hover": {
//                   backgroundColor: "lightcoral",
//                 },
//               }}
//               autoFocus
//             >
//               Delete
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </>
//   );
// }

// async function getStates(offset = 1, limit = 10, id, status = null) {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;
//   const headers = {
//     Authorization: token,
//   };
//   const response = await axios.get(
//     `${process.env.REACT_APP_BASEURL}userservice/location/states`,
//     {
//       params: { offset, limit, id, status },
//       headers,
//     }
//   );
//   return response.data;
// }

// async function deleteState(stateId) {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;
//   const headers = {
//     Authorization: token,
//   };
//   await axios.delete(
//     `${process.env.REACT_APP_BASEURL}userservice/location/states/${stateId}`,
//     {
//       headers,
//     }
//   );
// }

// export default StatesTable;


//-----------------------------------------------------------------------------------



// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Button,
//   Typography,
//   Paper,
//   Table,
//   TableBody,
//   TableHead,
//   TableCell,
//   TablePagination,
//   TableRow,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// function StatesTable({ filters, setFilters }) {
//   const navigate = useNavigate();

//   const [stateListData, setStateListData] = useState(null);
//   const [openDelete, setOpenDelete] = useState(false);
//   const [stateToDelete, setStateToDelete] = useState(null);

//   useEffect(() => {
//     async function fetchStates() {
//       try {
//         const response = await getStates(
//           filters.offset,
//           filters.limit,
//           filters.id,
//           filters.status
//         );
//         setStateListData(response);
//       } catch (error) {
//         console.error("Error fetching states:", error);
//       }
//     }
//     fetchStates();
//   }, [filters]);

//   const handlePageChange = (event, newPage) => {
//     setFilters({
//       ...filters,
//       offset: newPage + 1,
//     });
//   };

//   const handleCreateClick = () => {
//     navigate("/dhunia_bo/locations-create-form");
//   };

//   const handleDeleteIconClick = (stateId) => {
//     setStateToDelete(stateId);
//     setOpenDelete(true);
//   };

//   const handleDeleteState = async () => {
//     try {
//       await deleteState(stateToDelete);
//       setOpenDelete(false);
//       setStateToDelete(null);
//       const response = await getStates(
//         filters.offset,
//         filters.limit,
//         filters.id,
//         filters.status
//       );
//       setStateListData(response);
//     } catch (error) {
//       console.error("Error deleting state:", error);
//     }
//   };

//   const handleCloseDelete = () => {
//     setOpenDelete(false);
//     setStateToDelete(null);
//   };

//   return (
//     <>
//       <Box m="20px">
//         <Box
//           display="flex"
//           justifyContent="space-between"
//           alignItems="center"
//           mb="10px"
//         >
//           <Typography variant="h3" fontWeight="bold">
//             STATES TABLE
//           </Typography>
//           <Button
//             variant="contained"
//             style={{ backgroundColor: '#007effa6', color: 'white' }}
//             onClick={handleCreateClick}
//           >
//             Create
//           </Button>
//         </Box>

//         <Paper
//           sx={{
//             width: "100%",
//             overflow: "hidden",
//             boxShadow: 3,
//             borderRadius: "15px",
//             backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
         
//           }}
//         >
//           <Table stickyHeader aria-label="sticky table" 
//              sx={{
//               '& .MuiTableCell-root': {
//                 border: '1px solid #e0e0e0',
//                 padding: '8px',
//               },
//             }}
//             >
//             <TableHead  >
//               <TableRow   sx={{ backgroundColor: "#007effa6",  '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>#</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>State Name</strong>
//                 </TableCell>
//                 <TableCell align="center"
//                  sx={{ backgroundColor: "#007effa6", color: "black" }}
//                  >
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {stateListData?.info.map((row, index) => (
//                 <TableRow
//                   hover
//                   key={row.id}
//                   sx={{
//                     '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
//                     '&:hover': { backgroundColor: '#e0f7fa' },
//                   }}
//                 >
//                   <TableCell align="center">{index + 1}</TableCell>
//                   <TableCell align="center">
//                     <Typography>{row.state_name}</Typography>
//                   </TableCell>
//                   <TableCell align="center">
//                     <DeleteIcon
//                       onClick={() => handleDeleteIconClick(row.id)}
//                       sx={{
//                         margin: "10px",
                        
//                         cursor: "pointer",
//                         color: "#f50057",
//                         "&:hover": { color: "#ff4081" },
//                       }}
//                     />
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//           <TablePagination
//             component="div"
//             onPageChange={handlePageChange}
//             page={filters.offset - 1}
//             count={stateListData?.total || 0}
//             rowsPerPage={filters.limit}
//             sx={{ backgroundColor: "#007effa6", color: "black" }}
//           />
//         </Paper>

//         <Dialog
//           open={openDelete}
//           onClose={handleCloseDelete}
//           aria-labelledby="alert-dialog-title"
//           aria-describedby="alert-dialog-description"
//         >
//           <DialogTitle id="alert-dialog-title">
//             {"Confirm Deletion"}
//           </DialogTitle>
//           <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//               Are you sure you want to delete this state?
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button
//               onClick={handleCloseDelete}
//               sx={{
//                 color: "white",
//                 backgroundColor: "#318CE7",
//                 "&:hover": {
//                   backgroundColor: "#6CB4EE",
//                 },
//               }}
//             >
//               Cancel
//             </Button>
//             <Button
//               onClick={handleDeleteState}
//               sx={{
//                 color: "white",
//                 backgroundColor: "red",
//                 "&:hover": {
//                   backgroundColor: "lightcoral",
//                 },
//               }}
//               autoFocus
//             >
//               Delete
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Box>
//     </>
//   );
// }

// async function getStates(offset = 1, limit = 10, id, status = null) {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;
//   const headers = {
//     Authorization: token,
//   };
//   const response = await axios.get(
//     `${process.env.REACT_APP_BASEURL}userservice/location/states`,
//     {
//       params: { offset, limit, id, status },
//       headers,
//     }
//   );
//   return response.data;
// }

// async function deleteState(stateId) {
//   const userDetails = localStorage.getItem("userDetails");
//   const userData = JSON.parse(userDetails);
//   const token = `Bearer ${userData?.access_token}`;
//   const headers = {
//     Authorization: token,
//   };
//   await axios.delete(
//     `${process.env.REACT_APP_BASEURL}userservice/location/states/${stateId}`,
//     {
//       headers,
//     }
//   );
// }

// export default StatesTable;






import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function StatesTable({ filters, setFilters }) {
  const navigate = useNavigate();

  const [stateListData, setStateListData] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [stateToDelete, setStateToDelete] = useState(null);

  useEffect(() => {
    async function fetchStates() {
      try {
        const response = await getStates(
          filters.offset,
          filters.limit,
          filters.id,
          filters.status
        );
        setStateListData(response);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    }
    fetchStates();
  }, [filters]);

  const handlePageChange = (event, newPage) => {
    setFilters({
      ...filters,
      offset: newPage + 1,
    });
  };

  const handleCreateClick = () => {
    navigate("/dhunia_bo/locations-create-form");
  };

  const handleDeleteIconClick = (stateId) => {
    setStateToDelete(stateId);
    setOpenDelete(true);
  };

  const handleDeleteState = async () => {
    try {
      await deleteState(stateToDelete);
      setOpenDelete(false);
      setStateToDelete(null);
      const response = await getStates(
        filters.offset,
        filters.limit,
        filters.id,
        filters.status
      );
      setStateListData(response);
    } catch (error) {
      console.error("Error deleting state:", error);
    }
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setStateToDelete(null);
  };

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="10px"
        >
          <Typography variant="h3" fontWeight="bold"
           sx={{
            background: 'linear-gradient(to right, #30CFD0 0%, #330867 100%)', // Gradient color
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
          >
            STATES TABLE
          </Typography>
          <Button
            variant="contained"
            style={{ backgroundColor: '#007effa6', color: 'white' }}
            onClick={handleCreateClick}
          >
            Create
          </Button>
        </Box>

        <Paper
          sx={{
            width: "100%",
            overflow: "hidden",
            boxShadow: 3,
            borderRadius: "15px",
            backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              '& .MuiTableCell-root': {
                border: '1px solid #e0e0e0',
                padding: '8px',
              },
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#007effa6",
                  '& .MuiTableCell-root': { fontWeight: 'bold' },
                }}
              >
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>#</strong>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>State Name</strong>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ backgroundColor: "#007effa6", color: "black" }}
                >
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stateListData?.info.map((row, index) => (
                <TableRow
                  hover
                  key={row.id}
                  sx={{
                    '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                    '&:hover': { backgroundColor: '#e0f7fa' },
                  }}
                >
                  <TableCell align="center">
                    {(filters.offset - 1) * filters.limit + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{row.state_name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <DeleteIcon
                      onClick={() => handleDeleteIconClick(row.id)}
                      sx={{
                        margin: "10px",
                        cursor: "pointer",
                        color: "#f50057",
                        "&:hover": { color: "#ff4081" },
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            onPageChange={handlePageChange}
            page={filters.offset - 1}
            count={stateListData?.total || 0}
            rowsPerPage={filters.limit}
            sx={{ backgroundColor: "#007effa6", color: "black" }}
          />
        </Paper>

        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Deletion"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this state?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDelete}
              sx={{
                color: "white",
                backgroundColor: "#318CE7",
                "&:hover": {
                  backgroundColor: "#6CB4EE",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteState}
              sx={{
                color: "white",
                backgroundColor: "red",
                "&:hover": {
                  backgroundColor: "lightcoral",
                },
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

async function getStates(offset = 1, limit = 10, id, status = null) {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  const response = await axios.get(
    `${process.env.REACT_APP_BASEURL}userservice/location/states`,
    {
      params: { offset, limit, id, status },
      headers,
    }
  );
  return response.data;
}

async function deleteState(stateId) {
  const userDetails = localStorage.getItem("userDetails");
  const userData = JSON.parse(userDetails);
  const token = `Bearer ${userData?.access_token}`;
  const headers = {
    Authorization: token,
  };
  await axios.delete(
    `${process.env.REACT_APP_BASEURL}userservice/location/states/${stateId}`,
    {
      headers,
    }
  );
}

export default StatesTable;
