import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import {
  updateListingsForm,
  UploadFiles,
  getListingsCategories,
} from "./listingscategories";

const EditListingCategories = ({ onFormSubmit }) => {
  const params = useParams();
  const { id } = params;

  const [formData, setFormData] = useState({
    category_name: "",
    category_description: "",
  });
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getData = async () => {
      let data = await getListingsCategories(1, 10, id);
      data = data.info[0];
      setFormData({
        category_id: data?.id,
        category_name: data?.category_name,
        category_description: data?.category_description,
      });
      setImages(data?.category_image ? [data?.category_image] : []);
    };
    getData();
  }, []);

  const navigate = useNavigate();

  const handleRemoveImage = (indexToRemove) => {
    setImages(images.filter((_, index) => index !== indexToRemove));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages([...selectedImages]);
  };

  const resetForm = () => {
    setFormData({
      category_id: id,
      category_name: "",
      category_description: "",
    });
    setImages([]);
  };
  const UpdateAndSaveCategory = async (data, images) => {
    try {
      const newImages = images.filter((item) => typeof item === "object");
      const oldImages = images.filter((item) => typeof item === "string");

      // Upload new images
      let imagesResponse = { success: true, info: [oldImages] };
      if (newImages.length > 0) {
        const imagesFormData = new FormData();
        newImages.forEach((file, index) => {
          imagesFormData.append(`files`, file);
        });
        imagesFormData.append("entity", "categories");
        imagesResponse = await UploadFiles(imagesFormData);
      }

      if (imagesResponse.success) {
        data.category_image = imagesResponse.info ? imagesResponse.info[0] : null;
        const formResponse = await updateListingsForm(data);
        return formResponse;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await UpdateAndSaveCategory(formData, images);
      if (apiResponse && apiResponse.success) {
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/listings-categories"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  const handleCancel = () => {
    setFormData({
      category_id: id,
      category_name: "",
      category_description: "",
    });
    setImages([]);
    navigate("/dhunia_bo/listings-categories");
  };
  return (
    <Box
      marginRight="20px"
      marginLeft="20px"
      marginBottom="20px"
      height="600px"
    >
      <Typography variant="h5" fontWeight="bold" marginBottom="20px">
      EDIT CATEGORY
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="srOnly" fontWeight="bold">
            Category Name :
          </Typography>
          <TextField
            fullWidth
            aria-label="Category Name"
            variant="outlined"
            name="category_name"
            value={formData.category_name}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
            required
          />
        </Box>

        <Box>
          <Typography variant="srOnly" fontWeight="bold">
            Description :
          </Typography>
          <TextField
            fullWidth
            aria-label="Description"
            variant="outlined"
            name="category_description"
            value={formData.category_description}
            onChange={handleChange}
            margin="normal"
            multiline
            rows={4}
            required
          />
        </Box>

        <Box marginBottom="20px" marginTop="20px">
          <input
            type="file"
            id="category_image"
            name="category_image"
            accept="image/*"
            onChange={handleImageChange}
          />
          {images.length > 0 && (
            <ul>
              {images.map((image, index) => (
                <li key={index}>
                  <img
                    src={
                      typeof image === "object"
                        ? URL.createObjectURL(image)
                        : image
                    }
                    alt={`Preview ${index}`}
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                  <Button onClick={() => handleRemoveImage(index)}>
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          )}
        </Box>

        <Box marginTop="55px" display="flex" justifyContent="flex-end">
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            style={{ marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default EditListingCategories;
