import * as React from "react";
import { Box, useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { tokens } from "../../../theme";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Moment from "react-moment";
// import EditUpload from "./editUpload";
import { getUploadData } from "../../../services/ott_upload";
import { upload_columns } from "../../../utils/constants";
// import DeleteUpload from "./DeleteUpload";

export default function Uploads({ source }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [uploadsList, setUploadList] = useState([]);
  const [uploadPayload, setUploadPayload] = useState({});
  const [openEdit, setEditOpen] = useState(false);
  const [openDelete, setDeleteOpen] = useState(false);

  useEffect(() => {
    // console.log(source);
    getUploadData(setUploadList, source);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatusChange = (event) => {
    // console.log("clicked: ", event);
  };
  /**
   * The function `handleEditUpload` filters the `uploadsList` array to find the upload with the
   * matching `id` as the `event.target.id`, and if found, sets the `uploadPayload` state to the
   * selected upload and opens the edit modal.
   * @returns nothing (undefined) if the condition `!event.target.id` is true.
   */
  const handleEditUpload = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedUpload = uploadsList.filter((upload) => {
      if (upload.id.toString() === event.target.id) {
        return true;
      }
    });
    if (selectedUpload) {
      setUploadPayload(selectedUpload[0]);
      setEditOpen(true);
    }
  };

  const handleDeleteUpload = (event) => {
    if (!event.target.id) {
      return;
    }
    const selectedUpload = uploadsList.filter((upload) => {
      if (upload.id.toString() === event.target.id) {
        return true;
      }
    });
    if (selectedUpload) {
      setUploadPayload(selectedUpload[0]);
      setDeleteOpen(true);
    }
  };

  const renderContent = (row, column) => {
    const value = row[column.id];
    switch (column.format) {
      case "image":
        return <img src={value} style={{ width: 50, height: 50 }} />;
      case "boolean":
        return (
          <Switch
            id={row.id.toString()}
            checked={value}
            onChange={handleStatusChange}
          />
        );
      case "actions":
        return (
          <>
            <ModeIcon
              id={row.id.toString()}
              onClick={handleEditUpload}
              style={{ margin: "10px" }}
            />
            <DeleteForeverIcon
              id={row.id.toString()}
              onClick={handleDeleteUpload}
              style={{ margin: "10px" }}
            />
          </>
        );
      case "Date":
        return <Moment format="YYYY/MM/DD hh:mm ">{value}</Moment>;
      case "array":
        return value ? value.join(",") : "NA";
      default:
        return value;
    }
  };

  return (
    <>
      <Box
        m="20px"
        sx={{
          "& .MuiTableContainer-root": {
            border: "none",
          },
          "& .MuiTableContainer-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiToolbar-root": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiTableHead-root .MuiTableCell-root": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {upload_columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uploadsList &&
                  uploadsList
                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover key={row.id}>
                          {upload_columns.map((column) => {
                            // const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {renderContent(row, column)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={uploadsList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      {/* {openEdit && (
        <EditUpload
          openEdit={openEdit}
          setEditOpen={setEditOpen}
          uploadPayload={uploadPayload}
          getUploadData={getUploadData}
          setUploadList={setUploadList}
        />
      )}
      {openDelete && (
        <DeleteUpload
          openDelete={openDelete}
          setDeleteOpen={setDeleteOpen}
          uploadPayload={uploadPayload}
          getUploadData={getUploadData}
          setUploadList={setUploadList}
        />
      )} */}
    </>
  );
}
