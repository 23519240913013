import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { createStates } from "./state";

const StatesForm = ({ onFormSubmit }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    state_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormData({
      state_name: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiResponse = await createStates(formData);
      // console.log("apiResponse", apiResponse);
      if (apiResponse && apiResponse.success) {
        // console.log("bbb", apiResponse.message);
        alert("Data submitted successfully");
        onFormSubmit(navigate("/dhunia_bo/locations-states"));
        resetForm();
      } else {
        const errorMessage =
          apiResponse && apiResponse.message
            ? apiResponse.message
            : "Unknown error occurred";
        console.error(errorMessage);
        alert("Error in Submitting the data");
      }
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };
  const handleCancel = () => {
    setFormData({
      state_name: "",
    });

    navigate("/dhunia_bo/locations-states");
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: "#A7E6FF" }}
    >
      <Box
        padding="20px"
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          maxWidth: "600px",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          fontWeight="bold"
          marginBottom="20px"
          align="center"
          color="#007eff"
        >
          ADD STATE
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="State"
            variant="outlined"
            name="state_name"
            value={formData.state_name}
            onChange={handleChange}
            margin="normal"
            required
          />

          <Box display="flex" justifyContent="flex-end" gap={2} paddingTop="20px">
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Submit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default StatesForm;
