import { styled, Button } from "@mui/material";
import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { uploadImages } from "../../../services/jobs";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ImageUploader = ({
  file,
  setFile,
  thumbnailFiles,
  setThumbnailFiles,
}) => {
  async function uploadSingleFile(e) {
    e.preventDefault();
    const formData = new FormData();
    for (const element of e.target.files) {
      formData.append("files", element);
    }
    formData.append("entity", "classifieds");
    const uploadResponse = await uploadImages(formData);
    if (uploadResponse) {
      let ImagesArray = uploadResponse?.info;
      // console.log("ImagesArray", ImagesArray);
      // console.log("thumbnailFiles", thumbnailFiles);
      file ? setFile([...file, ...ImagesArray]) : setFile([...ImagesArray]);
      setThumbnailFiles(e.target.files);
      // setLogoSelectedFile(event.target.files[0]);
      // // console.log("uploadResponse", uploadResponse);
      // setLogoFile(uploadResponse?.info[0]);
    }
    // let ImagesArray = Object.entries(e.target.files).map((e) =>
    //   URL.createObjectURL(e[1])
    // );
    // // console.log("ImagesArray", ImagesArray);
    // // console.log("thumbnailFiles", thumbnailFiles);
    // file ? setFile([...file, ...ImagesArray]) : setFile([...ImagesArray]);
    // setThumbnailFiles(e.target.files);
  }
  // function upload(e) {
  //   e.preventDefault();
  //   // // console.log(file);
  //   // // console.log(thumbnailFiles);
  //   let fileList = [];
  //   for (var i = 0; i < thumbnailFiles.length; i++) {
  //     fileList.push(thumbnailFiles[i]);
  //   }
  //   const formData = new FormData();
  //   for (var i = 0; i < thumbnailFiles.length; i++) {
  //     formData.append("files", thumbnailFiles[i]);
  //   }
  //   formData.append("entity", "classifieds");
  //   const token =
  //     `Bearer ${userDetails?.access_token}`;
  //   const headers = {
  //     Authorization: token,
  //   };
  //   axios
  //     .post("http://15.206.8.225:8001/userservice/store/files", formData, {
  //       headers,
  //     })
  //     .then((response) => {
  //       // handle the response
  //       // console.log(response);
  //     })
  //     .catch((error) => {
  //       // handle errors
  //       // console.log(error);
  //     });
  // }
  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }
  return (
    <form>
      <div className="form-group preview" style={{ display: "flex" }}>
        {file &&
          file.length > 0 &&
          file.map((item, index) => {
            return (
              <div key={item}>
                <img src={item} alt="" height={100} width={100} />
                <button type="button" onClick={() => deleteFile(index)}>
                  delete
                </button>
              </div>
            );
          })}
      </div>
      <div className="form-group">
        <DialogContentText sx={{ mb: 1 }}>
          Upload Thumbnail images.
        </DialogContentText>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            onChange={uploadSingleFile}
            multiple
          />
        </Button>
        {/* <input
          type="file"
          disabled={file && file.length === 5}
          className="form-control"
          onChange={uploadSingleFile}
          multiple
        /> */}
      </div>
      {/* <button
        type="button"
        className="btn btn-primary btn-block"
        onClick={upload}
      >
        Upload
      </button> */}
    </form>
  );
};
export default ImageUploader;
